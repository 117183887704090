<template>
  <div class="box">
    <h1 class="subtitle is-5">Organizaciones</h1>
    <h1 class="title is-1">Nueva organización</h1>
    <div class="content">
      <p>Ingrese los datos de la organización a registrar. Una vez que finalizó de cargar los datos, presione el botón <span class="tag is-primary">Guardar</span></p>
    </div>
    <hr>
    <formulario :model="model" :error="error" v-on:submit="submit"></formulario>
  </div>
</template>

<script>
// import router from '@/router'
import Formulario from '@/components/organizacion/Formulario'

export default {
  props: ['id'],
  components: {
    Formulario
  },
  data () {
    return {
      model: {
        razon_social: '',
        cuit: '',
        slug: '',
        direccion: '',
        telefono: '',
        email: ''
      },
      error: ''
    }
  },
  methods: {
    submit () {
      const data = {}
      const valid = true
      for (const prop in this.model) {
        if (Object.prototype.hasOwnProperty.call(this.model, prop)) {
          if (this.model[prop]) {
            data[prop] = this.model[prop]
          }
        }
      }

      // let valid = validate(data);
      console.log(data)
      if (!valid) {
        // console.log(validate.errors);
      } else {
        this.$http
          .request({
            method: 'post',
            url: '/organizacion',
            data: data
          })
          .then(response => {
            this.$router.push({ name: 'adminOrganizacionesListado' })
            this.$buefy.snackbar.open({
              message: 'Organización creada exitosamente',
              type: 'is-success'
            })
          })
          .catch(error => {
            this.error = error.mensaje
            console.log(error)
          })
      }
    }
  }
}
</script>
