<template>
  <section>
    <div class="box">
      <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="true">
        <b-icon pack="fas" icon="sync-alt" size="is-large" custom-class="fa-spin"></b-icon>
      </b-loading>

      <h1 class="subtitle is-4" v-if="this.$route.query.fromOR">Oficial de Registro</h1>
      <h1 class="subtitle is-4" v-else>Mis certificados</h1>
      <h1 class="title is-3">Revocar certificado</h1>

      <div class="content" v-if="this.$route.query.fromOR">
        <p>
          Puede revocar el certificado del usuario seleccionando la razón correspondiente y
          agregar un comentario adicional si lo considera necesario.
        </p>
      </div>
      <div class="content" v-else>
        <p>
          Puede revocar su certificado seleccionando la razón correspondiente y
          agregar un comentario adicional si lo considera necesario.
        </p>
      </div>
    </div>

    <div class="box">
      <b-field label="Razón de la revocación">
        <b-dropdown
          v-model="reasonSelected"
          :disabled="!existCertToRevoke"
          aria-role="list"
          >
          <template #trigger>
            <b-button icon-right="caret-down">
              {{ reasonSelected ? reasonSelected.mensaje : 'Seleccionar' }}
            </b-button>
          </template>
          <b-dropdown-item :value="label" v-for="(label,i) in reasons" :key="`label-${i}`" aria-role="listitem">
            <span>{{ label.mensaje }}</span>
          </b-dropdown-item>
        </b-dropdown>
      </b-field>

      <b-field label="Comentario adicional (opcional)" class="mt-5">
        <textarea class="textarea" v-model="comment" placeholder="Ingresar comentario adicional"
          :disabled="!existCertToRevoke">
        </textarea>
      </b-field>

      <b-notification type="is-warning is-light mt-5" has-icon :closable="false" v-if="this.$route.query.fromOR">
        <i class="fas fa-info mr-4"></i>
        Al revocar el certificado del usuario, este quedará inutilizable.
      </b-notification>
      <b-notification type="is-warning is-light mt-5" has-icon :closable="false" v-else>
        <i class="fas fa-info mr-4"></i>
        Al revocar su certificado, este quedará inutilizable.
        Deberá iniciar una nueva solicitud para obtener un nuevo certificado.
      </b-notification>

      <div class="buttons is-right mt-6">
        <b-button class="is-primary" @click="openConfirmDialog" :disabled="!reasonSelected">
          Continuar
        </b-button>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  props: ['idCert'],
  data () {
    return {
      isLoading: false,
      reasons: [],
      reasonSelected: null,
      comment: null
    }
  },
  beforeMount: function () {
    this.isLoading = true
    if (!this.existCertToRevoke) {
      this.$buefy.toast.open({
        duration: 3000,
        message: 'No has seleccionado ningun certificado para revocar',
        type: 'is-danger'
      })
      this.isLoading = false
    } else {
      const tipo = this.$route.query.fromOR ? 'or' : 'usuario'
      this.$http
        .get('/razon-revocacion', { params: { tipo: tipo } })
        .then(res => {
          this.reasons = res.data
        })
        .catch(() => {
          this.$buefy.snackbar.open({
            message: 'Error al obtener las razones de revocación',
            type: 'is-danger'
          })
        })
        .finally(() => {
          this.isLoading = false
        })
    }
  },
  methods: {
    openConfirmDialog () {
      if (this.reasonSelected) {
        this.$buefy.dialog.confirm({
          title: 'Confirmar revocación',
          message: '<p>La <b>revocación</b> del certificado es irreversible',
          confirmText: 'Revocar',
          type: 'is-danger',
          hasIcon: true,
          onConfirm: () => { this.revokeCert() }
        })
      } else {
        this.$buefy.toast.open({
          duration: 3000,
          message: 'Debes ingresar una razón',
          type: 'is-danger'
        })
      }
    },
    revokeCert () {
      this.isLoading = true
      var requestBody = {
        razon: this.reasonSelected.id,
        comentario: this.comment
      }
      this.$http
        .post(`/certificado/${this.idCert}/revocar`, requestBody)
        .then(() => {
          this.$buefy.snackbar.open({
            message: 'Certificado revocado exitosamente',
            type: 'is-success'
          })
          this.$router.go(-1)
        })
        .catch(() => {
          this.$buefy.snackbar.open({
            message: 'Ocurrió un error al revocar el certificado',
            type: 'is-danger'
          })
        })
        .finally(() => {
          this.isLoading = false
        })
    }
  },
  computed: {
    existCertToRevoke: function () {
      return this.idCert
    }
  }
}
</script>
