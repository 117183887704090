<template>
  <div class="modal-card">
    <div class="modal-card-body">
            <h1 class="title is-4">Agregar nuevos metadatos</h1>
      <div class="field has-addons">
        <div class="control">
          <button @click="prevOption" class="button"><i class="fas fa-arrow-left"></i></button>
        </div>
        <div class="control is-expanded">
          <div class="select is-fullwidth">
             <select name="aspecto" v-model="aspectoSeleccionadoId">
                <option :value="aspecto.id" v-for="aspecto in aspectosDisponibles" :key="aspecto.id">{{aspecto.nombre}}</option>
              </select>
          </div>
        </div>
        <div class="control">
          <button @click="nextOption" class="button"><i class="fas fa-arrow-right"></i></button>
        </div>
      </div>
      <metadatos
        ref="metadatosForm"
        :key="`meta-form-aspect-${aspectoSeleccionado.id}`"
        :force-edit="true"
        :hide-save-button="true"
        v-if="aspectoSeleccionado"
        :aspecto="aspectoSeleccionado"
        :can-edit="true"
        :bordered="true"
        :is-on-modal="true"
      />
      <div class="buttons is-right">
        <a @click="$parent.close()" class="button is-light"> Cancelar</a>
        <a @click="saveWithoutMetadatos" class="button is-light">
          <i class="fas fa-plus"></i>&nbsp;Agregar sin metadatos
        </a>
        <a @click="saveWithMetadatos" class="button is-primary">
          <i class="fas fa-plus"></i>&nbsp;Guardar con metadatos
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import Metadatos from '@/components/globals/aspectos/Metadatos'
export default {
  props: ['aspectos', 'aspectosDocumentos'],
  data () {
    return {
      aspectoSeleccionadoId: null
    }
  },
  components: {
    Metadatos
  },
  methods: {
    reload: function () {
      this.$emit('reload')
      this.$parent.close()
    },
    nextOption: function () {
      const index = this.aspectosDisponiblesIds.findIndex(a => a === this.aspectoSeleccionadoId) + 1
      console.log(index)
      console.log(this.aspectosDisponiblesIds.length)
      this.aspectoSeleccionadoId = this.aspectosDisponiblesIds[index % this.aspectosDisponiblesIds.length]
    },
    prevOption: function () {
      const index = this.aspectosDisponiblesIds.findIndex(a => a === this.aspectoSeleccionadoId) - 1
      console.log(index)
      console.log(this.aspectosDisponiblesIds.length)
      this.aspectoSeleccionadoId = index < 0 ? this.aspectosDisponiblesIds[this.aspectosDisponiblesIds.length - 1] : this.aspectosDisponiblesIds[index % this.aspectosDisponiblesIds.length]
    },
    saveWithoutMetadatos: function () {
      this.$emit('save-without-metadatos', this.aspectoSeleccionadoId)
      this.$parent.close()
    },
    saveWithMetadatos: function () {
      this.$refs.metadatosForm.getPayload()
        .then(metadatos => {
          this.$emit('save-with-metadatos', this.aspectoSeleccionadoId, metadatos)
          this.$parent.close()
        })
        .catch(err => {
          console.log(err)
          this.$buefy.snackbar.open({
            message: 'Error, revisé los campos de los atributos',
            type: 'is-danger'
          })
        })
    }
  },
  mounted () {
    if (this.aspectosDisponiblesIds.length) this.aspectoSeleccionadoId = this.aspectosDisponiblesIds[0]
  },
  computed: {
    aspectosDisponibles: function () {
      if (!this.aspectos) return []
      const idsAspectosSerie = this.aspectosDocumentos.map(a => a.id)
      const arr = this.aspectos.filter(a => !idsAspectosSerie.includes(a.id))
      return arr
    },
    aspectosDisponiblesIds: function () {
      return this.aspectosDisponibles.map(a => a.id)
    },
    aspectoSeleccionado: function () {
      if (!this.aspectoSeleccionadoId) return null
      return this.aspectosDisponibles.find(a => a.id === this.aspectoSeleccionadoId)
    }
  }
}
</script>
