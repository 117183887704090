<template>
  <div class="card">
    <header class="card-header">
      <p class="card-header-title">Subiendo documentos</p>
    </header>
    <section class="card-content">
      <p>La carpeta ya contiene documentos con los siguientes nombres. ¿Qué acción desea realizar?</p>
      <div class="column my-2">
        <table class="table is-narrow is-fullwidth">
          <thead>
            <th>Nombre</th>
            <th width="45">
              <b-tooltip label="Reemplazar documento existente" position="is-left">
                <i class="fas fa-file"></i>&nbsp;<i class="fas fa-sync-alt"></i>
              </b-tooltip>
            </th>
            <th width="50">
              <b-checkbox @input="selectAll"></b-checkbox>
            </th>
          </thead>
          <tbody>
            <tr v-for="(file,i) in files" :key="`file-${i}`">
              <td>
                <p>{{file}}</p>
              </td>
              <td width="45"></td>
              <td width="50">
                <b-checkbox v-model="toReplace" :native-value="file"></b-checkbox>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <b-notification type="is-warning is-light" :closable="false">
        <i class="fas fa-exclamation-triangle mr-2"></i>
        Al reemplazar un documento, este se borrará definitivamente.
      </b-notification>
    </section>
    <footer class="card-footer">
      <a class="card-footer-item" type="button" @click="cancel">Cancelar</a>
      <a class="card-footer-item has-text-weight-bold is-uppercase" @click.prevent="submit" :class="{ disabled: toReplace.length < 1 }">
        Reemplazar
      </a>
    </footer>
  </div>
</template>

<script>
export default {
  props: ['existingFiles'],
  data () {
    return { files: [], toReplace: [] }
  },
  beforeMount: function () {
    this.files = [...this.existingFiles]
  },
  methods: {
    submit: function () {
      if (this.toReplace.length > 0) {
        this.$emit('replace', this.toReplace)
        this.$emit('close')
      }
    },
    cancel: function () {
      this.$emit('cancel')
      this.$emit('close')
    },
    selectAll: function (checked) {
      this.toReplace = checked ? [...this.files] : []
    }
  }
}
</script>

<style lang="scss" scoped>
.disabled {
  cursor: not-allowed;
  color: gray
}
</style>
