<template>
  <b-sidebar
    type="is-white"
    :overlay="overlay"
    :fullheight="fullheight"
    :fullwidth="fullwidth"
    :can-cancel="false"
    :right="right"
    @close="close"
    v-model="showSidebar"
  >
    <div class="file-previewer-container" :class="{'no-fullwidth': !fullwidth }">
        <div class="row-container has-background-primary-dark">
          <div class="first-row py-3 px-5">
            <div class="is-pulled-right ml-2 mb-2">
              <a @click="close" class="has-text-white"
                ><i class="fas fa-times fa-lg"></i
              ></a>
            </div>
            <h3 class="subtitle is-7 has-text-white">Previsualizar archivo</h3>
            <h3 class="title is-6 has-text-white" v-if="previewedFile">{{ previewedFile.nombre }}</h3>
          </div>
          <div v-if="loadingFile" class="second-row has-text-centered" >
            <i class="fas fa-sync fa-lg fa-spin mt-6 has-text-primary"></i>
          </div>
          <iframe :src="fileUrl" v-else frameborder="0" class="second-row"></iframe>
      </div>
    </div>
  </b-sidebar>
</template>

<script>
export default {
  data () {
    return {
      previewedFile: null,
      fileUrl: null,
      showSidebar: false,
      overlay: true,
      fullheight: true,
      fullwidth: false,
      loadingFile: false,
      right: true
    }
  },
  created () {
    this.$eventHub.$on('openFilePreview', this.openFilePreview)
  },
  methods: {
    open: function () {
      this.showSidebar = true
    },
    toggle: function () {
      this.showSidebar = !this.showSidebar
    },
    close: function () {
      this.showSidebar = false
    },
    openFilePreview (file) {
      this.loadingFile = true
      if (!file) {
        this.$buefy.snackbar.open({
          message:
            'No hay archivo para previsualizar',
          type: 'is-danger'
        })
      }
      if (this.previewedFile && this.previewedFile.id === file.id) {
        this.loadingFile = false
        this.open()
      } else {
        this.previewedFile = file
        this.open()
        const query = file.tipo_documento_id === 'recibo' ? '?v=recibo' : ''
        this.$http
          .get(`/documento/${file.id}/descarga${query}`)
          .then(response => {
            this.fileUrl = `${response.data.link}?inline=true`
          })
          .then(() => {
            this.loadingFile = false
          })
          .catch(error => {
            console.error('Fail', error)
            this.$buefy.snackbar.open({
              message:
                'Error de conexión. Compruebe la conectividad con internet.',
              type: 'is-danger'
            })
          })
      }
    },
    closeFilePreview: function () {
      this.fileUrl = null
      this.fileUrl = null
      this.open = false
    },
    download: function () {
      window.open(this.fileUrl, '_blank')
    }
  }
}
</script>

<style lang="scss">
.file-previewer-container {
  width: 100vw;
  height: 100%;
  &.no-fullwidth{
    @media screen and (min-width: $tablet){
      width: 50vw;
    }
  }
}
.row-container {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
}
.second-row {
  flex-grow: 1;
  border: none;
  margin: 0;
  padding: 0;
  background-color: #FFF;
  width: 100%;
}
</style>
