<template>
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">Permisos</p>
    </header>
    <section v-if="isLoading" class="modal-card-body">
      <p><i class="fas fa-sync fa-spin"></i>&nbsp; Cargando...</p>
    </section>
    <section v-else class="modal-card-body p-0">
      <Permisos @updateDocument="updateDocument" :documento="fileDetailed" :nivel-permiso-user="nivelPermisoUser" />
    </section>
    <footer class="modal-card-foot">
      <a class="button is-dark" type="button" @click="$emit('close')">Cerrar</a>
    </footer>
  </div>
</template>

<script>
import Permisos from '@/components/globals/permisos/Permisos'
import permisosUtils from '@/mixins/permisosUtils'

export default {
  mixins: [permisosUtils],
  data () {
    return {
      isLoading: false,
      fileDetailed: null
    }
  },
  components: {
    Permisos
  },
  created: function () {
    this.getFileDetailed()
  },
  computed: {
    user: function () {
      return this.$store.getters.getUser
    },
    file () {
      return this.$store.state.repository.selectedDocument
    },
    currentFolder: function () {
      return this.$store.state.repository.currentFolder
    },
    nivelPermisoUser: function () {
      if (!this.fileDetailed) return null
      if (this.$store.getters.hasAnyRole(['lyris'])) return 9
      return this.getNivelPermisoUser(this.fileDetailed.permisos, this.user)
    }
  },
  methods: {
    getFileDetailed: function () {
      this.isLoading = true
      this.$http
        .get('/documento/' + this.file.id)
        .then(response => {
          console.log('GET OK - Document updated!')
          this.fileDetailed = response.data
        })
        .catch(error => {
          console.error('Fail', error)
          console.log('Fetching data failed')
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    updateDocument: function () {
      this.getFileDetailed()
    }
  }
}
</script>
