<template>
<div>
  <table class="table is-narrow is-fullwidth is-marginless">
    <tbody>
      <tr v-for="(permisos, nivel) in permisos" :key="`permiso-nivel-info-${nivel}`">
        <th width="70">Nivel {{nivel}}</th>
        <td>
          <div class="field is-grouped is-grouped-multiline">
            <div class="control" v-for="(permiso,i) in permisos" :key="`permiso-${nivel}-info-${i}`">
              <div class="tags has-addons">
                <span class="tag" :class="getLevelColor(nivel)"><i :class="getLevelIcon(nivel)"></i></span>
                <span class="tag is-light" :class="getLevelColor(nivel)" v-html="permiso"></span>
              </div>
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
  <span class="help">(*) Requiere rol de usuario de aspectos</span>
  <!-- <ul>
    <li :class="{'unactive': level < 0}"><i class="fas" :class="level < 0 ? 'fa-times has-text-danger' : 'fa-check has-text-success'"></i> <b>Nivel 0</b> - Listar documentos en Repositorio</li>
    <li :class="{'unactive': level < 1}"><i class="fas" :class="level < 1 ? 'fa-times has-text-danger' : 'fa-check has-text-success'"></i> <b>Nivel 1</b> - Ver documento / Descargar documento</li>
    <li :class="{'unactive': level < 2}"><i class="fas" :class="level < 2 ? 'fa-times has-text-danger' : 'fa-check has-text-success'"></i> <b>Nivel 2</b> - Descargar documento confidenciales</li>
    <li :class="{'unactive': level < 3}"><i class="fas" :class="level < 3 ? 'fa-times has-text-danger' : 'fa-check has-text-success'"></i> <b>Nivel 3</b> - Firmar documento</li>
    <li :class="{'unactive': level < 4}"><i class="fas" :class="level < 4 ? 'fa-times has-text-danger' : 'fa-check has-text-success'"></i> <b>Nivel 4</b> - Agregar documentos a carpeta / Modificar metadatos</li>
    <li :class="{'unactive': level < 5}"><i class="fas" :class="level < 5 ? 'fa-times has-text-danger' : 'fa-check has-text-success'"></i> <b>Nivel 5</b> - Agregar nuevas versiones / Asociar aspectos</li>
    <li :class="{'unactive': level < 6}"><i class="fas" :class="level < 6 ? 'fa-times has-text-danger' : 'fa-check has-text-success'"></i> <b>Nivel 6</b> - Mover o renombrar</li>
    <li :class="{'unactive': level < 7}"><i class="fas" :class="level < 7 ? 'fa-times has-text-danger' : 'fa-check has-text-success'"></i> <b>Nivel 7</b> - Eliminar </li>
    <li :class="{'unactive': level < 8}"><i class="fas" :class="level < 8 ? 'fa-times has-text-danger' : 'fa-check has-text-success'"></i> <b>Nivel 8</b> - Modificar permisos</li>
    <li :class="{'unactive': level < 9}"><i class="fas" :class="level < 9 ? 'fa-times has-text-danger' : 'fa-check has-text-success'"></i> <b>Nivel 9</b> - Propietario <i class="fas fa-star"></i></li>
  </ul> -->
</div>
</template>

<script>
export default {
  props: ['level'],
  data () {
    return {
      permisos: {
        0: [
          'Listar documento en repositorio'
        ],
        1: [
          'Ver documento',
          'Descargar documento'
        ],
        2: [
          'Descargar documento confidencial'
        ],
        3: [
          'Firmar documento'
        ],
        4: [
          'Modificar metadatos (*)',
          'Agregar documento (carpeta)'
        ],
        5: [
          'Subir nueva versión de documento'
        ],
        6: [
          'Renombrar documento/carpeta',
          'Asociar aspectos (*)',
          'Quitar aspectos (*)'
        ],
        7: [
          'Mover documento/carpeta',
          'Eliminar documento'
        ],
        8: [
          'Modificar permisos documento'
        ],
        9: [
          'Propietario&nbsp;<i class="fas fa-star"></i>'
        ]
      }
    }
  },
  methods: {
    getLevelIcon: function (requiredLevel) {
      if (this.level >= requiredLevel) return 'fas fa-check'
      return 'fas fa-times'
    },
    getLevelColor: function (requiredLevel) {
      if (this.level >= requiredLevel) return 'is-success'
      return 'is-danger'
    }
  }
}
</script>

<style lang="scss" scoped>
.unactive{
  color: #CACACA
}
</style>
