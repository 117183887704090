<template>
  <section v-if="aspecto">
    <metadatos
      ref="metadatosForm"
      :force-edit="true"
      :hide-save-button="true"
      :metadatos="metadatos"
      :aspecto="aspecto"
      :read-only="false"
      :can-edit="true"
      :bordered="true"
    />
    <button class="button is-success is-fullwidth" @click="submit">
      <i class="fas fa-paper-plane"></i>&nbsp;Guardar
    </button>
  </section>
</template>

<script>
import Metadatos from '@/components/globals/aspectos/Metadatos'

export default {
  props: ['accionId', 'parametros'],
  data () {
    return {
    }
  },
  components: {
    Metadatos
  },
  methods: {
    submit: function () {
      this.$refs.metadatosForm.getPayload()
        .then(metadatos => {
          const data = { metadatos }
          this.$http
            .request({
              method: 'post',
              url: `/accion-ejecucion/${this.accionId}`,
              data: data
            })
            .then(response => {
              this.$buefy.snackbar.open({
                message: response.data.mensaje,
                type: 'is-success'
              })
              this.$emit('updateDocument')
            })
            .catch(err => {
              console.error(err)
              this.$buefy.snackbar.open({
                message: 'Error al ejecutar la acción',
                type: 'is-danger'
              })
            })
        })
        .catch(err => {
          console.log(err)
          this.$buefy.snackbar.open({
            message: 'Error, revisé los campos de los atributos',
            type: 'is-danger'
          })
        })
    }
  },
  computed: {
    aspecto: function () {
      return this.$store.state.unidadCompuesta.unidadCompuesta.aspectos.find(a => a.id === this.parametros.aspecto_id)
    },
    metadatos: function () {
      return this.$store.state.unidadCompuesta.unidadCompuesta.lista_metadatos
    }
  }
}
</script>

<style>
</style>
