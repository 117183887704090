<template>
  <div class="box">
    <div class="field">
      <label for class="label">{{ constants.STRINGS.COMMON_WORDS.TYPE }}</label>
      <div class="control">
        <div class="select is-fullwidth">
          <select v-model="query.tipoDocumento">
            <option :value="null">
              {{ constants.STRINGS.COMMON_WORDS.DASH_ALL_DASH }}
            </option>
            <option :value="constants.STRINGS.COMMON_WORDS.FOLDER_LOWERCASE">
              {{ constants.STRINGS.COMMON_WORDS.FOLDERS }}
            </option>
            <option :value="constants.STRINGS.COMMON_WORDS.DOCUMENT_LOWERCASE">
              {{ constants.STRINGS.COMMON_WORDS.DOCUMENTS }}
            </option>
            <option :value="constants.STRINGS.COMMON_WORDS.OTHER_DOCUMENT_CAMEL_CASE"
              v-if="this.isAllowedToFilterOtherDocuments">
              {{ constants.STRINGS.COMMON_WORDS.NOTIFICATIONS }}
            </option>
            <option :value="constants.STRINGS.COMMON_WORDS.RECEIPT_LOWERCASE"
              v-if="this.isAllowedToFilterReceipts">
              {{ constants.STRINGS.COMMON_WORDS.RECEIPTS }}
            </option>
            <option :value="constants.STRINGS.COMMON_WORDS.SUBUNIT_LOWERCASE"
              v-if="this.isAllowedToFilterSeriesAndSubunits">
              {{ constants.STRINGS.COMMON_WORDS.SUBUNITS }}
            </option>
            <option :value="constants.STRINGS.COMMON_WORDS.COMPOUND_UNITS_CAMEL_CASE"
              v-if="this.isAllowedToFilterSeriesAndSubunits">
              {{ constants.STRINGS.COMMON_WORDS.COMPOUND_UNITS }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <div class="field is-clearfix" v-if="this.isAllowedToFilterAspects">
      <div class="control is-pulled-right">
        <a @click="openAspectosModal" class="">
          <i class="fas fa-plus"></i>
        </a>
      </div>
      <label class="label is-pulled-left">{{ constants.STRINGS.COMMON_WORDS.ASPECTS }}</label>
      <div class="control">
        <div class="tags">
          <div class="tags has-addons" v-for="(aspecto,i) in aspectos" :key="i">
            <span class="tag is-link">{{aspecto.name}}</span>
            <a class="tag is-delete" @click="removeAspecto(aspecto)"></a>
          </div>
        </div>
      </div>
    </div>
    <div class="field is-clearfix" v-if="this.isAllowedToFilterAspects">
      <div class="control is-pulled-right">
        <a @click="openMetadatosModal" class="">
          <i class="fas fa-plus"></i>
        </a>
      </div>
      <label class="label is-pulled-left">{{ constants.STRINGS.COMMON_WORDS.METADATA }}</label>
      <div class="control">
        <div class="tags">
          <div class="tags has-addons" v-for="(metadato,i) in metadatos" :key="i">
            <span class="tag is-link">{{metadato.name}}</span>
            <a class="tag is-delete" @click="removeMetadatosList(metadato.value)"></a>
          </div>
        </div>
      </div>
    </div>
    <div class="field" v-if="this.isCompoundUnit && this.isAllowedToFilterSeriesAndSubunits">
      <label for class="label">{{ constants.STRINGS.COMMON_WORDS.DOCUMENTAL_SERIE }}</label>
      <div class="control">
        <pertenencia-series-documentales @setSerie="setSerieDocumental" @setEstadoSerie="setEstadoSerie"></pertenencia-series-documentales>
      </div>
    </div>
    <div class="field is-clearfix" v-if="this.isAllowedToFilterFolders">
      <label class="label" :class="{'is-pulled-left': explorerLoading}">{{ constants.STRINGS.COMMON_WORDS.ROOT_FOLDER }}</label>
      <i class="fas fa-lg fa-circle-notch fa-spin is-pulled-right" v-if="explorerLoading"></i>
      <a @click="selected = null" v-if="selected" class="is-pulled-right">
        <i class="fas fa-times"></i>
      </a>
      <p v-if="selected" class="help">{{selected.ruta}}</p>
      <button
        @click="showExplorerModal = true"
        v-if="!selected"
        class="button is-primary is-small is-outlined is-fullwidth"
      >{{ constants.STRINGS.SUBTITLES.FILTERS_GENERAL_SEARCH.CHOOSE_FOLDER }}</button>
      <b-modal ref="explorerModal" :active.sync="showExplorerModal" scroll="keep">
        <div class="card">
          <div class="card-content">
            <h1 class="title is-5">
              <i class="fas fa-arrow-right"></i>&nbsp;{{ constants.STRINGS.SUBTITLES.FILTERS_GENERAL_SEARCH.CHOOSE_ROOT_FOLDER }}
            </h1>
            <div class="field">
            <div class="control">
              <a class="button is-static is-fullwidth has-text-left">{{getRuta}}</a>
            </div>
          </div>
            <explorer :initFolder="initRoute" :slug="organizacionSlug" ref="listado" :rutas.sync="rutas" :selected.sync="selected"></explorer>
             <span class="help">{{ constants.STRINGS.SUBTITLES.FILTERS_GENERAL_SEARCH.CHOOSE_FOLDER_WHERE_RECEIPTS_WILL_SAVE }}</span>
            <br>
            <button
              class="button is-dark is-outlined is-fullwidth"
              type="button"
              @click="$refs.explorerModal.close()"
            >{{ constants.STRINGS.BUTTONS.CLOSE }}</button>
          </div>
        </div>
      </b-modal>
    </div>
    <!-- <div class="field">
      <label for class="label">{{ constants.STRINGS.COMMON_WORDS.OTHER_FILTERS }}</label>
      <b-checkbox v-model="query.confidencial">
        <i class="fas fa-lock"></i>&nbsp; {{ constants.STRINGS.SUBTITLES.FILTERS_GENERAL_SEARCH.ONLY_CONFIDENTIAL }}
      </b-checkbox>
    </div> -->
  </div>
</template>

<script>
import Explorer from '@/components/utils/Explorer'
import ModalAspectos from './ModalAspectos'
import ModalMetadatos from './ModalMetadatos'
import PertenenciaSeriesDocumentales from './filtros/PertenenciaSeriesDocumentales'
import constants from '../../../constants'

export default {
  props: ['query'],
  components: {
    Explorer,
    PertenenciaSeriesDocumentales
  },
  data () {
    return {
      constants: constants,
      rutas: null,
      selected: null,
      showExplorerModal: false,
      explorerLoading: true,
      initRoute: null,
      organizacionSlug: null,
      metadatos: [],
      aspectos: []
    }
  },
  mounted: function () {
    if (this.isAllowedToFilterFolders) {
      this.fetchCarpetaRoot()
    }
  },
  methods: {
    fetchCarpetaRoot: function () {
      let folder = null
      this.$http
        .get('/carpeta')
        .then(res => {
          folder = res.data.data.find(carpeta => {
            if (carpeta.nombre === '.') return carpeta
          })
          this.initRoute = folder.ruta.split('/')[0]
          this.organizacionSlug = folder.ruta.split('/')[0]
        })
        .catch(err => {
          console.error(err)
        })
        .finally(() => {
          this.explorerLoading = false
        })
    },
    openMetadatosModal: function () {
      this.$buefy.modal.open({
        parent: this,
        component: ModalMetadatos,
        events: {
          addMetadatoQuery: m => {
            this.metadatos.push(m)
            this.query.metadatos.push(m.value)
          }
        }
      })
    },
    addMetadatosList: function (m) {
      this.metadatos.push(m)
      this.query.metadatos = this.metadatos.map(m => {
        return m.value
      })
    },
    removeMetadatosList: function (value) {
      this.metadatos = this.metadatos.filter(m => {
        return m.value !== value
      })
      this.query.metadatos = this.metadatos.map(m => {
        return m.value
      })
    },
    openAspectosModal: function () {
      this.$buefy.modal.open({
        parent: this,
        component: ModalAspectos,
        events: {
          addAspectoQuery: aspecto => {
            if (this.aspectos.some(a => a.id === aspecto.id)) return
            this.aspectos.push(aspecto)
            this.query.aspectos.push(aspecto.id)
          }
        }
      })
    },
    removeAspecto: function (aspecto) {
      this.aspectos = this.aspectos.filter(a => a.id !== aspecto.id)
      this.query.aspectos = this.aspectos.map(a => a.id)
    },
    setSerieDocumental: function (serieId) {
      this.query.serie = serieId
    },
    setEstadoSerie: function (serieEstado) {
      this.query.estado = serieEstado
    }
  },
  computed: {
    isCompoundUnit: function () {
      return this.query.tipoDocumento === constants.STRINGS.COMMON_WORDS.COMPOUND_UNITS_CAMEL_CASE
    },
    hasRoleLyris: function () {
      return this.$store.getters.hasAnyRole([constants.STRINGS.ROLES.LYRIS.ID])
    },
    isAllowedToFilterAspects: function () {
      return this.hasRoleLyris || this.$store.getters.hasAnyRole(
        [constants.STRINGS.ROLES.ADMINISTRADOR_DE_ASPECTOS.ID,
          constants.STRINGS.ROLES.USUARIO_DE_ASPECTOS.ID
        ])
    },
    isAllowedToFilterReceipts: function () {
      return this.hasRoleLyris || this.$store.getters.hasAnyRole(
        [constants.STRINGS.ROLES.EMPLEADOR.ID,
          constants.STRINGS.ROLES.EMPLEADO.ID
        ])
    },
    isAllowedToFilterFolders: function () {
      return this.hasRoleLyris || this.$store.getters.hasAnyRole([constants.STRINGS.ROLES.USUARIO.ID])
    },
    isAllowedToFilterOtherDocuments: function () {
      return this.hasRoleLyris || this.$store.getters.hasAnyRole(
        [constants.STRINGS.ROLES.ADMINISTRADOR_DE_OTROS_DOCUMENTOS.ID,
          constants.STRINGS.ROLES.USUARIO_DE_OTROS_DOCUMENTOS.ID
        ])
    },
    isAllowedToFilterSeriesAndSubunits: function () {
      return this.hasRoleLyris || this.$store.getters.hasAnyRole(
        [constants.STRINGS.ROLES.ADMINISTRADOR_DE_SERIES.ID,
          constants.STRINGS.ROLES.USUARIO_DE_SERIES.ID
        ])
    },
    getRuta: function () {
      if (this.selected == null) {
        return this.organizacionSlug
      } else {
        return this.selected.ruta
      }
    },
    organizacion: function () {
      return this.$store.getters.getUser.organization
    }
  },
  watch: {
    selected: function (newVal, oldVal) {
      if (newVal) this.query.origen_id = newVal.id
      else this.query.origen_id = null
    },
    'query.tipoDocumento': function (newVal, oldVal) {
      if (oldVal === constants.STRINGS.COMMON_WORDS.COMPOUND_UNITS_DASH_CASE && newVal !== constants.STRINGS.COMMON_WORDS.OTHER_DOCUMENT_CAMEL_CASE) {
        this.query.serie = null
      }
    }
  }
}
</script>

<style>
.field:not(:last-child) {
  margin-bottom: 1.8em;
}
</style>
