<template>
    <div class="">
        <div v-show="!sent || !success">
            <article v-show="sent && !success" class="message is-danger is-small has-text-centered">
                <div class="message-body">
                    {{errorMessage}}
                </div>
            </article>
            <b-field position="is-centered">
                <b-upload v-model="file" drag-drop expanded>
                    <section class="section">
                        <div class="has-text-centered">
                            <p>
                                <i class="fas fa-2x" :class="file ? 'fa-file': 'fa-cloud-upload-alt'" size="2x"></i>
                            </p>
                            <p class="is-size-7">{{ file ? nameFile : "Arrastre o haga clic para subir una nueva versión"}}</p>
                        </div>
                    </section>
                </b-upload>
            </b-field>
            <button v-show="file" @click="submit()" class="button is-fullwidth is-primary" :class="{'is-loading': sending}"><i class="fas fa-upload fa-fw"></i>&nbsp;Subir nueva versión</button>
        </div>
        <div class="has-text-centered has-text-centered has-text-success" v-show="sent && success">
            <p class=""><i class="fas fa-check"></i>&nbsp;La nueva versión se ha subido con éxito</p>
        </div>
    </div>
</template>

<script>
export default {
  props: ['documentoId'],
  data () {
    return {
      file: null,
      sending: false,
      sent: false,
      success: false,
      errorMessage: ''
    }
  },
  computed: {

    nameFile: function () {
      if (!this.file) return 'No hay archivo'
      if (this.file && this.file.name.length > 20) {
        return this.file.name.substring(0, 20) + '...'
      }
      return this.file.name
    }
  },
  methods: {
    submit: function () {
      const data = new FormData()
      data.append('archivo', this.file)
      this.$http
        .request({
          method: 'post',
          url: '/documento/' + this.documentoId + '/version',
          data: data
        })
        .then(response => {
          this.sent = true
          this.success = true
          this.$emit('updateDocument')
          this.$buefy.snackbar.open({
            message: `La nueva versión (v${response.data.version.orden}) del documento se subió con éxito.`,
            duration: 6000
          })
        })
        .catch(error => {
          this.sent = true
          this.success = false
          this.errorMessage = error.message
          this.$buefy.snackbar.open({
            message: 'Error al subir el nuevo documento',
            type: 'is-warning',
            actionText: 'Cerrar',
            duration: 6000
          })
        })
    }
  }
}
</script>

<style lang="scss">
.upload .upload-draggable {
  width: 100%;
}
</style>
