<template>
  <div>
   <b-notification type="is-danger" v-if="error">
      {{error}}
    </b-notification>
    <b-field grouped>
      <div class="field is-expanded">
        <label class="label">Razón social</label>
        <div class="control">
          <input
            v-bind:disabled="!canEdit"
            v-model="model.razon_social"
            name="razon social"
            class="input"
            :class="{'is-danger': errors.has('razon social') }"
            v-validate="{required:true}"
          >
          <span
        class="help is-danger"
        v-if="errors.has('razon social')"
      >{{errors.first('razon social')}}</span>
        </div>
      </div>
      <div class="field">
        <label class="label">Slug</label>
        <div class="control">
          <input
            v-bind:disabled="!canEdit"
            v-model="model.slug"
            name="slug"
            class="input"
            :class="{'is-danger': errors.has('slug') }"
            v-validate="{required:true,max:20}"
          >
          <span
            class="help is-danger"
            v-if="errors.has('slug')"
          >{{errors.first('slug')}}</span>
        </div>
      </div>
    </b-field>
    <div class="field">
      <label class="label">
        CUIT
      </label>
      <div class="control">
        <the-mask v-bind:disabled="!canEdit" mask="##-########-#" v-model="model.cuit" type="text" :masked="false" class="input"></the-mask>
        <input type="hidden" v-model="model.cuit" name="cuit" class="input" :class="{'is-danger': errors.has('cuit') }" v-validate="{required:true}" />
      </div>
      <span class="help is-danger" v-if="errors.has('cuit')">{{errors.first('cuit')}}</span>
    </div>
    <div class="field">
      <label class="label">
        Email
      </label>
      <div class="control">
        <input type="text" v-model="model.email" name="email" class="input" :class="{'is-danger': errors.has('email') }" v-validate="{required:true,email:true}" />
      </div>
      <span class="help is-danger" v-if="errors.has('email')">{{errors.first('email')}}</span>
    </div>
     <div class="field">
      <label class="label">
        Teléfono
      </label>
      <div class="control">
        <input type="text" v-model="model.telefono" name="telefono" class="input" :class="{'is-danger': errors.has('telefono') }" v-validate="{required:true}" />
      </div>
      <span class="help is-danger" v-if="errors.has('telefono')">{{errors.first('telefono')}}</span>
    </div>
     <div class="field">
      <label class="label">
        Dirección
      </label>
      <div class="control">
        <input type="text" v-model="model.direccion" name="direccion" class="input" :class="{'is-danger': errors.has('direccion') }" v-validate="{required:true}" />
      </div>
      <span class="help is-danger" v-if="errors.has('direccion')">{{errors.first('direccion')}}</span>
    </div>
    <br>
    <div class="control is-clearfix">
      <button @click.prevent="submit()" :disabled="errors.any()" class="button is-primary is-pulled-right">
        <i class="fas fa-save fa-fw"></i>&nbsp;Guardar</button>
    </div>
  </div>
</template>

<script>
import slugify from 'slugify'
import validatorUtils from '@/mixins/validatorUtils'
import constants from '@/constants'

export default {
  props: ['model', 'error'],
  mixins: [validatorUtils],
  data () {
    return {
      constants,

      errorValidation: ''
    }
  },
  methods: {
    submit: function () {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.$emit('submit')
          this.clearValidatorErrors()
          return
        }
        this.$buefy.snackbar.open({
          message: 'Hay errores en el formulario',
          type: 'is-danger'
        })
      })
    }
  },
  computed: {
    canEdit: function () {
      return this.$store.getters.hasAnyRole(['lyris', constants.STRINGS.ROLES.LYRIS_SOPORTE.ID])
    }
  },
  watch: {
    'model.razon_social': function (newVal) {
      if (!newVal) {
        this.model.slug = null
        return
      }
      this.model.slug = slugify(newVal.toLowerCase())
    }
  }
}
</script>
