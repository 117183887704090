<template>
  <section>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="true">
      <b-icon pack="fas" icon="sync-alt" size="is-large" custom-class="fa-spin"></b-icon>
    </b-loading>

    <div class="box pb-0">
      <h1 class="subtitle is-4">Mis certificados</h1>
      <h2 class="title is-3">Renovar certificado</h2>
      <b-steps v-model="step" :animated="true" :has-navigation="false">
        <b-step-item :clickable="false" step="1" label="Renovación"></b-step-item>
        <b-step-item :clickable="false" step="2" label="Contraseña del certificado"></b-step-item>
        <b-step-item :clickable="false" step="3" label="Fin"></b-step-item>
      </b-steps>
    </div>

    <div class="box" v-if="step === 0">
      <h1 class="subtitle is-5">Renovación</h1>
      Se renovará su certificado de firma que está por vencerse. Obtendrá un nuevo certificado en estado 'Preaprobado' que deberá ser puesto en vigencia por un Oficial de Registro previo a estar listo para ser utilizado.
      <div class="buttons is-right mt-6">
        <button @click="nextStep" class="button is-primary">
          Continuar&nbsp;&nbsp;
          <i class="fas fa-arrow-right fa-sm"></i>
        </button>
      </div>
    </div>

    <div class="box" v-else-if="step === 1">
      <h1 class="subtitle is-5">Contraseña del certificado</h1>
      <p class="mt-4 mb-3">Verifique sus datos de usuario. Si observa algún error, comuníquese con el administrador antes de continuar con la renovación.</p>
      <table class="table is-bordered">
        <tbody>
          <tr>
            <th>Nombre</th>
            <td>{{usuario.nombre}}</td>
          </tr>
          <tr>
            <th>Apellido</th>
            <td>{{usuario.apellido}}</td>
          </tr>
          <tr>
            <th>CUIL</th>
            <td>{{usuario.cuil}}</td>
          </tr>
          <tr>
            <th>Email</th>
            <td>{{usuario.email}}</td>
          </tr>
        </tbody>
      </table>
      <p class="mt-4 mb-2">Ingrese una contraseña para proteger su certificado. Puede ser diferente a   la que utilizaba para proteger su actual certificado.</p>
      <div class="field">
        <label class="label">Contraseña</label>
        <div class="control">
          <input class="input" type="password" name="password" required maxlength="64" v-model="password"
            v-validate="'required|min:4|max:64'" ref="password" data-vv-scope="passwords" data-vv-as="Contraseña">
          <span class="help is-danger" v-if="errors.has('password', 'passwords')">
            {{ errors.first('password', 'passwords') }}
          </span>
        </div>
      </div>
      <div class="field">
        <label class="label">Confirmar contraseña</label>
        <div class="control">
          <input class="input" type="password" name="confirmedPassword" required maxlength="64" v-model="confirmedPassword"
            v-validate="'required|confirmed:password'" data-vv-scope="passwords" data-vv-as="Confirmar contraseña">
          <span class="help is-danger" v-if="errors.has('confirmedPassword', 'passwords')">
            {{ errors.first('confirmedPassword', 'passwords') }}
          </span>
        </div>
      </div>
      <b-notification type="is-warning is-light mt-5" has-icon :closable="false">
        <i class="fas fa-info mr-4"></i>
        <b>No debe olvidar su contraseña</b>, se le será solicitada más adelante.
      </b-notification>
      <b-field class="mx-1" >
        <b-checkbox v-model="confirmConditions">
          Comprendo que Signar almacenará mi archivo de certificado. A su vez, como ya cuento con un certificado vigente, comprendo que será reemplazado por esta renovación.
        </b-checkbox>
      </b-field>

      <div class="buttons is-right mt-6">
        <b-button class="is-light mr-2" @click="goBackStep0">
          Volver
        </b-button>
        <button @click="createKeyPair" class="button is-primary" :disabled="!confirmConditions">
          Continuar&nbsp;&nbsp;
          <i class="fas fa-arrow-right fa-sm"></i>
        </button>
      </div>
    </div>

    <div class="box" v-else-if="step === 2">
      <h1 class="subtitle is-5">Fin</h1>
      <div v-if="!error">
        <div class="section has-text-centered">
          <i class="fas fa-check-circle has-text-success fa-4x mb-4"></i>
          <h1 class="title is-4 has-text-success">La renovación ha sido exitosa</h1>
          <h1 class="subtitle is-5 has-text-success">Su certificado se encuentra Preaprobado</h1>
        </div>
        <b-notification type="is-warning is-light" has-icon :closable="false">
          <i class="fas fa-info mr-4"></i>
          Recuerde que no podrá utilizar su certificado hasta que sea puesto en vigencia por un Oficial de Registro.
        </b-notification>
      </div>
      <div class="section has-text-centered" v-else>
        <i class="fas fa-times-circle has-text-danger fa-4x mb-4"></i>
        <h1 class="title is-4 has-text-danger">Error al renovar el certificado</h1>
        <h1 class="subtitle is-5 has-text-info">Puede reintentar el proceso o comunicarse con Soporte</h1>
      </div>
      <div class="buttons is-right mt-6">
        <b-button class="is-primary" @click="$router.replace({name:'certificadosListado'})">
          Volver a mis certificados
        </b-button>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  props: ['idCert'],
  data () {
    return {
      step: 0,
      password: '',
      confirmedPassword: '',
      type: '',
      isLoading: false,
      usuario: null,
      p12Base64: null,
      newIdCert: null,
      errorMessage: '',
      confirmConditions: false
    }
  },
  beforeMount () {
    this.isLoading = true
    Promise
      .all([
        this.$http
          .get(`/usuario/${this.user.id}`),
        this.$http
          .get(`/certificado?usuario_id=${this.user.id}&organizacion_id=${this.user.organization}&estados=vigente`)
      ])
      .then(responses => {
        this.usuario = responses[0].data
        if (responses[1].data.length === 0) {
          throw new Error('No se encontró un certificado para ser renovado')
        }
      })
      .catch(() => {
        this.$buefy.snackbar.open({
          message: 'Error al recuperar los datos del usuario',
          type: 'is-danger'
        })
      })
      .finally(() => {
        this.isLoading = false
      })
  },
  beforeDestroy () {
    window.onbeforeunload = null
  },
  methods: {
    nextStep () {
      this.step++
    },
    goBackStep0 () {
      this.step = 0
    },
    createKeyPair () {
      window.onbeforeunload = function () {
        return '¿Esta seguro que desea cerrar la página?'
      }
      this.$validator.validateAll('passwords').then(isValid => {
        if (!isValid) {
          this.showAlert('Error de contraseña', 'Debes ingresar y confirmar una contraseña válida.')
        } else {
          this.$forge.rsa.generateKeyPair({ bits: 2048, workers: 2 }, (err, keypair) => {
            if (err) {
              this.showAlert('Error de solicitud', 'Ocurrió un error en la solicitud. Comuníquese con el administrador.')
            } else {
              this.keyPair = keypair
              var privateKeyEncrypted = this.$forge.pki.encryptRsaPrivateKey(keypair.privateKey, this.password)
              this.privateKeyBase64 = this.$forge.util.encode64(privateKeyEncrypted)
              this.createAndSendCSR()
            }
          })
        }
      })
    },
    createAndSendCSR () {
      this.isLoading = true
      var csrBase64 = this.createCSR()
      this.sendCSR(csrBase64)
    },
    createCSR () {
      var structure = {
        countryName: 'AR',
        commonName: `${this.usuario.nombre} ${this.usuario.apellido}`,
        serialName: `CUIL ${this.usuario.cuil}`
      }
      var subjectBody = Object.keys(structure).map(k => {
        return {
          name: k,
          value: structure[k],
          valueTagClass: this.$forge.asn1.Type.UTF8
        }
      })
      var csr = this.$forge.pki.createCertificationRequest()
      csr.publicKey = this.keyPair.publicKey
      csr.setSubject(subjectBody)
      csr.setAttributes([
        {
          name: 'extensionRequest',
          extensions: [
            {
              name: 'subjectAltName',
              altNames: [{ type: 1, value: this.usuario.email }]
            }
          ]
        }
      ])
      csr.sign(this.keyPair.privateKey)
      csr.verify()
      var pem = this.$forge.pki.certificationRequestToPem(csr)
      return this.$forge.util.encode64(pem)
    },
    sendCSR (csrBase64) {
      var request = {
        csr: csrBase64,
        usuario_id: this.user.id
      }
      this.$http
        .post(`/certificado/${this.idCert}/renovar`, request)
        .then(res => {
          this.newIdCert = res.data.idCert
          const chain = [res.data.certificado, res.data.ac_intermedia, res.data.ac_raiz]
          const options = { algorithm: '3des', friendlyName: `${this.user.name} ${this.user.surname}` }
          var p12Asn1 = this.$forge.pkcs12.toPkcs12Asn1(this.keyPair.privateKey, chain, this.password, options)
          var p12Der = this.$forge.asn1.toDer(p12Asn1).getBytes()
          this.p12Base64 = this.$forge.util.encode64(p12Der)
          this.sendCert()
        })
        .catch(err => {
          this.isLoading = false
          this.password = this.confirmedPassword = ''
          this.$buefy.snackbar.open({
            message: err.mensaje,
            type: 'is-danger',
            duration: 5000
          })
        })
    },
    sendCert () {
      this.$http
        .get(`data:application/octet-stream;charset=utf-8;base64,${this.p12Base64}`, { baseURL: '', responseType: 'blob' })
        .then(resBlob => {
          const data = new FormData()
          data.append('archivo', new File([resBlob.data], 'cert.p12', { type: 'application/x-pkcs12' }))
          return this.$http
            .post(`/certificado/${this.newIdCert}/keystore`, data)
        })
        .then(() => {
          this.isLoading = false
          this.nextStep()
          window.onbeforeunload = null
        })
        .catch(() => {
          this.isLoading = false
          this.showSnackbar('Error al guardar el certificado', 'is-danger')
        })
    },
    finish () {
      this.showSnackbar('Certificado renovado exitosamente', 'is-success')
      this.goBack()
    },
    goBack () {
      this.$router.go(-1)
    },
    noError () {
      this.type = ''
      this.errorMessage = ''
    },
    hasError () {
      this.type = 'is-danger'
      this.errorMessage = 'Contraseña incorrecta'
    },
    showAlert (title, message) {
      this.$buefy.dialog.alert({
        title: title,
        message: message,
        type: 'is-danger',
        ariaModal: true
      })
    },
    showSnackbar (message, type) {
      this.$buefy.snackbar.open({
        message: message,
        type: type
      })
    }
  },
  computed: {
    user: function () {
      return this.$store.getters.getUser
    }
  }
}
</script>
