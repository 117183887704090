<template>
<div class="column is-3 is-2-desktop is-hidden-touch">
  <div class="card sticky-column">
    <div class="card-content">
      <div class="pt-2 pb-4">
        <div class="has-text-centered is-size-3 has-text-weight-bold">
            <i class="fas fa-receipt"></i>&nbsp;{{selectedRecibos.length}}
        </div>
      </div>
      <p class="subtitle is-5 has-text-centered"> Recibo{{selectedRecibos.length > 1 ? 's' : ''}} seleccionado{{selectedRecibos.length > 1 ? 's' : ''}}</p>
    </div>
    <hr class="m-0">
    <div class="card-content has-text-centered">
      <ul>
        <li class="my-2">
          <a @click="$emit('download')" class="has-text-info">
            <i class="fas fa-download fa-fw"></i>&nbsp;Descargar
          </a>
        </li>
        <li class="my-2">
        </li>
      </ul>
    </div>
    <div class="card-footer">
      <a @click="unselect()" class="has-text-dark card-footer-item">
        <i class="fas fa-times-circle fa-fw"></i>&nbsp;Limpiar
      </a>
    </div>
  </div>
  </div>
</template>

<script>
export default {
  methods: {
    unselect () {
      this.$store.commit('recibos/setSelected', [])
    }
  },
  computed: {
    selectedRecibos () {
      return this.$store.state.recibos.selected
    }
  }
}
</script>

<style lang="scss" scoped>
// .panel-block {
//   font-size: 1rem;
//   font-weight: 400;
// }
.square {
  width: 50%;
  height: 0;
  padding-top: 100%;
  background-color: #e0e9f3;
  border-radius: 50%;
  position: relative;
  .the-content {
    position: absolute;
    top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: $info
  }
}
.name-file {
  word-break: break-all;
}
.sticky-column{
  position: sticky;
  top: 10px;
  max-height: 95vh;
}

</style>
