<template>
  <div>
    <div class="hero is-fullheight">
      <div class="hero-body ">
        <div class="container has-text-centered">
          <h1 class="title is-3 is-marginless">Cerrando sesion</h1>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {}
  },
  mounted: function () {
    this.startLoading()
    this.$store.commit('logout')
    setTimeout(
      location.reload(),
      1000
    )
  }
}
</script>
