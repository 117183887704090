const otrosDocumentosModule = {
  namespaced: true,
  state: {
    selected: []
  },
  mutations: {
    setSelected (state, otrosDocumentos) {
      state.selected = otrosDocumentos
    },
    unload (state) {
      state.selected = []
    }
  },
  getters: {},
  actions: {
    unload (context) {
      context.commit('unload')
    }
  }
}

export default otrosDocumentosModule
