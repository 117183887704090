
// Panel
import OtrosDocumentos from './OtrosDocumentos'

const BASE_PATH = '/otros-documentos'

const routes = [
  {
    path: BASE_PATH,
    name: 'listarOtrosDocumentos',
    meta: {
      requiresAuth: true,
      requiredRoles: ['lyris', 'otros-admin', 'otros-user']
    },
    component: OtrosDocumentos,
    props: true
  }
]
export default routes
