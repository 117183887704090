<template>
  <b-modal :active.sync="showUninstaller" scroll="keep" @close="cancelar">
    <div class="notification is-danger">
      <h1 class="title is-4 has-text-centered"><i class="fas fa-trash fa-fw"></i> ¿Está seguro que desea desinstalar el modulo?</h1>
      <div class="buttons has-text-centered">
        <button @click="cancelar" class="button is-white is-outlined">Cancelar</button>
        <button @click="desinstalar" class="button is-danger is-inverted">Desinstalar</button>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  props: ['showUninstaller', 'idOrganization', 'modulo'],
  data () {
    return {
      isLoading: false
    }
  },
  methods: {
    cancelar: function () {
      this.$emit('cancelarDesinstalar')
    },
    desinstalar: function () {
      this.isLoading = true
      this.$http
        .request({
          method: 'delete',
          url: '/organizacion/' + this.idOrganization + '/modulo/' + this.modulo,
          data: this.settings
        })
        .then(response => {
          this.$buefy.snackbar.open({
            message: 'El modulo ha sido desinstalado con éxito',
            duration: 8000
          })
          this.isLoading = false
          this.$emit('desinstalado')
        })
        .catch(error => {
          this.errorMessage = error.message
          this.$buefy.snackbar.open({
            message: 'Error al desinstalar el modulo',
            type: 'is-warning',
            actionText: 'Cerrar',
            duration: 8000
          })
          this.isLoading = false
        })
    }
  }
}
</script>

<style>

</style>
