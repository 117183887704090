<template>
  <div>
    <Acciones
      @firmar-pendientes="firmarPendientes"
      @download="download"
      @refresh="refresh"
    ></Acciones>
    <section class="section">
        <div class="columns">
          <div class="column is-4 is-3-tablet is-3-desktop">
            <Administrador @selectScope="openSelectScopeModal" v-if="$store.getters.hasAnyRole(['otros-admin'])"/>
            <ModalSeleccionarAlcance :showModal="showModal" @close-modal="closeModal" @launch-firmador="launchFirmador"/>
            <Filtros @set-query="setQuery" @refresh="refresh"/>
          </div>
          <div class="column">
            <div class="box">
              <Listado ref="listado" />
            </div>
          </div>
          <MultipleSidebar v-if="selectedDocumentos.length" @download="download"/>
        </div>
    </section>
  </div>
</template>

<script>
import Acciones from '@/components/otros-documentos/Acciones'
import Filtros from '@/components/otros-documentos/Filtros'
import Listado from '@/components/otros-documentos/Listado'
import MultipleSidebar from '@/components/otros-documentos/MultipleSidebar'
import Administrador from '@/components/otros-documentos/Administrador'
import ModalSeleccionarAlcance from '@/components/otros-documentos/ModalSeleccionarAlcance'
import ModalLaunchFirmador from '@/components/utils/ModalLaunchFirmador'
import store from '@/store'

export default {
  components: {
    Acciones,
    Filtros,
    Listado,
    Administrador,
    ModalSeleccionarAlcance,
    MultipleSidebar
  },
  data () {
    return {
      showModal: false
    }
  },
  destroyed: function () {
    store.dispatch('otrosDocumentos/unload')
  },
  methods: {
    openSelectScopeModal: function () {
      this.showModal = true
    },
    closeModal: function () {
      this.showModal = false
    },
    setQuery: function (query) {
      this.$refs.listado.setQuery(query)
    },
    refresh: function () {
      this.$refs.listado.refresh()
    },
    download: function () {
      this.$buefy.snackbar.open({
        message:
          'Comprimiendo los documentos seleccionados para descargar, aguarde un momento.',
        type: 'is-success'
      })
      const ids = this.selectedDocumentos.map(r => r.id)
      this.$http
        .get('/documento/' + ids.join(',') + '/descarga')
        .then(res => {
          window.location.href = res.data.link
        })
        .catch(error => {
          console.error('Fail', error)
          this.$buefy.snackbar.open({
            message:
              'Error de conexión. Compruebe la conectividad con internet.',
            type: 'is-danger'
          })
        })
    },
    launchFirmador: function (atributos) {
      const payload = {
        firmador: 'signar',
        opcion: 'otros-doc-admin',
        parametros: {
          areas: atributos.areas,
          esDocumentoGlobal: atributos.esDocumentoGlobal,
          organizacionCompleta: atributos.organizacionCompleta,
          requiereFirma: atributos.requiereFirma,
          usuarios: atributos.usuarios
        }
      }
      this.$store.dispatch('firmador/unload')
      this.$store.commit('firmador/setPayload', payload)
      this.$buefy.modal.open({
        parent: this,
        canCancel: false,
        hasModalCard: true,
        component: ModalLaunchFirmador
      })
    },
    firmarPendientes: function () {
      // Se setean los parámetros como un objeto vacio, contemplando que se seleccionó 'Firmar mis pendientes'
      const firmarPendientesPayload = {
        firmador: 'signar',
        opcion: 'otros-doc-user',
        parametros: {}
      }
      // En caso de haber seleccionado documentos, se agrega 'documentos' con los ids dentro de un array
      if (this.$store.state.otrosDocumentos.selected.length > 0) {
        firmarPendientesPayload.parametros = {
          documentos: this.$store.state.otrosDocumentos.selected.map(doc => doc.id)
        }
      }
      this.$store.dispatch('firmador/unload')
      this.$store.commit('firmador/setPayload', firmarPendientesPayload)
      this.$buefy.modal.open({
        parent: this,
        canCancel: false,
        hasModalCard: true,
        component: ModalLaunchFirmador
      })
    }
  },
  computed: {
    selectedDocumentos: function () {
      return this.$store.state.otrosDocumentos.selected
    }
  }
}
</script>

<style lang="scss" scoped>
td {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: default;
}
</style>
