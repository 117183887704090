<template>
  <section class="box">
    <i class="fas fa-check-circle fa-lg has-text-success is-pulled-right" v-if="ok"></i>
    <h1 class="subtitle is-5">Mi cuenta</h1>
    <h1 class="title is-1">Elegir firmador</h1>
    <p class="">Seleccione que firmadores habilitados desea utilizar con Signar </p>
    <hr>
    <div class="block">
      <b-field>
        <b-checkbox type="is-link" size="is-medium" v-model="model.signar">Signar Firmador</b-checkbox>
      </b-field>
      <b-field>
        <b-checkbox type="is-link" size="is-medium" v-model="model.pfdrar">FirmAR</b-checkbox>
      </b-field>
    </div>
     <hr>
      <div class="buttons is-right">
    <button @click.prevent="submit()" class="button is-primary">Guardar</button>
      </div>
  </section>
</template>

<script>
export default {
  data () {
    return {
      selected: [],
      model: {
        signar: false,
        pfdrar: false
      },
      ok: false
    }
  },
  mounted: function () {
    this.model.signar = this.user.firmadores_habilitados.signar
    this.model.pfdrar = this.user.firmadores_habilitados.pfdrar
  },
  methods: {
    submit: function () {
      if (!this.model.signar && !this.model.pfdrar) {
        this.model.signar = true
      }
      this.ok = false
      this.startLoading()
      this.$http.put(`/usuario/${this.user.id}/firmadores-habilitados`, this.model)
        .then(response => {
          this.$buefy.snackbar.open({
            message: 'Parametros de Firmador actualizados',
            type: 'is-success'
          })
          this.ok = true
          this.$emit('update')
        }).catch(err => {
          console.error(err)
          this.$buefy.snackbar.open({
            message: 'Error, no se pudo guardar los parametros.',
            type: 'is-danger'
          })
        }).finally(() => {
          this.stopLoading()
        })
      return false
    }
  },
  computed: {
    user: function () {
      return this.$store.state.profile.user
    }
  }
}
</script>
