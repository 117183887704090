const filesUtils = {
  methods: {
    isFieldOptional: function (value) {
      if (value === null || value === '') {
        return null
      }
      if (typeof value !== 'undefined' && value.length === 0) {
        return []
      } else return value
    },
    isFieldInputEmpty: function (value) {
      if (value === null || value === '') {
        return true
      }
      if (typeof value !== 'undefined' && value.length === 0) {
        return true
      } else return false
    },
    isObject: function (val) {
      return val != null && typeof val === 'object' && Array.isArray(val) === false
    },
    isObjectEmpty: function (obj) {
      for (const key in obj) {
        // eslint-disable-next-line no-prototype-builtins
        if (obj.hasOwnProperty(key)) { return false }
      }
      return true
    }
  }
}

export default filesUtils
