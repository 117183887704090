<template>
  <div>
    <h1 class="subtitle is-5">Serie Documental</h1>
    <h1 class="title is-5">{{unidadCompuesta.serie_documental.nombre}}</h1>
    <div class="content">
    <p class="is-size-7">{{unidadCompuesta.serie_documental.descripcion}}</p>
    </div>
    <h6 class="title is-6 mb-2">Estados</h6>
    <p class="is-size-7 my-2">(*) Estado inicial</p>
    <div class="tags my-2">
      <span
        class="tag"
        :class="unidadCompuesta.serie_documental.estado_inicial === index ? 'is-primary' : 'is-primary is-light'"
        v-for="(estado, index) in unidadCompuesta.serie_documental.estados"
        :key="index"
      >{{estado}} <span v-if="unidadCompuesta.serie_documental.estado_inicial === index">&nbsp;*</span> </span>
    </div>
    <h6 class="title is-6 mb-3">Subunidades</h6>
    <div class="columns is-multiline" v-if="!isObjectEmpty(unidadCompuesta.serie_documental.estructura)">
      <div
        v-for="(subunidad, index) in unidadCompuesta.serie_documental.estructura"
        :key="index"
        class="column is-6"
      >
        <p class="has-text-primary">
          <i class="fas" :class="subunidad.multiple ? 'fa-copy' : 'fa-file'"></i>
          &nbsp;{{subunidad.nombre}}
        </p>
        <p class="is-size-7">Formatos: {{subunidad.formatos.join(', ')}}</p>
      </div>
    </div>
    <div class="content" v-else>
      <p class="is-size-7">No hay subunidades asociados a la serie documental</p>
    </div>
    <h6 class="title is-6">Aspectos</h6>
    <div class="columns is-multiline" v-if="unidadCompuesta.aspectos.length">
      <div
        v-for="(aspecto, index) in unidadCompuesta.aspectos"
        :key="index"
        class="column is-6"
      >
        <p class="has-text-primary">
          <i class="fas fa-caret-right"></i>
          &nbsp;{{aspecto.nombre}}
        </p>
        <p class="is-size-7">{{aspecto.descripcion}}</p>
        <br>
        <p class="is-size-7">Atributos: {{aspecto.atributos.map(x => x.nombre).join(', ')}}</p>
      </div>
    </div>
    <div class="content" v-else>
      <p class="is-size-7">No hay aspectos asociados a la serie documental</p>
    </div>
  </div>
</template>

<script>
import fieldsUtils from '@/mixins/fieldsUtils'

export default {
  mixins: [fieldsUtils],
  computed: {
    unidadCompuesta: function () {
      return this.$store.state.unidadCompuesta.unidadCompuesta
    }
  }
}
</script>
