<template>
  <section>
    <h1 class="subtitle is-5">
      <i class="fas fa-sitemap fa-fw"></i> Circuito de firmas</h1>
    <progress class="progress is-medium" :class="getPercentageColor" :value="getPercentageValue" max="100"></progress>
    <div class="has-text-centered">
      <b-tooltip multilined size="is-large" :label="Constants.STRINGS.TOOLTIPS.INFORMATION_OTRO_DOCUMENTO.SIGNS_PROGRESS">
        <i class="fas fa-tachometer-alt fa-fw"></i> Progreso - {{ this.getActualSignsInSignedStateLength }} de {{ this.getAllSignsLength }} firma{{ this.getAllSignsLength > 1 ? 's' : '' }}
      </b-tooltip>
    </div>
    <br>
    <b-table
      :data="tableData"
      :bordered="false"
      :loading="false"
      narrowed
      hoverable
      mobile-cards>

      <b-table-column label="Nombre y Apellido" v-slot="props">
          <span><i class="fas fa-user fa-fw"></i>&nbsp;{{ props.row.usuario }} <i v-if="props.row.pendiente" class="fas fa-arrow-left fa-fw"></i></span>
      </b-table-column>

      <b-table-column label="Estado de firmas" centered v-slot="props">
        <b-icon pack="fas" :icon="props.row.icon"></b-icon>
      </b-table-column>

      <b-table-column label="Fecha y hora de firma" centered v-slot="props">
        <span v-if="props.row.signed_at">{{ new Date(props.row.signed_at) | moment("dddd, DD/MM/YYYY, HH:mm:ss") }}</span>
        <span v-else>-</span>
      </b-table-column>
    </b-table>
  </section>
</template>

<script>
import Constants from '../../constants'

export default {
  data () {
    return {
      Constants: Constants
    }
  },
  computed: {
    otroDocumento: function () {
      return this.$store.state.otroDocumento.otroDocumento
    },
    getPercentageValue: function () {
      return this.getActualSignsInSignedStateLength * 100 / this.getAllSignsLength
    },
    getPercentageColor: function () {
      let color = null
      switch (this.otroDocumento.estado) {
        case Constants.STRINGS.DOCUMENTS.STATUS.PENDING_SIGNATURE:
          color = 'is-warning'
          break
        case Constants.STRINGS.DOCUMENTS.STATUS.SIGNED:
          color = 'is-success'
          break
        default:
          color = 'is-primary'
          break
      }

      if (this.getPercentageValue === 100) {
        color = 'is-success'
      }

      return color
    },
    getActualSignsInSignedStateLength: function () {
      return this.otroDocumento.otro_documento_usuario.filter(firma => firma.estado === Constants.STRINGS.DOCUMENTS.STATUS.SIGNED).length + 1 // Admin sign
    },
    getAllSignsLength: function () {
      return this.otroDocumento.otro_documento_usuario.length + 1 // Admin sign
    },
    tableData: function () {
      const signsOverDocument = this.otroDocumento.otro_documento_usuario.map((firma, index) => {
        return {
          usuario: `${firma.usuario.apellido.toUpperCase()}, ${firma.usuario.nombre}`,
          icon: this.getSignStatusIcon(firma.estado),
          pendiente: this.isPendingStatus(firma.estado),
          signed_at: (firma.created_at === firma.updated_at) ? null : firma.updated_at
        }
      })

      // Add the admin who uploaded the document
      signsOverDocument.unshift({
        usuario: this.otroDocumento.administrador ? `${this.otroDocumento.administrador.apellido.toUpperCase()}, ${this.otroDocumento.administrador.nombre} (Administrador)` : 'No se pudo recuperar el administrador.',
        icon: 'check-circle has-text-success',
        pendiente: false,
        signed_at: this.otroDocumento.created_at
      })

      return signsOverDocument
    }
  },
  methods: {
    isPendingStatus: function (status) {
      return (status === Constants.STRINGS.DOCUMENTS.STATUS.PENDING_SIGNATURE)
    },
    getSignStatusIcon: function (status) {
      switch (status) {
        case Constants.STRINGS.DOCUMENTS.STATUS.SIGNED:
          return 'check-circle has-text-success'
        case Constants.STRINGS.DOCUMENTS.STATUS.PENDING_SIGNATURE:
          return 'clock has-text-warning'
        default:
          return 'question'
      }
    }
  }
}
</script>

<style>
</style>
