<template>
  <section>
    <div class="box">
      <h1 class="subtitle is-5">Autoridad Certificante</h1>
      <h1 class="title is-1">Oficiales de Registro</h1>
      <div class="content">
        <p>Usuarios de la organización con el rol "Oficial de registro".</p>
        <p>Puede habilitar o deshabilitar oficiales de registro mediante la acción correspondiente.</p>
      </div>
      <Listado/>
    </div>
  </section>
</template>
<script>
import Listado from '@/components/organizacion/autoridad-certificante/oficiales-registro/Listado'
export default {
  components: {
    Listado
  }
}
</script>
