<template>
  <div>
    <DragAndDrop/>
    <route-breadcrumb :routes="rutas" size="is-small" v-if="selectedDocumentsIndex.length == 0" />
    <MultipleSelectionBar
      @unselect-documents="unselectDocuments"
      @move-multiple-documents="openMoveMultipleDocuments"
      v-else />
    <PlusButton
      @create-folder="openCreateFolder"
      @create-composite-unit="openCreateCompositeUnit"
      @upload-documents="openUploadDocuments" />
    <div class="section pt-4">
      <div class="container-fluid">
        <explorador
          :idFolder.sync="idFolder"
          ref="explorador"
          :rutas.sync="rutas"
          :selected.sync="selected"
          @create-folder="openCreateFolder"
          @rename-document="openRenameDocument"
          @move-document="openMoveDocument"
          @drag-and-drop-move-documents="openDragAndDropMoveMultipleDocuments"
          @drag-and-drop-upload-documents="openDragAndDropUploadDocuments"
          @delete-document="openDeleteDocument"
          @preview-document="openPreviewDocument"
          @folder-permissions="openFolderPermissions"
          @move-multiple-documents="openMoveMultipleDocuments"
          @delete-multiple-documents="openDeleteMultipleDocuments" />
      </div>
    </div>
  </div>
</template>

<script>
import Explorador from '@/components/repositorio/Explorador'
import DragAndDrop from '@/components/repositorio/DragAndDrop'
import RouteBreadcrumb from '@/components/utils/RouteBreadcrumb'
import MultipleSelectionBar from '@/components/repositorio/MultipleSelectionBar'
import PlusButton from '@/components/repositorio/PlusButton'
import ModalCrearCarpeta from '@/components/repositorio/ModalCrearCarpeta'
import ModalCrearUnidadCompuesta from '@/components/repositorio/ModalCrearUnidadCompuesta'
import ModalSubirMultipleDocumentos from '@/components/repositorio/ModalSubirMultipleDocumentos'
import ModalPermisosFolder from '@/components/repositorio/ModalPermisosFolder'
import ModalEliminarDocumento from '@/components/repositorio/ModalEliminarDocumento'
import ModalRenombrarDocumento from '@/components/repositorio/ModalRenombrarDocumento'
import ModalMoverDocumento from '@/components/repositorio/ModalMoverDocumento'
import ModalMoverMultiplesDocumentos from '@/components/repositorio/ModalMoverMultiplesDocumentos'
import ModalEliminarMultiplesDocumentos from '@/components/repositorio/ModalEliminarMultiplesDocumentos'
import ModalDragAndDropMoverMultiplesDocumentos from '@/components/repositorio/ModalDragAndDropMoverMultiplesDocumentos'
// import store from '@/store'

export default {
  props: ['idFolder'],
  data () {
    return {
      documentos: [],
      rutas: null,
      selected: null,
      idPreviewed: null,
      urlPreviewer: null
    }
  },
  components: {
    DragAndDrop,
    Explorador,
    RouteBreadcrumb,
    MultipleSelectionBar,
    PlusButton
  },
  computed: {
    selectedDocumentsIndex () {
      return this.$store.state.repository.selectedDocumentsIndex
    }
  },
  // destroyed: function () {
  //   store.dispatch('repository/unload')
  // },
  methods: {
    openCreateFolder: function () {
      const modal = this.$buefy.modal.open({
        parent: this,
        component: ModalCrearCarpeta,
        events: {
          refreshAndSelectNewFolder: (newFolder) => {
            this.resetAll()
            this.$nextTick(() => {
              this.selectOneFolder(newFolder)
            })
          }
        }
      })
      modal.$on('close', () => {
        this.closeModal()
      })
    },
    openCreateCompositeUnit: function () {
      this.$buefy.modal.open({
        parent: this,
        component: ModalCrearUnidadCompuesta
      })
    },
    openUploadDocuments: function () {
      this.$buefy.modal.open({
        parent: this,
        component: ModalSubirMultipleDocumentos,
        events: {
          refresh: () => {
            this.refreshDocuments()
          }
        }
      })
    },
    openDragAndDropUploadDocuments: function (files) {
      this.$buefy.modal.open({
        parent: this,
        component: ModalSubirMultipleDocumentos,
        props: {
          droppedFiles: files
        },
        events: {
          refresh: () => {
            this.refreshDocuments()
          }
        }
      })
    },
    openDeleteDocument: function (isDeletingCurrentFolder = false) {
      const modal = this.$buefy.modal.open({
        parent: this,
        component: ModalEliminarDocumento,
        events: {
          refreshDocuments: () => {
            this.refreshDocuments()
          },
          refreshFolders: () => {
            this.resetAll(isDeletingCurrentFolder)
          }
        }
      })
      modal.$on('close', () => {
        this.closeModal()
      })
    },
    openRenameDocument: function () {
      this.$buefy.modal.open({
        parent: this,
        component: ModalRenombrarDocumento,
        width: 600,
        events: {
          refresh: () => {
            this.resetAll()
          }
        }
      })
    },
    openFolderPermissions: function () {
      this.$buefy.modal.open({
        parent: this,
        component: ModalPermisosFolder,
        hasModalCard: true,
        width: 720,
        events: {
          refresh: () => {
            this.resetAll()
          },
          close: () => {
          // console.log('User is in the same folder. Should update currentFolder info')
            const selectedDocument = this.$store.state.repository.selectedDocument
            const curentFolder = this.$store.state.repository.currentFolder
            if (curentFolder.id === selectedDocument.id) {
              console.log('User is in the same folder. Should update currentFolder info')
              this.resetAll()
            }
          }
        },
        onCancel: () => {
          // console.log('User is in the same folder. Should update currentFolder info')
          const selectedDocument = this.$store.state.repository.selectedDocument
          const curentFolder = this.$store.state.repository.currentFolder
          if (curentFolder.id === selectedDocument.id) {
            console.log('User is in the same folder. Should update currentFolder info')
            this.resetAll()
          }
        }
      })
    },
    openMoveDocument: function () {
      this.$buefy.modal.open({
        parent: this,
        component: ModalMoverDocumento,
        width: 720,
        props: {
          slugRoot: this.rutas && this.rutas[0]
        },
        events: {
          refresh: () => {
            this.resetAll()
          }
        }
      })
    },
    openMoveMultipleDocuments: function () {
      this.$buefy.modal.open({
        parent: this,
        component: ModalMoverMultiplesDocumentos,
        width: 720,
        props: {
          slugRoot: this.rutas && this.rutas[0]
        },
        events: {
          refresh: () => {
            this.resetAll()
          }
        }
      })
    },
    openDragAndDropMoveMultipleDocuments: function (folder) {
      this.$buefy.modal.open({
        parent: this,
        component: ModalDragAndDropMoverMultiplesDocumentos,
        width: 720,
        props: {
          folder: folder
        },
        events: {
          refresh: () => {
            this.resetAll()
          }
        }
      })
    },
    openDeleteMultipleDocuments: function () {
      const modal = this.$buefy.modal.open({
        parent: this,
        component: ModalEliminarMultiplesDocumentos,
        width: 720,
        events: {
          refresh: () => {
            this.refreshDocuments()
          }
        }
      })
      modal.$on('close', () => {
        this.closeModal()
      })
    },
    closeModal: function () {
      this.$refs.explorador.isModalOpen = false
    },
    selectOneFolder (folder) {
      this.$refs.explorador.selectOneFolder(folder)
    },
    unselectDocuments () {
      this.$refs.explorador.unselectDocuments()
    },
    resetAll (isDeletingCurrentFolder) {
      this.$refs.explorador.resetAll(isDeletingCurrentFolder)
    },
    refreshDocuments () {
      this.$refs.explorador.refreshDocuments()
    },
    openPreviewDocument: function () {
      this.$eventHub.$emit('openFilePreview', this.$store.state.repository.selectedDocument)
    }
  },
  beforeRouteUpdate (to, from, next) {
    this.$refs.explorador.resetAll()
    next()
  },
  beforeRouteLeave (to, from, next) {
    this.$store.dispatch('repository/unload')
    next()
  }
}
</script>

<style lang="scss" scoped>

</style>
