<template>
<section>

  <div class="box">
    <h1 class="subtitle is-5">Organizaciones</h1>
    <h1 class="title is-1">Listado</h1>
  </div>
  <div class="box">
    <table class="table is-fullwidth is-narrow">
      <thead>
        <tr>
          <th>Razón</th>
          <th>CUIT</th>
          <th>Email</th>
          <th class="has-text-right" width="200px;">Acciones</th>
        </tr>
      </thead>
      <tbody v-if="organizaciones && organizaciones.length > 0">
        <tr :id="org.id" v-for="org in organizaciones" :key="org.id">
          <td>
           <router-link :to="{ name: 'organizacionInfo', params: { idOrganization: org.id }}">{{org.razon_social}}</router-link>
          </td>
          <td>
            {{org.cuit}}
          </td>
          <td>
            {{org.email}}
          </td>
          <td>
            <div class="field has-addons is-pulled-right" >
              <p class="control">
                <router-link :to="{ name: 'organizacionInfo', params: { idOrganization: org.id }}" class="button is-white is-small">
                  <i class="fas fa-eye fa-lg fa-fw"></i>
                </router-link>
              </p>
              <p class="control">
                <router-link :to="{ name: 'organizacionEditar', params: { idOrganization: org.id }}" class="button is-white is-small">
                  <i class="fas fa-edit fa-lg fa-fw"></i>
                </router-link>
              </p>
              <p class="control">
               <b-dropdown aria-role="list" position="is-bottom-left">
                <template #trigger="{ active }">
                  <button class="button is-white is-small" aria-haspopup="true" aria-controls="dropdown-menu">
                    Más&nbsp;<i class="fas fa-fw" :class="active ? 'fa-angle-up' : 'fa-angle-down'"></i>
                  </button>
                </template>
                <b-dropdown-item aria-role="listitem" has-link>
                    <router-link :to="{ name: 'organizacionUsuarios', params: { idOrganization: org.id }}" class="dropdown-item">
                      Usuarios
                    </router-link>
                </b-dropdown-item>
                <b-dropdown-item aria-role="listitem" has-link>
                  <router-link :to="{ name: 'organizacionAreas', params: { idOrganization: org.id }}" class="dropdown-item">
                      Areas
                    </router-link>
                </b-dropdown-item>
                <b-dropdown-item aria-role="listitem" has-link>
                   <router-link :to="{ name: 'organizacionModulos', params: { idOrganization: org.id }}" class="dropdown-item" exact>
                      Módulos
                    </router-link>
                </b-dropdown-item>

            </b-dropdown>
              </p>
            </div>
          </td>
        </tr>
      </tbody>
      <tbody v-else-if="organizaciones && organizaciones.length == 0">
        <tr v-if="loading">
          <td colspan="4" class="has-text-centered">
           <loading-spin></loading-spin>
          </td>
        </tr>
        <tr v-else>
          <td colspan="4" class="has-text-centered is-mono">
            <i>Sin organizaciones para mostrar</i>
          </td>
        </tr>
      </tbody>
    </table>
    <paginator
      :resource="url"
      @update="updateResource"
      :loading.sync="loading"
    />
  </div>
</section>
</template>

<script>
import Paginator from '@/components/utils/Paginator'
import LoadingSpin from '@/components/utils/LoadingSpin'

export default {
  components: {
    Paginator,
    LoadingSpin
  },
  data () {
    return {
      organizaciones: [],
      url: '/organizacion',
      loading: true
    }
  },
  methods: {
    updateResource (data) {
      this.organizaciones = data
    }
  }
}

</script>

<style lang="scss" scoped>
.table th, .table td{
  vertical-align: middle;
}
</style>
