<template>
  <section class="box">
    <div class="card-content" v-if="!isLoading">
        <h1 class="title is-3">Filtrado por metadatos</h1>
        <h1 class="subtitle is-5">Seleccione el aspecto y el metadato y el valor</h1>
      <section v-if="aspectos.length">
        <div class="field">
          <div class="control">
            <div class="select is-fullwidth">
              <select v-model="aspectoSeleccionado">
                <option :value="null">- Elija el aspecto -</option>
                <option :value="aspecto" v-for="aspecto in aspectos" :key="aspecto.id">{{aspecto.nombre}}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="field" v-if="aspectoSeleccionado">
          <div class="control">
            <div class="select is-fullwidth">
              <select
                v-model="atributoSeleccionado"
                placeholder="Elija el aspecto"
              >
                <option :value="null" disabled>- Elija el atributo -</option>
                <option :value="atributo" v-for="atributo in aspectoSeleccionado.atributos" :key="atributo.id">{{atributo.nombre}}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="field" v-if="atributoSeleccionado">
          <div class="control">
            <div class="select is-fullwidth">
              <select v-model="operacionSeleccionada">
                <option :value="null">- Elija la lógica -</option>
                <option
                  v-for="(op,index) in operacionesDisponibles"
                  :key="index"
                  :value="op.value"
                >{{op.name}}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="field" v-if="operacionSeleccionada">
          <div class="control is-expanded" v-if="isInputText(atributoSeleccionado.tipo)">
            <input
              type="text"
              v-if="isNumber(atributoSeleccionado.tipo)"
              v-model.number="value"
              :name="atributoSeleccionado.id"
              class="input"
              v-validate="getRules(atributoSeleccionado.obligatorio, atributoSeleccionado.tipo)"
              :class="{'is-danger': errors.has(atributoSeleccionado.id)}"
              placeholder
            />
            <input
              type="text"
              v-else-if="isDate(atributoSeleccionado.tipo)"
              v-model="value"
              :name="atributoSeleccionado.id"
              class="input"
              v-validate="getRules(atributoSeleccionado.obligatorio, atributoSeleccionado.tipo)"
              :class="{'is-danger': errors.has(atributoSeleccionado.id)}"
              placeholder
            />
            <input
              type="text"
              v-else
              v-model="value"
              :name="atributoSeleccionado.id"
              class="input"
              v-validate="getRules(atributoSeleccionado.obligatorio, atributoSeleccionado.tipo)"
              :class="{'is-danger': errors.has(atributoSeleccionado.id)}"
              placeholder
            />
            <p class="help is-danger" v-if="errors.has(atributoSeleccionado.id)">{{errors.first(atributoSeleccionado.id)}}</p>
            <p class="help">
              <span class="has-text-primary" v-if="!atributoSeleccionado.obligatorio">(Opcional)</span>
              {{getNombreAtributo(atributoSeleccionado.tipo)}}. {{atributoSeleccionado.descripcion}}
            </p>
          </div>
          <div v-else class="control">
            <div class="select is-fullwidth">
              <select
                v-model="value"
                :name="atributoSeleccionado.nombre"
                v-if="atributoSeleccionado.tipo == 'boolean'"
              >
                <option :value="null">-</option>
                <option :value="1">Si / Verdadero</option>
                <option :value="0">No / Falso</option>
              </select>
              <select
                v-model="value"
                :name="atributoSeleccionado.nombre"
                v-if="atributoSeleccionado.tipo == 'enum'"
              >
                <option
                  :value="opcion"
                  v-for="opcion in atributoSeleccionado.schema.enum"
                  :key="opcion"
                >{{opcion}}</option>
              </select>
            </div>
            <p class="help" style="margin-top:10px;">
              <span class="has-text-primary" v-if="!atributoSeleccionado.obligatorio">(Opcional)</span>
              {{getNombreAtributo(atributoSeleccionado.tipo)}}. {{atributoSeleccionado.descripcion}}
            </p>
          </div>
        </div>
        <div class="buttons is-centered">
          <button class="button is-dark is-outlined" @click="$parent.close()">
            Cancelar
          </button>
          <button class="button is-primary" @click="agregarCondicion" :disabled="errors.any() || value == null">
            <i class="fas fa-plus"></i>&nbsp;Agregar condición
          </button>
        </div>
      </section>
       <div v-else>
        <div class="notification is-light">
          <i class="fas fa-info-circle"></i>&nbsp;No existen aspectos
        </div>
        <div class="buttons is-centered">
          <button class="button is-dark is-outlined" @click="$parent.close()">
            Cerrar
          </button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import attributesUtils from '@/mixins/attributesUtils'
export default {
  mixins: [attributesUtils],
  data () {
    return {
      isLoading: false,
      // aspectos: [],
      aspectoSeleccionado: null,
      metadatoSeleccionado: null,
      atributoSeleccionado: null,
      operacionSeleccionada: null,
      value: null,
      operacionesDisponibles: [
        {
          name: 'Es igual a',
          value: '='
        },
        {
          name: 'Es menor a',
          value: '<'
        },
        {
          name: 'Es mayor a',
          value: '>'
        },
        {
          name: 'Es menor o igual a',
          value: '<='
        },
        {
          name: 'Es mayor o igual a',
          value: '>='
        },
        {
          name: 'Es distinto a',
          value: '!='
        }
      ]
    }
  },
  mounted: function () {
    this.getAspectos()
  },
  methods: {
    getAspectos: function () {
      this.isLoading = true
      this.startLoading()
      Promise.all([
        this.$http.get(`/organizacion/${this.organizacion}/aspecto`),
        this.$http.get('/aspecto')
      ])
        .then(responses => {
          this.$store.commit('organizacion/aspectos', responses[0].data)
          this.$store.commit('organizacion/aspectosGlobales', responses[1].data)
          // this.stopLoading()
          // this.prepareData(responses[0].data, responses[1].data)
        })
        .catch(err => {
          this.stopLoading()
          console.error(err)
          this.$buefy.snackbar.open({
            message: 'El servidor respondió con un error.',
            type: 'is-danger'
          })
        })
        .finally(() => {
          this.stopLoading()
          this.isLoading = false
        })
    },
    // prepareData: function (aspectos, aspectosOrganizacion) {
    //   this.aspectos = aspectos.concat(aspectosOrganizacion)
    // },
    agregarCondicion: function () {
      const metadato = {
        name: `${this.atributoSeleccionado.nombre} ${this.operacionSeleccionada} ${this.value}`,
        value: `${this.atributoSeleccionado.id}:${this.operacionSeleccionada}:${this.value}`
      }
      this.$emit('addMetadatoQuery', metadato)
      this.$parent.close()
    },
    isInputText: function (tipo) {
      switch (tipo) {
        case 'string':
        case 'number':
        case 'integer':
        case 'date':
          return true
        case 'boolean':
        case 'enum':
          return false
        default:
          return false
      }
    },
    isInputSelect: function (tipo) {
      switch (tipo) {
        case 'string':
        case 'number':
        case 'integer':
        case 'date':
          return true
        case 'boolean':
        case 'enum':
          return false
        default:
          return false
      }
    },
    isNumber: function (tipo) {
      switch (tipo) {
        case 'number':
        case 'integer':
          return true
        default:
          return false
      }
    },
    isDate: function (tipo) {
      if (tipo === 'date') return true
      return false
    },
    getRules: function (required, tipo) {
      const rules = {}
      if (required) rules.required = true
      switch (tipo) {
        case 'integer':
          rules.integer = true
          break
        case 'number':
          rules.decimal = true
          break
        case 'date':
          rules.date_format = 'yyyy-MM-dd'
          break
      }
      return rules
    }
  },
  computed: {
    aspectos: function () {
      return this.$store.getters['organizacion/allAspectos']
    },
    organizacion: function () {
      return this.$store.getters.getUser.organization
    }
  },
  watch: {
    aspectoSeleccionado: function (newVal, oldVal) {
      this.atributoSeleccionado = null
      this.operacionSeleccionada = null
    },
    atributoSeleccionado: function (newVal, oldVal) {
      this.operacionSeleccionada = null
    },
    operacionSeleccionada: function (newVal, oldVal) {
      this.value = null
    }
  }
}
</script>

<style>
</style>
