<template>
  <div class="box">
    <h1 class="subtitle is-5">
      Administrar usuario
    </h1>
    <h1 class="title is-2">Cambiar contraseña</h1>
    <p>
      Puede forzar una nueva contraseña al usuario. Requiere ser
      <span class="tag is-success is-rounded">Lyris</span> o
      <span class="tag is-info is-rounded">Lyris Soporte</span>.
    </p>
    <hr />
    <div class="columns">
      <div class="column is-6-desktop is-8-tablet">

        <div class="field">
          <label class="label">Nueva contraseña</label>
          <div class="control">
            <input
              placeholder="Contraseña"
              name="force_password"
              type="password"
              class="input"
              data-vv-name="force_password"
              v-model="forcePassword"
              ref="force_password"
              v-validate="'required|min:6'"
              data-vv-as="Nueva contraseña"
            />
            <span
              class="help is-danger"
              v-show="errors.has('force_password')"
              >{{ errors.first("force_password") }}</span
            >
          </div>
        </div>
        <div class="field">
          <label class="label">Repetir contraseña</label>
          <div class="control">
            <input
              placeholder="Re-escriba la password"
              type="password"
              class="input"
              data-vv-name="force_password_confirmation"
              v-model="repeatForcePassword"
              v-validate="'required|confirmed:force_password'"
              data-vv-as="Repetir contraseña"
            />
            <span
              class="help is-danger"
              v-show="errors.has('force_password_confirmation')"
              >{{ errors.first("force_password_confirmation") }}</span
            >
          </div>
      </div>
    </div>
    </div>
    <hr>
    <div class="buttons is-right">
      <button
        @click="resetPassword()"
        :disabled="errors.any() || !repeatForcePassword"
        class="button is-primary"
      >
        Guardar
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: ['idOrganization', 'idUser'],
  data () {
    return {
      forcePassword: null,
      repeatForcePassword: null
    }
  },
  methods: {
    resetPassword: async function () {
      this.$validator.validateAll('forcePassword').then((result) => {
        if (result) {
          this.submit({
            clave_nueva: this.forcePassword,
            clave_control: this.repeatForcePassword
          })
          return
        }
        this.$buefy.snackbar.open({
          message: 'Hay errores en el formulario',
          type: 'is-danger'
        })
      })
    },

    submit: function (payload) {
      this.startLoading()
      this.$http
        .request({
          method: 'put',
          url: `/usuario/${this.idUser}/clave`,
          data: payload
        })
        .then((response) => {
          this.$buefy.snackbar.open({
            message: response.data.mensaje,
            type: 'is-success'
          })
          this.$emit('updateResource')
          this.$router.push({ name: 'usuarioInfo', params: { idOrganization: this.idOrganization, idUser: this.idUser } })
          this.stopLoading()
        })
        .catch((error) => {
          console.error(error)
          this.$buefy.snackbar.open({
            message: error.mensaje,
            type: 'is-danger'
          })
          this.stopLoading()
        })
    }
  },
  computed: {
    usuario: function () {
      return this.$store.state.organizacion.selectedUser
    }
  }
}
</script>
