<template>
  <section>
    <h1 class="subtitle is-5">
      <i class="fas fa-sitemap fa-fw"></i> Circuito de firmas</h1>
    <progress class="progress is-medium" :class="getPorcentajeColor" :value="getPorcentaje" max="100">{{getPorcentaje}}%</progress>
    <div class="has-text-centered">
      <i class="fas fa-tachometer-alt fa-fw"></i> Progreso - {{getCantidad}} de {{isDisponible ? "1 Firma" : "2 Firmas"}}
    </div>
    <br>
    <b-table
      :data="tableData"
      :bordered="false"
      :narrowed="true"
      :hoverable="true"
      :loading="false"
      :mobile-cards="true">

      <b-table-column field="id" label="Orden" width="40" numeric v-slot="props">
          # {{ props.row.id }}
      </b-table-column>

      <b-table-column label="Nombre y Apellido" v-slot="props">
          <span><i class="fas fa-user fa-fw"></i>&nbsp;{{props.row.usuario}} <i v-if="props.row.pendiente" class="fas fa-arrow-left fa-fw"></i></span>
      </b-table-column>

      <b-table-column label="Rol" v-slot="props">
          {{props.row.rol}}
      </b-table-column>

      <b-table-column label="Firmado" centered v-slot="props">
          <i class="fas fa-fw is-size-5" :class="props.row.icon" :alt="props.row.altIcon"></i>
      </b-table-column>
    </b-table>
    <!-- <table class="table is-fullwidth is-hoverable">
      <thead>
        <tr>
          <th width="50px">Orden</th>
          <th>Usuario</th>
          <th class="has-text-centered">Rol</th>
          <th class="has-text-centered">Firmado</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>#1</td>
          <td>{{recibo.empleador.usuario.apellido.toUpperCase()}}, {{recibo.empleador.usuario.nombre}}</td>
          <td class="has-text-centered">Empleador</td>
          <td class="has-text-centered has-text-success">
            <i class="fas fa-check fa-lg fa-fw" alt="En conformidad"></i>
          </td>
        </tr>
        <tr v-if="!isDisponible" :class="{'is-800': isPendiente}">
          <td>#2</td>
          <td>{{recibo.empleado.usuario.apellido.toUpperCase()}}, {{recibo.empleado.usuario.nombre}}<i v-if="isPendiente" class="fas fa-arrow-left fa-fw"></i></td>
          <td class="has-text-centered">Empleado</td>
          <td class="has-text-centered" :class="getConformidadColor(recibo.estado)">
            <i class="fas fa-fw fa-lg" :class="getConformidadIcon(recibo.estado)"></i>
          </td>
        </tr>
      </tbody>
    </table> -->
  </section>
</template>

<script>
export default {
  computed: {
    recibo: function () {
      return this.$store.state.recibo.recibo
    },
    getPorcentaje: function () {
      if (this.recibo.estado === 'pendiente') return 50
      else return 100
    },
    getPorcentajeColor: function () {
      let color = null
      switch (this.recibo.estado) {
        case 'pendiente':
          color = 'is-warning'
          break
        case 'conformidad':
          color = 'is-success'
          break
        case 'disconformidad':
          color = 'is-danger'
          break
        default:
          color = 'is-primary'
          break
      }
      return color
    },
    getCantidad: function () {
      if (this.recibo.estado === 'pendiente' || this.recibo.estado === 'disponible') return 1
      else return 2
    },
    isPendiente: function () {
      return this.recibo.estado === 'pendiente'
    },
    isDisponible: function () {
      return this.recibo.estado === 'disponible'
    },
    tableData: function () {
      const empleador = {
        id: 1,
        usuario: `${this.recibo.empleador.usuario.apellido.toUpperCase()}, ${this.recibo.empleador.usuario.nombre}`,
        pendiente: false,
        rol: 'Empleador',
        icon: 'fa-check has-text-success',
        altIcon: 'En conformidad'
      }
      const empleado = {
        id: 2,
        usuario: `${this.recibo.empleado.usuario.apellido.toUpperCase()}, ${this.recibo.empleado.usuario.nombre}`,
        pendiente: this.isPendiente,
        rol: 'Empleado',
        icon: `${this.getConformidadIcon(this.recibo.estado)}`
      }
      switch (this.recibo.estado) {
        case 'pendiente':
          empleado.estado = 'Pendiente'
          break
        case 'conformidad':
          empleado.estado = 'En conformidad'
          break
        case 'disconformidad':
          empleado.estado = 'En disconformidad'
          break
        default:
          empleado.estado = '- Error -'
          break
      }
      return [empleador, empleado]
    }
  },
  methods: {
    getConformidadIcon: function (status) {
      let icon = ''
      switch (status) {
        case 'pendiente':
          icon = 'fa-clock has-text-warning'
          break
        case 'conformidad':
          icon = 'fa-check has-text-success'
          break
        case 'disconformidad':
          icon = 'fa-times has-text-danger'
          break
        default:
          icon = 'fa-question has-text-link'
          break
      }
      return icon
    }
  }
}
</script>

<style>
</style>
