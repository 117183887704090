const filesUtils = {
  methods: {
    getNivelPermisoUser: function (permisosDocumento, user) {
      if (this.$store.getters.hasAnyRole(['lyris'])) return 9
      const permisos = []
      /**
       * OLD IMPLEMENTATION: Puede ocurrir que tenga mas de un permiso de usuario en un documento..
       * por las dudas, vamos a darle el mayor, en vez de el primero que encuentre...
       */
      // 1° User tiene permiso especifico con el documento?
      // const permisoUserDocumento = permisosDocumento.find(permiso => {
      //   return permiso.usuario && permiso.usuario.id === user.id
      // })
      /**
       * NEW IMPLEMENTATION: Como puede ocurrir que exista mas de un permiso por usuario (por esas cosas
       * de interacciones raras entre permisos, mejor implementarlo entre el maximo de varios
       * permisos del mismo usuario)
       */
      const nivelesUserPorUsuario = permisosDocumento.filter(permiso => {
        return permiso.usuario && permiso.usuario.id === user.id
      }).map(permiso => permiso.nivel)
      permisos.push(...nivelesUserPorUsuario)
      // Si tiene, ese es el nivel que le corresponde
      // if (permisoUserDocumento) permisos.push(permisoUserDocumento.nivel)
      // 2° User tiene permiso por area con el documento
      // Un documento puede tener varias areas con distintos niveles. EJ: User es parte de 2 areas, esta areas tienen niveles distintos en este documento
      // Predomina el maximo nivel de los niveles que el usuario tiene por area.
      const nivelesUserPorArea = permisosDocumento.filter(permiso => {
        return permiso.area && user.areas.includes(permiso.area.id)
      }).map(permiso => permiso.nivel)
      // const nivelUserMaxPorArea = Math.max(...nivelesUserPorArea)
      permisos.push(...nivelesUserPorArea)
      // console.log('nivelesUserPorArea', nivelesUserPorArea)
      // console.log('nivelUserMaxPorArea', nivelUserMaxPorArea)
      // if (nivelUserMaxPorArea !== -Infinity) return nivelUserMaxPorArea
      // 3° User tiene permiso por ser parte de la organizacion
      // Obtener el permiso de la organizacion del usuario sobre el documento
      // El nivel de este deberia ser el ultimo nivel posible del usuario en el documento
      const permisoUserOrganizacion = permisosDocumento.find(permiso => {
        return permiso.organizacion && permiso.organizacion.id === user.organization
      })
      // console.log('permisoUserOrganizacion', permisoUserOrganizacion)
      if (permisoUserOrganizacion) permisos.push(permisoUserOrganizacion.nivel)
      // Si todo lo anterior falla.. nivel es null.
      const nivelPermisoUser = Math.max(...permisos)
      if (nivelPermisoUser !== -Infinity) return nivelPermisoUser
      return null
    }
  }
}

export default filesUtils
