<template>
  <section class="something" @dragover="dragover" @dragleave="dragleave" @drop="drop" />
</template>

<script>
export default {
  data () {
    return {
      files: null
    }
  },
  methods: {
    dragover: function (event) {
      console.log('--dragover')
      event.preventDefault()
      // Add some visual fluff to show the user can drop its files
      if (!event.currentTarget.classList.contains('dragging')) {
        // event.currentTarget.classList.remove('')
        event.currentTarget.classList.add('dragging')
      }
    },
    dragleave: function (event) {
      // Clean up
      // event.currentTarget.classList.add('')
      console.log('--dragleave')
      event.currentTarget.classList.remove('dragging')
    },
    drop: function (event) {
      event.preventDefault()
      console.log('--drop')
      this.files = event.dataTransfer.files
      // this.$refs.file.files = event.dataTransfer.files;
      // this.onChange(); // Trigger the onChange event manually
      // // Clean up
      // event.currentTarget.classList.add('bg-gray-100');
      event.currentTarget.classList.remove('dragging')
    }
  }
}
</script>

<style lang="scss" scoped>
.something{
  background: rgba(255, 166, 0, 0.253);
  box-sizing: border-box;
  display: block;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 99999;
  visibility: hidden;
  transition: linear background 1s;
  &.dragging {
    display: block;
    visibility: visible;
    background: rgba(0, 60, 255, 0.253);
  }
}
</style>
