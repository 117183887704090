import http from '@/http'

const signarModule = {
  namespaced: true,
  state: {
    aspectosGlobales: [],
    operacionesDisponiblesUnidadCompuesta: []
  },
  mutations: {
    aspectosGlobales (state, aspectosGlobales) {
      state.aspectosGlobales = aspectosGlobales
    },
    operacionesDisponiblesUnidadCompuesta (state, operacionesDisponibles) {
      state.operacionesDisponiblesUnidadCompuesta = operacionesDisponibles
    },
    // aspectosOrganizacion (state, aspectosOrganizacion) {
    //   state.aspectosOrganizacion = aspectosOrganizacion
    // },
    // aspectosGlobales (state, aspectosGlobales) {
    //   state.aspectosGlobales = aspectosGlobales
    // },
    unload (state) {
      state.aspectosGlobales = []
      state.operacionesDisponiblesUnidadCompuesta = []
    }
  },
  getters: {
    allAspectosGlobales (state) {
      return state.aspectosGlobales
    }
  },
  actions: {
    fetchAspectosGlobales ({ commit }) {
      // Fetch, then store the value, then return the promise
      // you chan call this as store.dispatch('..').then(res => {})
      return http.get('/aspecto')
        .then(res => {
          console.info('[VUEX] GET OK - Got Aspectos Globales')
          commit('aspectosGlobales', res.data)
          return res
        })
    },
    fetchOperacionesDisponibles ({ commit }) {
      // Fetch, then store the value, then return the promise
      // you chan call this as store.dispatch('..').then(res => {})
      return http.get('/documento-operacion?tipo=unidadCompuesta')
        .then(res => {
          console.info('[VUEX] GET OK - Got Operaciones Disponibles (Unidad Compuesta)')
          commit('operacionesDisponiblesUnidadCompuesta', res.data)
          return res
        })
    },
    unload ({ commit }) {
      commit('unload')
    }
  }
}

export default signarModule
