<template>
  <div id="login">
    <section class="section">
      <div class="container has-text-centered">
        <div class="columns">
          <div class="column is-4 is-offset-4">
            <img src="/statics/signar-logo.svg" class="image" alt="" />
            <div class="box">
              <p>Recupere su clave</p>
              <br />
              <div class="notification is-warning" v-if="error">
                {{ error }}
              </div>
              <div class="content">
                <b-field label="Clave nueva">
                  <b-input
                    type="password"
                    v-model="claveNueva"
                    password-reveal
                  ></b-input>
                </b-field>
                <b-field label="Repetir clave nueva">
                  <b-input
                    type="password"
                    v-model="claveControl"
                    password-reveal
                  ></b-input>
                </b-field>
              </div>
              <div class="has-text-centered">
                <a @click.prevent="submit()" class="button is-primary"
                  >Recuperar mi clave</a
                >
              </div>
            </div>
            <a href="https://lyris.com.ar" target="_blank"><img src="/statics/lyris-grey.svg" class="lyris-logo mt-5" alt="Lyris IT"></a>
            <p class="is-size-7 my-3">
              <a class="mx-2 has-text-grey" target="_blank" href="https://soporte-lyris.atlassian.net/servicedesk/customer/portals">Soporte&nbsp;<i class="fas fa-external-link-alt fa-fw"></i></a>
              <a class="mx-2 has-text-grey" target="_blank" href="https://docs.signar.com.ar">Documentación <i class="fas fa-external-link-alt fa-fw"></i></a>
            </p>
            <p class="is-size-7 mx-2 has-text-grey">v{{version}}</p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  props: ['token'],
  data () {
    return {
      claveNueva: '',
      claveControl: '',
      error: ''
    }
  },
  methods: {
    submit () {
      const data = {
        clave_nueva: this.claveNueva,
        clave_control: this.claveControl,
        token: this.token
      }
      this.$http
        .request({
          method: 'post',
          url: '/clave/recuperar',
          data: data
        })
        .then((response) => {
          this.$buefy.snackbar.open({
            message: 'Clave reiniciada exitosamente',
            type: 'is-success'
          })
          this.$router.push({ name: 'login' })
        })
        .catch((error) => {
          this.error = error.mensaje
          console.log(error)
          this.claveNueva = ''
          this.claveControl = ''
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.input {
  text-align: center;
}
img {
  max-width: 200px;
  margin: 10px auto 20px;
}
img.lyris-logo {
  max-width: 80px;
  margin-bottom: 0;
}
</style>
