<template>
  <div class="box">
    <h1 class="subtitle is-5">Usuarios de la organización</h1>
      <h1 class="title is-1">Roles por defecto</h1>
      <div class="content">
      <p>
      Defina que roles se asignan automaticamente para cada nuevo usuario. Los roles son parte de los modulos instalados de la organización.
      </p>
      </div>
    <hr>
    <section>
      <div class="columns">
        <div class="column">
          <b-message type="is-primary">
            <h1 class="title is-5">Roles por defecto</h1>
            <h1 class="subtitle is-6 is-mono">Arrastre aquí para activar</h1>
            <draggable v-model="rolesDefault" v-bind="{group:'roles'}" tag="b-taglist" @start="drag=true" @end="drag=false" style="min-height: 25px">
              <b-tag v-for="rolDefault in rolesDefault" :key="rolDefault.id" type="is-primary" size="is-medium">
                {{rolDefault.descripcion}}
              </b-tag>
            </draggable>
          </b-message>
        </div>
        <div class="column">
          <b-message>
            <h1 class="title is-5">Roles disponibles</h1>
            <h1 class="subtitle is-6 is-mono">Arrastre aquí para desactivar</h1>
            <draggable v-model="rolesOrg" v-bind="{group:'roles'}" tag="b-taglist" @start="drag=true" @end="drag=false" style="min-height: 25px">
              <b-tag v-for="rolOrg in availableRoles" :key="rolOrg.id" type="is-black" size="is-medium">
                {{rolOrg.descripcion}}
              </b-tag>
            </draggable>
          </b-message>
        </div>
      </div>
      <hr>
      <div class="field">
        <button @click.prevent="submit()" class="button is-primary">
          <i class="fas fa-save fa-fw"></i>&nbsp;Guardar</button>
      </div>
    </section>

  </div>
</template>

<script>
import draggable from 'vuedraggable'

export default {
  props: ['idOrganization', 'idUser'],
  components: {
    draggable
  },
  data () {
    return {
      error: '',
      rolesDefault: [],
      rolesOrg: [],
      rolesDisponibles: [],
      drag: false,
      showHelp: false
    }
  },
  created () {
    // this.rolesDefault = this.usuario.roles
    // this.rolesDefault = this.organizacion.parametros.roles_default
    this.obtenerRolesDisponibles()
  },
  methods: {
    obtenerRolesDisponibles () {
      this.startLoading()
      this.$http
        .get('/organizacion/' + this.idOrganization + '/rol?size=100')
        .then(response => {
          this.rolesDisponibles = response.data
          if (this.organizacion.parametros != null && this.organizacion.parametros.roles_default != null) {
            console.log(this.rolesDisponibles)
            this.rolesDefault = this.organizacion.parametros.roles_default.map(x => {
              return this.rolesDisponibles.find(rolDisponible => {
                console.log(x)
                console.log(rolDisponible.id === x)
                return rolDisponible.id === x
              })
            })
            console.log(this.rolesDefault)
          }
          this.rolesOrg = response.data.filter(rol => { return !this.rolesDefault.map(x => { return x.id }).includes(rol.id) })
          this.stopLoading()
        })
        .catch(error => {
          this.$buefy.snackbar.open({
            message: error.mensaje,
            type: 'is-danger'
          })
          this.stopLoading()
        })
    },
    submit () {
      this.$http
        .request({
          method: 'put',
          url: '/organizacion/' + this.idOrganization + '/parametros',
          data: this.payload
        })
        .then(response => {
          console.log('Updating organizacion...')
          this.$http
            .get('/organizacion/' + this.idOrganization)
            .then(resTwo => {
              // this.$store.commit('setOrganizacion', resTwo.data)
              this.$store.commit('organizacion/organizacion', resTwo.data)
              console.log('GET OK - Got organizacion')
            })
            .catch(error => {
              console.log('Fetching data failed', error)
            })
          this.$buefy.snackbar.open({
            message: 'Roles por defecto actualizados exitosamente.',
            type: 'is-success'
          })
          this.stopLoading()
        })
        .catch(error => {
          this.$buefy.snackbar.open({
            message: error.mensaje,
            type: 'is-danger'
          })
          this.stopLoading()
        })
    }
  },
  computed: {
    organizacion: function () {
      return this.$store.state.organizacion.organizacion
    },
    payload: function () {
      return {
        roles_default: this.rolesDefault.map(x => { return x.id })
      }
    },
    availableRoles: function () {
      return this.rolesOrg.filter(r => !this.rolesDefault.includes(r))
    }
  }
}
</script>

<style lang="scss" scoped>
.tag:hover {
  cursor: move;
}
</style>
