<template>
  <section>
    <div class="box">
      <h1 class="title is-5">Datos básicos</h1>
      <div class="field">
        <label class="label">Nombre</label>
        <div class="control">
          <input
            v-model="model.nombre"
            name="nombre"
            class="input"
            data-vv-scope='serie'
            :class="{'is-danger': errors.has('nombre','serie') }"
            v-validate="{required:true}"
          >
        </div>
        <span class="help is-danger" v-if="errors.has('nombre','serie')">{{errors.first('nombre','serie')}}</span>
      </div>
      <!-- ===================== -->
      <div class="field">
        <label class="label">Descripcion</label>
        <div class="control">
          <textarea
            v-model="model.descripcion"
            name="descripcion"
            class="textarea"
            data-vv-scope='serie'
            :class="{'is-danger': errors.has('descripcion','serie') }"
            v-validate="{required:true}"
            rows="2"
          />
        </div>
        <span class="help is-danger" v-if="errors.has('descripcion','serie')">{{errors.first('descripcion','serie')}}</span>
      </div>
    </div>
    <!-- ===================== -->
    <div class="box" v-if="$store.getters.hasAnyRole(['aspec-admin','lyris'])">
      <span class="tag is-primary is-pulled-right">Módulo activado&nbsp;<b-tooltip type="is-primary is-light" label="Disponible por contar con el módulo activado"><i class="fas fa-question-circle"></i></b-tooltip></span>
      <h1 class="title is-5">Aspectos iniciales</h1>
      <!-- ===================== -->
      <div class="content">
        <p>Elija que aspectos de documentos quiere definir para la serie.</p>
      </div>
      <div class="field">
          <div class="control">
          <div class="block">
            <b-checkbox
              v-model="model.aspectosSeleccionados"
              v-for="aspecto in aspectos"
              :key="aspecto.id"
              :native-value="aspecto"
            >{{aspecto.nombre}}</b-checkbox>
          </div>
        </div>
      </div>
    </div>
    <!-- ===================== -->
    <div class="box">
        <h1 class="title is-4">Estados</h1>
        <div class="content">
        <p>Agregue los estados de la serie completando los campos y haciendo clic en <span class="has-text-primary"><i class="fas fa-plus"></i>&nbsp;Agregar</span>. Puede eliminar haciendo clic en el botón <i class="fas fa-times-circle has-text-primary"></i></p>
        <p>Recuerde seleccionar un estado inicial para la serie</p>
      </div>
        <div class="field">
          <div class="field is-grouped">
            <div class="control is-expanded">
              <input
                v-model="input.estado.nombre"
                name="Nombre del estado"
                class="input"
                v-validate="{required: true}"
                data-vv-scope="estado"
                :class="{'is-danger': errors.has('Nombre del estado','estado') }"
                placeholder="Ingrese el nombre de un estado"
                @keyup.enter="agregarEstado"
              >
              <span class="help">Nombre del estado</span>
              <span
                class="help is-danger"
                v-if="errors.has('Nombre del estado','estado')"
              >{{errors.first('Nombre del estado','estado')}}</span>
            </div>
            <div class="control is-expanded">
              <input
                v-model="input.estado.slug"
                name="Clave del estado"
                data-vv-scope="estado"
                v-validate="{required: true, regex: /^[a-z0-9]+(?:[-_+][a-z0-9]+)*$/ }"
                class="input"
                :class="{'is-danger': errors.has('Clave del estado','estado') }"
                @keyup.enter="agregarEstado"
              >
              <span class="help">La clave del estado es autogenerado pero pude editarlo de preferirlo</span>
              <span
                class="help is-danger"
                v-if="errors.has('Clave del estado','estado')"
              >{{errors.first('Clave del estado','estado')}}</span>
            </div>
            <div class="control" @click="agregarEstado">
              <button class="button is-primary">
                <i class="fas fa-plus"></i>&nbsp;Agregar
              </button>
            </div>
          </div>
        </div>
        <div class="field">
          <label class="label">Listad de estados</label>
          <p v-if="estados.length == 0">No se han ingresado estados de la serie</p>
          <div class="tags" v-else>
            <p class="tag is-info is-medium" v-for="(estado,index) in estados" :key="index">
              {{estado.nombre}}
              <button
                @click="eliminarEstado(estado.slug)"
                class="delete is-small"
              ></button>
            </p>
          </div>
        </div>
        <div class="field">
          <label class="label">Estado inicial</label>
          <div class="control">
            <div class="select is-fullwidth">
              <select
                v-model="model.estado_inicial"
                name="Estado inicial"
                v-validate="{required:true}"
                data-vv-scope="serie"
                :class="{'is-danger': errors.has('Estado inicial','serie') }"
              >
                <option :value="null" disabled>- Seleccione un estado inicial -</option>
                <option
                  :value="estado.slug"
                  v-for="(estado, index) in estados"
                  :key="index"
                >{{estado.nombre}}</option>
              </select>
            </div>
          </div>
        </div>
        <span
          class="help is-danger"
          v-if="errors.has('Estado inicial','serie')"
        >{{errors.first('Estado inicial','serie')}}</span>
    </div>
    <!-- ===================== -->
    <div class="box">
                <h1 class="title is-5">Subunidades del documento</h1>
        <div class="content">
          <p>Agregué a la serie documental nuevas subunidades. Complete los campos y haga clic en <span class="has-text-primary"><i class="fas fa-plus"></i> Agregar</span></p>
          <p><b>NOTA:</b> Preferiblemente utilice un nombre y/o una clave no utilizada en las subunidades ya instanciadas</p>
        </div>
        <!-- ===================== -->
        <div class="field">
          <label class="label">Nombre y clave</label>
          <div class="field is-grouped">
            <div class="control is-expanded">
              <input
                v-model="input.subdocumento.nombre"
                name="Nombre del sub-documento"
                class="input"
                data-vv-scope="subdoc"
                :class="{'is-danger': errors.has('Nombre del sub-documento','subdoc') }"
                v-validate="{required:true}"
              >
              <span
                class="help is-danger"
                v-if="errors.has('Nombre del sub-documento','subdoc')"
              >{{errors.first('Nombre del sub-documento','subdoc')}}</span>
            </div>
            <div class="control is-expanded">
              <input
                v-model="input.subdocumento.key"
                name="Clave del sub-documento"
                class="input"
                data-vv-scope="subdoc"
                :class="{'is-danger': errors.has('Clave del sub-documento','subdoc') }"
                v-validate="{ required: true, regex: /^[a-z0-9]+(?:[-_+][a-z0-9#]+)*$/ }"
              >
              <span class="help">La clave de la subunidad es autogenerado pero pude editarlo de preferirlo</span>
              <span
                class="help is-danger"
                v-if="errors.has('Clave del sub-documento','subdoc')"
              >{{errors.first('Clave del sub-documento','subdoc')}}</span>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <div class="field">
                <label class="label">Formatos aceptables</label>
                <div class="block">
                  <b-checkbox
                    v-model="input.subdocumento.formatosSeleccionados"
                    v-for="formato in formatosDisponibles"
                    :key="formato"
                    :native-value="formato"
                    disabled
                  >{{formato}}</b-checkbox>
                </div>
              </div>
            </div>
            <div class="column">
              <div class="field">
                <label class="label">¿Es colección de documentos?</label>
                <div class="block">
                  <b-radio v-model="input.subdocumento.multiple" :native-value="true">
                    <i class="fas fa-check"></i>&nbsp;Si
                  </b-radio>
                  <b-radio v-model="input.subdocumento.multiple" :native-value="false">
                    <i class="fas fa-times"></i>&nbsp;No
                  </b-radio>
                </div>
              </div>
            </div>
            <div class="column is-narrow">
              <button @click="addSubdocumento" class="button is-primary">
                <i class="fas fa-plus"></i>&nbsp;Agregar
              </button>
            </div>
          </div>
        </div>
        <hr>
        <!-- ===================== -->
        <h1 class="subtitle is-5">Subunidades agregadas</h1>
        <div class="columns is-multiline" v-if="subdocumentos.length > 0">
          <div class="column is-6 is-4-desktop"  v-for="subdoc in subdocumentos" :key="`doc-${subdoc.key}`">
            <div class="box p-3 is-flex is-flex-direction-row">
              <div class="mr-4">
                <i class="far fa-2x has-text-primary" :class="{'fa-copy': subdoc.multiple, 'fa-file': !subdoc.multiple}"></i>
              </div>
              <div class="is-flex-grow-1">
                <h4 class="title is-6">{{subdoc.nombre}}</h4>
                <h4 class="subtitle is-7 mb-3">{{subdoc.key}}</h4>
                <div class="is-size-7">
                <p><b>Formatos</b>: {{subdoc.formatosSeleccionados.join(', ')}}</p>
                <p><b>¿Colección?</b>&nbsp;
                 <i class="fas" :class="{'fa-check': subdoc.multiple, 'fa-times': !subdoc.multiple}"></i>&nbsp;{{subdoc.multiple ? 'Si' : 'No'}}
                </p>
                </div>
              </div>
              <div class="ml-4">
                <p>
                <a @click="eliminarSubdocumento(subdoc.key)" class="has-text-primary">
                    <i class="fas fa-times-circle"></i>
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
        <p class="mt-5 is-italic is-mono" v-else>
          - No hay eventos en la serie -
        </p>
        <!-- <br>
        <div class="table-bordered">
          <table class="table is-fullwidth is-narrow">
            <thead>
              <tr>
                <th>Nombre</th>
                <th>Clave</th>
                <th>Formatos</th>
                <th class="has-text-centered">Colección</th>
                <th width="40" class="has-text-centered">
                  <i class="fas fa-trash"></i>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="subdoc in subdocumentos" :key="subdoc.key">
                <td>{{subdoc.nombre}}</td>
                <td class="is-mono">{{subdoc.key}}</td>
                <td>{{subdoc.formatosSeleccionados.join(', ')}}</td>
                <td v-if="subdoc.multiple" class="has-text-centered">
                  <i class="fas fa-check"></i>&nbsp;Si
                </td>
                <td v-else class="has-text-centered">
                  <i class="fas fa-times"></i>&nbsp;No
                </td>
                <td class="has-text-centered">
                  <a @click="eliminarSubdocumento(subdoc.key)" class="has-text-danger">
                    <i class="fas fa-trash"></i>
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div> -->
      </div>
    <br>
    <b-message type="is-link">
      <h5 class="title is-5 has-text-link is-marginless"><i class="fas fa-info-circle"></i>&nbsp;Información importante</h5>
      <p>Esta serie se creará sin eventos. Podra cargarlos luego de haber creado la serie documental. Presione&nbsp;<i class="fas fa-save fa-fw"></i>&nbsp;<b>Crear serie</b> para ir a la pantalla y continuar con la carga de datos</p>
    </b-message>
    <div class="has-text-centered">
    <button class="button is-primary is-medium"  @click="submit">
      <i class="fas fa-save fa-fw"></i>&nbsp;Crear serie
    </button>
    </div>
  </section>
</template>

<script>
import slugify from 'slugify'
import fieldsUtils from '@/mixins/fieldsUtils'
import validatorUtils from '@/mixins/validatorUtils'

export default {
  data () {
    return {
      model: {
        nombre: '',
        descripcion: '',
        estados: '',
        estructura: '',
        estado_inicial: null,
        tipo_documento_id: '',
        aspectosSeleccionados: []
      },
      input: {
        estado: {
          nombre: '',
          slug: ''
        },
        subdocumento: {
          nombre: '',
          key: '',
          multiple: false,
          formatosSeleccionados: ['pdf']
        }
      },
      estados: [],
      subdocumentos: [],
      formatosDisponibles: ['pdf'],
      fetchingAspectos: false,
      aspectos: []
    }
  },
  mixins: [fieldsUtils, validatorUtils],
  mounted: function () {
    this.fetchAspectos()
  },
  methods: {
    fetchAspectos: function () {
      this.fetchingAspectos = true
      Promise.all([
        this.$http.get(`/organizacion/${this.idOrganizacion}/aspecto`),
        this.$http.get('/aspecto')
      ]).then(responses => {
        this.aspectos = responses[0].data.concat(responses[1].data)
        this.fetchingAspectos = false
      }).catch(err => {
        console.error(err)
        this.$buefy.snackbar.open({
          message: 'El estado a ingresar es invalido',
          type: 'is-danger'
        })
      })
    },
    submit: function () {
      if (this.estados.length === 0) {
        this.$buefy.snackbar.open({
          message: 'No puede crear una serie documental sin estados',
          type: 'is-warning'
        })
        return
      }
      if (this.subdocumentos.length === 0) {
        this.$buefy.snackbar.open({
          message: 'No puede crear una serie documental sin subdocumentos',
          type: 'is-warning'
        })
        return
      }
      if (this.isFieldInputEmpty(this.estado_inicial)) {
        this.$buefy.snackbar.open({
          message: 'Debe asignar un estado inicial a la serie docoumental',
          type: 'is-warning'
        })
        return
      }
      this.$validator.validateAll('serie').then(result => {
        if (!result) {
          this.$buefy.snackbar.open({
            message: 'Hay errores en el formulario',
            type: 'is-danger'
          })
          return
        }
        const payload = {
          nombre: this.model.nombre,
          descripcion: this.model.descripcion,
          estado_inicial: this.model.estado_inicial,
          tipo_documento_id: 'unidadCompuesta',
          aspectos: this.model.aspectosSeleccionados.map(a => a.id),
          estados: {},
          estructura: {}
        }
        // const estados = {}
        this.estados.forEach((estado) => {
          payload.estados[estado.slug] = estado.nombre
        })
        this.subdocumentos.forEach((subdoc) => {
          payload.estructura[subdoc.key] = {
            multiple: subdoc.multiple,
            nombre: subdoc.nombre,
            formatos: subdoc.formatosSeleccionados
          }
        })
        // console.log(payload)
        this.$emit('submit', payload)
        this.clearValidatorErrors()
      }).catch(err => {
        console.error(err)
      })
    },
    agregarEstado: function () {
      const exists = this.estados.find(estado => {
        return estado.slug === this.input.estado.slug
      })
      if (exists) {
        this.$buefy.snackbar.open({
          message: 'El estado a ingresar es invalido',
          type: 'is-danger'
        })
        return false
      }
      this.$validator.validateAll('estado').then(result => {
        if (!result) {
          this.$buefy.snackbar.open({
            message: 'Error al agregar un estado',
            type: 'is-danger'
          })
          return
        }
        const estado = {
          nombre: this.input.estado.nombre,
          slug: this.input.estado.slug
        }
        this.estados.push(estado)
        this.input.estado.nombre = ''
        this.input.estado.slug = null
        this.clearValidatorErrors()
      })
    },
    addSubdocumento: function () {
      const exists = this.subdocumentos.find(subdoc => {
        return subdoc.key === this.input.subdocumento.key
      })
      if (exists) {
        this.$buefy.snackbar.open({
          message: 'El subdocumento a ingresar ya existe',
          type: 'is-danger'
        })
        return false
      }
      this.$validator.validateAll('subdoc').then(result => {
        if (!result) {
          this.$buefy.snackbar.open({
            message: 'Error al agregar un subdocumento',
            type: 'is-danger'
          })
          return
        }
        const subdoc = {
          nombre: this.input.subdocumento.multiple ? this.input.subdocumento.nombre + ' #f' : this.input.subdocumento.nombre,
          key: this.input.subdocumento.key,
          formatosSeleccionados: this.input.subdocumento.formatosSeleccionados,
          multiple: this.input.subdocumento.multiple
        }
        this.subdocumentos.push(subdoc)
        this.input.subdocumento.nombre = ''
        this.input.subdocumento.multiple = false
        this.clearValidatorErrors()
      })
    },
    eliminarEstado: function (slug) {
      this.estados = this.estados.filter(estado => {
        return estado.slug !== slug
      })
    },
    eliminarSubdocumento: function (key) {
      this.subdocumentos = this.subdocumentos.filter(subdoc => {
        return subdoc.key !== key
      })
    }
  },
  watch: {
    'input.estado.nombre': function (newVal) {
      if (!newVal) {
        this.input.estado.slug = null
        return
      }
      this.input.estado.slug = slugify(newVal.toLowerCase())
    },
    'input.subdocumento.nombre': function (newVal) {
      if (!newVal) {
        this.input.subdocumento.key = null
        return
      }
      // if(this.input.subdocumento.multiple){
      //   let pos = newVal.lastIndexOf(' #f');
      //   let fixed = newVal.substring(0,pos) + newVal.substring(pos+3)
      //   console.log(fixed.toLowerCase())
      //   this.input.subdocumento.key = slugify(fixed.toLowerCase());
      //   return
      // }
      this.input.subdocumento.key = slugify(newVal.toLowerCase())
    }
    // "input.subdocumento.multiple": function(newVal){
    //   if(this.input.subdocumento.nombre){
    //     if(newVal){
    //       this.input.subdocumento.nombre = this.input.subdocumento.nombre + ' #f'
    //       return
    //     } else {
    //       this.input.subdocumento.nombre = this.input.subdocumento.nombre.replace(' #f','');
    //       return
    //     }
    //   }
    // }
  },
  computed: {
    idOrganizacion: function () {
      return this.$store.state.organizacion.organizacion.id
    }
  }
}
</script>

<style lang="scss" scoped>
.table-bordered {
  padding: 1em;
  border: 1px solid #cacaca;
  border-radius: 0.3em;
  table.table {
    margin-bottom: 0;
  }
}
</style>
