<template>
<div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title is-800">Nueva Acción</p>
    </header>
    <div class="modal-card-body">
      <div class="field">
          <label class="label">Evento</label>
          <div class="control">
            <input type="text" class="input" :value="evento.nombre" disabled>
          </div>
      </div>
       <div class="field">
          <label class="label">¿Que acción ejecuta el evento?</label>
          <div class="control" v-for="accion in acciones" :key="accion.id">
            <b-radio v-model="accionSelected" :native-value="accion">{{accion.nombre}}</b-radio>
          </div>
          <p v-for="(inc,index) in incompatibilidades" class="help is-danger" :key="index"><i class="fas fa-times-circle"></i>&nbsp;"{{inc.queAccion}}" es incompatible con "{{inc.cualAccion}}"</p>
        </div>
        <!-- Parametros accion ============================= -->
        <div class="field">
          <label class="label">Descripción de la acción</label>
          <div class="control">
            <input
              v-model="descripcion"
              name="Descripcion de la acción"
              v-validate="{required: true}"
              class="input"
              :class="{'is-danger': errors.has('Descripcion de la acción') }"
            />
            <span class="help">Describa en detalle de que se trata la acción</span>
            <span
              class="help is-danger"
              v-if="errors.has('Descripcion de la acción')"
            >{{errors.first('Descripcion de la acción')}}</span>
          </div>
        </div>
        <div class="field" v-if="parametrosArray.includes('subunidad')">
          <label class="label">Subunidad</label>
          <div class="control">
            <div class="select is-fullwidth">
              <select v-model="subunidadSelected" name="Subunidad" v-validate="'required'">
                <option :value="null" disabled>- Seleccione una subunidad -</option>
                <option :value="index" v-for="(s,index) in subunidades" :key="index">{{s.nombre}}</option>
              </select>
            </div>
          </div>
        </div>
        <span class="help is-danger" v-if="errors.has('Subunidad')">{{errors.first('Subunidad')}}</span>
        <div class="field" v-if="parametrosArray.includes('aspecto_id')">
          <label class="label">Aspecto</label>
          <div class="control">
            <div class="select is-fullwidth">
              <select v-model="aspectoSelected" name="Aspecto" v-validate="'required'">
                <option :value="null" disabled>- Seleccione un aspecto -</option>
                <option :value="a.id" v-for="(a,index) in aspectosSerie" :key="index">{{a.nombre}}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="notification is-warning" v-if="alertNoAspectos">
          <p><b>No se puede agregar la acción al evento</b><br>La serie documental no cuenta con aspectos.</p>
        </div>
        <div class="notification is-warning" v-if="alertNoSubunidades">
          <p><b>No se puede agregar la acción al evento</b><br>La serie documental no cuenta con subunidades.</p>
        </div>
    </div>
    <div class="modal-card-foot is-justify-content-flex-end">
      <button class="button is-dark is-outlined" type="button" @click="$parent.close()">Cancelar</button>
      <button class="button is-primary" type="button" @click="submit">Crear acción</button>
    </div>
  </div>
</template>

<script>
export default {
  props: ['evento', 'acciones', 'subunidades', 'aspectosIniciales', 'idOrganizacion'],
  data () {
    return {
      descripcion: null,
      accionSelected: null,
      subunidadSelected: null,
      aspectoSelected: null,
      incompatibilidades: []
    }
  },
  computed: {
    alertNoAspectos: function () {
      return (this.accionSelected && this.accionSelected.id === 'cargarAspectoCompuesta' && this.serieDocumental.aspectos.length === 0)
    },
    alertNoSubunidades: function () {
      return (this.accionSelected && (this.accionSelected.id === 'firmarPdfSubunidad' || this.accionSelected.id === 'anexarSubunidad') && this.serieDocumental.estructura.length === 0)
    },
    serieDocumental: function () {
      return this.$store.state.serieDocumental.serieDocumental
    },
    aspectos: function () {
      return this.$store.getters['organizacion/allAspectos']
    },
    aspectosSerie: function () {
      if (!this.aspectos) return []
      return this.aspectos.filter((a) =>
        Object.values(this.serieDocumental.aspectos).includes(a.id)
      )
    },
    parametrosArray: function () {
      if (!this.accionSelected) return []
      return Object.keys(this.accionSelected.parametros)
    }
  },
  methods: {
    validateForm: function () {
      const promise = new Promise((resolve, reject) => {
        this.$validator.validateAll().then(result => {
          if (!result) {
            console.log('Hay errores en los datos')
            return reject(result)
          }
          console.log('OK')
          return resolve(result)
        })
      })
      return promise
    },
    getPayload: function () {
      const payload = {
        documento_evento_id: this.evento.id,
        documento_operacion_id: this.accionSelected.id,
        descripcion: this.descripcion,
        parametros: {}
      }
      if (this.subunidadSelected) {
        payload.parametros.subunidad = this.subunidadSelected
      }
      if (this.aspectoSelected) {
        payload.parametros.aspecto_id = this.aspectoSelected
      }
      return payload
    },
    submit: function () {
      if (this.alertNoAspectos || this.alertNoSubunidades) {
        this.$buefy.dialog.alert({
          title: 'Atención',
          message: 'No se puede agregar el tipo de acción.',
          type: 'is-primary',
          hasIcon: true,
          icon: 'exclamation-triangle',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
        return
      }
      this.$validator.validateAll().then(result => {
        if (!result) {
          this.$buefy.snackbar.open({
            message:
              'Hay errores en el campo de Descripcion de la acción',
            type: 'is-danger'
          })
          return
        }
        const payload = this.getPayload()
        this.$emit('submit', this.evento, payload)
        this.$parent.close()
      })
    }
  }
}
</script>
