<template>
  <section class="section">
    <div class="container">
      <div class="columns">
        <div class="column">
          <sidebar :newRequestButtonEnable="newRequestButtonEnable"></sidebar>
        </div>
        <div class="column is-9">
          <router-view @enable-new-request-button="enableNewRequestButton" @disable-new-request-button="disableNewRequestButton"></router-view>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import Sidebar from '@/components/certificados/Sidebar'
export default {
  props: ['idOrganization'],
  components: {
    Sidebar
  },
  data () {
    return {
      newRequestButtonEnable: false
    }
  },
  methods: {
    enableNewRequestButton () {
      this.newRequestButtonEnable = true
    },
    disableNewRequestButton () {
      this.newRequestButtonEnable = false
    }
  }
}
</script>
