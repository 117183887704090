<template>
  <section class="section">
    <div class="container">
      <div class="columns">
        <div class="column">
          <sidebar></sidebar>
        </div>
        <div class="column is-9">
          <router-view></router-view>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Sidebar from '@/components/admin/Sidebar'

export default {
  components: {
    Sidebar
  }
}
</script>
