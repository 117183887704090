<template>
  <table class="table is-narrow is-fullwidth is-marginless">
    <tbody>
      <tr>
        <th>Peso del documento</th>
        <td>{{readableSizeFile(documento.peso)}}</td>
      </tr>
      <tr>
        <th>Versión</th>
        <td>
          {{documento.ultima_version.orden + 1}}
        </td>
      </tr>
      <tr>
        <th>Ruta</th>
        <td>{{documento.ruta}}</td>
      </tr>
      <tr>
        <th>Fecha creación</th>
        <td>{{new Date(documento.created_at) | moment("dddd, DD/MM/YYYY, HH:mm:ss")}}</td>
      </tr>
      <tr>
        <th>Aspectos asignados</th>
        <td>
          <div class="field is-grouped is-grouped-multiline">
            <div class="control"  v-for="asp in documento.aspectos" :key="`info-aspecto-${asp.id}`">
                <div class="tags has-addons">
                  <span class="tag is-primary">{{asp.nombre}}</span>
                  <a v-if="canAsignarAspectos" class="tag is-delete" @click="quitarAspecto(asp)"></a>
                </div>
              </div>
              <div class="control"  v-if="canDeleteAspectos && documento.aspectos.length < todosLosAspectos.length">
            <button @click="openAsignarAspecto" class="button tag is-primary is-light"><i class="fas fa-plus"></i></button>
              </div>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import filesUtils from '@/mixins/filesUtils'
import ModalConfirmAction from '@/components/utils/ModalConfirmAction'

export default {
  props: {
    canAsignarAspectos: {
      type: Boolean,
      default: false
    },
    canDeleteAspectos: {
      type: Boolean,
      default: false
    }
  },
  mixins: [filesUtils],
  data () {
    return {
      cargando: true
    }
  },
  methods: {
    quitarAspecto: function (aspecto) {
      if (!this.canDeleteAspectos) return
      this.$buefy.modal.open({
        parent: this,
        component: ModalConfirmAction,
        width: 720,
        props: {
          title: 'Quitar aspecto',
          subtitle: `¿Seguro que desea quitar el aspecto "${aspecto.nombre}"?`,
          actionText: 'Quitar',
          actionClass: 'has-text-danger'
        },
        events: {
          accept: () => this.$emit('quitar-aspecto', aspecto)
        }
      })
    },
    openAsignarAspecto: function () {
      this.$emit('open-asignar-aspecto')
    }
  },
  computed: {
    documento: function () {
      return this.$store.state.document.document
    },
    todosLosAspectos: function () {
      return this.$store.getters['organizacion/allAspectos']
    }
  }
}
</script>

<style lang="scss" scoped>
th {
  color: $primary;
}
td{
  font-size: 1rem;
  @include mobile {
    font-size: 0.9rem;
    word-break: break-all;
    text-align: right !important;
  }
}
</style>
