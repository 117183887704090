// Panel
import Home from './Home'

// const BASE_PATH = '/panel'

const routes = [
  {
    path: '/',
    name: 'inicio',
    component: Home,
    meta: {
      requiresAuth: true
    }
  }
]

export default routes
