<template>
  <div class="box">
    <h1 class="title is-2">
      Editar usuario
    </h1>
    <div class="content">
      <p>Edite los datos del usuario de la organización.<br> Una vez que finalizó de cargar los datos, presione el botón
      <span class="tag is-primary">Guardar</span></p>
    </div>
      <hr>
    <Formulario :model="model" :error="error" @submit="submit" :edit="true"/>
  </div>
</template>

<script>
import Formulario from '@/components/organizacion/usuario/Formulario'

export default {
  components: {
    Formulario
  },
  props: ['idOrganization', 'idUser'],
  data () {
    return {
      model: {
        acceso: '',
        cuil: '',
        nombre: '',
        apellido: '',
        email: ''
      },
      error: '',
      showHelp: false
    }
  },
  mounted: function () {
    for (const prop in this.model) {
      if (Object.prototype.hasOwnProperty.call(this.usuario, prop)) {
        this.model[prop] = this.usuario[prop]
      }
    }
  },
  methods: {
    submit: function () {
      this.startLoading()
      this.$http
        .request({
          method: 'post',
          url: '/usuario/' + this.idUser,
          data: this.payload
        })
        .then(response => {
          this.$buefy.snackbar.open({
            message: response.data.mensaje,
            type: 'is-success'
          })
          this.$emit('updateResource')
          this.$router.push({ name: 'usuarioInfo', params: { idOrganization: this.idOrganization, idUser: this.idUser } })
          this.stopLoading()
        })
        .catch(error => {
          console.error(error)
          this.$buefy.snackbar.open({
            message: error.mensaje,
            type: 'is-danger'
          })
          this.stopLoading()
        })
    }
  },
  computed: {
    usuario: function () {
      return this.$store.state.organizacion.selectedUser
    },
    payload: function () {
      return {
        acceso: this.model.acceso,
        cuil: this.model.cuil,
        nombre: this.model.nombre,
        apellido: this.model.apellido,
        email: this.model.email
      }
    }
  }
}
</script>
