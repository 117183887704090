<template>
  <section class="recent-docs">
    <div class="recent-docs-container pt-3">
      <div class="px-3 pb-2 is-flex is-justify-content-space-between">
        <p><b>Últimos documentos</b>&nbsp;
        <span v-if="isLoading" class="has-text-primary is-size-7"><i class="fas fa-sync fa-spin fa-fw"></i>&nbsp;<span class="">Cargando..</span></span>
        <a @click="fetchRecentDocuments" v-if="show && !isLoading" ><i class="fas fa-sync fa-fw"></i></a></p>
        <div class="ml-2">
          <a @click="show = !show"> <i class="fas fa-lg" :class="{'fa-angle-double-down': show, 'fa-angle-double-up': !show}"></i></a>
        </div>
      </div>
      <div class="px-2 pb-2" v-if="show && isLoading">
        <div class="is-flex">
          <div class="mx-2"><b-skeleton height="125px" width="125px"></b-skeleton></div>
          <div class="mx-2"><b-skeleton height="125px" width="125px"></b-skeleton></div>
          <div class="mx-2"><b-skeleton height="125px" width="125px"></b-skeleton></div>
          <div class="mx-2"><b-skeleton height="125px" width="125px"></b-skeleton></div>
          <div class="mx-2"><b-skeleton height="125px" width="125px"></b-skeleton></div>
          <div class="mx-2"><b-skeleton height="125px" width="125px"></b-skeleton></div>
          <div class="mx-2"><b-skeleton height="125px" width="125px"></b-skeleton></div>
          <div class="mx-2"><b-skeleton height="125px" width="125px"></b-skeleton></div>
          <div class="mx-2"><b-skeleton height="125px" width="125px"></b-skeleton></div>
          <div class="mx-2"><b-skeleton height="125px" width="125px"></b-skeleton></div>
          <div class="mx-2"><b-skeleton height="125px" width="125px"></b-skeleton></div>
          <div class="mx-2"><b-skeleton height="125px" width="125px"></b-skeleton></div>
          <div class="mx-2"><b-skeleton height="125px" width="125px"></b-skeleton></div>
          <div class="mx-2"><b-skeleton height="125px" width="125px"></b-skeleton></div>
          <div class="mx-2"><b-skeleton height="125px" width="125px"></b-skeleton></div>
        </div>
      </div>
      <div class="flex-container is-flex" v-if="show && !isLoading">
        <div class="pl-3"/>
        <div class="document-card is-clickable" v-for="document in documents" :key="document.id" @click="openFile(document)">
            <div class="background" v-if="document.tipo_documento_id == 'carpeta'" :style="`background-image: url('/statics/default-folder.png')`" />
            <div class="background" v-else :style="`background-image: url(${document.preview_uri ? document.preview_uri : '/statics/default-document.png'})`" />
            <div class="py-2 px-3">
            <p class="has-text-primary name-file"><b>{{document.nombre}}</b></p>
            </div>
        </div>
        <div class="pl-3"/>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data () {
    return {
      show: true,
      isLoading: true,
      documents: []
    }
  },
  beforeMount: function () {
    this.show = this.settings.alwaysShowOpen
  },
  mounted: function () {
    this.fetchRecentDocuments()
  },
  methods: {
    fetchRecentDocuments () {
      this.isLoading = true
      this.$http.get('/documento?size=30')
        .then(res => {
          this.documents = res.data.data
        }).catch(err => {
          console.error(err.message)
          this.$buefy.snackbar.open({
            message: 'Error inesperado.',
            type: 'is-danger'
          })
        }).finally(() => {
          this.isLoading = false
        })
    },
    openFile (document) {
      if (document.tipo_documento_id === 'carpeta') {
        this.$router.push('/repositorio/' + document.id)
      } else if (document.tipo_documento_id === 'recibo') {
        this.$router.push('/recibo/' + document.id)
      } else if (document.tipo_documento_id === 'unidadCompuesta') {
        this.$router.push('/unidad-compuesta/' + document.id)
      } else {
        this.$router.push('/documento/' + document.id)
      }
    }
  },
  computed: {
    settings () {
      return this.$store.getters['customSettings/userSettings'].recentDocuments
    }
  }
}
</script>

<style lang="scss" scoped>
.recent-docs{
  position: fixed;
  z-index: 10;
  width: 100%;
  bottom: 0;
  background: $background;
}
.recent-docs-container{
  border-top: 1px solid #CACACA;
  display: block;
}
.flex-container{
  overflow-x: scroll;
    &::-webkit-scrollbar {
    height: 6px;
  }
  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #888;
  }
  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
   }
  .document-card{
    background-color: #FFF;
    border-radius: 5px;
    border: 1px solid #e2e2e2;
    width: 125px;
    min-width: 125px;
    max-width: 125px;
    margin: 0 5px 8px;
  }
  .background{
    height: 75px;
    background-size: cover;
    background-position: top left;
    border-bottom: 1px solid #e2e2e2;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  .name-file {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}
</style>
