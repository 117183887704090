<template>
  <table class="table is-narrow is-fullwidth is-marginless" v-if="recibo">
    <tbody>
      <tr>
        <th>Tipo de recibo</th>
        <td>{{recibo.tipo_recibo.nombre}}</td>
      </tr>
      <tr>
        <th>Fecha liquidación</th>
        <td>{{new Date(recibo.fecha) | moment("dddd, DD/MM/YYYY")}} </td>
      </tr>
      <tr>
        <th>Fecha de ingreso</th>
        <td>{{new Date(recibo.created_at) | moment("dddd, DD/MM/YYYY, HH:mm:ss")}} </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  props: ['id'],
  data () {
    return {
      cargando: true
    }
  },

  methods: {},
  computed: {
    recibo: function () {
      return this.$store.state.recibo.recibo
    }
  }
}
</script>

<style lang="scss" scoped>
img {
  border-radius: 100px;
}
.field.is-grouped {
  justify-content: flex-end;
}
</style>
