<template>
  <section>
    <!-- SUBUNIDAD SIMPLE -->
    <b-field class="file is-fullwidth">
      <b-upload v-model="file" :accept="getAccept(subunidad.formatos)">
        <a class="button is-primary">
          <i class="fas fa-upload"></i>&nbsp;
          <span>Seleccionar archivo</span>
        </a>
      </b-upload>
      <span class="file-name" v-if="file">{{ file.name }}</span>
      <span class="file-name" v-else>
        <i>Haga clic para seleccionar un archivo</i>
      </span>
    </b-field>
    <div class="content">
      <p>
        <b>Subunidad:</b>
        {{subunidad.nombre}} -
        <b>Formatos:</b>
        {{subunidad.formatos.join(',')}} -
        <b>Colección:</b>
        {{subunidad.multiple ? 'Si' : 'No'}}
      </p>
    </div>
    <button class="button is-success is-fullwidth" @click="submit" v-if="file">
      <i class="fas fa-paper-plane"></i>&nbsp;Enviar archivo
    </button>
  </section>
</template>

<script>
export default {
  props: ['accionId', 'parametros'],
  data () {
    return {
      file: null
    }
  },
  methods: {
    submit: function () {
      const data = new FormData()
      data.append('archivo', this.file)
      this.startLoading()

      this.$http
        .request({
          method: 'post',
          url: `/accion-ejecucion/${this.accionId}`,
          data: data
        })
        .then(response => {
          this.$buefy.snackbar.open({
            message: response.data.mensaje,
            type: 'is-success'
          })
          this.$emit('updateDocument')
        })
        .catch(err => {
          console.error(err)
          this.$buefy.snackbar.open({
            message: 'Error al ejecutar la acción',
            type: 'is-danger'
          })
        })
        .finally(() => {
          this.stopLoading()
        })
    },
    getAccept (formatos) {
      const mimetypes = formatos.map(type => {
        return this.getMimeType(type)
      })
      return mimetypes.join(',')
    },
    getMimeType: function (type) {
      switch (type) {
        case 'pdf':
          return 'application/pdf'
        default:
          return 'application/pdf'
      }
    }
  },
  computed: {
    subunidad: function () {
      return this.$store.state.unidadCompuesta.unidadCompuesta.serie_documental.estructura[
        this.parametros.subunidad
      ]
    }
  }
}
</script>

<style>
</style>
