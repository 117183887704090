<template>
  <div class="box">
    <h1 class="subtitle is-5">Administrar organización</h1>
      <h1 class="title is-1">Áreas</h1>
      <hr>
    <table class="table is-fullwidth">
      <thead>
        <tr>
          <th>ID</th>
          <th>Área</th>
          <th>Depende de</th>
          <th width="100" class="has-text-right">Acciones</th>
        </tr>
      </thead>
      <tbody v-if="loading">
        <tr>
          <td colspan="4" class="has-text-centered">
            <loading-spin></loading-spin>
          </td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr :id="area.id" v-for="area in areas" :key="area.id">
          <td>{{area.id}}</td>
          <td>{{area.nombre}}</td>
          <td>{{area.dependencia? area.dependencia.nombre: '-'}}</td>
          <td>
            <div class="field has-addons is-pulled-right">
              <p class="control">
                <span @click="openEditModal(area)" class="button is-small">
                  <i class="fas fa-edit fa-lg"></i>
                </span>
              </p>
              <p class="control">
                <span @click="openDeleteModal(area)" class="button is-outlined is-small is-danger">
                  <i class="fas fa-trash fa-lg"></i>
                </span>
              </p>
            </div>
          </td>
        </tr>
        <tr v-if="areas.length==0">
          <td class="has-text-centered" colspan="3">
            <section class="section is-mono">
              <span class="tag is-rounded is-medium">
                <i class="fas fa-info fa-fw"></i>&nbsp;Sin áreas para mostrar
              </span>
            </section>
          </td>
        </tr>
      </tbody>
    </table>
    <b-modal :active.sync="confirmEditModal" :width="640" scroll="keep">
      <div class="card">
        <div class="card-header">
          <p class="card-header-title">Renombrar área</p>
        </div>
        <div class="card-content">
          <div class="field has-addons">
            <p class="control is-expanded">
              <input class="input" ref="nombre" type="text" v-model="newNombre" @keyup.enter="confirmEditArea()" required placeholder="Ingrese un nuevo nombre para el área">
            </p>
          </div>
          <p class="help has-text-danger" v-if="renombroInvalidoMessage">{{renombroInvalidoMessage}}</p>
        </div>
        <div class="card-footer">
          <a class="card-footer-item" type="button" @click="confirmEditModal = false">Cancelar</a>
          <a v-if="!renombroInvalido" class="card-footer-item has-text-weight-bold is-uppercase" @click="confirmEditArea()">Renombrar</a>
        </div>
      </div>
    </b-modal>
    <b-modal :active.sync="confirmDeleteModal" :width="640" scroll="keep">
      <div class="card">
        <div class="card-content">
          <h1 class="title is-3">
          <i class="fas fa-exclamation-triangle"></i>&nbsp;Atención
        </h1>
        <h1 class="subtitle is-6">
          Está por realizar una acción sin retorno. ¿Confirma que desea eliminar el área <b>{{areaToDelete && areaToDelete.nombre}}</b>?
        </h1>
        <div class="is-clearfix">
          <button @click="confirmDeleteModal = false" class="button is-dark is-outlined">
            Cancelar
          </button>
          <button @click="confirmDeleteArea()" class="button is-danger is-pulled-right">
            Eliminar
          </button>
        </div>
        </div>
      </div>
    </b-modal>
    <paginator ref="paginator" :resource="url" @update="updateResource" :loading.sync="loading"/>
  </div>
</template>

<script>
import Paginator from '@/components/utils/Paginator'
import LoadingSpin from '@/components/utils/LoadingSpin'

export default {
  props: ['idOrganization'],
  components: {
    Paginator,
    LoadingSpin
  },
  data () {
    return {
      areas: [],
      loading: true,
      newNombre: '',
      oldNombre: '',
      areaToEdit: null,
      areaToDelete: null,
      confirmEditModal: false,
      confirmDeleteModal: false
    }
  },
  methods: {
    focusInput () {
      setTimeout(() => {
        this.$refs.nombre.focus()
      }, 200)
    },
    updateResource (data) {
      this.areas = data
    },
    openDeleteModal: function (area) {
      this.areaToDelete = area
      this.confirmDeleteModal = true
    },
    openEditModal: function (area) {
      this.focusInput()
      this.areaToEdit = area
      this.confirmEditModal = true
      console.log(area)
      this.oldNombre = area.nombre
      this.newNombre = area.nombre
    },
    confirmEditArea: function () {
      if (!this.renombroInvalido) {
        this.startLoading()
        this.$http
          .patch(`/area/${this.areaToEdit.id}`, {
            nombre: this.newNombre
          })
          .then(() => {
            this.$buefy.snackbar.open({
              message: 'Área renombrada exitosamente.',
              type: 'is-success'
            })
            this.newNombre = ''
            this.oldNombre = ''
            this.areaToEdit = null
            this.confirmEditModal = false
            this.$refs.paginator.fetchData()
          })
          .catch(error => {
            console.error(error)
            var mensajeAMostrar = ''
            if (error.codigo === 'entityAlreadyExists') {
              mensajeAMostrar = 'Ya existe un área con el mismo nombre.'
            } else {
              mensajeAMostrar = 'El servidor respondió con un error.'
            }
            this.$buefy.snackbar.open({
              message: mensajeAMostrar,
              type: 'is-danger'
            })
          })
          .finally(() => {
            this.stopLoading()
          })
      } else {
        this.$buefy.snackbar.open({
          message: 'El nombre ingresado es inválido.',
          type: 'is-danger'
        })
      }
    },
    confirmDeleteArea: function () {
      this.startLoading()
      this.$http
        .delete(`/area/${this.areaToDelete.id}`)
        .then(res => {
          this.$buefy.snackbar.open({
            message: 'Área eliminada exitosamente.',
            type: 'is-success'
          })
          this.areaToDelete = null
          this.confirmDeleteModal = false
          this.$refs.paginator.fetchData()
        })
        .catch(error => {
          console.log(error)
          this.$buefy.snackbar.open({
            message: 'El servidor respondió con un error.',
            type: 'is-danger'
          })
        })
        .finally(() => {
          this.stopLoading()
        })
    }
  },
  computed: {
    renombroInvalido: function () {
      // if newNombre is the same than the old one, return true
      if (this.newNombre === this.oldNombre) {
        return true
      }
      // if newNombre is empty, return true
      if (this.newNombre === '') {
        return true
      }
      // if newNombre doesnt start with a letter or a digit
      var regex = /^[^A-Za-z0-9áéíóúÁÉÍÓÚüÜñÑ]/
      if (regex.test(this.newNombre)) {
        return true
      }
      // if newNombre contains invalid characters
      regex = /[<>:;,?"*|\\/]+/
      if (regex.test(this.newNombre)) {
        return true
      }
      // if newNombre is not any of the above
      return false
    },
    renombroInvalidoMessage: function () {
      // if newNombre is the same than the old one, return true
      if (this.newNombre === this.oldNombre) {
        return 'El nuevo nombre debe ser distinto al nombre actual del área.'
      }
      // if newNombre is empty, return true
      if (this.newNombre === '') {
        return 'El nuevo nombre no puede ser vacío.'
      }
      // if newNombre doesnt start with a letter or a digit
      var regex = /^[^A-Za-z0-9áéíóúÁÉÍÓÚüÜñÑ]/
      if (regex.test(this.newNombre)) {
        return 'El nuevo nombre debe comenzar con una letra o dígito.'
      }
      // if newNombre contains invalid characters
      regex = /[<>:;,?"*|\\/]+/
      if (regex.test(this.newNombre)) {
        return 'El nuevo nombre no puede contener ninguno de los siguientes caracteres < > : ; , ? “ * | /'
      }
      // if newNombre is not any of the above
      return false
    },
    url () {
      return '/organizacion/' + this.idOrganization + '/area'
    }
  }
}
</script>
