import CertificadosUsuario from './Certificados'
import Listado from './Listado'
import NuevaSolicitud from './NuevaSolicitud'
import Revocacion from './Revocacion'
import Certificados from './oficial-registro/Certificados'
import OficialesRegistro from './oficial-registro/OficialesRegistro'
import Aprobacion from './oficial-registro/Aprobacion'
import Rechazo from './oficial-registro/Rechazo'
import Renovacion from './Renovacion'
import store from '@/store'

const BASE_PATH = '/ac'

const checkRoles = (requiredRoles) => (to, from, next) => {
  const userRoles = store.state.session.user.roles
  const hasRequiredRole = requiredRoles.some(role => userRoles.includes(role))
  if (!hasRequiredRole) {
    next({ path: '/unauthorized' })
  } else {
    next()
  }
}

const routes = [
  {
    path: BASE_PATH,
    meta: {
      requiresAuth: true,
      requiredRoles: ['or', 'suscriptor', 'lyris', 'cert-admin']
    },
    component: CertificadosUsuario,
    props: true,
    children: [
      {
        path: 'certificados',
        component: Listado,
        name: 'certificadosListado',
        props: true,
        beforeEnter: checkRoles(['lyris', 'suscriptor'])
      },
      {
        path: 'nuevo',
        component: NuevaSolicitud,
        name: 'certificadosNuevaSolicitud',
        props: true,
        beforeEnter: checkRoles(['lyris', 'suscriptor'])
      },
      {
        path: 'revocacion/:idCert',
        component: Revocacion,
        name: 'certificadosRevocacion',
        props: true,
        beforeEnter: checkRoles(['lyris', 'or', 'suscriptor'])
      },
      {
        path: 'renovacion/:idCert',
        component: Renovacion,
        name: 'certificadosRenovacion',
        props: true,
        beforeEnter: checkRoles(['lyris', 'suscriptor'])
      },
      {
        path: 'administrador-certificados/certificados',
        component: Certificados,
        name: 'certificadosAdministradorCertificadosListado',
        props: true,
        beforeEnter: checkRoles(['lyris', 'cert-admin'])
      },
      {
        path: 'oficial-registro/certificados',
        component: Certificados,
        name: 'certificadosOficialRegistroListado',
        props: true,
        beforeEnter: checkRoles(['lyris', 'or'])
      },
      {
        path: 'oficial-registro/organizacion/:idOrganization',
        component: OficialesRegistro,
        name: 'oficialesRegistro',
        props: true,
        beforeEnter: checkRoles(['lyris', 'or'])
      },
      {
        path: 'or/aprobacion/:idCert',
        component: Aprobacion,
        name: 'certificadoAprobacion',
        props: true,
        beforeEnter: checkRoles(['lyris', 'or'])
      },
      {
        path: 'or/rechazo/:idCert',
        component: Rechazo,
        name: 'certificadoRechazo',
        props: true,
        beforeEnter: checkRoles(['lyris', 'or', 'cert-admin'])
      }
    ]
  }
]
export default routes
