// Panel
import CustomSettings from './CustomSettings'

const BASE_PATH = '/custom-settings'

const routes = [
  {
    path: BASE_PATH,
    component: CustomSettings,
    name: 'customSettings',
    meta: {
      requiresAuth: true
    }
  }
]
export default routes
