const unidadCompuestaModule = {
  namespaced: true,
  state: {
    unidadCompuesta: null,
    operacionesDisponibles: []
  },
  mutations: {
    unidadCompuesta (state, unidadCompuesta) {
      state.unidadCompuesta = unidadCompuesta
    },
    operacionesDisponibles (state, operacionesDisponibles) {
      state.operacionesDisponibles = operacionesDisponibles
    },
    unload (state) {
      state.unidadCompuesta = null
    }
  },
  getters: {
    // getOperacionesDisponibles: (state) => {
    //   return state.serieDocumentalModuleService.operacionesDisponibles
    // },
    getOperacion: (state) => (id) => {
      return state.operacionesDisponibles.find(x => {
        return x.id === id
      })
    }
  },
  actions: {
    unload (context) {
      context.commit('unload')
    }
  }
}

export default unidadCompuestaModule
