<template>
  <div class="card">
    <div class="card-content">
      <p class="title is-3">{{ title }}</p>
      <p class="subtitle is-5">{{ subtitle }}</p>
      <div class="block">
        <p class="mb-2">
          <b-tooltip label="Nombre" position="is-right">
            <i class="fas fa-user fa-fw"></i>&nbsp;{{user.apellido}}, {{user.nombre}}
          </b-tooltip>
        </p>
        <p class="mb-2">
          <b-tooltip label="CUIL" position="is-right">
            <i class="fas fa-id-badge fa-fw"></i>&nbsp;{{user.cuil}}
          </b-tooltip>
        </p>
        <p>
          <b-tooltip label="Email" position="is-right">
            <i class="fas fa-envelope fa-fw"></i>&nbsp;{{user.email}}
          </b-tooltip>
        </p>
      </div>
      <div class="block" v-if="showReason">
        <div class="field">
          <label class="label">Motivo</label>
          <div class="control">
            <input
              ref="motivo"
              v-model="reason"
              v-validate="`max:${getMaxLength}`"
              type="text"
              name="motivo"
              class="input"
              :class="{'is-danger': errors.has('motivo')}">
          </div>
          <span v-if="errors.has('motivo')" class="help is-danger">{{errors.first('motivo')}}</span>
          <p v-else class="help">Breve descripción del motivo de baja (opcional)</p>
        </div>
      </div>
      <b-notification type= "is-info is-light" :closable="false" v-html="footerText">
      </b-notification>
    </div>
    <div class="card-footer" v-if="isLoading">
      <span class="card-footer-item animated flash slow" type="button">
        <i class="fas fa-sync fa-spin"></i>&nbsp;{{ loadingText }}
      </span>
    </div>
    <div class="card-footer" v-else>
      <a class="card-footer-item" type="button" @click="$parent.close()">Cancelar</a>
      <a class="card-footer-item has-text-weight-bold is-uppercase" :class="{'has-text-danger': buttonDangerColor}" @click.prevent="confirm">{{ action }}</a>
    </div>
  </div>
</template>

<script>
export default {
  props: ['title', 'subtitle', 'userData', 'showReason', 'loadingText', 'footerText', 'action', 'buttonDangerColor'],
  data () {
    return {
      user: null,
      reason: '',
      isLoading: false
    }
  },
  beforeMount: function () {
    this.user = { ...this.userData }
  },
  mounted: function () {
    if (this.showReason) this.focusInput()
  },
  methods: {
    focusInput () {
      setTimeout(() => {
        this.$refs.motivo.focus()
      }, 200)
    },
    confirm: function () {
      if (this.errors.has('motivo')) return
      this.isLoading = true
      const body = {
        habilitar: false,
        motivo: this.reason
      }
      this.$emit('confirm', body)
    }
  },
  computed: {
    getMaxLength () {
      return this.user.motivo_baja ? Math.min(50, 255 - this.user.motivo_baja.length) : 50
    }
  }
}
</script>
