<template>
<div class="box">
  <div v-if="$store.getters.hasAnyRole([constants.STRINGS.ROLES.LYRIS_SOPORTE.ID, 'lyris'])">
    <router-link :to="{ name: 'adminOrganizacionesListado' }" class="has-text-primary is-size-7" exact v-if="$store.getters.hasAnyRole([constants.STRINGS.ROLES.LYRIS_SOPORTE.ID, 'lyris'])">
      <i class="fas fa-arrow-left fa-fw"></i> Volver a organizaciones
    </router-link>
    <hr>
  </div>
  <b-menu :activable="false">
    <b-menu-list>
      <template #label>
        <i class="fas fa-building"></i> {{organizacion.razon_social}}
      </template>
      <b-menu-item tag="router-link" :to="{ name: 'organizacionInfo', params: { idOrganization: organizacion.id }}" exact icon="info-circle" label="Información"/>
      <b-menu-item v-if="$store.getters.hasAnyRole([constants.STRINGS.ROLES.LYRIS_SOPORTE.ID, 'lyris','admin'])" tag="router-link" exact :to="{ name: 'organizacionEditar', params: { idOrganization: organizacion.id }}" icon="edit" label="Editar información"/>
    </b-menu-list>
    <b-menu-list >
      <template #label>
        <i class="fas fa-cog"></i> Administrar
      </template>
      <b-menu-item v-if="$store.getters.hasAnyRole([constants.STRINGS.ROLES.LYRIS_SOPORTE.ID, 'lyris','admin'])">
        <template #label="props">
          <i class="fas fa-users fa-fw"></i>&nbsp;Usuarios
          <i class="is-pulled-right fas" :class="props.expanded ? 'fa-caret-up' : 'fa-caret-down'"/>
        </template>
        <b-menu-item tag="router-link" :to="{ name: 'organizacionUsuarios', params: { idOrganization: organizacion.id }}" exact icon="list" label="Listar"></b-menu-item>
        <b-menu-item tag="router-link" :to="{ name: 'organizacionUsuariosCrear', params: { idOrganization: organizacion.id }}" exact icon="plus" label="Nuevo"></b-menu-item>
        <b-menu-item tag="router-link" :to="{ name: 'organizacionUsuariosRolesPorDefecto', params: { idOrganization: organizacion.id }}" exact icon="user-tag" label="Roles por defecto"></b-menu-item>
      </b-menu-item>
      <b-menu-item v-if="$store.getters.hasAnyRole([constants.STRINGS.ROLES.LYRIS_SOPORTE.ID, 'lyris','admin'])">
        <template #label="props">
          <i class="fas fa-tags fa-fw"></i>&nbsp;Areas
          <i class="is-pulled-right fas" :class="props.expanded ? 'fa-caret-up' : 'fa-caret-down'"/>
        </template>
        <b-menu-item tag="router-link" :to="{ name: 'organizacionAreas', params: { idOrganization: organizacion.id }}" exact icon="list" label="Listar"></b-menu-item>
        <b-menu-item tag="router-link" :to="{ name: 'organizacionAreasCrear', params: { idOrganization: organizacion.id }}" exact icon="plus" label="Nueva"></b-menu-item>
      </b-menu-item>
      <b-menu-item v-if="$store.getters.hasAnyRole([constants.STRINGS.ROLES.LYRIS_SOPORTE.ID, 'lyris','aspec-admin'])">
        <template #label="props">
          <i class="fas fa-file-alt fa-fw"></i>&nbsp;Aspectos de documentos
          <i class="is-pulled-right fas" :class="props.expanded ? 'fa-caret-up' : 'fa-caret-down'"/>
        </template>
        <b-menu-item tag="router-link" :to="{ name: 'organizacionAspectos', params: { idOrganization: organizacion.id }}" exact icon="list" label="Listar"></b-menu-item>
        <b-menu-item tag="router-link" :to="{ name: 'organizacionCrearAspecto', params: { idOrganization: organizacion.id }}" exact icon="plus" label="Nuevo"></b-menu-item>
      </b-menu-item>
      <b-menu-item v-if="$store.getters.hasAnyRole([constants.STRINGS.ROLES.LYRIS_SOPORTE.ID, 'lyris','serie-admin'])">
        <template #label="props">
          <i class="fas fa-layer-group fa-fw"></i>&nbsp;Series documentales
          <i class="is-pulled-right fas" :class="props.expanded ? 'fa-caret-up' : 'fa-caret-down'"/>
        </template>
        <b-menu-item tag="router-link" :to="{ name: 'organizacionSerieDocumental', params: { idOrganization: organizacion.id }}" exact icon="list" label="Listar"></b-menu-item>
        <b-menu-item tag="router-link" :to="{ name: 'organizacionCrearSerieDocumental', params: { idOrganization: organizacion.id }}" exact icon="plus" label="Nueva"></b-menu-item>
      </b-menu-item>
      <b-menu-item v-if="$store.getters.hasAnyRole(['lyris'])">
        <template #label="props">
          <i class="fas fa-shield-alt fa-fw"></i>&nbsp;Autoridad Certificante
          <i class="is-pulled-right fas" :class="props.expanded ? 'fa-caret-up' : 'fa-caret-down'"/>
        </template>
        <b-menu-item tag="router-link" :to="{ name: 'organizacionAutoridadCertificanteOficialesRegistro', params: { idOrganization: organizacion.id }}" exact label="Oficiales de Registro"></b-menu-item>
        <b-menu-item tag="router-link" :to="{ name: 'organizacionAutoridadCertificanteCertificados', params: { idOrganization: organizacion.id }}" exact label="Certificados"></b-menu-item>
      </b-menu-item>
      <b-menu-item v-if="$store.getters.hasAnyRole([constants.STRINGS.ROLES.LYRIS_SOPORTE.ID, 'lyris','auditor'])" tag="router-link" :to="{ name: 'organizacionAuditorias', params: { idOrganization: organizacion.id }}" exact icon="tasks" label="Auditoría"/>
      <b-menu-item  tag="router-link" :to="{ name: 'organizacionModulos', params: { idOrganization: organizacion.id }}" exact icon="cubes" label="Módulos"/>
    </b-menu-list>
  </b-menu>
</div>
</template>

<script>
import constants from '@/constants'

export default {
  data () {
    return {
      constants
    }
  },
  computed: {
    organizacion: function () {
      return this.$store.state.organizacion.organizacion
    }
  }
}
</script>
