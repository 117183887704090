<template>
    <section class="section">
      <div class="container">
        <div class="columns">
          <div class="column">
            <!-- <sidebar v-if="organizacion" :organizacion="organizacion"></sidebar> -->
            <sidebar></sidebar>
          </div>
          <div class="column is-9">
            <router-view></router-view>
          </div>
        </div>
      </div>
    </section>
</template>

<script>
import Sidebar from '@/components/organizacion/Sidebar'
// import http from '@/http'
import store from '@/store'

export default {
  props: ['idOrganization'],
  components: {
    Sidebar
  },
  data () {
    return {
      // organizacion: null
    }
  },
  // created () {
  //   this.organizacion = this.$store
  // },
  // updated () {
  //   this.organizacion = this.$store
  // },
  beforeRouteUpdate: (to, from, next) => {
    // Save the next function so its visible for the promise..
    // by default this is pased to the promise.
    if (to.params.idOrganization === from.params.idOrganization) {
      console.info('[ROUTER] No need to fetch the organization, already fetched')
      next()
    } else {
      console.info('[ROUTER] Updated route - Getting organization data')
      store.dispatch('organizacion/fetchOrganizacion', to.params.idOrganization)
        .then(response => {
          next()
        })
        .catch(error => {
          console.error('[ROUTER] Fetching data failed', error)
          next(false)
        })
      // http
      //   .get('/organizacion/' + to.params.idOrganization)
      //   .then(response => {
      //     console.log('GET OK - Organizacion updated!')
      //     // store.commit('setOrganizacion', response.data)
      //     store.commit('organizacion/organizacion', response.data)
      //     next()
      //   })
      //   .catch(error => {
      //     console.error(error)
      //     console.log('Fetching data failed')
      //     next(false)
      //   })
    }
  }
}
</script>
