<template>
  <section class="box">
    <h1 class="title is-5">Estados</h1>
    <div class="content">
      <p>Puede cambiar el nombre de un estado haciendo clic en <i class="fas fa-edit has-text-primary"></i>, o eliminar haciendo clic en el botón <i class="fas fa-times-circle has-text-primary"></i></p>
    </div>
    <p v-if="serieDocumental.estados.length == 0" class="is-italic is-mono">La serie no tiene estados</p>
    <div class="field is-grouped is-grouped-multiline" v-else>
      <div class="control" v-for="(estado,slug) in serieDocumental.estados" :key="`estado-${slug}`">
        <div class="tags has-addons">
          <div class="tag is-info is-medium">{{estado}}</div>
          <div class="tag is-light is-medium"><a @click="openPromptNuevoNombre(estado,slug)" class="has-text-primary"><i class="fas fa-edit"></i></a></div>
          <div class="tag is-light is-medium"><a @click="openDialogDeleteEstado(estado,slug)" class="has-text-primary"><i class="fas fa-times-circle"></i></a></div>
        </div>
      </div>
    </div>
    <hr/>
    <!-- ===================== -->
    <h1 class="subtitle is-5">Crear un nuevo estado</h1>
    <div class="field">
      <div class="field is-grouped">
        <div class="control is-expanded">
          <input
            v-model="input.estado.nombre"
            name="Nombre del estado"
            class="input"
            v-validate="{required: true}"
            data-vv-scope="estado"
            :class="{'is-danger': errors.has('Nombre del estado','estado') }"
            placeholder="Ingrese el nombre de un estado"
          >
          <span class="help">Nombre del estado</span>
          <span
            class="help is-danger"
            v-if="errors.has('Nombre del estado','estado')"
          >{{errors.first('Nombre del estado','estado')}}</span>
        </div>
        <div class="control is-expanded">
          <input
            v-model="input.estado.slug"
            name="Clave del estado"
            data-vv-scope="estado"
            v-validate="{required: true, regex: /^[a-z0-9]+(?:[-_+][a-z0-9]+)*$/ }"
            class="input is-mono"
            :class="{'is-danger': errors.has('Clave del estado','estado') }"
          >
          <span class="help">La clave del estado es autogenerado pero pude editarlo de preferirlo</span>
          <span
            class="help is-danger"
            v-if="errors.has('Clave del estado','estado')"
          >{{errors.first('Clave del estado','estado')}}</span>
        </div>
        <div class="control" @click="submitEstado">
          <button class="button is-primary">
            <i class="fas fa-plus"></i>&nbsp;Agregar
          </button>
        </div>
      </div>
    </div>
 <!-- ===================== -->
    <hr>
    <h1 class="subtitle is-5">Estado inicial de la serie</h1>
    <div class="field is-grouped">
      <div class="control is-expanded">
        <div class="select is-fullwidth">
          <select
            v-model="selectedSlugEstadoInicial"
            name="Estado inicial"
            v-validate="{required:true}"
            data-vv-scope="serie"
            :class="{'is-danger': errors.has('Estado inicial','serie') }"
          >
            <option :value="null" disabled>- Seleccione un estado inicial -</option>
            <option
              :value="slug"
              v-for="(estado, slug) in serieDocumental.estados"
              :key="`estado-inicial-${slug}`"
            >{{estado}}</option>
          </select>
        </div>
      </div>
      <div class="control">
        <button class="button is-primary"  @click="submit">
          <i class="fas fa-save fa-fw"></i>&nbsp;Guardar
        </button>
      </div>
    </div>
    <span
      class="help is-danger"
      v-if="errors.has('Estado inicial','serie')"
    >{{errors.first('Estado inicial','serie')}}</span>
  </section>
</template>

<script>
import slugify from 'slugify'
import fieldsUtils from '@/mixins/fieldsUtils'
import validatorUtils from '@/mixins/validatorUtils'

export default {
  mixins: [fieldsUtils, validatorUtils],
  data () {
    return {
      selectedSlugEstadoInicial: null,
      input: {
        estado: {
          nombre: '',
          slug: ''
        }
      }
    }
  },
  beforeMount: function () {
    this.selectedSlugEstadoInicial = this.serieDocumental.estado_inicial
  },
  methods: {
    openPromptNuevoNombre: function (estado, slug) {
      this.$buefy.dialog.prompt({
        title: 'Cambiar nombre estado',
        message: `<p>Escriba el nuevo nombre para el estado <b>${estado}</b></p>`,
        inputAttrs: {
          placeholder: 'Ej: Inicio',
          required: true
        },
        trapFocus: true,
        confirmText: 'Cambiar nombre',
        onConfirm: (value) => this.$emit('editarNombreEstado', estado, slug, value)
      })
    },
    openDialogDeleteEstado: function (estado, slug) {
      this.$buefy.dialog.confirm({
        title: 'Eliminar estado',
        message: `<div class="content has-text-danger">
          <h1 class="title has-text-danger">Atención</h1>
          <p>
            Esta acción impactará a todos los documentos que hayan pasado por este estado.
          </p>
          <p>
            No puede eliminar estados que estén involucrados en la ejecución de un evento.
          </p>
        </div>
        <p>
          ¿Esta seguro que quiere <b>eliminar</b> el estado <b>${estado}</b>?
        </p>`,
        confirmText: 'Eliminar',
        cancelText: 'Cancelar',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => this.$emit('deleteEstado', estado, slug)
      })
    },
    submit: function () {
      if (this.estados.length === 0) {
        this.$buefy.snackbar.open({
          message: 'No puede crear una serie documental sin estados',
          type: 'is-warning'
        })
        return
      }
      if (this.isFieldInputEmpty(this.estado_inicial)) {
        this.$buefy.snackbar.open({
          message: 'Debe asignar un estado inicial a la serie docoumental',
          type: 'is-warning'
        })
        return
      }
      this.$validator.validateAll('serie').then(result => {
        if (!result) {
          this.$buefy.snackbar.open({
            message: 'Hay errores en el formulario',
            type: 'is-danger'
          })
          return
        }
        const payload = {
          estado_inicial: this.selectedSlugEstadoInicial
        }
        this.$emit('submitEstadoInicial', payload)
        this.clearValidatorErrors()
      }).catch(err => {
        console.error(err)
      })
    },
    submitEstado: function () {
      const exists = Object.keys(this.serieDocumental.estados).find(slug => {
        return slug === this.input.estado.slug
      })
      if (exists) {
        this.$buefy.snackbar.open({
          message: 'El estado a ingresar ya existe',
          type: 'is-danger'
        })
        return false
      }
      this.$validator.validateAll('estado').then(result => {
        if (!result) {
          this.$buefy.snackbar.open({
            message: 'Error al agregar un estado',
            type: 'is-danger'
          })
          return
        }
        const estado = {
          nombre: this.input.estado.nombre,
          slug: this.input.estado.slug
        }
        this.$emit('submitEstado', estado)
        // Clear inputs
        this.input.estado.nombre = ''
        this.input.estado.slug = ''
        this.clearValidatorErrors()
      })
        .catch(error => {
          console.error('Fail', error)
          this.$buefy.snackbar.open({
            message: 'Error en la validacion',
            type: 'is-danger'
          })
          return false
        })
    }
  },
  watch: {
    'input.estado.nombre': function (newVal) {
      if (!newVal) {
        this.input.estado.slug = null
        return
      }
      this.input.estado.slug = slugify(newVal.toLowerCase())
    }
  },
  computed: {
    serieDocumental: function () {
      return this.$store.state.serieDocumental.serieDocumental
    },
    idOrganizacion: function () {
      return this.$store.state.organizacion.organizacion.id
    }
  }
}
</script>

<style lang="scss" scoped>
.table-bordered {
  padding: 1em;
  border: 1px solid #cacaca;
  border-radius: 0.3em;
  table.table {
    margin-bottom: 0;
  }
}
</style>
