<template>
  <div id="app">
    <pila-documentos ref="pilaDocumentos"></pila-documentos>
    <FilePreview />
    <the-navbar v-if="!(['login','logout','loginFlow'].includes(this.$route.name))"></the-navbar>
    <router-view @startLoading="startLoading" @stopLoading="stopLoading"></router-view>
    <b-loading ref="loader" :active.sync="appLoading"></b-loading>
  </div>
</template>

<script>
import TheNavbar from '@/components/utils/TheNavbar.vue'
import PilaDocumentos from '@/components/utils/PilaDocumentos.vue'
import FilePreview from '@/components/utils/FilePreview.vue'

export default {
  name: 'app',
  data () {
    return {
      appLoading: false
    }
  },
  components: {
    TheNavbar,
    PilaDocumentos,
    FilePreview
  },
  created () {
    this.$eventHub.$on('startLoading', this.startLoading)
    this.$eventHub.$on('stopLoading', this.stopLoading)
    const splitUrl = document.location.href.split('/')
    const possibleEnv = splitUrl[3]
    if (possibleEnv !== '#') {
      this.$store.commit('setOrganizacionEnv', possibleEnv)
    }
  },
  methods: {
    startLoading: function () {
      this.appLoading = true
    },
    stopLoading: function () {
      this.appLoading = false
    }
  }
}
</script>

<style lang="scss">
// The style of the app.
// https://vue-loader.vuejs.org/en/configurations/pre-processors.html
// To make Webpack use SASS/SCSS
// >> npm install sass-loader node-sass --save-dev@

@import "@/assets/scss/app.scss";
</style>
