<template>
    <div class="box">
      <h1 class="subtitle is-5">Administrar organización</h1>
      <h1 class="title is-1">Editar información</h1>
      <div class="content">
        <p>Edite los datos de la organizacion. Una vez que finalizó de cargar los datos, presione el botón <span class="tag is-primary">Guardar</span></p>
      </div>
      <hr>
      <formulario :model="model" :error="error" v-on:submit="submit"></formulario>
    </div>
</template>

<script>
import Formulario from '@/components/organizacion/Formulario'

export default {
  props: ['idOrganization'],
  components: {
    Formulario
  },
  data () {
    return {
      // organizacion: null,
      model: {
        razon_social: '',
        cuit: '',
        slug: '',
        direccion: '',
        telefono: '',
        email: ''
      },
      error: '',
      showHelp: false
    }
  },
  created () {
    // this.organizacion = this.$store.state.organizacion.organizacion
    this.model.razon_social = this.organizacion.razon_social
    this.model.cuit = this.organizacion.cuit
    this.model.slug = this.organizacion.slug
    this.model.direccion = this.organizacion.direccion
    this.model.telefono = this.organizacion.telefono
    this.model.email = this.organizacion.email
    // this.isLoading = false
  },
  methods: {
    submit () {
      this.startLoading()
      this.$http
        .request({
          method: 'post',
          url: '/organizacion/' + this.idOrganization,
          data: this.model
        })
        .then(response => {
          this.$store.dispatch('organizacion/fetchOrganizacion', this.idOrganization)
            .catch(error => {
              this.$buefy.snackbar.open({
                message: error.response.data.message,
                type: 'is-danger'
              })
            })
          this.$buefy.snackbar.open({
            message: response.data.mensaje,
            type: 'is-success'
          })
          this.$router.push({ name: 'organizacionInfo' })
        })
        .catch(error => {
          console.error(error)
          this.error = error.mensaje
        })
        .finally(() => {
          this.stopLoading()
        })
    }
  },
  computed: {
    organizacion: function () {
      return this.$store.state.organizacion.organizacion
    }
  }
}
</script>
