<template>
  <nav class="pagination is-small" role="navigation" aria-label="pagination">
    <ul class="pagination-list" v-show="currentPage">
      <li>
        <button class="pagination-link is-current" aria-current="page">
          Pág. {{currentPage}} de {{lastPage}}
        </button>
      </li>
      <li>
        <button class="pagination-link is-current" aria-current="page">
          Tamaño:
        </button>
      </li>
      <li>
        <div class="select is-small">
          <select v-model="sizePage">
            <option :value="5">5</option>
            <option :value="10">10</option>
            <option :value="20">20</option>
            <option :value="30">30</option>
            <option :value="50">50</option>
          </select>
        </div>
      </li>
    </ul>
    <button class="pagination-previous" @click="fetchData(prevUrl)" :disabled="!prevUrl">
      {{config.prevButtonText}}
    </button>
    <button class="pagination-next" @click="fetchData(nextUrl)" :disabled="!nextUrl">
      {{config.nextButtonText}}
    </button>
  </nav>
</template>

<script>
import { debounce } from 'lodash'

export default {
  props: {
    resource: {
      type: String,
      required: true
    },
    loading: {
      type: Boolean
    }
  },
  data () {
    return {
      sizePage: 10,
      currentPage: '',
      lastPage: '',
      nextUrl: '',
      prevUrl: '',
      config: {
        prevButtonText: 'Anterior',
        nextButtonText: 'Siguiente'
      }
    }
  },
  methods: {
    fetchData: debounce(function (newUrl) {
      this.$emit('update:loading', true)
      const url = newUrl || this.resource
      const vars = this.getQueryString(url)
      const path = this.getPath(url)
      vars.size = this.sizePage
      const querystring = Object.keys(vars)
        .map(function (x) {
          return x + '=' + vars[x]
        })
        .join('&')
      this.$http
        .get(path + '?' + querystring)
        .then(response => {
          this.handleResponseData(response.data)
          this.$emit('update:loading', false)
        })
        .catch(error => {
          this.showWarning()
          this.$emit('update:loading', false)
          console.error('Fetching data failed', error)
        })
    }, 600),
    handleResponseData (response) {
      this.currentPage = response.current_page
      this.lastPage = response.last_page
      if (process.env.NODE_ENV === 'development') {
        this.nextUrl = this.currentPage === this.lastPage ? null : this.getPathFromURLOnlyDevelopment(response.next_page_url)
        this.prevUrl = this.currentPage === 1 ? null : this.getPathFromURLOnlyDevelopment(response.prev_page_url)
      } else {
        this.nextUrl = this.currentPage === this.lastPage ? null : response.next_page_url
        this.prevUrl = this.currentPage === 1 ? null : response.prev_page_url
      }
      this.$emit('update', response.data)
    },
    getPathFromURLOnlyDevelopment (url) {
      const link = document.createElement('a')
      link.href = url
      console.log(url)
      console.log(link.pathname)
      console.log(link.pathname.replace('/v1', ''))
      console.log(`${link.pathname}${link.search}`)
      return `${link.pathname.replace('/v1', '')}${link.search}`
    },
    getQueryString: function (url) {
      const vars = {}
      const hashes = url.split('?')[1]
      if (hashes !== undefined) {
        const hash = hashes.split('&')
        for (let i = 0; i < hash.length; i++) {
          const params = hash[i].split('=')
          vars[params[0]] = params[1]
        }
      }
      return vars
    },
    getPath: function (url) {
      const path = url.split('?')[0]
      return path
    },
    showWarning: function () {
      this.$buefy.snackbar.open({
        message: 'Error al obtener el recurso. Recargue la pagina',
        type: 'is-warning',
        actionText: 'Recargar',
        indefinite: true,
        onAction: () => {
          location.reload()
        }
      })
    }
  },
  watch: {
    resource () {
      this.fetchData()
    },
    sizePage: function (newVal, oldVal) {
      this.fetchData()
    }
  },
  created () {
    this.fetchData()
  }
}
</script>

<style lang="scss" scoped>
// .pagination {
//   margin-top: 10px;
// }
button.pagination-next,
button.pagination-previous {
  // border:0;
  padding-left: 10px;
  padding-right: 10px;
  background-color: transparent;
}
.pagination-link.is-current {
  background: transparent;
  border: 0;
  color: #6f6f6f;
}
</style>
