<template>
  <div class="box p-4 has-background-white mb-0">
    <div class="buttons">
      <button v-if="hasPendientes && (cantDocumentos==0 || !seleccionaSoloSusDocumentosPendientes)"  @click="$emit('firmar-pendientes')" class="button is-primary" :class="{ 'is-loading' : isFetching}">
        <i class="fas fa-file-signature fa-fw"></i>&nbsp;Firmar mis pendientes</button >
      <button v-if="hasPendientes && cantDocumentos>0 && seleccionaSoloSusDocumentosPendientes"
        @click="$emit('firmar-pendientes')" class="button is-primary" :class="{ 'is-loading' : isFetching}">
        <i class="fas fa-file-signature fa-fw"></i>&nbsp;Firmar {{cantDocumentos}} seleccionado{{cantDocumentos > 1 ? 's' : ''}}</button >
      <a @click="$emit('download')" v-show="hasDocumentosSelected" class="button is-light">
        <i class="fas fa-download fa-fw"></i>&nbsp;Descargar {{cantDocumentos}} seleccionado{{cantDocumentos > 1 ? 's' : ''}}</a>
      <a @click="refresh" class="button is-light">
        <i class="fas fa-sync fa-fw"></i>&nbsp;Recargar</a>
    </div>
  </div>
</template>

<script>
import constants from '../../constants'

export default {
  data () {
    return {
      isFetching: true,
      hasPendientes: false
    }
  },
  mounted: function () {
    this.fetch()
  },
  methods: {
    refresh: function () {
      this.$emit('refresh')
    },
    fetch: function () {
      this.isFetching = true
      this.$http.get(`/otro-documento?usuario=${this.user.id}&size=1&estado_documento_usuario=${constants.STRINGS.DOCUMENTS.STATUS.PENDING_SIGNATURE}`)
        .then(res => {
          this.hasPendientes = res.data.data.length
        }).finally(() => {
          this.isFetching = false
        })
    }
  },
  computed: {
    hasDocumentosSelected: function () {
      return this.$store.state.otrosDocumentos.selected.length > 0
    },
    cantDocumentos: function () {
      return this.$store.state.otrosDocumentos.selected.length
    },
    user: function () {
      return this.$store.getters.getUser
    },
    seleccionaSoloSusDocumentosPendientes: function () {
      return this.$store.state.otrosDocumentos.selected.every(doc =>
        doc.otro_documento_usuario.filter(odu =>
          odu.usuario.id === this.user.id &&
          odu.estado === constants.STRINGS.DOCUMENTS.STATUS.PENDING_SIGNATURE
        ).length > 0
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.box {
  margin-bottom: 1rem;
  padding: 1rem;
}
</style>
