<template>
  <div id="login">
    <section class="section">
      <div class="container has-text-centered">
        <div class="columns">
          <div class="column is-6 is-offset-3 is-4-desktop is-offset-4-desktop">
            <div v-if="!showRecover">
              <div class="box" v-if="showNormalLogin">
                <img src="/statics/signar-logo.svg" class="image" alt>
                <div class="message is-danger is-size-7" v-if="error">
                  <div class="message-body">{{error}}</div>
                </div>
                <div class="content">
                  <p class="control">
                    <input
                      @focus="reset()"
                      ref="inputEmail"
                      class="input is-medium"
                      v-model="username"
                      v-on:keyup.enter="submit"
                      type="email"
                      placeholder="Usuario"
                    >
                  </p>
                  <p class="control">
                    <input
                      @focus="reset()"
                      class="input is-medium"
                      v-model="password"
                      type="password"
                      v-on:keyup.enter="submit"
                      placeholder="Contraseña"
                    >
                  </p>
                </div>
                <div class="buttons is-centered">
                  <button @click="submit()" class="button is-primary is-fullwidth">
                    <i class="fas fa-sign-in-al"></i>&nbsp;&nbsp;Iniciar sesión
                  </button>
                  <button @click="externalLogin()" class="button is-primary is-outlined is-fullwidth">
                    <i class="far fa-id-badge"></i>&nbsp;&nbsp;Ingresar con ID externo
                  </button>
                  <button
                    @click="reset(true); showRecover = !showRecover"
                    class="button is-white is-fullwidth"
                  >¡Olvide mi contraseña!</button>
                </div>
              </div>
              <div class="box" v-else>
                <div class="content" v-if="!loadingProviders">
                  <p class="is-500"><i class="far fa-id-badge"></i>&nbsp;&nbsp;Ingresar con ID externo</p>
                  <p class="is-size-7">Iniciar sesión con...</p>
                  <p class="is-size-7" v-if="!providersToShow.length">No existen proveedores de identidad externos</p>
                  <div class="buttons">
                    <button @click="doExternalLogin(provider)" class="button is-fullwidth is-primary is-outlined" v-for="(provider, index) in providersToShow" :key="index">
                      {{provider.nombre}}
                    </button>
                  </div>
                  <div class="buttons">
                    <button
                      @click="showNormalLogin = !showNormalLogin"
                      class="button is-white is-fullwidth"
                    >Volver</button>
                  </div>
                </div>
                <div class="div" v-else>
                  <i class="fas fa-sync fa-spin"></i>
                </div>
              </div>
            </div>
            <div class="box" v-else>
              <p class="is-500">¿Olvido su contraseña?</p>
              <p
                class="is-size-7"
              >Ingrese su email para recibir un correo con los pasos a seguir para recuperar su contraseña</p>
              <br>
              <div class="notification is-danger is-size-7" v-if="error">
                <div class="message-body">{{error}}</div>
              </div>
              <div class="message is-success is-size-7" v-if="showRecoverSuccess">
                <div
                  class="message-body"
                >Hemos enviado a tu casilla de email un correo electrónico para poder recuperar tu cuenta.</div>
              </div>
              <div class="content">
                <p class="control">
                  <input
                    @focus="reset()"
                    ref="inputEmail"
                    class="input is-medium"
                    v-model="username"
                    v-on:keyup.enter="recover"
                    type="email"
                    placeholder="Email"
                  >
                </p>
              </div>
                <div class="field">
                  <div class="control">
                    <vue-recaptcha :sitekey="siteKey" @verify="catchRecaptcha" @expired="recaptcha = null"></vue-recaptcha>
                  </div>
                </div>
              <div class="buttons is-centered">
                <button
                  @click="recover()"
                  :disabled="!recaptcha"
                  class="button is-primary is-fullwidth"
                >Enviar email de recuperación</button>
                <button
                  @click="reset(true); showRecover = !showRecover"
                  class="button is-white is-fullwidth"
                >Volver</button>
                <br>
              </div>
            </div>
            <a href="https://lyris.com.ar" target="_blank"><img src="/statics/lyris-grey.svg" class="lyris-logo mt-5" alt="Lyris IT"></a>
            <p class="is-size-7 my-3">
              <a class="mx-2 has-text-grey" target="_blank" href="https://soporte-lyris.atlassian.net/servicedesk/customer/portals">Soporte&nbsp;<i class="fas fa-external-link-alt fa-fw"></i></a>
              <a class="mx-2 has-text-grey" target="_blank" href="https://docs.signar.com.ar">Documentación <i class="fas fa-external-link-alt fa-fw"></i></a>
              <a class="mx-2 has-text-grey" target="_blank" href="https://docs.signar.com.ar/release-notes/">Changelog <i class="fas fa-external-link-alt fa-fw"></i></a>
            </p>
            <p class="is-size-7 has-text-grey">Ver. {{version}}</p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import VueRecaptcha from 'vue-recaptcha'

export default {
  data () {
    return {
      showRecover: false,
      showNormalLogin: true,
      username: '',
      password: '',
      error: '',
      showRecoverSuccess: '',
      loadingProviders: true,
      providers: [],
      recaptcha: null
    }
  },
  components: {
    VueRecaptcha
  },
  beforeMount: function () {
    if (this.$store.state.session) {
      this.$router.push('/')
    }
  },
  mounted: function () {
    this.$refs.inputEmail.focus()
    // this.getProviders()
  },
  methods: {
    catchRecaptcha (response) {
      this.recaptcha = response
    },
    submit () {
      const credentials = {
        username: this.username,
        password: this.password
      }
      this.$http
        .request({
          method: 'post',
          url: '/token',
          auth: credentials
        })
        .then(response => {
          const session = {
            token: response.data.access_token,
            expiration: response.data.expiration,
            user: response.data.user
          }
          this.$store.commit('login', session)
          this.getOrganizacion(session.user.organization)
          this.$router.push(this.$route.query.redirect || '/')
        })
        .catch(error => {
          console.error(error)
          this.error = error.mensaje
          this.password = ''
        })
    },
    getOrganizacion: function (orgId) {
      this.$store.dispatch('organizacion/fetchOrganizacion', orgId)
        .catch(error => {
          console.error(error)
          this.$buefy.snackbar.open({
            message: 'Error fetching organizacion!',
            type: 'is-success'
          })
        })
    },
    recover () {
      if (!this.recaptcha) return
      const data = {
        email: this.username,
        recaptcha: this.recaptcha
      }
      this.$http
        .request({
          method: 'post',
          url: '/clave/reiniciar',
          data: data
        })
        .then(response => {
          this.$buefy.snackbar.open({
            message: 'El correo ha sido enviado con éxito',
            type: 'is-success'
          })
          this.username = ''
          this.showRecoverSuccess = true
        })
        .catch(error => {
          this.error = error.mensaje
          console.log(error)
          this.username = ''
        })
    },
    reset: function (full = false) {
      this.error = ''
      if (full) {
        this.username = ''
        this.password = ''
      }
    },
    externalLogin: function () {
      if (!this.organizacionEnv) {
        this.showNormalLogin = false
        this.getProviders()
        return
      }
      this.getProvidersAndLogin()
    },
    getProviders: function () {
      this.loadingProviders = true
      this.$http.get('/identity-provider')
        .then(response => {
          this.providers = response.data
        }).catch(error => {
          console.error(error)
        })
        .finally(() => {
          this.loadingProviders = false
        })
    },
    getProvidersAndLogin: function () {
      this.loadingProviders = true
      this.$http.get('/identity-provider')
        .then(response => {
          this.providers = response.data
          const theProvider = this.providers.find(p => {
            return p.slug === this.organizacionEnv
          })
          this.doExternalLogin(theProvider)
        }).catch(error => {
          console.error(error)
        })
        .finally(() => {
          this.loadingProviders = false
        })
    },
    doExternalLogin: function (provider) {
      if (process.env.NODE_ENV === 'development') {
        window.location.href = `${process.env.API_URL}/${provider.tipo}/${provider.slug}/login`
      } else {
        window.location.href = `/${provider.tipo}/${provider.slug}/login`
      }
    }
  },
  computed: {
    organizacionEnv: function () {
      return this.$store.state.organizacionEnv
    },
    providersToShow: function () {
      if (this.organizacionEnv === null) return this.providers
      else {
        return this.providers.filter(el => {
          return el.nombre === this.organizacionEnv
        })
      }
    },
    siteKey: function () {
      return process.env.VUE_APP_GOOGLE_CAPTCHA
    },
    version: function () {
      return process.env.VUE_APP_VERSION
    }
  }
}
</script>

<style lang="scss" scoped>
.input {
  border: 0;
  border-bottom: 1px solid #eaeaea;
  border-radius: 0 !important;
  font-weight: 300;
  text-align: center;
  letter-spacing: -0.5px; // padding-left: 2px;
  background-color: #fff;
  &:focus {
    border-bottom-color: $primary;
    box-shadow: none !important;
    background-color: #fffadd;
  }
  &.is-danger {
    color: $danger;
  }
}

img {
  max-width: 200px;
  margin: 10px auto 20px;
}
img.lyris-logo {
  max-width: 80px;
  margin-bottom: 0;
}
</style>
