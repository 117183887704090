<template>
   <b-table
    :data="firmas"
    :bordered="false"
    :narrowed="true"
    :hoverable="true"
    :loading="isLoading"
    :focusable="true"
    sortable
    :mobile-cards="true">

    <b-table-column field="id" sortable label="Orden" width="40" numeric v-slot="props">
      # {{ props.row.id }}
    </b-table-column>

    <b-table-column label="Nombre y Apellido" v-slot="props">
      <span v-if="!isLoading && getUsuario(props.row.usuario_id)">
        <i class="fas fa-user fa-fw"></i>&nbsp;{{getUsuario(props.row.usuario_id).apellido.toUpperCase()}}, {{getUsuario(props.row.usuario_id).nombre}}
      </span>
      <span v-else>
        <i class="fas fa-sync fa-spin"></i>&nbsp;Cargando...
      </span>
    </b-table-column>

    <b-table-column label="Email" v-slot="props">
      <span v-if="!isLoading && getUsuario(props.row.usuario_id)">
        <a :href="'mailto:'+getUsuario(props.row.usuario_id).email">{{getUsuario(props.row.usuario_id).email}}</a>
      </span>
      <span v-else>
        <i class="fas fa-sync fa-spin"></i>&nbsp;Cargando...
      </span>
    </b-table-column>

    <b-table-column label="Tipo de firma" v-slot="props">
      {{props.row.tipo}}
    </b-table-column>

    <b-table-column field="created_at" label="Date" sortable centered v-slot="props">
      {{ new Date(props.row.created_at) | moment('DD/MM/YYYY') }}
    </b-table-column>

    <template #empty>
      <div class="is-family-monospace">{{subunidad ? 'La subunidad' : 'El documento'}} no ha sido firmado por ningún usuario</div>
    </template>
  </b-table>
</template>

<script>
export default {
  props: ['subunidad'],
  data () {
    return {
      firmas: [],
      usuarios: [],
      isLoading: false
    }
  },
  beforeMount: function () {
    this.getVersiones()
  },
  methods: {
    getVersiones: function () {
      this.isLoading = true
      this.$http
        .get(`/documento/${this.documento.id}/versiones`)
        .then(res => {
          // Si el usuario sube una nueva versión del documento (no hay garantías de que esta contenga firmas),
          // por convención, se mostrarán solamente las firmas relizadas a partir de esta nueva versión.
          const ultimaVersionSinFirma = res.data.reverse().find(version => !version.contiene_firma)
          if (ultimaVersionSinFirma === undefined) {
            this.firmas = this.documento.firmas
          } else {
            this.firmas = this.documento.firmas.filter(
              firma => Date.parse(firma.created_at) > Date.parse(ultimaVersionSinFirma.created_at)
            )
          }
          this.getFirmantes()
        })
        .catch(error => {
          console.error(error)
          this.$buefy.snackbar.open({
            message: 'Ocurrió un error al obtener las firmas.',
            type: 'is-danger'
          })
          this.isLoading = false
        })
    },
    getFirmantes: function () {
      Promise.all(
        this.firmas.map(firma => {
          return this.$http.get('/usuario/' + firma.usuario_id)
        })
      )
        .then(responses => {
          this.usuarios = responses.map(res => {
            return res.data
          })
          this.isLoading = false
        })
        .catch(error => {
          console.error(error)
          this.$buefy.snackbar.open({
            message: error.mensaje,
            type: 'is-danger'
          })
          this.isLoading = false
        })
    },
    getUsuario: function (userId) {
      return this.usuarios.find(usuario => {
        return usuario.id === userId
      })
    }
  },
  computed: {
    documento: function () {
      if (this.subunidad) return this.subunidad
      return this.$store.state.document.document
    }
  },
  watch: {
    documento: function (newDoc) {
      this.getVersiones()
    }
  }
}
</script>
