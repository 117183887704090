<template>
  <div>
    <b-table
      :class="recibos.length > 0 ? 'cursor-pointer' : 'cursor-default'"
      :data="recibos"
      :bordered="false"
      :narrowed="false"
      :hoverable="true"
      :loading="isLoading"
      checkable
      :checked-rows="selectedRecibos"
      :mobile-cards="true"
      :sortable="true"
      @dblclick="goToDocument"
      @check="checkRow">

      <b-table-column field="nombre" label="Recibo" sortable v-slot="props">
        <span class="break-word"><i class="fas fa-dollar-sign"></i>&nbsp;&nbsp;{{ props.row.nombre }}</span>
      </b-table-column>

      <b-table-column field="empleado.usuario.apellido" sortable label="Empleado" v-slot="props" v-if="$store.getters.hasAnyRole(['lyris', 'empleador'])">
        {{ props.row.empleado.usuario.apellido }}, {{ props.row.empleado.usuario.nombre }}
      </b-table-column>

      <b-table-column field="estado" label="Conformidad" sortable centered v-slot="props">
        <span><i class="fas" :class="`${getConformidadIcon(props.row.estado)} ${getConformidadIcon(props.row.estado)}`"></i></span>
      </b-table-column>

      <b-table-column field="fecha" label="Fecha" sortable centered v-slot="props">
        {{ new Date(props.row.fecha) | moment('DD/MM/YYYY') }}
      </b-table-column>

      <b-table-column label="Tipo" centered v-slot="props">
        {{ props.row.tipo_recibo.nombre }}
      </b-table-column>

      <template #empty>
        <div class="is-family-monospace has-text-centered"><span v-if="isLoading"></span><span v-else>No hay recibos que cumplan con los filtros de busqueda</span></div>
      </template>
    </b-table>
    <infinite-loading @infinite="infiniteHandlerDocuments" :identifier="identifierLoaderDocuments">
      <div slot="no-more" class=" py-5 is-size-7 is-family-monospace"><span v-if="isLoading">Cargando...</span><span v-else>~ Fin del listado ~</span></div>
      <div slot="no-results" class=" py-5 is-size-7 is-family-monospace"><span v-if="isLoading">Cargando...</span><span v-else>~ Fin del listado ~</span></div>
    </infinite-loading>
  </div>
</template>

<script>
// import Paginator from '@/components/utils/Paginator.vue'
// import LoadingSpin from '@/components/utils/LoadingSpin.vue'
import InfiniteLoading from 'vue-infinite-loading'

export default {
  data () {
    return {
      isLoading: true,
      recibos: [],
      selectedArray: [],
      identifierLoaderDocuments: Math.random(),
      queryDocuments: {
        estado: null,
        tipo: null,
        fecha: null,
        organizacionId: null,
        empleado: null,
        page: 1
      }
    }
  },
  computed: {
    url () {
      return '/recibo'
    },
    selectedRecibos: function () {
      return this.$store.state.recibos.selected
    }
  },
  components: {
    InfiniteLoading
    // Paginator,
    // LoadingSpin
  },
  methods: {
    infiniteHandlerDocuments ($state) {
      this.isLoading = true
      this.$http.get(this.url, { params: this.queryDocuments })
        .then(({ data }) => {
          this.isLoading = false
          if (data.current_page === data.last_page) {
            this.recibos.push(...data.data)
            $state.complete()
          }
          if (data.current_page < data.last_page) {
            this.queryDocuments.page += 1
            this.recibos.push(...data.data)
            $state.loaded()
          }
        })
    },
    setQuery (query) {
      this.queryDocuments.estado = query.estado
      this.queryDocuments.tipo = query.tipo
      this.queryDocuments.fecha = query.fecha
      this.queryDocuments.organizacion_id = query.organizacionId
      this.queryDocuments.empleado = query.empleado
      this.queryDocuments.page = 1
      this.recibos = []
      this.identifierLoaderDocuments = Math.random()
    },
    refresh () {
      this.queryDocuments.page = 1
      this.recibos = []
      this.identifierLoaderDocuments = Math.random()
    },
    // updateResource (data) {
    //   this.selected = null
    //   // const anchor = data.find(e => e.nombre === '.')
    //   this.recibos = data
    // },
    goToDocument (documento) {
      console.log(documento)
      this.$router.push('/recibo/' + documento.id)
    },
    checkRow: function (checkedDocsArray) {
      this.$store.commit('recibos/setSelected', checkedDocsArray)
    },
    getConformidadIcon: function (status) {
      let icon = ''
      switch (status) {
        case 'pendiente':
          icon = 'fa-clock has-text-warning'
          break
        case 'conformidad':
        case 'disponible':
          icon = 'fa-check has-text-success'
          break
        case 'disconformidad':
          icon = 'fa-times has-text-danger'
          break
        default:
          icon = 'fa-question'
          break
      }
      return icon
    }
  }
}
</script>

<style lang="scss" scoped>
table.table {
  tbody td {
    border: 0;
  }
}
.cursor-pointer {
  cursor: pointer;
}
.cursor-default {
  cursor: default;
}
.tag {
  cursor: pointer;
}
</style>
