
const certificateExpiration = {
  data () {
    return {
      today: new Date()
    }
  },
  computed: {
    user: function () {
      return this.$store.state.profile.user
    },
    userCertificateExpirationDate: function () {
      if (this.user === null) return null
      if (this.user.expiracion_keystore === null) return null
      return new Date(this.user.expiracion_keystore)
    },
    userCertificateExpirationWarningDate: function () {
      if (!this.userCertificateExpirationDate) return null
      return this.$moment(this.user.expiracion_keystore).subtract(1, 'month').toDate()
    },
    userCertificateIsAboutToExpired: function () {
      if (!this.userCertificateExpirationDate) return null
      return this.userCertificateExpirationWarningDate < this.today && this.today < this.userCertificateExpirationDate
    },
    userCertificateIsExpired: function () {
      if (!this.userCertificateExpirationDate) return null
      return this.today >= this.userCertificateExpirationDate
    },
    userHasCertificate: function () {
      if (this.user === null) return null
      return this.user.keystore
    }
  }
}

export default certificateExpiration
