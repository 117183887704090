<template>
  <section>
  <div class="box">
    <h1 class="subtitle is-5">Administrar usuario</h1>
    <h1 class="title is-1">Areas</h1>
    <div class="content">
       <p>Elija el área, describa su cargo (opcional) y haga clic en <span class="tag is-primary">Asignar</span></p>
    </div>
    <hr>
<div class="field is-horizontal">
  <div class="field-body">
    <div class="field">
      <label class="label">Seleccióne el area</label>
      <b-select v-model="selectedArea" expanded>
          <option :value="null" selected disabled>- Seleccione el area -</option>
          <option v-for="option in areasDisponibles" :key="option.id" :value="option.id">
            {{ option.nombre }}
          </option>
        </b-select>
    </div>
    <div class="field">
      <label class="label">Defina el cargo en el area</label>
        <b-input type="text" v-model="cargo" placeholder="Cargo" :disabled="!selectedArea" expanded></b-input>
    </div>
    </div>
  </div>
  <hr>
  <div class="buttons is-right">
          <button class="button is-primary" @click="agregarArea(selectedArea)" :disabled="!selectedArea && cargo === null && false"><i class="fas fa-plus"></i>&nbsp;Asignar</button>
  </div>
</div>
  <div class="box">
    <h3 class="title is-5">Areas asignadas</h3>
    <div class="content">
      <p>Puede eliminar cualquiera de los registros haciendo clic en el boton <i class="fas fa-times-circle has-text-primary"></i></p>
    </div>
    <hr>
      <b-field grouped group-multiline v-if="usuario.areas.length">
        <div class="control" v-for="area in usuario.areas" :key="area.id">
          <b-taglist attached>
            <b-tag type="is-dark" size="is-medium">{{area.nombre}}</b-tag>
            <b-tag type="is-light" size="is-medium" closable @close="quitarArea(area.id)">{{area.pivot.cargo}}&nbsp;</b-tag>
          </b-taglist>
        </div>
      </b-field>
      <p v-else class="is-mono">- El usuario no tiene areas registradas - </p>
  </div>
  </section>
</template>

<script>
export default {
  props: ['idOrganization', 'idUser'],
  data () {
    return {
      error: '',
      areasOrg: [],
      isLoading: false,
      selectedArea: null,
      cargo: null,
      showHelp: false
    }
  },
  created () {
    this.obtenerAreasDisponibles()
  },
  methods: {
    obtenerAreasDisponibles () {
      this.startLoading()
      this.$http
        .get('/organizacion/' + this.idOrganization + '/area?size=100')
        .then(response => {
          this.areasOrg = response.data.data
          this.stopLoading()
        })
        .catch(error => {
          this.$buefy.snackbar.open({
            message: error.mensaje,
            type: 'is-danger'
          })
          this.stopLoading()
        })
    },
    quitarArea (area) {
      this.$http
        .request({
          method: 'delete',
          url: '/area/' + area + '/usuario/' + this.idUser
        })
        .then(response => {
          // let needle = this.areas.find(e => e.id == area);
          // this.areas = this.areas.filter(e => e.id != area);
          // this.areasOrg.push(needle);
          this.$emit('updateResource')
          this.$buefy.snackbar.open({
            message: response.data.mensaje,
            type: 'is-success'
          })
        })
        .catch(error => {
          this.$buefy.snackbar.open({
            message: error.mensaje,
            type: 'is-danger'
          })
        })
    },
    agregarArea (area) {
      const data = this.cargo ? { cargo: this.cargo } : null
      this.$http
        .request({
          method: 'post',
          url: '/area/' + area + '/usuario/' + this.idUser,
          data: data
        })
        .then(response => {
          this.$emit('updateResource')
          this.cargo = null
          this.selectedArea = null
          this.$buefy.snackbar.open({
            message: response.data.mensaje,
            type: 'is-success'
          })
        })
        .catch(error => {
          this.$buefy.snackbar.open({
            message: error.mensaje,
            type: 'is-danger'
          })
        })
    }
  },
  computed: {
    usuario: function () {
      return this.$store.state.organizacion.selectedUser
    },
    areasDisponibles: function () {
      const pluck = this.usuario.areas.map(o => o.id)
      return this.areasOrg.filter(item => {
        return !pluck.includes(item.id)
      })
    }
  }
}
</script>
