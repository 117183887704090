<template>
    <table class="table is-narrow is-fullwidth table-metadatos" :class="{'is-bordered': bordered}">
      <thead>
        <tr>
          <th colspan="2" class="is-400">
            <div class="is-flex is-justify-content-space-between is-align-items-center">
              <span class="is-size-6 is-500 has-text-primary">
                {{aspecto.nombre}}
              <span class="tag is-primary is-rounded ml-3" v-if="editMode && !forceEdit"><span class="animated flash infinite slower">Editando</span> <button class="delete is-small" @click="editMode = false"></button></span>
              </span>
              <b-switch v-model="showDetails" :outlined="true" :left-label="true" :rounded="false" size="is-small" class="is-400">Ver detalles</b-switch>
            </div>
            <p class="is-size-7" v-if="showDetails">
            {{aspecto.descripcion}}
            </p>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(atributo, index) in atributos" :key="`att-${index}`">
          <td>
            <div class="is-flex is-justify-content-space-between is-align-items-center">
              <p>
              <b>{{atributo.nombre}}</b>
              <span class="has-text-danger is-size-7 is-300" v-if="atributo.obligatorio">* Requerido</span>
              </p>
              <span class="is-size-7 has-text-grey-light">({{getNombreAtributo(atributo.tipo)}})</span>
            </div>
            <p class="help" v-if="showDetails">
              <span :class="{'has-text-danger': atributo.obligatorio}">({{atributo.obligatorio ? 'Requerido' : 'Opcional'}})</span> {{atributo.descripcion}}
            </p>
          </td>
          <td :class="{'editing': editMode}">
            <section @click="showEditMode" v-if="!editMode">
              <p
              class=""
              v-if="getNombreAtributo(atributo.tipo) == 'Binario'"
            >{{metadatos[atributo.id] !== null && getValueBinario(metadatos[atributo.id]) || '-'}}</p>
            <p
              class=""
              v-else-if="getNombreAtributo(atributo.tipo) == 'Fecha'"
            >{{(metadatos[atributo.id] !== null && (getMomentDate(metadatos[atributo.id]))) || '-'}}</p>
            <p
              class=""
              v-else
            >{{(metadatos[atributo.id] ) || '-'}}</p>
            </section>
            <section v-else>
                <div class="control is-expanded" v-if="isInputText(atributo.tipo)">
                  <input
                    type="text"
                    v-if="isNumber(atributo.tipo)"
                    v-model="inputs[atributo.id]"
                    :name="atributo.id"
                    class="input"
                    v-validate="getRules(atributo.obligatorio, atributo.tipo)"
                    :class="{'is-danger': errors.has(atributo.id)}"
                    :placeholder="getPlaceholderAtributo(atributo.tipo)"
                  />
                  <b-datepicker
                    v-model="inputs[atributo.id]"
                    v-else-if="isDate(atributo.tipo)"
                    :date-formatter="dateFormatter"
                    :first-day-of-week="1"
                    :append-to-body="isOnModal"
                    :mobile-native="false"
                    placeholder="Haga clic para elegir una fecha"
                    @input="(date) => updateDateInput(date, atributo.id)"
                    trap-focus>
                    <b-button
                      label="Borrar fecha"
                      type="is-danger is-small is-fullwidth"
                      icon-left="times"
                      @click="clearDate(atributo.id)" />
                  </b-datepicker>
                  <input
                    type="text"
                    v-else
                    v-model="inputs[atributo.id]"
                    :name="atributo.id"
                    class="input"
                    v-validate="getRules(atributo.obligatorio, atributo.tipo)"
                    :class="{'is-danger': errors.has(atributo.id)}"
                    :placeholder="getPlaceholderAtributo(atributo.tipo)"
                  />
                  <input
                    type="hidden"
                    v-model="auxInputs[atributo.id]"
                    :name="`aux-${atributo.id}`"
                    class="input"
                    v-if="atributo.tipo === 'date'"
                    v-validate="getRules(atributo.obligatorio, atributo.tipo)"
                    :class="{'is-danger': errors.has(`aux-${atributo.id}`)}"
                    :placeholder="getPlaceholderAtributo(atributo.tipo)"
                  />
                  <p class="help is-danger" v-if="errors.has(atributo.id)">{{errors.first(atributo.id)}}</p>
                  <p class="help is-danger" v-if="errors.has(`aux-${atributo.id}`)">{{errors.first(`aux-${atributo.id}`)}}</p>
                </div>
              <div v-else class="control is-expanded">
                <div class="select is-fullwidth">
                  <select
                    v-model="inputs[atributo.id]"
                    :name="atributo.nombre"
                    v-if="atributo.tipo == 'boolean'"
                  >
                    <option :value="null">-</option>
                    <option :value="true">Si / Verdadero</option>
                    <option :value="false">No / Falso</option>
                  </select>
                  <select
                    v-model="inputs[atributo.id]"
                    :name="atributo.nombre"
                    v-if="atributo.tipo == 'enum'"
                  >
                    <option :value="null">-</option>
                    <option
                      :value="opcion"
                      v-for="opcion in atributo.parametros.opciones"
                      :key="opcion"
                    >{{opcion}}</option>
                  </select>
                </div>
              </div>
            </section>
          </td>
        </tr>
        <tr class="save-button" v-if="editMode && !hideSaveButton && canEdit" @click="submit">
          <td colspan="2">
            <button class="button is-info is-fullwidth is-small is-radiusless"><i class="fas fa-save"></i>&nbsp;Guardar metadatos</button>
          </td>
        </tr>
      </tbody>
    </table>
</template>

<script>
import fieldsUtils from '@/mixins/fieldsUtils'
import attributesUtils from '@/mixins/attributesUtils'

export default {
  props: {
    aspecto: {
      type: Object,
      required: true
    },
    metadatos: {
      type: Object,
      required: false,
      default: () => ({})
    },
    forceEdit: {
      type: Boolean,
      required: false,
      default: false
    },
    hideSaveButton: {
      type: Boolean,
      required: false,
      default: false
    },
    readOnly: {
      type: Boolean,
      required: false,
      default: false
    },
    bordered: {
      type: Boolean,
      required: false,
      default: false
    },
    canEdit: {
      type: Boolean,
      required: false,
      default: false
    },
    isOnModal: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  mixins: [fieldsUtils, attributesUtils],
  data () {
    return {
      inputs: {},
      auxInputs: {},
      showDetails: false,
      editMode: false
    }
  },
  mounted: function () {
    this.updateInputs()
    if (this.forceEdit) this.editMode = true
  },
  methods: {
    showEditMode: function () {
      if (this.readOnly) {
        this.editMode = false
        return
      }
      if (this.canEdit) this.editMode = true
    },
    isInputText: function (tipo) {
      switch (tipo) {
        case 'string':
        case 'number':
        case 'integer':
        case 'date':
          return true
        case 'boolean':
        case 'enum':
          return false
        default:
          return false
      }
    },
    isInputSelect: function (tipo) {
      switch (tipo) {
        case 'string':
        case 'number':
        case 'integer':
        case 'date':
          return true
        case 'boolean':
        case 'enum':
          return false
        default:
          return false
      }
    },
    // isBoolean: function(schema) {
    //   if (schema.type != undefined && schema.type == "boolean") {
    //     return true;
    //   }
    //   if (schema.oneOf != undefined && schema.oneOf[0].type == "boolean") {
    //     return true;
    //   }
    //   return false;
    // },
    // isNumber: function(schema) {
    //   if (schema.type != undefined && (schema.type == "integer" || schema.type == "number")) {
    //     return true;
    //   }
    //   if (schema.oneOf != undefined && (schema.oneOf[0].type == "integer" || schema.oneOf[0].type == "number")) {
    //     return true;
    //   }
    //   return false;
    // },
    isNumber: function (tipo) {
      switch (tipo) {
        case 'number':
        case 'integer':
          return true
        default:
          return false
      }
    },
    isDate: function (tipo) {
      if (tipo === 'date') return true
      return false
    },
    makePayload: function () {
      if (!this.canEdit) return
      const returnedPayload = {}
      this.atributosKeys.forEach(atr => {
        const atributo = this.getAtributo(atr)
        if (!atributo.obligatorio) {
          if (this.isFieldInputEmpty(this.inputs[atr])) returnedPayload[atr] = null
          else returnedPayload[atr] = this.makeValue(atributo.tipo, this.inputs[atr])
        } else {
          returnedPayload[atr] = this.makeValue(atributo.tipo, this.inputs[atr])
        }
      })
      return returnedPayload
    },
    makeValue: function (tipo, val) {
      switch (tipo) {
        case 'number':
        case 'integer': {
          const n = parseFloat(val)
          return isNaN(n) ? val : n
        }
        case 'date':
          return this.$moment(val).format('YYYY-MM-DD')
        case 'boolean':
        case 'string':
        case 'enum':
        default:
          return val
      }
    },
    getAtributo: function (atributoKey) {
      return this.aspecto.atributos.find(atributo => atributo.id === atributoKey)
    },
    getValueBinario: function (val) {
      if (val === null || val === undefined) {
        return '-'
      } else {
        if (val === true) {
          return 'Si / Verdadero'
        } else {
          return 'No / Falso'
        }
      }
    },
    updateInputs: function () {
      this.inputs = {}
      this.atributosKeys.forEach(atr => {
        if (this.metadatos[atr]) {
          if (this.getAtributo(atr).tipo === 'date') {
            this.inputs[atr] = new Date(`${this.metadatos[atr]} 00:00:00`)
            this.auxInputs[atr] = this.metadatos[atr]
            // this.inputs[atr] = this.metadatos[atr]
          } else {
            this.inputs[atr] = this.metadatos[atr]
          }
        } else if (this.metadatos[atr] === false) {
          this.inputs[atr] = false
        } else this.inputs[atr] = null
      })
    },
    clearDate (atributoId) {
      const aux = {}
      aux[atributoId] = null
      this.inputs = Object.assign({}, this.inputs, aux)
      this.auxInputs = Object.assign({}, this.auxInputs, aux)
      this.$nextTick()
        .then(() => this.$validator.validate(`aux-${atributoId}`))
    },
    dateFormatter: function (date) {
      return this.$moment(date).format('DD/MM/YYYY')
    },
    updateDateInput: function (date, atributoId) {
      const aux = {}
      aux[atributoId] = this.$moment(date).format('YYYY-MM-DD')
      this.auxInputs = Object.assign({}, this.auxInputs, aux)
      this.$nextTick()
        .then(() => this.$validator.validate(`aux-${atributoId}`))
    },
    getMomentDate: function (date) {
      if (!date) return '-'
      return this.$moment(new Date(`${date} 00:00:00`)).format('DD/MM/YYYY')
    },
    getRules: function (required, tipo) {
      const rules = {}
      if (required) rules.required = true
      switch (tipo) {
        case 'integer':
          rules.integer = true
          break
        case 'number':
          rules.decimal = true
          break
        case 'date':
          rules.date_format = 'yyyy-MM-dd'
          // rules.date_format = 'dd/MM/yyyy'
          break
      }
      return rules
    },
    reloadForm: function () {
      this.updateInputs()
      this.$nextTick().then(() => {
        this.$validator.reset()
        this.errors.clear()
      })
    },
    submit: function () {
      if (!this.canEdit) return
      this.$validator
        .validate()
        .then(result => {
          if (!result) {
            throw Error()
          }
          this.$emit('submit', this.makePayload())
          this.editMode = false
        })
        .catch(err => {
          console.error(err)
          this.$buefy.snackbar.open({
            message: 'Error, revisé los campos de los atributos',
            type: 'is-danger'
          })
        })
    },
    getPayload: function () {
      if (!this.canEdit) return
      return new Promise((resolve, reject) => {
        this.$validator
          .validate()
          .then(result => {
            if (!result) {
              throw Error()
            }
            resolve(this.makePayload())
          })
          .catch(err => {
            reject(err)
          })
      })
    }
  },
  computed: {
    atributos: function () {
      return this.aspecto.atributos
    },
    required: function () {
      const obligatorios = this.aspecto.atributos.map(atr => {
        if (atr.obligatorio) return atr.id
      })
      return obligatorios
    },
    atributosKeys: function () {
      return this.atributos.map(att => att.id)
    },
    validationFields: function () {
      return Object.keys(this.fields)
    }
  },
  watch: {}
}
</script>

<style>
</style>
