<template>
  <div>
    <div class="box">
      <h1 class="subtitle is-5">Administrar organización</h1>
      <h1 class="title is-1">Módulos instalados</h1>
    </div>
    <section class="has-text-centered" v-if="loading">
      <loading-spin></loading-spin>
    </section>
    <section v-else>
    <div class="section is-mono" v-if="!response.ok">
                <span class="tag is-rounded is-medium is-danger"><i class="fas fa-times fa-fw"></i>&nbsp;Sin áreas para mostrar</span>
    </div>
    <admin-brief :idOrganization="idOrganization" :about.sync="modulos.administracion" :settings.sync="config.administracion" v-if="response.ok" @update="getData"></admin-brief>
    <repositorio-brief :idOrganization="idOrganization" :about.sync="modulos.repositorios" :settings.sync="config.repositorios" v-if="response.ok" @update="getData"></repositorio-brief>
    <recibo-brief :idOrganization="idOrganization" :about.sync="modulos.recibos" :settings.sync="config.recibos" v-if="response.ok" @update="getData"></recibo-brief>
    <otros-documentos-brief :idOrganization="idOrganization" :about.sync="modulos.otrosDocumentos" :settings.sync="config.otrosDocumentos" v-if="response.ok" @update="getData"></otros-documentos-brief>
    <aspectos-brief :idOrganization="idOrganization" :about.sync="modulos.aspectos" :settings.sync="config.aspectos" v-if="response.ok" @update="getData"></aspectos-brief>
    <series-documentales-brief :idOrganization="idOrganization" :about.sync="modulos.seriesDocumentales" :settings.sync="config.seriesDocumentales" v-if="response.ok" @update="getData"></series-documentales-brief>
    <autoridad-registro-brief :idOrganization="idOrganization" :about.sync="modulos.autoridadRegistro" :settings.sync="config.autoridadRegistro" v-if="response.ok" @update="getData"/>
    <auditorias-brief :idOrganization="idOrganization" :about.sync="modulos.auditorias" :settings.sync="config.auditorias" v-if="response.ok" @update="getData"></auditorias-brief>
    </section>
  </div>
</template>

<script>
import AdminBrief from '@/components/organizacion/modulos/administracion/ModuleBrief'
import RepositorioBrief from '@/components/organizacion/modulos/repositorio/ModuleBrief'
import ReciboBrief from '@/components/organizacion/modulos/recibos/ModuleBrief'
import OtrosDocumentosBrief from '@/components/organizacion/modulos/otros-documentos/ModuleBrief'
import AspectosBrief from '@/components/organizacion/modulos/aspectos/ModuleBrief'
import SeriesDocumentalesBrief from '@/components/organizacion/modulos/series-documentales/ModuleBrief'
import AuditoriasBrief from '@/components/organizacion/modulos/auditorias/ModuleBrief'
import AutoridadRegistroBrief from '@/components/organizacion/modulos/autoridad-registro/ModuleBrief'
import LoadingSpin from '@/components/utils/LoadingSpin'

export default {
  props: ['idOrganization'],
  components: {
    AdminBrief,
    RepositorioBrief,
    ReciboBrief,
    OtrosDocumentosBrief,
    AspectosBrief,
    SeriesDocumentalesBrief,
    AuditoriasBrief,
    AutoridadRegistroBrief,
    LoadingSpin
  },
  data () {
    return {
      modulos: {
        administracion: null,
        recibos: null,
        otrosDocumentos: null,
        aspectos: null,
        // circuitos: null,
        repositorios: null,
        seriesDocumentales: null,
        autoridadRegistro: null,
        auditorias: null
      },
      config: {
        administracion: null,
        recibos: null,
        otrosDocumentos: null,
        aspectos: null,
        // circuitos: null,
        repositorios: null,
        seriesDocumentales: null,
        autoridadRegistro: null,
        auditorias: null
      },
      loading: true,
      response: {
        ok: false
      }
    }
  },
  beforeMount: function () {
    this.getData()
  },
  computed: {},
  methods: {
    getData: function () {
      this.loading = true
      this.response.ok = false
      const promises = [
        this.$http.get('/organizacion/' + this.idOrganization + '/modulo'),
        this.$http.get('/modulo')
      ]
      Promise.all(promises)
        .then(responses => {
          this.config.recibos = responses[0].data.find(element => {
            return element.modulo_id === 'recib'
          })
          this.config.otrosDocumentos = responses[0].data.find(element => {
            return element.modulo_id === 'otros'
          })
          this.config.administracion = responses[0].data.find(element => {
            return element.modulo_id === 'admin'
          })
          this.config.aspectos = responses[0].data.find(element => {
            return element.modulo_id === 'aspec'
          })
          // this.config.circuitos = responses[0].data.find(element => {
          //   return element.modulo_id === 'circu'
          // })
          this.config.repositorios = responses[0].data.find(element => {
            return element.modulo_id === 'repos'
          })
          this.config.seriesDocumentales = responses[0].data.find(element => {
            return element.modulo_id === 'serie'
          })
          this.config.autoridadRegistro = responses[0].data.find(element => {
            return element.modulo_id === 'aureg'
          })
          this.config.auditorias = responses[0].data.find(element => {
            return element.modulo_id === 'audit'
          })
          this.modulos.recibos = responses[1].data.find(element => {
            return element.id === 'recib'
          })
          this.modulos.otrosDocumentos = responses[1].data.find(element => {
            return element.id === 'otros'
          })
          this.modulos.administracion = responses[1].data.find(element => {
            return element.id === 'admin'
          })
          this.modulos.aspectos = responses[1].data.find(element => {
            return element.id === 'aspec'
          })
          // this.modulos.circuitos = responses[1].data.find(element => {
          //   return element.id === 'circu'
          // })
          this.modulos.repositorios = responses[1].data.find(element => {
            return element.id === 'repos'
          })
          this.modulos.seriesDocumentales = responses[1].data.find(element => {
            return element.id === 'serie'
          })
          this.modulos.autoridadRegistro = responses[1].data.find(element => {
            return element.id === 'aureg'
          })
          this.modulos.auditorias = responses[1].data.find(element => {
            return element.id === 'audit'
          })
          this.loading = false
          this.response.ok = true
        })
        .catch(e => {
          console.error(e)
          this.loading = false
          this.response.ok = false
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.card {
  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }
  margin: 10px 0;
}
</style>
