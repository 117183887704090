import { render, staticRenderFns } from "./ModuleInstaller.vue?vue&type=template&id=369b9c74&scoped=true"
import script from "./ModuleInstaller.vue?vue&type=script&lang=js"
export * from "./ModuleInstaller.vue?vue&type=script&lang=js"
import style0 from "./ModuleInstaller.vue?vue&type=style&index=0&id=369b9c74&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "369b9c74",
  null
  
)

export default component.exports