<template>
  <b-collapse :open="false" class="card" animation="slide" aria-id="contentIdForA11y3">
    <template #trigger="props">
      <div class="card-header" role="button" aria-controls="contentIdForA11y3">
        <p class="card-header-title"><i class="fas fa-filter"></i>&nbsp;Filtros</p>
        <a class="card-header-icon">
          <i class="fas fa-lg" :class="props.open ? 'fa-angle-up' : 'fa-angle-down'"></i>
        </a>
      </div>
    </template>
    <div class="card-content">
      <b-field class="mb-4">
        <b-checkbox v-model="checkboxGroup" native-value="fecha-accion">
          Fecha acción
        </b-checkbox>
            <b-datepicker class="mb-2"
              v-if="checkboxGroup.includes('fecha-accion')"
              aria-label="desde"
              v-model="model.fechaDesde"
              placeholder="desde - DD/MM/AAAA"
              icon="calendar-today"
              :locale="locale"
              size="is-small"
              :date-formatter="dateFormatter"
            >
              <b-button
                label="Borrar fecha"
                icon-left="times"
                type="is-danger"
                expanded
                v-on:click="model.fechaDesde = null">
                <i class="fas fa-times"></i>&nbsp; Borrar fecha
              </b-button>
            </b-datepicker>
            <b-datepicker
              v-if="checkboxGroup.includes('fecha-accion')"
              v-model="model.fechaHasta"
              placeholder="hasta - DD/MM/AAAA"
              icon="calendar-today"
              :locale="locale"
              size="is-small"
              :date-formatter="dateFormatter"
            >
              <b-button
                label="Borrar fecha"
                icon-left="times"
                type="is-danger"
                expanded
                v-on:click="model.fechaHasta = null">
                <i class="fas fa-times"></i>&nbsp; Borrar fecha
              </b-button>
            </b-datepicker>
      </b-field>
      <b-field class="mb-4">
        <b-checkbox v-model="checkboxGroup" native-value="usuarioId">
          Usuario
        </b-checkbox>
        <b-autocomplete
          size="is-small"
          v-if="checkboxGroup.includes('usuarioId') && !selectedUser"
          v-model="userInput"
          :data="searchUserResults"
          placeholder="Escriba nombre o apellido"
          :loading="isFetching"
          @input="getUser"
          @select="(option) => selectUser(option)"
        >
          <template slot-scope="props">
            <i class="fas fa-user"></i>
            {{ props.option.apellido.toUpperCase() }}, {{ props.option.nombre }}
            <br />
            <span class="is-size-7"
              >{{ props.option.cuil }} - {{ props.option.email }}</span
            >
          </template>
          <template slot="empty"
            >No hay resultados para {{ userInput }}</template
          >
        </b-autocomplete>
        <p v-if="checkboxGroup.includes('usuarioId') && selectedUser">
          <a @click.prevent="clearSelectedUser()"
            ><i class="fas fa-times-circle"></i></a
          >&nbsp;{{ selectedUser.apellido.toUpperCase() }},
          {{ selectedUser.nombre }}
        </p>
      </b-field>
      <b-field class="mb-4">
        <b-checkbox v-model="checkboxGroup" native-value="ipOrigen">
          IP origen
        </b-checkbox>
        <b-input
          v-if="checkboxGroup.includes('ipOrigen')"
          v-model="model.ipOrigen"
          placeholder="IP origen"
          size="is-small"
        ></b-input>
      </b-field>
      <b-field class="mb-4">
        <b-checkbox v-model="checkboxGroup" native-value="accion">
          Acción
        </b-checkbox>
        <b-input
          v-if="checkboxGroup.includes('accion')"
          v-model="model.accion"
          placeholder="acción"
          size="is-small"
        ></b-input>
      </b-field>
      <b-field class="mb-4">
        <b-checkbox v-model="checkboxGroup" native-value="entidad">
          Entidad
        </b-checkbox>
        <b-input
          v-if="checkboxGroup.includes('entidad')"
          v-model="model.entidad"
          placeholder="entidad"
          size="is-small"
        ></b-input>
      </b-field>
      <b-field>
        <b-checkbox v-model="checkboxGroup" native-value="entidadId">
          ID Entidad
        </b-checkbox>
        <b-input
          v-if="checkboxGroup.includes('entidadId')"
          type="number"
          step="1"
          min="1"
          v-model="model.entidadId"
          placeholder="ID entidad"
          size="is-small"
        ></b-input>
      </b-field>
    </div>
    <footer class="card-footer">
      <a class="card-footer-item" @click="emitToParent">Filtrar</a>
    </footer>
  </b-collapse>
</template>

<script>
import debounce from 'lodash/debounce'
export default {
  props: ['idOrganization', 'model'],
  components: {},
  data () {
    return {
      userInput: '',
      selectedUser: null,
      locale: undefined, // Browser localel
      checkboxGroup: [],
      searchUserResults: [],
      isFetching: null
    }
  },
  methods: {
    emitToParent (event) {
      console.log(this.checkboxGroup)
      this.$emit('filtrar', this.checkboxGroup)
    },
    selectUser (option) {
      this.selectedUser = option
      this.model.usuarioId = option.id
    },
    clearSelectedUser () {
      this.selectedUser = this.userInput = null
      this.model.usuarioId = null
    },
    dateFormatter (dt) {
      return dt.toLocaleDateString('en-GB', { year: 'numeric', month: 'numeric', day: 'numeric' })
    },
    getUser: debounce(
      function () {
        if (!this.selectedUser) {
          this.isFetching = true
          this.$http
            .request({
              method: 'get',
              url:
                '/organizacion/' +
                this.idOrganization +
                '/usuario?s=' +
                this.userInput
            })
            .then((response) => {
              console.log(response.data)
              this.searchUserResults = response.data.data
              this.isFetching = false
            })
            .catch((error) => {
              console.error('Fail', error)
              this.isFetching = false
            })
        }
      },
      // This is the number of milliseconds we wait for the
      // user to stop typing.
      500
    )
  }
}
</script>
