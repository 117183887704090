<template>
  <b-table
    :data="data"
    :loading="isLoading"
    paginated
    backend-pagination
    :current-page="pagination.page"
    pagination-size="is-small"
    :per-page="pagination.size"
    :total="pagination.last*pagination.size"
    @page-change="onPageChange"
    aria-next-label="Next page"
    aria-previous-label="Previous page"
    aria-page-label="Page"
    aria-current-label="Current page"
    >
    <b-table-column label="Usuario" v-slot="props">
      <span>
        <b-icon pack="fas" icon='user'></b-icon>
        {{ props.row.nombre + ' ' + props.row.apellido }}
      </span>
    </b-table-column>
    <b-table-column label="CUIL" v-slot="props">
      <span>
        {{ props.row.cuil }}
      </span>
    </b-table-column>
    <b-table-column label="Estado" v-slot="props">
      <span>
        <span class="tag is-success is-rounded is-medium" v-if="props.row.or_activo">Activo</span>
        <span class="tag is-danger is-rounded is-medium" v-else>Inactivo</span>
        <b-tooltip label="Descargar acta de habilitación">
          <a @click="downloadActa(props.row)" v-if="props.row.or_activo">
            <b-icon pack="fas" icon='cloud-download-alt' size="is-medium" class="centered" />
          </a>
        </b-tooltip>
      </span>
    </b-table-column>
    <b-table-column label="Acción" v-slot="props" v-if="$store.getters.hasAnyRole(['lyris'])">
      <div class="buttons">
        <b-button type="is-ghost" class="pl-0" @click="disableOR(props.row.id)" v-if="props.row.or_activo">Deshabilitar</b-button>
        <b-button type="is-ghost" class="pl-0" @click="enableOR(props.row.id)" v-else>Habilitar</b-button>
      </div>
    </b-table-column>
    <template #empty>
      <div class="my-6 has-text-centered">
        <section class="section is-mono">
          <span class="tag is-rounded is-medium">
            <i class="fas fa-info fa-fw"></i>&nbsp;Sin usuarios para mostrar
          </span>
        </section>
      </div>
    </template>
  </b-table>
</template>
<script>
import ModalLaunchFirmador from '@/components/utils/ModalLaunchFirmador'
export default {
  data () {
    return {
      isLoading: false,
      firmadorSelected: 'signar',
      data: [],
      pagination: { page: 1, last: 1, size: 10 }
    }
  },
  beforeMount: function () {
    this.fetchUsers()
  },
  methods: {
    fetchUsers: function () {
      this.isLoading = true
      const params = {
        rol: 'or',
        datos_or: '1',
        organizacion: this.$route.params.idOrganization,
        page: this.pagination.page,
        size: this.pagination.size
      }
      this.$http
        .get('/usuario', { params })
        .then(res => {
          this.data = res.data.data
          this.pagination.last = res.data.last_page
        })
        .catch(() => {
          this.pagination.page = 1
          this.pagination.last = 1
          this.$buefy.snackbar.open({
            message: 'Ocurrió un error al recuperar los oficiales de registro',
            type: 'is-danger'
          })
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    onPageChange: function (page) {
      this.pagination.page = page
      this.fetchUsers()
    },
    enableOR: function (userId) {
      const ors = [{ usuario_id: userId, accion: 'habilitacion' }]
      const payload = {
        opcion: 'acta-or',
        firmador: this.firmadorSelected,
        parametros: { ors: ors }
      }
      this.$store.dispatch('firmador/unload')
      this.$store.commit('firmador/setPayload', payload)
      this.$buefy.modal.open({
        parent: this,
        canCancel: false,
        hasModalCard: true,
        component: ModalLaunchFirmador
      })
    },
    disableOR: function (userId) {
      const ors = [{ usuario_id: userId, accion: 'deshabilitacion' }]
      const payload = {
        opcion: 'acta-or',
        firmador: this.firmadorSelected,
        parametros: { ors: ors }
      }
      this.$store.dispatch('firmador/unload')
      this.$store.commit('firmador/setPayload', payload)
      this.$buefy.modal.open({
        parent: this,
        canCancel: false,
        hasModalCard: true,
        component: ModalLaunchFirmador
      })
    },
    downloadActa: function (user) {
      // TODO definir la estructura del listado de ORs primero, para luego saber como acceder a 'documento_alta_id'
      if (user.or_activo && user.oficial_registro) {
        this.isLoading = true
        this.$http
          .get('/documento/' + user.oficial_registro.documento_alta_id + '/descarga')
          .then(res => {
            window.location.href = res.data.link
          })
          .catch(() => {
            this.$buefy.snackbar.open({
              message: 'Ha ocurrido un error con la descarga',
              type: 'is-danger'
            })
          })
          .finally(() => {
            this.isLoading = false
          })
      }
    }
  },
  computed: {
    user: function () {
      return this.$store.getters.getUser
    }
  }
}
</script>
