<template>
  <div>
    <Acciones :estado="otroDocumento.estado" @download="download" @refresh="refresh" @deleteDocument="deleteDocument"  @preview="openPreview" @print="printFile"/>
    <section class="section">
      <div class="columns">
        <div class="column">
          <div class="box">
            <h1 class="title document-title is-4">
              <i class="fas fa-file"></i>&nbsp;&nbsp;{{otroDocumento.nombre.length > 50 ? otroDocumento.nombre.substring(0,50) + '...' : otroDocumento.nombre}}</h1>
            <Informacion />
          </div>
          <div class="box" v-if="!this.isAvailableStatus">
            <Status />
          </div>
        </div>
        <div class="column is-3">
          <div v-if="otroDocumento.preview_uri" class="box">
            <figure class="image">
                <a @click="previewModalActive = true" v-if="otroDocumento.preview_uri" class="show-magnifier-cursor">
              <img :src="otroDocumento.preview_uri" alt="Previsualización">
            </a>
            <img src="/statics/default-document.png" v-else alt="Previsualización">
            </figure>
          </div>
          <b-modal :active.sync="previewModalActive">
                <figure class="image">
            <a :href="otroDocumento.preview_uri" target="_blank">
                    <img :src="otroDocumento.preview_uri" alt="Previsualización">
            </a>
              </figure>
          </b-modal>
        </div>
      </div>
    </section>
</div>
</template>

<script>
import print from 'print-js'
import Acciones from '@/components/otro-documento/Acciones'
import Status from '@/components/otro-documento/Status'
import Informacion from '@/components/otro-documento/Informacion'

import store from '@/store'
import Constants from '../../constants'

export default {
  props: ['id'],
  components: {
    Acciones,
    Status,
    Informacion
  },
  data () {
    return {
      Constants: Constants,
      rutas: null,
      previewModalActive: false
    }
  },
  created () {
    this.rutas = this.otroDocumento.ruta.split('/')
  },
  beforeRouteUpdate: (to, from, next) => {
    if (to.params.id === from.params.id) {
      console.info('[ROUTE] No need to fetch data, already fetched')
      next()
    } else {
      console.log('[ROUTE] Updated route')
      store.dispatch('otroDocumento/fetchOtroDocumento', to.params.id)
        .then(() => {
          console.info('[ROUTER] Data fetch success. Continue to route')
          this.next()
        })
        .catch(error => {
          console.error('[ROUTER] Data fetch failed', error)
          this.next(false)
        })
    }
  },
  methods: {
    download: function (v) {
      this.$http
        .get('/documento/' + this.otroDocumento.id + '/descarga')
        .then(response => {
          window.location.href = response.data.link
        })
        .catch(error => {
          console.error('Fail', error)
          this.$buefy.snackbar.open({
            message:
              'Error de conexión. Compruebe la conectividad con internet.',
            type: 'is-danger'
          })
        })
    },
    deleteDocument: function () {
      this.$http
        .delete('/documento/' + this.otroDocumento.id)
        .then(() => {
          this.$buefy.snackbar.open({
            message: 'El documento ha sido eliminado',
            type: 'is-success'
          })
          this.$router.push({ name: 'listarOtrosDocumentos' })
        })
        .catch(error => {
          console.error('Fail', error)
          this.$buefy.snackbar.open({
            message: 'Error inesperado.',
            type: 'is-danger'
          })
        })
    },
    refresh: function () {
      this.startLoading()
      store.dispatch('otroDocumento/fetchOtroDocumento', this.otroDocumento.id)
        .catch(err => {
          if (err.estado === 404) {
            this.$buefy.snackbar.open({
              message: 'El documento no existe o no se encuentra disponible',
              type: 'is-warning'
            })
            console.info('[VUEX] fetchOtroDocumento returned error. Back to origin.')
            this.$router.push({ name: '404' })
          }
        })
        .finally(() => {
          this.stopLoading()
        })
    },
    addToPila: function () {
      this.$store.commit('firmador/addPila', this.otroDocumento)
    },
    openPreview: function () {
      this.$eventHub.$emit('openFilePreview', this.otroDocumento)
    },
    printFile: function () {
      this.$http
        .get('/documento/' + this.otroDocumento.id + '/descarga')
        .then(response => {
          print({
            printable: response.data.link,
            type: 'pdf',
            documentTitle: this.otroDocumento.nombre,
            onError: () => {
              this.$buefy.snackbar.open({
                message: 'Hubo un error al intentar imprimir el archivo.<br>Por favor, <b>intente descargar <i class="fas fa-download"></i> el archivo e imprmalo</b>.',
                type: 'is-danger',
                duration: 15000
              })
            }
          })
        })
        .catch(error => {
          console.error('Fail', error)
          this.$buefy.snackbar.open({
            message:
              'Error de conexión. Compruebe la conectividad con internet.',
            type: 'is-danger'
          })
        })
    }
  },
  computed: {
    isAvailableStatus: function () {
      return this.otroDocumento.estado === Constants.STRINGS.DOCUMENTS.STATUS.AVAILABLE
    },
    otroDocumento: function () {
      return this.$store.state.otroDocumento.otroDocumento
    }
  }
}
</script>

<style lang="scss" scoped>
.document-title {
  word-break: break-all;
}
progress.progress{
  margin-bottom: 5px;
}
</style>
