<template>
  <table class="table is-narrow is-fullwidth is-marginless">
    <tbody>
      <tr>
        <th>Ruta</th>
        <td>{{unidadCompuesta.ruta}}</td>
      </tr>
      <tr>
        <th>Fecha creación</th>
        <td>{{new Date(unidadCompuesta.created_at) | moment("dddd, DD/MM/YYYY, HH:mm:ss")}}</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  props: ['id'],
  data () {
    return {
      cargando: true
    }
  },
  computed: {
    unidadCompuesta: function () {
      return this.$store.state.unidadCompuesta.unidadCompuesta
    }
  }
}
</script>

<style lang="scss" scoped>
th {
  color: $primary;
}
</style>
