import http from '@/http'

const profileModule = {
  namespaced: true,
  state: {
    user: null
  },
  mutations: {
    user (state, file) {
      state.user = file
    },
    unload (state) {
      state.user = null
    }
  },
  getters: {},
  actions: {
    fetchUser ({ commit }, id) {
      // Fetch, then store the value, then return the promise
      // you chan call this as store.dispatch('..').then(res => {})
      return http.get(`/usuario/${id}`)
        .then(res => {
          console.info('[VUEX] GET OK - Got Data Usuario (profile)')
          commit('user', res.data)
          return res
        })
    },
    unload (context) {
      context.commit('unload')
    }
  }
}

export default profileModule
