<template>
  <div class="box">
    <h1 class="subtitle is-5">Usuarios de la organización</h1>
    <h1 class="title is-1">Nuevo usuario</h1>
    <div class="content">
      <p>Ingrese los datos del nuevo usuario de la organización. Una vez que finalizó de cargar los datos, presione el botón <span class="tag is-primary">Guardar</span></p>
    </div>
    <hr>
    <formulario :model="model" :error="error" v-on:submit="submit" :edit="false"></formulario>
  </div>
</template>

<script>
import Formulario from '@/components/organizacion/usuario/Formulario'

export default {
  props: ['idOrganization'],
  components: {
    Formulario
  },
  data () {
    return {
      model: {
        acceso: '',
        clave: '',
        cuil: '',
        nombre: '',
        apellido: '',
        email: ''
      },
      error: '',
      showHelp: false
    }
  },
  methods: {
    submit () {
      this.startLoading()
      this.$http
        .request({
          method: 'post',
          url: '/organizacion/' + this.idOrganization + '/usuario',
          data: this.payload
        })
        .then(response => {
          this.$emit('updateResource')
          this.$router.push({
            name: 'usuarioInfo',
            params: { idOrganization: this.idOrganization, idUser: response.data.usuario.id }
          })
          this.$buefy.snackbar.open({
            message:
              '¡Usuario creado exitosamente!',
            type: 'is-success'
          })
        })
        .catch(error => {
          console.log(error)
          this.$buefy.snackbar.open({
            message: error.mensaje,
            type: 'is-danger'
          })
        })
        .finally(() => [
          this.stopLoading()
        ])
    }
  },
  computed: {
    payload: function () {
      const payload = {
        acceso: this.model.acceso,
        cuil: this.model.cuil,
        nombre: this.model.nombre,
        apellido: this.model.apellido,
        email: this.model.email
      }
      if (this.model.clave !== '' && this.model.clave !== null) {
        payload.clave = this.model.clave
      }
      return payload
    }
  }
}
</script>
