<template>
  <section>
    <div class="field">
      <label class="label">Nombre del aspecto (*)</label>
      <div class="control">
        <input
          v-model="model.nombre"
          name="nombre del aspecto"
          class="input"
          :class="{'is-danger': errors.has('nombre del aspecto') }"
          v-validate="{required:true}"
        />
      </div>
      <span
        class="help is-danger"
        v-if="errors.has('nombre del aspecto')"
      >{{errors.first('nombre del aspecto')}}</span>
    </div>
    <div class="field">
      <label class="label">Descripción del aspecto</label>
      <div class="control">
        <input v-model="model.descripcion" name="descripción del atributo" class="input" />
        <span class="help">(Opcional) Describa el significado del conjunto de atributos</span>
      </div>
    </div>
    <div class="field is-clearfix" v-if="edit">
      <button @click="submitEdit" class="button is-primary is-pulled-right">
        <i class="fas fa-save fa-fw"></i>Guardar
      </button>
    </div>
    <div class="card">
      <div class="card-content">
        <h1 class="subtitle is-5">
          <i class="fas fa-plus fa-fw"></i>Agregar atributos
        </h1>
        <div class="field">
          <label class="label">Nombre del atributo (*)</label>
          <div class="control">
            <input
              v-model="input.title"
              name="Título atributo"
              v-validate="{required:true}"
              class="input"
              :class="{'is-danger': errors.has('Título atributo') }"
            />
            <span
              class="help is-danger"
              v-if="errors.has('Título atributo')"
            >{{errors.first('Título atributo')}}</span>
          </div>
        </div>
        <div class="field">
          <label class="label">Clave del atributo (*)</label>
          <div class="control">
            <input
              v-model="input.key"
              name="Clave atributo"
              v-validate="{ required: true, regex: /^[a-z0-9]+(?:[-_+][a-z0-9]+)*$/ }"
              class="input is-mono"
              :class="{'is-danger': errors.has('Clave atributo') }"
              :readonly="!enableCustomKey"
            />
            <span
              class="help"
            >Autogenerado por el nombre, si desea personalizarlo, haga clic en 'Personalizar clave'</span>
            <span
              class="help is-danger"
              v-if="errors.has('Clave atributo')"
            >{{errors.first('Clave atributo')}}</span>
          </div>
        </div>
        <div class="field">
          <label class="label">Clave del atributo (*)</label>
          <div class="control">
            <b-checkbox v-model="enableCustomKey">Personalizar clave de atributo</b-checkbox>
            <span
              class="help"
            >Nota: no puede usar caracteres especiales, tildes, o espacios. Solamente se permiten minusculas, numeros y guiones</span>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <div class="field">
              <label class="label">Tipo de atributo (*)</label>
              <div class="control">
                <div class="select is-fullwidth">
                  <select
                    v-model="input.type"
                    name="Tipo atributo"
                    v-validate="{required:true}"
                    :class="{'is-danger': errors.has('Tipo atributo') }"
                  >
                    <option :value="null" disabled>Seleccione</option>
                    <option :value="'string'">Texto</option>
                    <option :value="'number'">Número</option>
                    <option :value="'integer'">Número entero</option>
                    <option :value="'boolean'">Binario</option>
                    <option :value="'date'">Fecha</option>
                    <option :value="'enum'">Opción</option>
                  </select>
                </div>
              </div>
            </div>
            <span
              class="help is-danger"
              v-if="errors.has('Tipo atributo')"
            >{{errors.first('Tipo atributo')}}</span>
          </div>
          <div class="column" v-if="input.type">
            <b-field label="Acerca...">
              <div class="message is-small is-primary">
                <div class="message-body">
                  <p
                    v-show="input.type == 'string'"
                  >Se aceptan textos como entradas en este atributo</p>
                  <p
                    v-show="input.type == 'number'"
                  >Se aceptan números (con decimales) como entradas en este atributo</p>
                  <p
                    v-show="input.type == 'integer'"
                  >Se aceptan numeros enteros (sin decimales) como entradas en este atributo</p>
                  <p v-show="input.type == 'boolean'">La entrada es binario: Si/No - Verdadero/Falso</p>
                  <p v-show="input.type == 'date'">Se aceptan fechas con formato YYYY-MM-DD</p>
                  <p
                    v-show="input.type == 'enum'"
                  >Se elije una opción de una lista de valores. La lista se define en este formulario</p>
                </div>
              </div>
            </b-field>
          </div>
        </div>
        <div class="field" v-if="input.type == 'enum'">
          <label class="label">Lista de opciones (*)</label>
          <div class="control">
            <div class="field has-addons">
              <div class="control is-expanded">
                <input
                  class="input"
                  v-model="input.optionInput"
                  type="text"
                  @keyup.enter="addOption"
                  placeholder="Escriba una opción"
                />
              </div>
              <div class="control">
                <a @click="addOption" class="button is-info">Agregar</a>
              </div>
            </div>
          </div>
          <span class="help">
            Agregue a la lista haciendo clic en
            <b>Agregar</b>, o presionando la tecla
            <b>ENTER</b>
          </span>
        </div>
        <div class="field is-grouped is-grouped-multiline" v-if="input.type == 'enum'">
          <div class="control" v-for="(option, i) in input.options" :key="option">
            <div class="tags has-addons">
              <span class="tag is-link">{{option}}</span>
              <a class="tag is-delete" @click="removeOption(i)"></a>
            </div>
          </div>
        </div>
        <div class="field">
          <label class="label">Descripcion del atributo (*)</label>
          <div class="control">
            <input
              v-model="input.description"
              name="Descripcion atributo"
              v-validate="{required:true}"
              class="input"
              :class="{'is-danger': errors.has('Descripcion atributo') }"
            />
            <span
              class="span help"
            >Se utilizará para explicarle al usuario acerca de que significa el atributo</span>
            <span
              class="help is-danger"
              v-if="errors.has('Descripcion atributo')"
            >{{errors.first('Descripcion atributo')}}</span>
          </div>
        </div>
        <div class="field">
          <label class="label">Atributo opcional</label>
          <div class="control">
            <b-checkbox v-model="input.optional">Opcional</b-checkbox>
            <span class="span help">Habilitelo si el atributo es opcional</span>
          </div>
        </div>
        <div class="is-clearfix">
          <button @click="add" v-if="!edit" class="button is-primary is-pulled-right">
            <i class="fas fa-plus fa-fw"></i>Agregar al listado
          </button>
          <button @click="addNewAttribute" v-else class="button is-primary is-pulled-right">
            <i class="fas fa-plus fa-fw"></i>Agregar al listado
          </button>
        </div>
      </div>
    </div>
    <br />
    <h1 class="subtitle is-5">Listado de atributos</h1>
    <b-message size="is-small" v-if="edit">
      Puede editar el nombre y la descripción del atributo haciendo clic en el icono
      <i class="fas fa-edit"></i>
    </b-message>
    <b-message
      size="is-small"
      v-else
    >Si quiere cambiar algun atributo que fue ingresado en el listado, eliminelo, y vuelvalo a crear.</b-message>
    <table class="table is-bordered is-narrow is-fullwidth">
      <thead>
        <th width="150px">Clave</th>
        <th width="180px">Nombre</th>
        <th>Tipo | Descripción</th>
        <th width="40" class="has-text-centered" v-if="edit">
          <i class="fas fa-edit"></i>
        </th>
        <th width="40" class="has-text-centered">
          <i class="fas fa-times"></i>
        </th>
      </thead>
      <tbody v-if="model.atributos">
        <tr v-for="atributo in model.atributos" :key="atributo.key">
          <td>
            <span class="is-mono">{{atributo.key}}</span>
          </td>
          <td>
            <b>{{atributo.title}}</b>
            <span class="has-text-danger" v-show="atributo.required">*</span>
          </td>
          <td class="is-size-7">
            <span class="tag">{{getType(atributo.type)}}</span>&nbsp;
            <i>
              <span class="has-text-primary" v-show="!atributo.required">(Opcional)</span>
              {{atributo.description}}
              <span v-if="atributo.type == 'enum'">
                <br />
                <b>Lista de opciones:</b>
                {{atributo.parametros.opciones.join(' • ')}}
              </span>
            </i>
          </td>
          <td class="has-text-centered" v-if="edit">
            <a @click="editAtributo(atributo)">
              <i class="fas fa-edit"></i>
            </a>
          </td>
          <td class="has-text-centered">
            <a @click="showDeleteAtributo(atributo.key)" class="has-text-danger" v-if="edit">
              <i class="fas fa-trash"></i>
            </a>
            <a @click="removeAtributos(atributo.key)" class="has-text-danger" v-else>
              <i class="fas fa-times"></i>
            </a>
          </td>
        </tr>
        <tr v-if="model.atributos.length == 0">
          <td colspan="6" class="has-text-centered">
            <section class="section is-mono">
              <span class="tag is-rounded is-medium">No se han creado atributos</span>
            </section>
          </td>
        </tr>
      </tbody>
    </table>
    <button
      @click="submit()"
      class="button is-primary is-fullwidth"
      :disabled="model.atributos.length == 0"
      v-if="!edit"
    >
      <i class="fas fa-save fa-fw"></i>Guardar
    </button>
    <b-modal :active.sync="deleteAtributo.showModal">
      <div class="box">
        <h1 class="title is-3 has-text-centered">Eliminar aspecto</h1>
        <h2 class="subtitle is-5 has-text-centered">¿Está seguro?</h2>
        <b-message type="is-danger">
          <span class="tag is-rounded is-pulled-right is-black is-mono">{{deleteAtributo.selected}}</span>
          <h1 class="title is-4 has-text-danger">
            <i class="fas fa-exclamation-triangle"></i>&nbsp;Atención
          </h1>
          <div class="content">
            <p>
              La acción que esta por realizar tiene un efecto importante de sus documentos con el aspecto
              <b>{{model.nombre}}</b>
            </p>
            <p>De proceder con la eliminación, se perderán todos los metadatos de los documentos asociados a este atributo.</p>
            <p>
              <i class="fas fa-exclamation-circle"></i>&nbsp;Puede crear otro aspecto que reemplace el actual para no perder los datos asociados a los documentos afectados.
            </p>
          </div>
        </b-message>
        <hr />
        <div class="buttons is-centered">
          <button @click="deleteAtributo.showModal = false" class="button is-light">Cancelar</button>
          <button @click="submitDeleteAtributo" class="button is-danger">
            <i class="fas fa-trash"></i>&nbsp;Eliminar
          </button>
        </div>
      </div>
    </b-modal>
    <b-modal :active.sync="editingAtributo.showModal">
      <div class="box">
        <h1 class="tag is-rounded is-pulled-right is-primary is-mono">{{editingAtributo.selected}}</h1>
        <h1 class="subtitle is-4">Editando aspecto</h1>
        <hr />
        <div class="field">
          <label class="label">Nombre del atributo (*)</label>
          <div class="control">
            <input
              v-model="editingAtributo.title"
              name="Nuevo título atributo"
              v-validate="{required:true}"
              class="input"
              :class="{'is-danger': errors.has('Nuevo titulo atributo') }"
            />
            <span
              class="help is-danger"
              v-if="errors.has('Nuevo titulo atributo')"
            >{{errors.first('Nuevo titulo atributo')}}</span>
          </div>
        </div>
        <div class="field">
          <label class="label">Descripción del atributo (*)</label>
          <div class="control">
            <input
              v-model="editingAtributo.description"
              name="Nueva descripcion atributo"
              v-validate="{required:true}"
              class="input"
              :class="{'is-danger': errors.has('Nueva descripcion atributo') }"
            />
            <span
              class="span help"
            >Se utilizará para explicarle al usuario acerca de que significa el atributo</span>
            <span
              class="help is-danger"
              v-if="errors.has('Nueva descripcion atributo')"
            >{{errors.first('Nueva descripcion atributo')}}</span>
          </div>
        </div>
        <div class="field">
          <label class="label">Atributo opcional</label>
          <div class="control">
            <b-checkbox v-model="editingAtributo.opcional">Opcional</b-checkbox>
            <span class="span help">Habilitelo si el atributo es opcional</span>
          </div>
        </div>
        <div class="field is-clearfix">
          <button
            @click="submitEditAtributo"
            class="button is-primary is-pulled-right"
            :disabled="model.atributos.length == 0"
          >
            <i class="fas fa-save fa-fw"></i>Guardar
          </button>
        </div>
      </div>
    </b-modal>
  </section>
</template>

<script>
import slugify from 'slugify'
import fieldsUtils from '@/mixins/fieldsUtils'
import validatorUtils from '@/mixins/validatorUtils'

export default {
  props: {
    edit: {
      type: Boolean,
      default: false
    },
    previousData: {
      type: Object,
      default: null
    }
  },
  mixins: [fieldsUtils, validatorUtils],
  data () {
    return {
      error: '',
      enableCustomKey: false,
      model: {
        nombre: '',
        descripcion: null,
        atributos: []
      },
      input: {
        key: '',
        title: '',
        description: '',
        type: null,
        optional: false,
        optionInput: '',
        options: []
      },
      editingAtributo: {
        showModal: false,
        selected: null,
        title: null,
        opcional: null,
        description: null
      },
      deleteAtributo: {
        showModal: false,
        selected: null
      }
    }
  },
  mounted: function () {
    if (this.edit) {
      this.syncData()
    }
  },
  methods: {
    syncData: function () {
      this.model.nombre = this.previousData.nombre
      this.model.descripcion = this.previousData.descripcion
      this.model.atributos = this.previousData.atributos.map(a => {
        return {
          key: a.id,
          title: a.nombre,
          description: a.descripcion,
          type: a.tipo,
          required: a.obligatorio,
          parametros: a.parametros
        }
      })
    },

    add: function (attr) {
      if (this.edit) {
        const atr = {
          key: attr.id,
          title: attr.nombre,
          description: attr.descripcion,
          type: attr.tipo,
          required: !attr.optional,
          parametros: {}
        }
        if (atr.type === 'enum') {
          atr.parametros.opciones = attr.parametros.opciones
        }
        this.model.atributos.push(atr)
        this.input.key = ''
        this.input.title = ''
        this.input.description = ''
        this.input.type = null
        this.input.optional = false
        this.input.optionInput = ''
        this.input.options = []
        this.enableCustomKey = false
        this.clearValidatorErrors()
        return
      }
      Promise.all([
        this.$validator.validate('Título atributo'),
        this.$validator.validate('Clave atributo'),
        this.$validator.validate('Tipo atributo'),
        this.$validator.validate('Descripcion atributo')
      ])
        .then(results => {
          if (
            // Si alguno falla en al validacion
            !results.every(el => {
              return el === true
            })
          ) {
            throw Error()
          }
          // Si no falla ninguna validacion
          const atr = {
            key: this.input.key,
            title: this.input.title,
            description: this.input.description,
            type: this.input.type,
            required: !this.input.optional,
            parametros: {}
          }
          if (this.input.type === 'enum') {
            atr.parametros.opciones = this.input.options
          }
          this.model.atributos.push(atr)
          this.input.key = ''
          this.input.title = ''
          this.input.description = ''
          this.input.type = null
          this.input.optional = false
          this.input.optionInput = ''
          this.input.options = []
          this.enableCustomKey = false
          this.clearValidatorErrors()
        })
        .catch(error => {
          console.error('Fail', error)
          this.$buefy.snackbar.open({
            message: 'Error, revisé las entradas de creación de aspectos',
            type: 'is-danger'
          })
        })
    },
    addNewAttribute: function () {
      Promise.all([
        this.$validator.validate('Título atributo'),
        this.$validator.validate('Clave atributo'),
        this.$validator.validate('Tipo atributo'),
        this.$validator.validate('Descripcion atributo')
      ])
        .then(results => {
          if (
            // Si alguno falla en al validacion
            !results.every(el => {
              return el === true
            })
          ) {
            throw Error()
          }
          // Si no falla ninguna validacion
          const payload = {
            nombre: this.input.title,
            descripcion: this.input.description,
            tipo: this.input.type,
            obligatorio: this.input.required,
            parametros: {}
          }
          if (this.input.type === 'enum') {
            payload.parametros.opciones = this.input.options
          }
          this.$http
            .post(
              `/aspecto/${this.previousData.id}/atributo/${this.input.key}`,
              payload
            )
            .then(res => {
              this.$buefy.snackbar.open({
                message: 'Se ha agregado el nuevo atributo',
                type: 'is-success'
              })
              this.add(res.data.atributo)
            })
            .catch(err => {
              console.error(err)
              this.$buefy.snackbar.open({
                message: 'Error inesperado',
                type: 'is-danger'
              })
            })
            .finally(() => {
              this.stopLoading()
            })
        })
        .catch(err => {
          console.error(err)
          this.$buefy.snackbar.open({
            message: 'Error, revisé las entradas de creación de aspectos',
            type: 'is-danger'
          })
        })
    },
    editAtributo: function (aspecto) {
      this.editingAtributo.selected = aspecto.key
      this.editingAtributo.title = aspecto.title
      this.editingAtributo.description = aspecto.description
      this.editingAtributo.opcional = !aspecto.required
      this.editingAtributo.showModal = true
    },
    showDeleteAtributo: function (key) {
      this.deleteAtributo.selected = key
      this.deleteAtributo.showModal = true
    },
    removeAtributos: function (key) {
      this.model.atributos = this.model.atributos.filter(function (ele) {
        return ele.key !== key
      })
    },
    getType: function (type) {
      switch (type) {
        case 'string':
          return 'Texto'
        case 'number':
          return 'Número'
        case 'integer':
          return 'Número entero'
        case 'boolean':
          return 'Binario'
        case 'date':
          return 'Fecha'
        case 'enum':
          return 'Opción'
      }
    },
    submit: function () {
      if (this.model.atributos.length === 0) {
        this.$buefy.snackbar.open({
          message: 'No puede crear un aspecto sin atributos',
          type: 'is-danger'
        })
        return
      }
      this.$validator
        .validate('nombre del aspecto')
        .then(result => {
          if (!result) {
            this.$buefy.snackbar.open({
              message: 'Error. Faltan completar campos del formulario',
              type: 'is-danger'
            })
            return
          }
          this.$emit('submit', this.payload)
        })
        .catch(err => {
          console.error(err)
          this.$buefy.snackbar.open({
            message: 'Error inesperado',
            type: 'is-danger'
          })
        })
    },
    submitEdit: function () {
      this.startLoading()
      this.$http
        .patch(`/aspecto/${this.previousData.id}`, {
          nombre: this.model.nombre,
          descripcion: this.model.descripcion
        })
        .then(res => {
          this.$buefy.snackbar.open({
            message: 'El aspecto ha sido editado exitosamente',
            type: 'is-success'
          })
        })
        .catch(err => {
          console.error(err)
          this.$buefy.snackbar.open({
            message: 'Error inesperado',
            type: 'is-danger'
          })
        })
        .finally(() => {
          this.stopLoading()
        })
    },
    submitEditAtributo: function () {
      this.startLoading()
      this.$http
        .patch(`/aspecto-atributo/${this.editingAtributo.selected}`, {
          nombre: this.editingAtributo.title,
          descripcion: this.editingAtributo.description,
          obligatorio: !this.editingAtributo.opcional
        })
        .then(res => {
          this.$buefy.snackbar.open({
            message: 'El atributo ha sido editado exitosamente',
            type: 'is-success'
          })
          const index = this.model.atributos.findIndex(
            a => a.key === this.editingAtributo.selected
          )
          this.model.atributos[index].title = this.editingAtributo.title
          this.model.atributos[
            index
          ].description = this.editingAtributo.description
          this.model.atributos[index].required = !this.editingAtributo.opcional
          this.editingAtributo.showModal = false
        })
        .catch(err => {
          console.error(err)
          this.$buefy.snackbar.open({
            message: 'Error inesperado',
            type: 'is-danger'
          })
        })
        .finally(() => {
          this.stopLoading()
        })
    },
    submitDeleteAtributo: function () {
      this.startLoading()
      this.$http
        .delete(`/aspecto-atributo/${this.deleteAtributo.selected}`)
        .then(res => {
          this.$buefy.snackbar.open({
            message: 'El atributo ha sido eliminado exitosamente',
            type: 'is-success'
          })
          this.model.atributos = this.model.atributos.filter(
            a => a.key !== this.deleteAtributo.selected
          )
          this.deleteAtributo.showModal = false
        })
        .catch(err => {
          console.error(err)
          this.$buefy.snackbar.open({
            message: 'Error inesperado',
            type: 'is-danger'
          })
        })
        .finally(() => {
          this.stopLoading()
        })
    },
    addOption: function () {
      this.input.options.push(this.input.optionInput)
      this.input.optionInput = ''
    },
    removeOption: function (i) {
      this.input.options.splice(i, 1)
    }
  },
  watch: {
    'input.title': function (newVal) {
      if (!this.enableCustomKey) {
        this.input.key = slugify(newVal.toLowerCase())
      }
    },
    'input.type': function (newVal, oldVal) {
      if (oldVal === 'enum') {
        this.input.options = []
        this.input.optionInput = ''
      }
    }
  },
  computed: {
    payload: function () {
      const atributosObject = {}
      const requiredAtributos = []
      this.model.atributos.forEach(atr => {
        // if (atr.required) {
        //   requiredAtributos.push(atr.key);
        //   atributosObject[atr.key] = {
        //     nombre: atr.title,
        //     descripcion: atr.description,
        //     tipo: atr.type,
        //     obligatorio: atr.required,
        //     schema: {
        //       type: atr.type
        //     }
        //   };
        // } else {
        //   atributosObject[atr.key] = {
        //     nombre: atr.title,
        //     descripcion: atr.description,
        //     obligatorio: atr.required,
        //     schema: {
        //       oneOf: [
        //         {
        //           type: atr.type
        //         },
        //         {
        //           type: "null"
        //         }
        //       ]
        //     }
        //   };
        // }
        if (atr.required) requiredAtributos.push(atr.key)
        atributosObject[atr.key] = {
          nombre: atr.title,
          descripcion: atr.description,
          tipo: atr.type,
          obligatorio: atr.required,
          parametros: atr.parametros
        }
      })
      return {
        nombre: this.model.nombre,
        descripcion: this.isFieldOptional(this.model.descripcion),
        atributos: atributosObject
      }
    }
  }
}
</script>

<style lang="scss" scoped>
table.table input.input {
  padding: 3px;
  height: 27px;
  font-size: 1rem;
}
</style>
