import store from '@/store'
// import http from '@/http'

import Recibo from './Recibo'

const BASE_PATH = '/recibo'

const routes = [
  {
    path: BASE_PATH + '/:id',
    component: Recibo,
    props: true,
    meta: {
      requiresAuth: true
    },
    beforeEnter: (to, from, next) => {
      console.info('[ROUTER] First time entering:', to.path)
      Promise.all([
        store.dispatch('recibo/fetchRecibo', to.params.id)
      ])
        .then(responses => {
          console.info('[ROUTER] Data fetch success. Continue to route')
          next()
        })
        .catch(error => {
          console.error('[ROUTER] Data fetch failed', error)
          next()
        })
      // console.info('[ROUTER] First time entering:', to.path)
      // http
      //   .get('/recibo/' + to.params.id)
      //   .then(response => {
      //     store.commit('recibo/recibo', response.data)
      //     // store.commit('setRecibo', response.data)
      //     console.log('GET OK - Got receipt')
      //     next()
      //   })
      //   .catch(error => {
      //     console.log('Fetching data failed', error)
      //     next()
      //   })
    }
  }
]
export default routes
