<template>
  <div class="card">
    <header class="card-header">
        <p class="card-header-title">Crear carpeta</p>
    </header>
    <section class="card-content">
      <b-notification type="is-warning" :active.sync="isActive" @click="isActive = !isActive">
        {{error}}
      </b-notification>
        <div class="field">
          <label for="" class="label">Nombre de la carpeta</label>
          <div class="control">
            <input ref="nombre" type="text" v-model="nombre" name="Nombre de la carpeta" class="input" :class="{'is-danger': errors.has('nombre') }" v-validate="{required:true}" @keyup.enter="submit()"/>
          </div>
          <p class="help has-text-danger" v-if="invalidRenameMessage">{{invalidRenameMessage}}</p>
        </div>
        <div class="field">
          <label class="label">Heredar permisos</label>
          <p class="is-size-7 has-text-primary mb-3" v-if="forceHerencia"><i class="fas fa-exclamation-triangle"></i>&nbsp;<b>Nota:</b> Los documentos y carpetas de la carpeta {{currentFolder.name}} heredarán los permisos por defecto</p>
          <div class="control">
          <b-switch size="is-small" v-model="permisos" :disabled="forceHerencia">Heredar permisos de la carpeta superior</b-switch>
          </div>
        </div>
    </section>
    <footer class="card-footer">
        <a class="card-footer-item" type="button" @click="$parent.close()">Cancelar</a>
        <a class="card-footer-item has-text-weight-bold is-uppercase"
          :class="{ 'disabled': invalidRenameMessage }"
          @click.prevent="submit()">Crear</a>
    </footer>
  </div>
</template>

<script>
export default {
  data () {
    return {
      nombre: '',
      permisos: true,
      isActive: false,
      error: ''
    }
  },
  mounted: function () {
    this.focusInput()
  },
  computed: {
    invalidRenameMessage: function () {
      if (this.nombre === '') {
        return 'El nuevo nombre no puede ser vacío.'
      }
      // if nombre doesn't start with a letter or a digit
      var regex = /^[^A-Za-z0-9áéíóúÁÉÍÓÚüÜñÑ]/
      if (regex.test(this.nombre)) {
        return 'El nuevo nombre debe comenzar con una letra o dígito.'
      }
      // if nombre contains invalid characters
      regex = /[<>:;,?"*|\\/]+/
      if (regex.test(this.nombre)) {
        return 'El nuevo nombre no puede contener ninguno de los siguientes caracteres < > : ; , ? “ * | /'
      }

      return false
    },
    currentFolder: function () {
      return this.$store.state.repository.currentFolder
    },
    forceHerencia: function () {
      return (this.currentFolder && this.currentFolder.permisos_bloqueados)
    }
  },
  methods: {
    focusInput () {
      setTimeout(() => {
        this.$refs.nombre.focus()
      }, 200)
    },
    submit: function () {
      if (!this.invalidRenameMessage) {
        const data = {
          nombre: this.nombre,
          origen_id: this.currentFolder.id,
          tipo_documento_id: 'carpeta',
          permisos_heredados: this.permisos
        }
        this.$http.request({
          method: 'post',
          url: '/documento',
          data: data
        })
          .then(response => {
            console.log(response.data)
            this.$emit('refreshAndSelectNewFolder', response.data.documento)
            this.$parent.close()
            this.$buefy.snackbar.open({
              message: 'Carpeta creada exitosamente.',
              type: 'is-success'
            })
          })
          .catch(error => {
            this.isActive = true
            this.error = 'Ya existe una carpeta con el nombre "' + this.nombre + '"'
            console.log(error)
          })
      } else {
        this.$buefy.snackbar.open({
          message: 'El nombre ingresado es inválido.',
          type: 'is-danger'
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.disabled {
  cursor: not-allowed;
  color: #808080
}
</style>
