<template>
  <div id="processLogin">
    <section class="section">
      <div class="container has-text-centered">
        <div class="columns">
          <div class="column is-4 is-offset-4">
            <div class="box content">
              <img src="/statics/signar-logo.svg" class="image" alt>
              <br>
              <p>
                <i class="fas fa-sync fa-spin fa-2x"></i>
              </p>
              <p class="is-size-7">Procesando inicio de sesión...</p>
              <p class="is-size-7 has-text-danger" v-if="showPleaseReload">
                ¿No carga? Haga clic
                <a href="/">Aquí</a> para recargar...
              </p>
              <br>
            </div>
               <a href="https://lyris.com.ar" target="_blank"><img src="/statics/lyris-grey.svg" class="lyris-logo mt-5" alt="Lyris IT"></a>
            <p class="is-size-7 my-3">
              <a class="mx-2 has-text-grey" target="_blank" href="https://soporte-lyris.atlassian.net/servicedesk/customer/portals">Soporte&nbsp;<i class="fas fa-external-link-alt fa-fw"></i></a>
              <a class="mx-2 has-text-grey" target="_blank" href="https://docs.signar.com.ar">Documentación <i class="fas fa-external-link-alt fa-fw"></i></a>
            </p>
              <p class="is-size-7 mx-2 has-text-grey">v{{version}}</p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  props: ['token'],
  data () {
    return {
      intervalId: null,
      showPleaseReload: false
    }
  },
  mounted: function () {
    this.intervalId = setTimeout(() => {
      this.showPleaseReload = true
    }, 20000)
    this.submit()
  },
  methods: {
    submit: function () {
      this.$http
        .request({
          method: 'post',
          url: '/token',
          data: {
            code: this.token,
            grant_type: 'authorization_code'
          }
        })
        .then(response => {
          clearTimeout(this.intervalId)
          const session = {
            token: response.data.access_token,
            expiration: response.data.expiration,
            user: response.data.user
          }
          this.$store.commit('login', session)
          this.getOrganizacion(session.user.organization)
          this.$router.push(this.$route.query.redirect || '/')
        })
        .catch(error => {
          console.log(error)
          this.error = error.mensaje
          this.$buefy.snackbar.open({
            message: 'Ocurrio un error en el flujo de login',
            type: 'is-danger'
          })
        })
    },
    getOrganizacion: function (orgId) {
      this.$store.dispatch('organizacion/fetchOrganizacion', orgId)
        .catch(error => {
          console.error(error)
          this.$buefy.snackbar.open({
            message: 'Error fetching organizacion!',
            type: 'is-success'
          })
        })
    }
  },
  computed: {}
}
</script>

<style lang="scss" scoped>
img {
  max-width: 200px;
  margin: 10px auto 20px;
}
img.lyris-logo {
  max-width: 80px;
  margin-bottom: 0;
}
</style>
