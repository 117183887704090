<template>
  <div>
    <acciones
      @firmar-pendientes="firmarPendientes"
      @download="download"
      @refresh="refresh"
    ></acciones>
    <section class="section">
        <div class="columns">
          <div class="column is-4 is-3-tablet is-3-desktop">
            <Empleador @launch-firmador="launchFirmador" v-if="$store.getters.hasAnyRole(['empleador'])"/>
            <Filtros @set-query="setQuery" @refresh="refresh" />
          </div>
          <div class="column">
            <div class="box">
              <Listado ref="listado" />
            </div>
          </div>
          <MultipleSidebar v-if="selectedRecibos.length" @download="download"/>
        </div>
    </section>
  </div>
</template>

<script>
import Acciones from '@/components/recibos/Acciones'
import Listado from '@/components/recibos/Listado'
import Empleador from '@/components/recibos/Empleador'
import Filtros from '@/components/recibos/Filtros'
import MultipleSidebar from '@/components/recibos/MultipleSidebar'
import ModalSubirRecibos from '@/components/recibos/ModalSubirRecibos'
// import ModalFirmarPendientes from '@/components/utils/ModalFirmarPendientes'
import ModalLaunchFirmador from '@/components/utils/ModalLaunchFirmador'

import store from '@/store'

export default {
  components: {
    Acciones,
    Listado,
    Empleador,
    Filtros,
    MultipleSidebar
  },
  data () {
    return {
      selected: null,
      urlJnlp: null
    }
  },
  destroyed: function () {
    store.dispatch('recibos/unload')
  },
  methods: {
    setQuery: function (query) {
      this.$refs.listado.setQuery(query)
    },
    refresh: function () {
      this.$refs.listado.refresh()
    },
    launchFirmador: function (type) {
      const payload = {
        firmador: 'signar',
        opcion: 'documentos',
        lista: []
      }
      // this.$store.commit('firmador/cleanPila')
      // this.$store.commit('firmador/setGoBack')
      this.$http
        .post(
          'usuario/' + this.user.id + '/pila-documentos',
          payload
        )
        .then(res => {
          if (type === 'jnlp') {
            const urlJnlp = location.href.replace(
              window.location,
              '/jnlp/recibos?token=' + res.data.token
            )
            window.open(urlJnlp, '_self')
          } else {
            const urlProtocol = `signar://${res.data.token};${res.data.url};${res.data.usr};recibos`
            window.open(urlProtocol, '_self')
          }
        })
        .then(() => {
          this.$buefy.modal.open({
            parent: this,
            canCancel: false,
            hasModalCard: true,
            props: {
              type: type
            },
            component: ModalSubirRecibos,
            events: {
              reload: () => this.refresh()
            }
          })
        })
        .catch(err => {
          console.error('No se pudo actualizar la pila de documentos')
          console.error(err)
          this.$snackbar.open({
            message: 'Error. No se pudo comunicar con el servidor de archivos',
            type: 'is-warning',
            actionText: 'Recargar',
            indefinite: true,
            onAction: () => {
              location.reload()
            }
          })
        })
    },
    // launchFirmadorLegacy: function (type) {
    //   const payload = {
    //     opcion: 'documentos',
    //     lista: [],
    //     firmador: 'signar'
    //   }
    //   this.$http
    //     .post(
    //       'usuario/' + this.user.id + '/pila-documentos',
    //       payload
    //     )
    //     .then(res => {
    //         const urlJnlp = location.href.replace(
    //           window.location,
    //           '/jnlp/recibos?token=' + res.data.token
    //         )
    //         window.open(urlJnlp, '_self')
    //     })
    //     .catch(err => {
    //       console.error('No se pudo actualizar la pila de documentos')
    //       console.error(err)
    //       this.$snackbar.open({
    //         message: 'Error. No se pudo comunicar con el servidor de archivos',
    //         type: 'is-warning',
    //         actionText: 'Recargar',
    //         indefinite: true,
    //         onAction: () => {
    //           location.reload()
    //         }
    //       })
    //     })
    // },
    firmarPendientes: function () {
      const firmarPendientesPayload = {
        firmador: 'signar',
        opcion: 'recibos'
      }
      this.$store.commit('firmador/cleanPila')
      this.$store.dispatch('firmador/unload')
      this.$store.commit('firmador/setGoBack', { name: 'listarRecibos' })
      this.$store.commit('firmador/setPayload', firmarPendientesPayload)
      this.$buefy.modal.open({
        parent: this,
        canCancel: false,
        hasModalCard: true,
        component: ModalLaunchFirmador
      })
      // this.$router.push({ name: 'launchFirmador' })
      //  this.$http.post('/solicitud-firma', firmarPendientesPayload)
      //   .then(res => {
      //     this.urlSignar = res.data.link
      //     window.open(res.data.link, '_self')
      //     this.$buefy.modal.open({
      //       parent: this,
      //       canCancel: false,
      //       hasModalCard: true,
      //       component: ModalFirmarPendientes,
      //       events: {
      //         firmarPendientesJnlp: () => this.firmarPendientesJnlp(),
      //         reload: () => this.refresh()
      //       }
      //     })
      //   })
      //   .catch(err => {
      //     console.error(err)
      //     this.$buefy.snackbar.open({
      //       message: 'Ocurrió un error, por favor intentelo mas tarde',
      //       type: 'is-danger'
      //     })
      //   })
      //   .finally(() => {
      //     this.isLoading = false
      //   })
    },
    // firmarPendientesJnlp() {
    //   this.isLoading = true
    //   const jnlpPayload = {
    //     opcion: this.payloadClone.opcion,
    //     firmador: this.payloadClone.firmador
    //   }
    //   if (this.payloadClone.opcion !== 'recibos') {
    //     jnlpPayload.lista = this.payloadClone.parametros.documentos
    //   }
    //   this.$http.post('usuario/' + this.$store.getters.getUser.id + '/pila-documentos', jnlpPayload)
    //     .then((res) => {
    //       if (this.payloadClone.opcion === 'recibos') {
    //         this.urlJnlp = location.href.replace(
    //           window.location,
    //           `/jnlp/recibos?token=${res.data.token}`
    //         )
    //       } else {
    //         this.urlJnlp = location.href.replace(
    //           window.location,
    //           `/jnlp/firmador?token=${res.data.token}`
    //         )
    //       }
    //     })
    //     .then(() => {
    //       window.open(this.urlJnlp, '_self')
    //     })
    //     .catch(err => {
    //       console.error(err)
    //       this.$buefy.snackbar.open({
    //         message: 'Ocurrió un error, por favor intentelo mas tarde',
    //         type: 'is-danger'
    //       })
    //     })
    //     .finally(() => {
    //       this.isLoading = false
    //     })
    // },
    download: function () {
      this.$buefy.snackbar.open({
        message:
          'Comprimiendo los archivos seleccionados para descargar, aguarde un momento',
        type: 'is-success'
      })
      const ids = this.$store.state.recibos.selected.map(r => r.id)
      this.$http
        .get('/documento/' + ids.join(',') + '/descarga?v=recibo')
        .then(response => {
          window.location.href = response.data.link
        })
        .catch(error => {
          console.error('Fail', error)
          this.$buefy.snackbar.open({
            message:
              'Error de conexión. Compruebe la conectividad con internet.',
            type: 'is-danger'
          })
        })
    },
    cleanSelected: function () {
      this.$refs.listado.cleanSelected()
    }
  },
  computed: {
    selectedRecibos: function () {
      return this.$store.state.recibos.selected
    },
    user: function () {
      return this.$store.getters.getUser
    }
  }
  // beforeRouteLeave (to, from, next) {
  // this.$store.commit('cleanSelectedRecibo')
  // this.$store.commit('setReciboService', {
  //   key: 'showFirmarMisRecibos',
  //   value: false
  // })
  // next()
  // }
}
</script>

<style lang="scss" scoped>
td {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: default;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}
</style>
