<template>
  <div class="modal-card">
    <div class="modal-card-body">
      <h1 class="title is-4">Permisos</h1>
      <ul>
        <li :class="{'unactive': level < 0}"><b>Nivel 0</b> - Solo listar</li>
        <li :class="{'unactive': level < 1}"><b>Nivel 1</b> - Ver / Descargar</li>
        <li :class="{'unactive': level < 2}"><b>Nivel 2</b> - Descargar documento confidencial</li>
        <li :class="{'unactive': level < 3}"><b>Nivel 3</b> - Realizar acciones como firmar</li>
        <li :class="{'unactive': level < 4}"><b>Nivel 4</b> - Agregar documentos a carpeta / Modificar metadatos</li>
        <li :class="{'unactive': level < 5}"><b>Nivel 5</b> - Agregar nuevas versiones / Asociar aspectos</li>
        <li :class="{'unactive': level < 6}"><b>Nivel 6</b> - Mover o renombrar</li>
        <li :class="{'unactive': level < 7}"><b>Nivel 7</b> - Eliminar </li>
        <li :class="{'unactive': level < 8}"><b>Nivel 8</b> - Modificar permisos</li>
        <li :class="{'unactive': level < 9}"><b>Nivel 9</b> - Propietario <i class="fas fa-star"></i></li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: ['level']
}
</script>

<style lang="scss" scoped>
.unactive{
  color: #CACACA
}
</style>
