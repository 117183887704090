<template>
  <section id="firmador" class="section">
    <div class="columns" v-if="!noDocs">
      <div class="column is-6 is-offset-3 has-text-centered">
        <div class="card" v-if="!firmadorSelected">
          <div class="card-content">
            <h1 class="subtitle is-5">Paso 1</h1>
            <h1 class="title is-4 animated flash">Seleccione el firmador</h1>
            <div class="columns">
              <div class="column has-text-centered" v-if="user.firmadores_habilitados.signar">
                  <img src="/statics/signar-logo.svg" @click="submitPila('signar')" class="image firmador-select" alt>
              </div>
              <div class="column has-text-centered" v-if="user.firmadores_habilitados.pfdrar">
                  <img src="/statics/pfdrar-logo.png" @click="submitPila('pfdrar')" class="image firmador-select" alt>
              </div>
            </div>
          </div>
        </div>
        <div class="card" v-else>
          <div class="card-content">
            <h1 class="subtitle is-5">Paso 2</h1>
            <h1 class="title is-3 animated flash">Abriendo firmador</h1>
            <h1 class="subtitle is-5" v-if="firmadorSelected === 'signar'">Signar ®</h1>
            <h1 class="subtitle is-6" v-if="firmadorSelected === 'pfdrar'">Plataforma de Firma Digital Remota - Secretaria de Modernización - Argentina</h1>
              <img src="/statics/signar-logo.svg" v-if="firmadorSelected === 'signar'" class="image" alt="Signar Firmador - By Lyris">
              <img src="/statics/pfdrar-logo.png" v-if="firmadorSelected === 'pfdrar'" class="image" alt="Plataforma de firma digital remota - Argentina">
            <div class="content">
              <p class="has-text-info">NOTA: Si el firmador
                <u>NO</u> se ejecutó, verifique si su navegador está bloqueando los pop-ups <span v-if="firmadorSelected === 'signar'">y <a @click="firmarSignar(urlSignar)"><u>haga click aquí</u></a></span>
                <br>Recomendamos habilitar los pop-ups para esta página.
              </p>
              <hr>
              <br>
              <p v-if="firmadorSelected === 'signar'">¿No cuenta con Signar® Firmador?&nbsp;&nbsp;&nbsp;<a href="https://firmador.lyris.com.ar"><i class="fas fa-download"></i>&nbsp;Descárguelo aquí</a></p>
              <p v-if="firmadorSelected === 'signar'" class="is-size-7">Si desea, puede utilizar al versión legacy (JNLP) <a :href="this.urlJnlp" target="_blank">haciendo clic aquí</a></p>
              <br>
            </div>
            <a @click="goBack" class="button is-dark">
              <i class="fas fa-reply"></i>&nbsp;Volver
            </a>
          </div>
        </div>
        <div class="card" v-if="firmadorSelected && postergados">
          <div class="card-content has-text-centered">
            <h1 class="title has-text-primary is-5"><i class="fas fa-exclamation-triangle"></i>&nbsp;Existen documentos pendientes por firmar</h1>
            <p class="subtitle is-6">Para firmar los documentos pendientes, haga clic en "Continuar"</p>
           <p>
            <a @click="continuarPostergados" class="button is-info">
              Continuar&nbsp;<i class="fas arrow-right"></i>
            </a>
           </p>
          </div>
        </div>
      </div>
      <div class="column" v-if="docStack.length > 0">
        <div class="box">
          <a @click="showTable = !showTable"><i class="fas fa-lg is-pulled-right" :class="angle"></i>
            <h1 class="subtitle is-5" :class="{'is-marginless': !showTable}">Documentos a firmar <small>({{docStack.length}})</small></h1>
          </a>
          <div class="content" v-show="showTable">
            <br>
      <table class="table">
        <tbody>
          <tr v-for="doc in docStack" :key="doc.id" :title="doc.nombre">
            <td class="is-size-7">
              <i class="fas fa-folder" v-if="doc.tipo_documento_id == 'carpeta'"></i>
              <i class="fas fa-dollar-sign" v-else-if="doc.tipo_documento_id == 'recibo'"></i>
              <i class="fas fa-file" v-else></i>
              {{doc.nombre}}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
        </div>
      </div>
    </div>
    <div class="columns" v-else>
      <div class="column is-6 is-offset-3 has-text-centered">
        <h1 class="title is-3">La pila de documentos está vacia</h1>
        <h1 class="subtitle is-6">Para poder firmar debe haber documentos en pila</h1>
        <router-link to="/" class="button is-dark">
          <i class="fas fa-reply fa-fw"></i>&nbsp;Volver al inicio
        </router-link>
      </div>
    </div>
  </section>
</template>

<script>
import fieldsUtils from '@/mixins/fieldsUtils'

export default {
  mixins: [fieldsUtils],
  data () {
    return {
      firmadorSelected: null,
      showTable: false,
      noDocs: false,
      postergados: false,
      urlJnlp: null,
      urlSignar: null
    }
  },
  mounted: function () {
    if (this.docStack.length === 0 && this.isObjectEmpty(this.firmadorStore.payload)) this.noDocs = true
    if (this.user.firmadores_habilitados.signar && !this.user.firmadores_habilitados.pfdrar) {
      this.submitPila('signar')
    } else if (!this.user.firmadores_habilitados.signar && this.user.firmadores_habilitados.pfdrar) {
      this.submitPila('pfdrar')
    }
  },
  beforeRouteLeave (to, from, next) {
    // called when the route that renders this component is about to
    // be navigated away from.
    // has access to `this` component instance.
    this.$store.dispatch('firmador/unload')
    next()
  },
  methods: {
    submitPila: function (firmador) {
      this.firmadorSelected = firmador
      const payload = this.firmadorStore.payload
      payload.firmador = firmador
      this.$http
        .post(
          '/solicitud-firma',
          payload
        )
        .then(res => {
          switch (firmador) {
            case 'pfdrar':
              if (res.data.pila_postergada && res.data.pila_postergada.length > 0) {
                this.$store.commit('firmador/setPila', res.data.pila_postergada)
                payload.lista = res.data.pila_postergada
                this.postergados = true
                setTimeout(() => {
                  this.$eventHub.$emit('hidePila')
                }, 1000)
              } else {
                // this.$store.commit('firmador/cleanPila')
                this.$store.dispatch('firmador/unloadPila')
              }
              this.firmarPfdrar(res.data.link)
              break
            default: // signar
              this.urlSignar = res.data.link
              // if(payload.opcion === 'recibos') {
              //   this.firmarRecibosSignar(res.data.token)
              // } else {
              //   this.firmarDocumentosSignar(res.data.token)
              // }
              // this.firmarDocumentosSignar(res.data.token, res.data.url, res.data.usr)
              // this.$store.commit('firmador/cleanPila')
              this.$store.dispatch('firmador/unloadPila')
              this.firmarSignar(res.data.link)
              break
          }
        })
        .catch(err => {
          console.error(err)
          this.$buefy.snackbar.open({
            message: 'Error. No se pudo comunicar con el servidor de archivos',
            type: 'is-danger'
          })
        })
    },
    firmarSignar: function (url) {
      window.open(url, '_self')
    },
    // firmarDocumentosSignar: function (token, url, id) {
    //   this.urlJnlp = location.href.replace(
    //     window.location,
    //     '/jnlp/firmador?token=' + token
    //   )
    //   this.urlProtocol = `signar://${token};${url};${id};firmador`
    //   this.$store.commit('cleanPila')
    //   this.$store.commit('cleanPilaDocumentoPayload')
    //   // deployJava.launchEx(url);
    //   window.open(this.urlProtocol, '_self')
    // },
    // firmarRecibosSignar: function(token){
    //   let url = location.href.replace(
    //       window.location,
    //       "/jnlp/recibos?token=" + token
    //     );
    //   this.$store.commit('cleanPila');
    //   this.$store.commit('cleanPilaDocumentoPayload');
    //   window.open(url);
    // },
    firmarPfdrar: function (url) {
      window.open(url, '_self')
    },
    continuarPostergados: function () {
      const payload = this.firmadorStore.payload
      payload.parametros.documentos = this.docStack.map(doc => doc.id)
      delete payload.firmador
      this.$store.commit('firmador/setPayload', payload)
      this.firmadorSelected = null
      this.postergados = false
    },
    goBack: function () {
      if (this.firmadorStore.goBack != null) {
        this.$router.push(this.firmadorStore.goBack)
      } else {
        this.$router.push('/')
      }
    }
  },
  computed: {
    session () {
      return this.$store.state.session
    },
    user: function () {
      return this.$store.getters.getUser
    },
    angle: function () {
      return this.showTable ? 'fa-angle-up' : 'fa-angle-down'
    },
    firmadorStore: function () {
      return this.$store.state.firmador
    },
    docStack: function () {
      return this.firmadorStore.pila
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
img.image {
  width: 170px;
  margin: 0 auto 20px;

}
.firmador-select{
  cursor: pointer;
  padding: 20px 30px;
  border-radius: 5px;
  border: 1px solid #cacaca;
}
</style>
