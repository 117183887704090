<template>
  <b-modal :active.sync="showInstaller" :can-cancel="false" scroll="keep" @close="cancelar">
    <b-loading :is-full-page="false" v-model="isLoading" :can-cancel="true"></b-loading>
    <div class="card">
      <div class="card-content">
        <h1 class="title is-4"><i class="fas fa-cube"></i>&nbsp;Instalación: Autoridad de Registro</h1>
        <h1 class="subtitle is-6">Configuración de parámetros</h1>

        <div class="field">
          <label class="label">Autoridad Certificante Intermedia</label>
          <div class="control is-expanded">
            <div class="select is-fullwidth">
              <select v-model.number="settings.autoridad_certificante_id" name="AC Intermedia" v-validate="{required:true}" >
                <option v-for="autoridad in acDisponibles" :key="`ac-id-${autoridad.id}`" :value="autoridad.id">{{autoridad.nombre}}</option>
              </select>
            </div>
            <span class="help is-danger" v-if="errors.has('AC Intermedia')">{{errors.first('AC Intermedia')}}</span>
          </div>
        </div>

        <div class="field">
          <label class="label">Instrucciones para el suscriptor</label>
          <div class="control">
            <textarea v-model="settings.instrucciones" name="Instrucciones" class="textarea" v-validate="{required:true}"></textarea>
          </div>
          <span class="help is-danger" v-if="errors.has('Instrucciones')">{{errors.first('Instrucciones')}}</span>
        </div>

        <div class="columns">
          <div class="column is-9">
            <div class="field">
              <label class="label">Patrón de acta</label>
              <div class="field has-addons">
                <div class="control is-expanded">
                  <input class="input" type="text" name="patronActa" v-validate="'required|regex:#at'"
                    :class="{'is-danger': errors.has('patronActa')}" v-model="settings.patron_actas">
                </div>
                <div class="control">
                  <a class="button is-static">.pdf</a>
                </div>
              </div>
            </div>
            <b-notification type="is-warning is-light mt-5 py-3 px-4" :closable="false">
              Se debe incluir el flag #at obligatoriamente y garantizar que el nombre del acta sea único.
            </b-notification>
          </div>
          <div class="column content is-small">
            <table class="table is-narrow mt-3">
              <thead>
                <tr>
                  <th colspan="2">Patrones disponibles</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>#at</th>
                  <td>Tipo de acta</td>
                </tr>
                <tr>
                  <th>#d</th>
                  <td>Día</td>
                </tr>
                <tr>
                  <th>#m</th>
                  <td>Mes</td>
                </tr>
                <tr>
                  <th>#Y</th>
                  <td>Año</td>
                </tr>
                <tr>
                  <th>#H</th>
                  <td>Hora</td>
                </tr>
                <tr>
                  <th>#i</th>
                  <td>Minutos</td>
                </tr>
                <tr>
                  <th>#s</th>
                  <td>Segundos</td>
                </tr>
                <tr>
                  <th>#ucf</th>
                  <td>CUIT del firmador</td>
                </tr>
                <tr>
                  <th>#ucb</th>
                  <td>CUIT del beneficiario</td>
                </tr>
                <tr>
                  <th>#ns</th>
                  <td>Número de serie del certificado</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="field">
          <label class="label">Carpeta de actas de la autoridad de registro</label>
          <div class="field">
            <div class="control">
              <a class="button is-static is-fullwidth has-text-left">{{getRuta}}</a>
            </div>
            <span class="help">Elija la carpeta donde se guardaran las actas.</span>
            <span class="help is-danger" v-if="errors.has('Carpeta de actas')">{{errors.first('Carpeta de actas')}}</span>
          </div>
          <explorer :initFolder="settings.origen_id || organizacion.slug" :slug="organizacion.slug" ref="listado" :rutas.sync="rutas" :selected.sync="selected"></explorer>
          <input type="hidden" name="Carpeta de actas" :value="getIdRuta" v-validate="{required:true}">
        </div>

        <div class="field">
          <div class="notification is-primary">
            <i class="fas fa-info-circle fa-fw"></i> Recuerde asignar los roles <span class="tag is-white is-rounded">Oficial de Registro</span> y <span class="tag is-white is-rounded">Suscriptor</span>  a los usuarios para que utilicen la funcionalidad.
          </div>
        </div>

        <div class="field is-clearfix">
          <div class="buttons is-pulled-right">
            <button @click="cancelar" class="button is-dark is-outlined">
              Cancelar
            </button>
            <button v-if="params" @click="reinstalar" :disabled="errors.count() > 0" class="button is-primary">
              Guardar
            </button>
            <button v-else @click="instalar" class="button is-primary">
              Instalar módulo
            </button>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>
<script>
import Explorer from '@/components/utils/Explorer'
import fieldsUtils from '@/mixins/fieldsUtils'
export default {
  props: ['showInstaller', 'idOrganization', 'params'],
  mixins: [fieldsUtils],
  data () {
    return {
      acDisponibles: [],
      settings: {
        autoridad_certificante_id: null,
        instrucciones: '',
        carpeta_actas_id: null,
        patron_actas: '#at-#Y-#m-#d-#H-#i-#s-#ucf-#ucb-#ns'
      },
      rutas: null,
      selected: null,
      showExplorer: false,
      isLoading: true,
      directorio: []
    }
  },
  components: {
    Explorer
  },
  beforeMount: function () {
    this.isLoading = true
    this.$http.get('/autoridad-certificante')
      .then(res => {
        this.acDisponibles = res.data
      })
      .catch(() => {
        this.$buefy.snackbar.open({
          message: 'Error al recuperar autoridades certificantes disponibles',
          type: 'is-danger'
        })
      })
      .finally(() => {
        this.isLoading = false
      })
  },
  created: function () {
    this.isLoading = false
    if (this.params) {
      this.settings.autoridad_certificante_id = this.params.parametros.autoridad_certificante_id
      this.settings.instrucciones = this.params.parametros.instrucciones
      this.settings.carpeta_actas_id = this.params.parametros.carpeta_actas_id
      this.settings.patron_actas = this.params.parametros.patron_actas.replace('.pdf', '')
    }
  },
  methods: {
    cancelar: function () {
      this.selected = null
      if (this.params) { this.$emit('cancelarReinstalar') } else { this.$emit('cancelarInstalar') }
    },
    instalar: function () {
      this.isLoading = true
      this.$validator.validate()
        .then(result => {
          if (!result) {
            throw Error()
          }
          this.$http
            .request({
              method: 'post',
              url: '/organizacion/' + this.organizacion.id + '/modulo/aureg',
              data: this.payload
            })
            .then(() => {
              this.$buefy.snackbar.open({
                message: 'El modulo ha sido instalado con éxito',
                duration: 8000
              })
              this.isLoading = false
              this.$emit('instalado')
            })
            .catch(error => {
              this.errorMessage = error.message
              this.$buefy.snackbar.open({
                message: 'Error al instalar el modulo',
                type: 'is-warning',
                actionText: 'Cerrar',
                duration: 8000
              })
              this.isLoading = false
            })
        }).catch(err => {
          console.error(err)
          this.$buefy.snackbar.open({
            message: 'Error, revisé los campos de los atributos',
            type: 'is-danger'
          })
        }).finally(() => {
          this.isLoading = false
        })
    },
    reinstalar: function () {
      this.isLoading = true
      this.$http
        .request({
          method: 'post',
          url: '/instalacion/' + this.params.id,
          data: this.payload
        })
        .then(() => {
          this.$buefy.snackbar.open({
            message: 'El modulo ha sido reinstalado con éxito',
            duration: 8000
          })
          this.isLoading = false
          this.$emit('instalado')
        })
        .catch(error => {
          this.errorMessage = error.message
          this.$buefy.snackbar.open({
            message: 'Error al reinstalar el modulo',
            type: 'is-warning',
            actionText: 'Cerrar',
            duration: 8000
          })
          this.isLoading = false
        })
    }
  },
  computed: {
    organizacion: function () {
      return this.$store.state.organizacion.organizacion
    },
    getRuta: function () {
      if (this.selected == null) {
        return this.organizacion.slug
      } else {
        return this.selected.ruta
      }
    },
    getIdRuta: function () {
      return this.selected != null ? this.selected.id : null
    },
    payload: function () {
      return {
        autoridad_certificante_id: this.settings.autoridad_certificante_id,
        instrucciones: this.settings.instrucciones,
        carpeta_actas_id: this.getIdRuta,
        patron_actas: `${this.settings.patron_actas}.pdf`
      }
    }
  }
}
</script>

<style lang="scss" scoped>
table.table{
  margin-bottom: 0;
}
</style>
