<template>
  <section v-if="serie">
    <div class="notification is-black my-0 is-radiusless" v-if="!serie.abierta">
      <i class="fas fa-lock"></i>&nbsp;&nbsp;Serie documental <b>Cerrada</b>
    </div>
    <div class="box">
      <h1 class="subtitle is-5">Serie documental</h1>
      <h1 class="title is-1">{{serie.nombre}}</h1>
      <div class="content">
        <p>{{serie.descripcion}}</p>
        <p class="is-size-7">Última edición: {{serie.created_at}}</p>
      </div>
      <div class="buttons">
        <router-link
          class="button is-primary is-outlined"
          :to="{name: 'organizacionEditarSerieDocumental', params: {idSerie: serie.id} }"
        >
          <i class="fas fa-edit"></i>&nbsp;Activar edición de serie
        </router-link>
        <button
          class="button is-primary is-outlined"
          v-if="serie.abierta"
          @click="openConfirmCerrar(serie)"
        >
          <i class="fas fa-lock"></i>&nbsp;Cerrar serie
        </button>
        <button
          class="button is-primary is-outlined"
          v-else
          @click="openConfirmAbrir(serie)"
        ><i class="fas fa-unlock"></i>&nbsp;Abrir serie</button>
        <button class="button is-danger is-outlined" @click="openConfirmDelete(serie)">
          <i class="fas fa-trash"></i>&nbsp;Eliminar serie
        </button>
      </div>
    </div>
    <div class="box">

      <div class="columns">
        <div class="column is-8">
          <h2 class="subtitle is-5">Estados</h2>
          <div class="tags">
            <div
              class="tag is-medium"
              :class="index != serie.estado_inicial ? 'is-info' : 'is-link'"
              v-for="(estado, index) in serie.estados"
              :key="index"
            >{{estado}}</div>
          </div>
        </div>
        <div class="column">
          <h2 class="subtitle is-5">Estado Inicial</h2>
          <div class="tags">
            <div
              class="tag is-link is-medium"
            >{{serie.estado_inicial && serie.estados[serie.estado_inicial]}}</div>
          </div>
        </div>
      </div>
    </div>
     <div class="box">
      <h2 class="subtitle is-5">Aspectos</h2>
      <div class="tags" v-if="aspectosSerie.length > 0 ">
        <div
          class="tag is-light is-medium"
          v-for="a in aspectosSerie"
          :key="a.id"
        >{{a.nombre}}</div>
      </div>
      <div v-else>
        <p class="is-italic is-mono">
          - No se han asignados aspectos a la serie -
        </p>
      </div>
   </div>
 <div class="box">
    <h2 class="subtitle is-5">Estructura</h2>
      <div class="columns is-multiline">
          <div class="column is-6 is-4-desktop"  v-for="(subdoc, key) in serie.estructura" :key="`doc-${key}`">
            <div class="box p-3 is-flex is-flex-direction-row">
              <div class="mr-4">
                <i class="far fa-2x has-text-primary" :class="{'fa-copy': subdoc.multiple, 'fa-file': !subdoc.multiple}"></i>
              </div>
              <div class="is-flex-grow-1">
                <h4 class="title is-6">{{subdoc.nombre}}</h4>
                <h4 class="subtitle is-7 mb-3">{{subdoc.key}}</h4>
                <div class="is-size-7">
                <p><b>Formatos</b>: {{subdoc.formatos.join(', ')}}</p>
                <p><b>¿Colección?</b>&nbsp;
                 <i class="fas" :class="{'fa-check': subdoc.multiple, 'fa-times': !subdoc.multiple}"></i>&nbsp;{{subdoc.multiple ? 'Si' : 'No'}}
                </p>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
    <b-collapse
      class="card is-radiusless my-1 is-shadowless"
      animation="slide"
      v-for="(evento, index) of serie.eventos"
      :key="index"
      :open="openCollapseEvent == index"
      @open="openCollapseEvent = index">
      <template #trigger="props">
        <div
            class="card-header is-radiusless"
            role="button"
        >
          <div class="card-header-title is-flex-direction-column is-align-items-start">
            <p class="mb-3">Evento:&nbsp;&nbsp;{{ evento.nombre }} <span class="is-4 ml-5 has-text-primary is-400 "><i class="fas fa-bolt"></i> {{evento.acciones.length}}</span><span class="is-4 ml-5 has-text-primary is-400 " v-if="evento.areas_habilitadas"><i class="fas fa-lock"></i> {{evento.areas_habilitadas.length}} Areas</span></p>
            <div class="tags is-400">
              <span
                class="tag"
                :class="e != serie.estado_inicial ? 'is-light' : 'is-link'"
                v-for="e in evento.estados_iniciales"
                :key="e"
              >{{serie.estados[e]}}</span>
              <span class="tag is-white">
                <i class="fas fa-arrow-right"></i>
              </span>
              <span
                class="tag"
                :class="evento.estado_final != serie.estado_inicial ? 'is-light' : 'is-link'"
                v-if="serie.estados[evento.estado_final]"
              >{{serie.estados[evento.estado_final]}}</span>
              <span class="tag is-light" v-else>
                <i class="fas fa-asterisk"></i>
              </span>
            </div>
          </div>
          <a class="card-header-icon">
            <b-icon
              :icon="props.open ? 'angle-up' : 'angle-down'">
            </b-icon>
          </a>
        </div>
        </template>
        <div class="card-content">
          <h1 class="subtitle is-4">
            Evento
          </h1>
          <h1 class="title is-3">
            {{ evento.nombre }}
          </h1>
          <hr>
          <div class="columns is-multiline">
            <div class="column is-6">
              <h1 class="subtitle is-5">Estados iniciales</h1>
              <div class="tags">
                <span
                  class="tag is-medium"
                  :class="e != serie.estado_inicial ? 'is-light' : 'is-link'"
                  v-for="e in evento.estados_iniciales"
                  :key="e"
                >{{serie.estados[e]}}</span>
              </div>
            </div>
            <div class="column is-6">
              <h1 class="subtitle is-5">Estados final</h1>
              <div class="tags">
                <span
                  class="tag is-primary"
                  v-if="serie.estados[evento.estado_final]"
                >{{serie.estados[evento.estado_final]}}</span>
                <span v-else>
                  <i>
                    <i class="fas fa-asterisk"></i>&nbsp;Mantiene el estado
                  </i>
                </span>
              </div>
            </div>
          </div>
            <hr>
          <h1 class="subtitle is-5">Areas habilitadas para ejecutar acciones</h1>
            <div class="tags" v-if="evento.areas_habilitadas">
              <span
                class="tag is-dark"
                v-for="a in evento.areas_habilitadas"
                :key="a"
              >{{getArea(a) || '???'}}</span>
            </div>
            <div class="content" v-else>
              <p class="is-italic is-mono">Todas las areas pueden ejecutar las acciones</p>
            </div>
            <hr>
            <h1 class="subtitle is-5">Acciones</h1>
              <div class="content">
              <p>El siguiente listado son las acciones del evento.</p>
              </div>
            <div class="content" v-if="evento.acciones.length === 0">
              <p class="is-italic is-mono">No se requieren acciones para ejecutar el evento</p>
            </div>
            <div v-else>
              <div class="box is-flex is-flex-direction-row" v-for="(a, index) in evento.acciones" :key="index">
                <div class="mr-4">
                  <i class="fas fa-bolt fa-2x has-text-primary"></i>
                </div>
                <div class="is-flex-grow-1">
                  <h1 class="subtitle is-6 mb-3">Acción #{{index+1}}</h1>
                  <p class=" my-3 is-italic">{{a.descripcion}}</p>
                  <p><b>Parámetros:</b></p>
                  <p class="ml-6" v-for="(p,index2) in a.parametros" :key="index2">
                    <i class="fas fa-dot"></i>&nbsp;{{index2}}&nbsp;
                    <i class="fas fa-arrow-right"></i>
                    &nbsp;{{p}}
                  </p>
                </div>
              </div>
            </div>
        </div>
      </b-collapse>
  </section>
</template>

<script>
export default {
  props: ['idOrganization', 'idSerie'],
  data () {
    return {
      openCollapseEvent: null
    }
  },
  methods: {
    getArea: function (area) {
      if (this.areas.length > 0) {
        const theArea = this.areas.find((a) => {
          return a.id === area
        })
        if (theArea) {
          return theArea.nombre
        }
      }
      return null
    },
    // ---------------------------
    openConfirmDelete: function (serie) {
      this.$buefy.dialog.confirm({
        title: 'Eliminar serie documental',
        message: `<div class="content has-text-danger">
          <h1 class="title has-text-danger">Atención</h1>
          <p>
            La acción que está por realizar tiene un efecto importante de las unidades compuesta que se instanciaron desde la serie
            <b>${serie.nombre}</b>
          </p>
          <p>De proceder con la eliminación, se <b>eliminarán</b> todas las unidades compuestas asociadas a este atributo.</p>
          <p>
            <i class="fas fa-exclamation-triangle"></i>&nbsp;Como alternativa puede cerrar la serie documental para que no se instancien mas unidades compuestas a partir de esta serie.
          </p>
        </div>
        <p>
          ¿Esta seguro que quiere <b>eliminar</b> la serie <b>${serie.nombre}</b>?
        </p>`,
        confirmText: 'Eliminar',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => this.deleteSerie(serie)
      })
    },
    openConfirmAbrir: function (serie) {
      this.$buefy.dialog.confirm({
        title: 'Abrir serie documental',
        message: `<p>Al abrir la serie documental, habilita la instanciación de nuevas unidades compuestas de la serie <b>${serie.nombre}</b></p>`,
        confirmText: 'Abrir serie',
        onConfirm: () => this.abrirSerie(serie)
      })
    },
    openConfirmCerrar: function (serie) {
      this.$buefy.dialog.confirm({
        title: 'Cerrar serie documental',
        message: `<p>Al cerrar la serie documental, deshabilita la instanciación de nuevas unidades compuestas de la serie <b>${serie.nombre}</b></p>`,
        confirmText: 'Cerrar serie',
        onConfirm: () => this.cerrarSerie(serie)
      })
    },
    deleteSerie: function (serie) {
      this.startLoading()
      this.$http.delete(`/serie-documental/${serie.id}`)
        .then(res => {
          this.$router.push({ name: 'organizacionSerieDocumental', params: { idOrganization: this.idOrganization } })
          this.$store.dispatch('serieDocumental/unload')
          this.$buefy.snackbar.open({
            message: 'La serie documental se ha eliminado exitosamente',
            type: 'is-success'
          })
        })
        .catch(error => {
          console.error('Fail', error)
          this.$buefy.snackbar.open({
            message: 'Error al eliminar la serie documental',
            type: 'is-warning'
          })
        })
        .finally(() => {
          this.stopLoading()
        })
    },
    abrirSerie: function (serie) {
      this.startLoading()
      this.$http.patch(`/serie-documental/${serie.id}`, { abierta: true })
        .then(res => {
          this.$store.dispatch('serieDocumental/fetchSerieDocumental', this.idSerie)
          this.$buefy.snackbar.open({
            message: 'La serie documental se abrio exitosamente',
            type: 'is-success'
          })
        })
        .catch(error => {
          console.error('Fail', error)
          this.$buefy.snackbar.open({
            message: `Error al eliminar la serie documental: ${error.mensaje}`,
            type: 'is-danger'
          })
        })
        .finally(() => {
          this.stopLoading()
        })
    },
    cerrarSerie: function (serie) {
      this.startLoading()
      this.$http.patch(`/serie-documental/${serie.id}`, { abierta: false })
        .then(res => {
          this.$store.dispatch('serieDocumental/fetchSerieDocumental', this.idSerie)
          this.$buefy.snackbar.open({
            message: 'La serie documental se cerrado exitosamente',
            type: 'is-success'
          })
        })
        .catch(error => {
          console.error('Fail', error)
          this.$buefy.snackbar.open({
            message: 'Error al cerrar la serie documental',
            type: 'is-warning'
          })
        })
        .finally(() => {
          this.stopLoading()
        })
    }
  },
  computed: {
    serie: function () {
      return this.$store.state.serieDocumental.serieDocumental
    },
    aspectos: function () {
      return this.$store.getters['organizacion/allAspectos']
    },
    areas: function () {
      return this.$store.state.organizacion.areas
    },
    aspectosSerie: function () {
      if (!this.aspectos) return []
      return this.aspectos.filter((a) =>
        Object.values(this.serie.aspectos).includes(a.id)
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.detailed-event .subtitle{
  margin-bottom: 7px;
}
</style>
