<template>
  <section>
    <div class="box has-text-centered" v-if="isLoading">
      <loading-spin></loading-spin>
    </div>

    <template v-else>
      <div class="box mb-4" v-for="(cert, index) of certs" :key="index">
        <section v-if="cert.estado === 'pre-pendiente'" class="is-flex is-flex-direction-row is-align-items-center is-justify-content-center pb-4">
          <b-message
            type="is-danger">
            Este certificado se encuentra en un estado incompleto. Por favor, comunicarse con un administrador de Lyris IT para resolver el problema.
        </b-message>
        </section>
      <section v-else>
        <div class="is-flex is-flex-direction-row is-align-items-center mb-1">
          <div class="is-flex is-flex-direction-column is-flex-grow-1 is-italic">
            <div v-if="cert.estado === 'pendiente'" class="is-6 mb-2">
              <h6>Solicitud&nbsp;<b-tooltip v-if="cert.usuario.cantidad_certificados === 1" label="Esta es la primera solicitud del suscriptor" type="is-warning">
                <i class="fas fa-exclamation-circle fa-lg has-text-warning"></i>
              </b-tooltip></h6>
            </div>
            <div class="is-flex is-flex-direction-row">
              <span class="is-size-6 is-400">
                <span class="is-size-6 is-500">Usuario:</span> {{ cert.usuario.nombre }} {{ cert.usuario.apellido }}
              </span>&nbsp;-&nbsp;
              <span class="is-size-6 is-400">
                <span class="is-size-6 is-500">CUIL:</span> {{ cert.usuario.cuil }}
              </span>
            </div>
            <span class="is-size-6 is-500 disabled" v-if="hasRole([constants.STRINGS.ROLES.LYRIS.ID, constants.STRINGS.ROLES.LYRIS_OR.ID])">
              Organización: {{ cert.organizacion.razon_social }}
            </span>
          </div>
          <b-tag rounded type="is-warning is-light" size="is-medium" class="is-500"
            v-if="cert.estado === 'pendiente'">Pendiente</b-tag>
          <b-tag rounded type="is-info is-light" size="is-medium" class="is-500"
            v-if="cert.estado === 'preaprobado'">Preaprobado</b-tag>
          <b-tooltip label="El certificado está por vencerse" type="is-warning is-light" :active="cert.por_vencer">
            <b-tag rounded :type="cert.por_vencer ? 'is-warning' : 'is-success is-light'" size="is-medium" class="is-500"
            v-if="cert.estado === 'vigente'">Vigente <b-icon v-if="cert.por_vencer" pack="fas" icon="exclamation-triangle"></b-icon></b-tag>
          </b-tooltip>
          <b-tag rounded type="is-danger is-light" size="is-medium" class="is-500"
            v-if="cert.estado === 'revocado'">Revocado</b-tag>
          <b-tag rounded type="is-danger is-light" size="is-medium" class="is-500"
            v-if="cert.estado === 'rechazado'">Rechazado</b-tag>
          <b-tag rounded type="is-light" size="is-medium" class="is-500"
            v-if="cert.estado === 'vencido'">Vencido</b-tag>
        </div>

        <div class="is-flex is-flex-direction-row is-align-items-center py-2"
          v-if="cert.estado === 'vigente' || cert.estado === 'pendiente' || cert.estado === 'preaprobado'">
          <b-button v-if="cert.estado === 'pendiente' && hasRole([constants.STRINGS.ROLES.ADMINISTRADOR_DE_CERTIFICADOS.ID, constants.STRINGS.ROLES.LYRIS.ID])" class="button is-info is-outlined mr-4"
            @click="showPreapprovedDialog(cert)">
            <i class="fas fa-signature"></i>&nbsp;&nbsp;Firmó el acuerdo de suscriptores
          </b-button>
          <router-link v-if="cert.estado === 'preaprobado' && enabledOr" class="button is-success is-outlined mr-4"
            :to="({ name: 'certificadoAprobacion', params: { idCert: cert.id }})">
            <i class="fas fa-check"></i>&nbsp;&nbsp;Aprobar
          </router-link>
          <router-link v-if="canBeRejected(cert)" class="button is-danger is-outlined"
            :to="({ name: 'certificadoRechazo', params: { idCert: cert.id }})">
            <i class="fas fa-times"></i>&nbsp;&nbsp;{{ getTextForRejectAction(cert.estado) }}
          </router-link>
          <router-link v-if="cert.estado === 'vigente' && enabledOr" class="button is-danger is-outlined mr-4"
            :to="({ name: 'certificadosRevocacion', params: { idCert: cert.id }, query: { fromOR: true }})">
            <i class="fas fa-times"></i>&nbsp;&nbsp;Revocar
          </router-link>
          <b-button v-if="cert.estado === 'vigente' && hasRole([constants.STRINGS.ROLES.OFICIAL_DE_REGISTRO.ID])" class="is-primary is-outlined mr-4"
            @click="downloadActaAlta(cert.documento_alta_id)">
            <i class="fas fa-download"></i>&nbsp;&nbsp;Descargar acta de alta
          </b-button>
          <b-button v-if="cert.estado === 'vigente' && hasRole([constants.STRINGS.ROLES.ADMINISTRADOR_DE_CERTIFICADOS.ID])" class="is-link is-outlined mr-4"
            @click="downloadCurrentCertificate(cert.usuario)">
            <i class="fas fa-cloud-download-alt"></i>&nbsp;&nbsp;Descargar certificado
          </b-button>
        </div>

        <div class="is-flex is-flex-direction-column">
          <span v-if="cert.estado === 'rechazado' && cert.comentario" class="is-size-6 is-400 mb-1">
            <span class="is-500">Motivo de rechazo:</span> {{ cert.comentario }}
          </span>
          <span v-if="cert.estado === 'pendiente' || cert.estado === 'rechazado'" class="mt-1">
            <span class="is-500">Fecha de solicitud:</span> {{ dateFormat(cert.created_at) }}
          </span>
          <span v-if="cert.estado !== 'pendiente' && cert.estado !== 'rechazado'">
            <span class="is-500">Fecha de emisión:</span> {{ dateFormat(cert.fecha_emision) }}
          </span>
          <span v-if="cert.estado !== 'pendiente' && cert.estado !== 'rechazado'" class="mt-1">
            <span class="is-500">Fecha de vencimiento:</span> {{ dateFormat(cert.fecha_vencimiento) }}
          </span>
          <span v-if="cert.estado === 'revocado'" class="mt-1">
            <span class="is-500">Fecha de revocación:</span> {{ dateFormat(cert.fecha_revocacion) }}
          </span>
            <span v-if="cert.estado === 'revocado'" class="mt-1">
            <span class="is-500">Motivo de revocación:</span> {{ reasonString(cert.razon_revocacion.toLowerCase()) }}
          </span>
          <span v-if="cert.estado === 'revocado' && cert.comentario" class="mt-1">
            <span class="is-500">Comentario adicional:</span> {{ cert.comentario }}
          </span>
          <span v-if="cert.estado !== 'pendiente' && cert.estado !== 'rechazado'" class="is-size-6 is-400 mt-1">
            <span class="is-500">Número de serie:</span> {{ cert.numero_serie }}
          </span>
        </div>
      </section>
      </div>
    </template>

    <div class="box has-text-centered" v-if="certs.length === 0 && !isLoading">
      <section class="section is-mono">
        <span class="tag is-rounded is-medium">
          <i class="fas fa-info fa-fw"></i>&nbsp;Sin certificados para mostrar
        </span>
      </section>
    </div>

    <paginator :resource="url" ref="paginator" @update="updateResource" :loading.sync="isLoading" />
  </section>
</template>
<script>
import Paginator from '@/components/utils/Paginator'
import LoadingSpin from '@/components/utils/LoadingSpin'
import constants from '@/constants'
export default {
  components: {
    Paginator,
    LoadingSpin
  },
  data () {
    return {
      constants: constants,

      isLoading: false,
      certs: [],
      enabledOr: false,
      user: null,
      reasons: null,
      queryCertificates: {
        organizacionId: null,
        usuarioId: null,
        estados: null,
        diasHastaVencimiento: null
      },
      paramsProcessed: ''
    }
  },
  beforeMount: function () {
    this.fetchReasons()
    this.getOrState(this.getUser.id)
  },
  mounted: function () {
    this.refresh(this.isCertAdminAndNotOR ? ['Pendiente'] : null)
  },
  methods: {
    setQuery (query) {
      this.isLoading = true
      this.queryCertificates.ordenamiento = query.order
      this.queryCertificates.organizacionId = query.selectedOrganizationId
      this.queryCertificates.usuarioId = query.selectedUserId
      this.queryCertificates.estados = query.selectedStates
      this.queryCertificates.diasHastaVencimiento = query.selectedDaysUntilExpiration
      this.paramsProcessed = this.preprocessingParams()
    },
    refresh (initialState = null) {
      this.isLoading = true
      this.queryCertificates.ordenamiento = 'desc'
      this.queryCertificates.organizacionId = (this.hasRole([constants.STRINGS.ROLES.LYRIS.ID, constants.STRINGS.ROLES.LYRIS_OR.ID])) ? null : this.getOrganizacionId
      this.queryCertificates.usuarioId = null
      this.queryCertificates.estados = initialState
      this.paramsProcessed = this.preprocessingParams()
    },
    hasRole (roles) {
      return this.$store.getters.hasAnyRole(roles)
    },
    fetchReasons () {
      this.isLoading = true
      this.$http
        .get('/razon-revocacion')
        .then(res => {
          this.reasons = res.data
        })
        .catch(() => {
          this.$buefy.snackbar.open({
            message: 'Ocurrió un error al recuperar los certificados',
            type: 'is-danger'
          })
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    preprocessingParams () {
      let params = ''

      params += '&direction=' + this.queryCertificates.ordenamiento

      if (this.queryCertificates.usuarioId != null) {
        params += '&usuario_id=' + this.queryCertificates.usuarioId
      }

      if (this.queryCertificates.organizacionId != null) {
        params += '&organizacion_id=' + this.queryCertificates.organizacionId
      }

      if (this.queryCertificates.estados != null) {
        let queryEstados = ''
        Object.keys(this.queryCertificates.estados).forEach(k => {
          var paramToLowerCase = this.queryCertificates.estados[k].toLowerCase()
          var paramCleaned = paramToLowerCase.replace('-', '')
          queryEstados ? queryEstados += `,${paramCleaned}` : queryEstados = `${paramCleaned}`
        })
        params += '&estados=' + queryEstados
      }

      if (this.queryCertificates.diasHastaVencimiento != null) {
        params += '&vencimiento=' + this.queryCertificates.diasHastaVencimiento
      }

      this.isLoading = false
      return params
    },
    getOrState (userId) {
      this.isLoading = true
      this.$http
        .get(`/usuario/${userId}`)
        .then(res => {
          this.enabledOr = res.data.or_activo && this.hasRole([constants.STRINGS.ROLES.OFICIAL_DE_REGISTRO.ID])
        })
        .catch(() => {
          this.$buefy.snackbar.open({
            message: 'Ocurrió un error al verificar el estado del Oficial de Registro',
            type: 'is-danger'
          })
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    showPreapprovedDialog (cert) {
      this.$buefy.dialog.confirm({
        title: 'El usuario firmó el acuerdo de suscriptores',
        message: `Confirmo que el usuario <b>${cert.usuario.apellido.toUpperCase()} ${cert.usuario.nombre}</b> firmó el acuerdo de suscriptores en tiempo y forma.`,
        confirmText: 'Confirmar',
        cancelText: 'Cancelar',
        closeOnConfirm: false,
        trapFocus: true,
        type: 'is-info',
        hasIcon: true,
        onConfirm: (value, { close, startLoading, stopLoading }) => {
          const loadingTimeout = setTimeout(startLoading, 500)
          this.$http
            .post(`/certificado/${cert.id}/preaprobar`)
            .then(() => {
              clearTimeout(loadingTimeout)
              cert.estado = 'preaprobado'
              this.$buefy.snackbar.open({
                message: 'Se guardó la respuesta',
                type: 'is-success'
              })
              close()
            })
            .catch(() => {
              clearTimeout(loadingTimeout)
              this.$buefy.snackbar.open({
                message: 'Ocurrió un error en la aprobación del certificado',
                type: 'is-danger'
              })
            })
            .finally(() => {
              stopLoading()
            })
        }
      })
    },
    aprobarCert (cert) {
      this.isLoading = true
      const request = {
        tipo: 'aprobacion',
        certificado_id: cert.id
      }
      this.$http
        .post('/acta-certificado', request)
        .then(() => {})
        .catch(() => {
          this.$buefy.snackbar.open({
            message: 'Ocurrió un error en la aprobación del certificado',
            type: 'is-danger'
          })
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    downloadActaAlta (docId) {
      this.isLoading = true
      this.$http
        .get(`/documento/${docId}/descarga`)
        .then(res => {
          this.isLoading = false
          window.location.href = res.data.link
        })
        .catch(() => {
          this.isLoading = false
          this.$buefy.snackbar.open({
            message: constants.STRINGS.ERRORS.COULD_NOT_DOWNLOAD_RECORD,
            type: 'is-danger',
            actionText: 'Cerrar',
            duration: 6000
          })
        })
    },
    downloadCurrentCertificate: function (user) {
      this.isLoading = true
      this.$http({
        url: `/usuario/${user.id}/keystore`,
        method: 'GET',
        responseType: 'blob'
      })
        .then(res => {
          this.isLoading = false
          const nombreCertificado = `${user.cuil}_${user.apellido}_${user.nombre}.p12`
          const url = window.URL.createObjectURL(new Blob([res.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', nombreCertificado.replace(/ /g, '_'))
          document.body.appendChild(link)
          link.click()
        })
        .catch(error => {
          this.isLoading = false
          console.error(error)
          this.$buefy.snackbar.open({
            message: constants.STRINGS.ERRORS.COULD_NOT_DOWNLOAD_CERTIFICATE,
            type: 'is-danger',
            actionText: 'Cerrar',
            duration: 6000
          })
        })
    },
    updateResource (data) {
      this.certs = data
    },
    dateFormat (date) {
      return new Date(date).toLocaleDateString('fr')
    },
    reasonString (reason) {
      return `${reason.charAt(0).toUpperCase()}${reason.slice(1)}`
    },
    canBeRejected (cert) {
      return (cert.estado === 'preaprobado' && this.enabledOr) || (cert.estado === 'pendiente' && this.hasRole([constants.STRINGS.ROLES.ADMINISTRADOR_DE_CERTIFICADOS.ID]))
    },
    getTextForRejectAction (estado) {
      return estado === 'preaprobado' ? 'Rechazar' : 'Cancelar solicitud'
    }
  },
  watch: {
    certs: function (oldVal, newVal) {
      this.isLoading = true
      this.certs.forEach(cert => {
        if (cert.estado === 'revocado') {
          this.reasons.forEach(r => {
            if (r.id === cert.razon_revocacion) {
              this.razonRevocacion = r.mensaje
            }
          })
          cert.razon_revocacion = this.razonRevocacion
          this.razonRevocacion = ''
        }
      })
      this.isLoading = false
    }
  },
  computed: {
    getOrganizacionId () {
      return this.getUser.organization
    },
    getUser () {
      return this.$store.getters.getUser
    },
    url: function () {
      return '/certificado?sort=created_at' + this.paramsProcessed
    },
    isCertAdminAndNotOR () {
      return this.hasRole([constants.STRINGS.ROLES.ADMINISTRADOR_DE_CERTIFICADOS.ID]) && !this.hasRole([constants.STRINGS.ROLES.OFICIAL_DE_REGISTRO.ID])
    }
  }
}
</script>

<style lang="scss" scoped>
.disabled {
  color: gray
}
</style>
