<template>
    <b-modal :active.sync="showInstaller" :can-cancel="false" scroll="keep" @close="cancelar">
      <div class="card">
        <div class="card-content">
          <h1 class="title is-4">
            <i class="fas fa-cube"></i>&nbsp;Instalación: Notificaciones</h1>
          <h1 class="subtitle is-6">Configuración de parametros</h1>
          <div class="field">
            <label class="label">Carpeta de Notificaciones</label>
            <div class="field">
              <div class="control">
                <a class="button is-static is-fullwidth has-text-left">{{getRuta}}</a>
              </div>
            </div>
            <explorer :initFolder="settings.origen_id || organizacion.slug || 1" :slug="organizacion.slug" ref="listado" :rutas.sync="rutas" :selected.sync="selected"></explorer>
            <span class="help">Elija la carpeta donde se guardarán los documentos.</span>
          </div>
          <div class="field">
            <label class="label">Flujo de firmas del usuario</label>
            <div class="control">
              <div class="select">
                <select v-model="settings.firmaUsuario">
                  <option value="0">Sin firma</option>
                  <option value="1">Firma débil</option>
                  <option value="2">Firma fuerte</option>
                </select>
              </div>
            </div>
          </div>
          <div class="field">
            <label class="label">Almacenes de software</label>
            <div class="control">
              <label class="checkbox">
                <input type="checkbox" value="backup" v-model="settings.almacenesSoft">
                Almacén en SignAR
              </label>
              <label class="checkbox">
                <input type="checkbox" value="hwtoken" v-model="settings.almacenesSoft">
                Token de hardware
              </label>
            </div>
          </div>
          <div class="field">
            <label class="label">(Opcional) Área de Administradores de RRHH</label>
            <div class="control">
              <input class="input" name="area" type="number" v-model="settings.areaAdminRRHH">
            </div>
          </div>
          <!-- <div class="field">
            <label class="label">Coordenadas de firma del usuario</label>
            <div class="field is-horizontal">
              <div class="field-body">
                <div class="field">
                  <div class="control">
                    <input class="input has-text-centered" type="text" name="coorUsua0" v-model.number="settings.coorUsua[0]" v-validate="'integer'" :class="{'is-danger': errors.has('coorUsua0')}" placeholder="Número">
                  </div>
                </div>
                <div class="field">
                  <div class="control">
                    <input class="input has-text-centered" type="text" name="coorUsua1" v-model.number="settings.coorUsua[1]" v-validate="'integer'" :class="{'is-danger': errors.has('coorUsua1')}" placeholder="Número">
                  </div>
                </div>
                <div class="field">
                  <div class="control">
                    <input class="input has-text-centered" type="text" name="coorUsua2" v-model.number="settings.coorUsua[2]" v-validate="'integer'" :class="{'is-danger': errors.has('coorUsua2')}" placeholder="Número">
                  </div>
                </div>
                <div class="field">
                  <div class="control">
                    <input class="input has-text-centered" type="text" name="coorUsua3" v-model.number="settings.coorUsua[3]" v-validate="'integer'" :class="{'is-danger': errors.has('coorUsua3')}" placeholder="Número">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="field">
                <label class="label">Coordenadas de firma del Administrador de RRHH</label>
            <div class="field is-horizontal">
              <div class="field-body">
                <div class="field">
                <div class="control">
                  <input class="input has-text-centered" type="text" name="coorAdminRRHH0" v-model.number="settings.coorAdminRRHH[0]" v-validate="'integer'" :class="{'is-danger': errors.has('coorAdminRRHH0')}" placeholder="Número">
                </div>
                </div>
                <div class="field">
                 <div class="control">
                  <input class="input has-text-centered" type="text" name="coorAdminRRHH1" v-model.number="settings.coorAdminRRHH[1]" v-validate="'integer'" :class="{'is-danger': errors.has('coorAdminRRHH1')}" placeholder="Número">
                </div>
                </div>
                <div class="field">
                 <div class="control">
                  <input class="input has-text-centered" type="text" name="coorAdminRRHH2" v-model.number="settings.coorAdminRRHH[2]" v-validate="'integer'" :class="{'is-danger': errors.has('coorAdminRRHH2')}" placeholder="Número">
                </div>
                </div>
                <div class="field">
                 <div class="control">
                  <input class="input has-text-centered" type="text" name="coorAdminRRHH3" v-model.number="settings.coorAdminRRHH[3]" v-validate="'integer'" :class="{'is-danger': errors.has('coorAdminRRHH3')}" placeholder="Número">
                </div>
                </div>
              </div>
              </div>
          </div> -->
          <div class="field">
            <div class="notification is-primary">
              <i class="fas fa-info-circle fa-fw"></i> Recuerde asignar los roles <span class="tag is-white is-rounded">Administrador de notificaciones</span> y <span class="tag is-white is-rounded">Usuario de notificaciones</span>  a los usuarios para que utilicen la funcionalidad.
            </div>
          </div>
          <div class="field">
            <div class="notification is-warning">
              <i class="fas fa-exclamation-triangle fa-fw"></i> Las coordenadas de firma no pueden cargarse debido a que se optó a que los usuarios utilicen <span class="is-underlined has-text-weight-bold">firma invisible</span> (para evitar solapamientos de firma y por los diferentes tamaños entre distintos documentos) y los administradores siempre <span class="is-underlined has-text-weight-bold">firmen abajo a la derecha</span>.
            </div>
          </div>
          <div class="field is-clearfix">
            <div class="buttons is-pulled-right">
              <button @click="cancelar" class="button is-dark is-outlined">
                Cancelar
              </button>
              <button v-if="params" @click="reinstalar" :disabled="errors.count() > 0 || disableConfirmButton" class="button is-primary">
                Guardar
              </button>
              <button v-else @click="instalar" :disabled="errors.count() > 0 || disableConfirmButton" class="button is-primary">
                Instalar módulo
              </button>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
</template>

<script>
import Explorer from '@/components/utils/Explorer'
import fieldsUtils from '@/mixins/fieldsUtils'

export default {
  props: ['showInstaller', 'idOrganization', 'params'],
  mixins: [fieldsUtils],
  data () {
    return {
      settings: {
        origen_id: null,
        almacenesSoft: ['backup'],
        areaAdminRRHH: '',
        coorUsua: [
          null,
          null,
          null,
          null
        ],
        coorAdminRRHH: [
          null,
          null,
          null,
          null
        ],
        firmaUsuario: 1
      },
      rutas: null,
      selected: null,
      isLoading: true
    }
  },
  components: {
    Explorer
  },
  created: function () {
    this.isLoading = false
    if (this.params) {
      this.settings.origen_id = this.params.parametros.origen_id
      this.settings.almacenesSoft = this.params.parametros.almacenes_software ? this.params.parametros.almacenes_software : []
      this.settings.areaAdminRRHH = this.params.parametros.area_administradores_rrhh
      this.settings.coorUsua = this.params.parametros.coordenadas_usuario
      this.settings.coorAdminRRHH = this.params.parametros.coordenadas_administrador
      this.settings.firmaUsuario = this.params.parametros.firma_usuario
    }
  },
  methods: {
    cancelar: function () {
      this.selected = null
      if (this.params) { this.$emit('cancelarReinstalar') } else { this.$emit('cancelarInstalar') }
    },
    instalar: function () {
      this.isLoading = true
      this.$http
        .request({
          method: 'post',
          url: '/organizacion/' + this.organizacion.id + '/modulo/otros',
          data: this.payload
        })
        .then(() => {
          this.$buefy.snackbar.open({
            message: 'El modulo ha sido instalado con éxito',
            duration: 8000
          })
          this.isLoading = false
          this.$emit('instalado')
        })
        .catch(error => {
          this.errorMessage = error.message
          this.$buefy.snackbar.open({
            message: 'Error al instalar el modulo',
            type: 'is-warning',
            actionText: 'Cerrar',
            duration: 8000
          })
          this.isLoading = false
        })
    },
    reinstalar: function () {
      this.isLoading = true
      this.$http
        .request({
          method: 'post',
          url: '/instalacion/' + this.params.id,
          data: this.payload
        })
        .then(() => {
          this.$buefy.snackbar.open({
            message: 'El modulo ha sido reinstalado con éxito',
            duration: 8000
          })
          this.isLoading = false
          this.$emit('instalado')
        })
        .catch(error => {
          this.errorMessage = error.message
          this.$buefy.snackbar.open({
            message: 'Error al reinstalar el modulo',
            type: 'is-warning',
            actionText: 'Cerrar',
            duration: 8000
          })
          this.isLoading = false
        })
    }
  },
  computed: {
    disableConfirmButton: function () {
      return this.settings.origen_id == null || this.settings.firmaUsuario == null || this.settings.almacenesSoft.length === 0
    },
    organizacion: function () {
      return this.$store.state.organizacion.organizacion
    },
    getRuta: function () {
      if (this.selected == null) {
        return this.organizacion.slug
      } else {
        return this.selected.ruta
      }
    },
    getIdRuta: function () {
      return this.selected != null ? this.selected.id : null
    },
    payload: function () {
      return {
        origen_id: this.getIdRuta,
        almacenes_software: this.settings.almacenesSoft,
        area_administradores_rrhh: this.isFieldOptional(this.settings.areaAdminRRHH),
        coordenadas_usuario: [0, 0, 0, 0], // this.settings.coorUsua, Por ahora es firma invisible
        coordenadas_administrador: [0, 0, 0, 0], // this.settings.coorAdminRRHH, Por ahora es firma abajo a la derecha
        firma_usuario: this.settings.firmaUsuario
      }
    }
  }
}
</script>

<style lang="scss" scoped>
table.table{
    margin-bottom: 0;
}
</style>
