const respositoryModule = {
  namespaced: true,
  state: {
    currentFolder: null,
    selectedDocumentsIndex: [],
    selectedDocuments: [],
    selectedDocument: null
  },
  mutations: {
    currentFolder (state, folder) {
      state.currentFolder = folder
    },
    selectedDocumentsIndex (state, selectedDocumentsIndex) {
      state.selectedDocumentsIndex = selectedDocumentsIndex
    },
    selectedDocuments (state, selectedDocuments) {
      state.selectedDocuments = selectedDocuments
    },
    selectedDocument (state, selectedDocument) {
      state.selectedDocument = selectedDocument
    },
    unload (state) {
      state.currentFolder = null
      state.selectedDocumentsIndex = []
      state.selectedDocuments = []
      state.selectedDocument = null
    }
  },
  getters: {},
  actions: {
    unload (context) {
      context.commit('unload')
    }
  }
}

export default respositoryModule
