const permissionsUtils = {
  data () {
    return {
      minimumLevelPermissionTo: {
        list: 1,
        download: 1,
        sign: 3,
        createOrUpload: 4,
        rename: 6,
        move: 7,
        delete: 7
      }
    }
  },
  methods: {
    openWarningSnackBar (message) {
      this.$buefy.snackbar.open({
        message: message,
        type: 'is-danger'
      })
    },
    userPermissionsOverMinimumLevel (permissions, minimumLevelPermission) {
      const userPermissions = permissions.find(perm => (perm.usuario && perm.usuario.id === this.getUser.id))
      return (userPermissions && userPermissions.nivel >= minimumLevelPermission)
    },
    areaPermissionsOverMinimumLevel (permissions, minimumLevelPermission) {
      const areaPermissions = permissions.find(perm => (perm.area && this.getUser.areas.some(idArea => idArea === perm.area.id)))
      return (areaPermissions && areaPermissions.nivel >= minimumLevelPermission)
    },
    organizationPermissionsOverMinimumLevel (permissions, minimumLevelPermission) {
      const organizationPermissions = permissions.find(perm => (perm.organizacion && perm.organizacion.id === this.getUser.organization))
      return (organizationPermissions && organizationPermissions.nivel >= minimumLevelPermission)
    },
    hasPermissionLevelOver (documents, minimumLevelPermission) {
      // If documents is not an array, convert it to an array
      if (!Array.isArray(documents)) {
        documents = [documents]
      }

      return this.$store.getters.hasAnyRole(['lyris']) ||
            documents.every(file => {
              return file && (this.userPermissionsOverMinimumLevel(file.permisos, minimumLevelPermission) ||
                      this.areaPermissionsOverMinimumLevel(file.permisos, minimumLevelPermission) ||
                      this.organizationPermissionsOverMinimumLevel(file.permisos, minimumLevelPermission))
            })
    }
  },
  computed: {
    getUser: function () {
      return this.$store.getters.getUser
    }
  }
}

export default permissionsUtils
