import http from '@/http'
import { SnackbarProgrammatic as Snackbar } from 'buefy'

const organizacionModule = {
  namespaced: true,
  state: {
    organizacion: null,
    areas: [],
    roles: [],
    aspectos: [],
    // aspectosGlobales: [],
    seriesDocumentales: [],
    operacionesDisponibles: [],
    selectedUser: null,
    certificados: []
  },
  mutations: {
    organizacion (state, organizacion) {
      state.organizacion = organizacion
    },
    areas (state, areas) {
      state.areas = areas
    },
    roles (state, roles) {
      state.roles = roles
    },
    aspectos (state, aspectos) {
      state.aspectos = aspectos
    },
    // aspectosGlobales (state, aspectosGlobales) {
    //   state.aspectosGlobales = aspectosGlobales
    // },
    seriesDocumentales (state, seriesDocumentales) {
      state.seriesDocumentales = seriesDocumentales
    },
    operacionesDisponibles (state, operacionesDisponibles) {
      state.operacionesDisponibles = operacionesDisponibles
    },
    selectedUser (state, user) {
      state.selectedUser = user
    },
    certificados (state, certificados) {
      state.certificados = certificados
    },
    unload (state) {
      state.organizacion = null
      state.areas = []
      state.roles = []
      state.aspectos = []
      state.aspectosGlobales = []
      state.seriesDocumentales = []
      state.operacionesDisponibles = []
      state.selectedUser = null
      state.certificados = []
    }
  },
  getters: {
    allAspectos: function (state, getters, rootState, rootGetters) {
      return state.aspectos.concat(rootGetters['signar/allAspectosGlobales'])
    }
  },
  actions: {
    fetchOrganizacion ({ commit }, id) {
      // Fetch, then store the value, then return the promise
      // you chan call this as store.dispatch('..').then(res => {})
      return http.get(`/organizacion/${id}`)
        .then(res => {
          console.info('[VUEX] GET OK - Got Organizacion')
          commit('organizacion', res.data)
          return res
        })
        .catch(error => {
          console.error('[VUEX] GET ERROR - Organizacion/Organizacion', error)
          Snackbar.open({
            message: 'Error obteniendo los datos de la organización',
            type: 'is-warning'
          })
        })
    },
    fetchAreas ({ commit }, id) {
      // Fetch, then store the value, then return the promise
      // you chan call this as store.dispatch('..').then(res => {})
      return http.get(`/organizacion/${id}/area?size=100`)
        .then(res => {
          console.info('[VUEX] GET OK - Got Organizacion/Areas')
          commit('areas', res.data.data)
          return res
        })
        .catch(error => {
          console.error('[VUEX] GET ERROR - Organizacion/Areas', error)
          Snackbar.open({
            message: 'Error obteniendo las areas de la organización',
            type: 'is-warning'
          })
        })
    },
    fetchRoles ({ commit }, id) {
      // Fetch, then store the value, then return the promise
      // you chan call this as store.dispatch('..').then(res => {})
      return http.get(`/organizacion/${id}/rol?size=100`)
        .then(res => {
          console.info('[VUEX] GET OK - Got Organizacion/Roles')
          commit('roles', res.data.data)
          return res
        })
        .catch(error => {
          console.error('[VUEX] GET ERROR - Organizacion/Roles', error)
          Snackbar.open({
            message: 'Error obteniendo los roles de la organización',
            type: 'is-warning'
          })
        })
    },
    fetchAspectos ({ commit }, id) {
      // Fetch, then store the value, then return the promise
      // you chan call this as store.dispatch('..').then(res => {})
      return http.get(`/organizacion/${id}/aspecto`)
        .then(res => {
          console.info('[VUEX] GET OK - Got Orgnizacion/Aspectos')
          commit('aspectos', res.data)
          return res
        })
        .catch(error => {
          console.error('[VUEX] GET ERROR - Organizacion/Aspectos', error)
          Snackbar.open({
            message: 'Error obteniendo los aspectos de la organización',
            type: 'is-warning'
          })
        })
    },
    fetchSeriesDocumentales ({ commit }, id) {
      // Fetch, then store the value, then return the promise
      // you chan call this as store.dispatch('..').then(res => {})
      return http.get(`/organizacion/${id}/serie-documental`)
        .then(res => {
          console.info('[VUEX] GET OK - Got Organizacion/SeriesDocumentales')
          commit('seriesDocumentales', res.data)
          return res
        })
        .catch(error => {
          console.error('[VUEX] GET ERROR - Organizacion/SeriesDocumentales', error)
          Snackbar.open({
            message: 'Error obteniendo las series documentales de la organización',
            type: 'is-warning'
          })
        })
    },
    fetchSelectedUser ({ commit }, id) {
      // Fetch, then store the value, then return the promise
      // you chan call this as store.dispatch('..').then(res => {})
      return http.get(`/usuario/${id}`)
        .then(res => {
          console.info('[VUEX] GET OK - Got Organizacion/User')
          commit('selectedUser', res.data)
          return res
        })
        .catch(error => {
          console.error('[VUEX] GET ERROR - Organizacion/User', error)
          Snackbar.open({
            message: 'Error obteniendo el usuario seleccionado de la organización',
            type: 'is-warning'
          })
        })
    },
    fetchCertificados ({ commit }) {
      return http.get('/certificado')
        .then(res => {
          console.info('[VUEX] GET OK - Got Certificados')
          commit('certificados', res.data)
          return res
        })
        .catch(error => {
          console.error('[VUEX] GET ERROR - Got Certificados', error)
          Snackbar.open({
            message: 'Error obteniendo los certificados',
            type: 'is-warning'
          })
        })
    },
    unload (context) {
      context.commit('unload')
    }
  }
}

export default organizacionModule
