<template>
  <div>
    <h1 class="title is-4">Filtros de búsqueda</h1>
    <section class="is-flex is-flex-wrap-wrap">
      <article class="field mr-3 active-article">
        <label class="label">
          Usuarios
        </label>
        <b-dropdown
          v-model="queryActive"
          aria-role="list"
          >
          <template #trigger>
            <b-button
              :label="userActiveness.find(ua => ua.id === queryActive).descripcion"
              type='is-light'
              icon-right="caret-down"/>
          </template>
          <b-dropdown-item
            v-for="(ua, i) in userActiveness"
            :key="`ua${i}`"
            :value="ua.id" aria-role="listitem">
            <span>{{ ua.descripcion }}</span>
          </b-dropdown-item>
        </b-dropdown>
      </article>
      <article class="field mr-3 name-article">
        <label class="label">
          Nombre
        </label>
        <b-field>
          <b-input
            v-model="queryNameFilter"
            placeholder="Nombre o apellido"
            maxlength="35">
          </b-input>
        </b-field>
      </article>
      <article class="field mr-3 role-article">
        <label class="label">
          Roles
        </label>
        <b-dropdown
          expanded
          :scrollable="isScrollable"
          :max-height="maxHeight"
          v-model="queryRoleFilter"
          aria-role="list">
          <template #trigger>
            <b-button
              class="is-justify-content-space-between"
              expanded
              :label="queryRoleFilter ? queryRoleFilter.descripcion : 'Todos los roles'"
              type='is-light'
              icon-right="caret-down"/>
          </template>
          <b-dropdown-item :value="null" aria-role="listitem">
            <span>Todos los roles</span>
          </b-dropdown-item>
          <b-dropdown-item
            v-for="(queryRoleFilter, i) in roles"
            :key="`rol${i}`"
            :value="queryRoleFilter" aria-role="listitem">
            <span>{{ queryRoleFilter.descripcion }}</span>
          </b-dropdown-item>
        </b-dropdown>
      </article>
      <article class="field mr-3 mb-3 area-article">
        <label class="label">
          Áreas
        </label>
        <b-dropdown
          expanded
          :scrollable="isScrollable"
          :max-height="maxHeight"
          v-model="queryAreaFilter"
          aria-role="list">
          <template #trigger>
            <b-button
              class="is-justify-content-space-between"
              expanded
              :label="queryAreaFilter ? queryAreaFilter.nombre : 'Todas las áreas'"
              type='is-light'
              icon-right="caret-down"/>
          </template>
          <b-dropdown-item :value="null" aria-role="listitem">
            <span>Todas las áreas</span>
          </b-dropdown-item>
          <b-dropdown-item
            v-for="(queryAreaFilter, i) in areas"
            :key="`area${i}`"
            :value="queryAreaFilter" aria-role="listitem">
            <span>{{ queryAreaFilter.nombre }}</span>
          </b-dropdown-item>
        </b-dropdown>
      </article>
    </section>
  </div>
</template>

<script>
import constants from '@/constants'

export default {
  props: {
    areas: {
      type: Array,
      required: true
    },
    roles: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      constants: constants,
      isScrollable: true,
      maxHeight: 150,
      queryNameFilter: '',
      queryRoleFilter: null,
      queryAreaFilter: null,
      queryActive: true,
      userActiveness: [
        { id: true, descripcion: 'Habilitados' },
        { id: false, descripcion: 'Deshabilitados' },
        { id: null, descripcion: 'Todos' }
      ]
    }
  },
  methods: {
    hasRole (roles) {
      return this.$store.getters.hasAnyRole(roles)
    },
    emptyFilters () {
      this.queryNameFilter = ''
      this.queryRoleFilter = null
      this.queryAreaFilter = null
      this.queryActive = true
    }
  },
  computed: {
    query () {
      const obj = {}

      obj.order = this.order
      if (this.selectedUser != null) obj.selectedUserId = this.selectedUser.id
      if (this.selectedStates.length > 0) obj.selectedStates = this.selectedStates
      if (this.selectedOrganization != null) obj.selectedOrganizationId = this.selectedOrganization.id

      return obj
    }
  },
  watch: {
    queryNameFilter (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.$emit('update:queryChanged', 'queryName', newVal)
      }
    },
    queryRoleFilter (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.$emit('update:queryChanged', 'queryRole', newVal ? newVal.id : null)
      }
    },
    queryAreaFilter (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.$emit('update:queryChanged', 'queryArea', newVal ? newVal.id : null)
      }
    },
    queryActive (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.$emit('update:queryChanged', 'queryActive', newVal)
      }
    }
  }
}

</script>

<style scoped>
.active-article {
  flex: 1;
}
.name-article {
  flex: 3;
}
.role-article {
  flex: 3;
}
.area-article {
  flex: 3;
}
</style>
