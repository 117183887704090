const customSettingsModule = {
  namespaced: true,
  state: {
    savedSettings: {}
  },
  mutations: {
    save (state, payload) {
      state.savedSettings[payload.userId] = payload.settings
    }
  },
  getters: {
    userSettings (state, getters, rootState, rootGetters) {
      const userId = rootGetters.getUser.id
      // If there is no savedSettings
      if (!state.savedSettings[userId]) {
        return {
          homeSearch: {
            showThumbnails: false
          },
          recentDocuments: {
            alwaysShowOpen: true
          }
        }
      }
      return state.savedSettings[userId]
    }
  },
  actions: {
    save ({ state, commit, rootState, rootGetters }, settings) {
      const userId = rootGetters.getUser.id
      commit('save', { userId, settings })
    }
  }
}

export default customSettingsModule
