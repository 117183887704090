<template>
  <div class="card">
    <div class="card-header">
        <p class="card-header-title">Renombrar documento</p>
    </div>
    <div class="card-content">
      <div class="field has-addons">
        <p class="control is-expanded">
          <input class="input" ref="nombre" type="text" v-model="newNombre" @keyup.enter="renombrarDocumento" required :placeholder="'Nuevo documento...'">
        </p>
        <p class="control" v-if="extension">
          <a class="button is-static">
            {{extension}}
          </a>
        </p>
      </div>
      <p class="help has-text-danger" v-if="renombroInvalidoMessage">{{renombroInvalidoMessage}}</p>
    </div>
    <div class="card-footer">
        <a class="card-footer-item" type="button" @click="$parent.close()">Cancelar</a>
        <a v-if="!renombroInvalido" class="card-footer-item has-text-weight-bold is-uppercase" @click.prevent="renombrarDocumento">Renombrar</a>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      newNombre: '',
      oldNombre: '',
      extension: null
    }
  },
  mounted: function () {
    this.focusInput()
    this.extension = this.file.nombre.match(/\.[0-9a-z]+$/i)[0]
    this.newNombre = this.oldNombre = this.file.nombre.replace(this.extension, '')
  },
  methods: {
    focusInput () {
      setTimeout(() => {
        this.$refs.nombre.focus()
      }, 200)
    },
    renombrarDocumento: function () {
      if (!this.renombroInvalido) {
        this.$http
          .patch(`/documento/${this.file.id}/nombre`, {
            nombre: `${this.newNombre.trim()}`,
            extension: `${this.extension || ''}`
          })
          .then(() => {
            this.$buefy.snackbar.open({
              message: 'El archivo ha sido renombrado exitosamente.',
              type: 'is-success'
            })
            this.newNombre = ''
            this.$emit('refresh')
            this.$parent.close()
          })
          .catch(error => {
            console.error(error)
            var mensajeAMostrar = ''
            if (error.codigo === 'entityAlreadyExists') {
              mensajeAMostrar = 'Ya existe un archivo con el mismo nombre dentro de la carpeta.'
            } else {
              mensajeAMostrar = 'El nombre ingresado es inválido.'
            }
            this.$buefy.snackbar.open({
              message: mensajeAMostrar,
              type: 'is-danger'
            })
          })
      } else {
        this.$buefy.snackbar.open({
          message: 'El nombre ingresado es inválido.',
          type: 'is-danger'
        })
      }
    }
  },
  computed: {
    renombroInvalido: function () {
      // if newNombre is the same than the old one, return true
      if (this.newNombre === this.oldNombre) {
        return true
      }
      // if newNombre is empty, return true
      if (this.newNombre === '') {
        return true
      }
      // if newNombre doesnt start with a letter or a digit
      var regex = /^[^A-Za-z0-9áéíóúÁÉÍÓÚüÜñÑ]/
      if (regex.test(this.newNombre)) {
        return true
      }
      // if newNombre contains invalid characters
      regex = /[<>:;,?"*|\\/]+/
      if (regex.test(this.newNombre)) {
        return true
      }
      // if newNombre is not any of the above
      return false
    },
    renombroInvalidoMessage: function () {
      if (this.newNombre === this.oldNombre) {
        return 'El nuevo nombre debe ser distinto al nombre actual del archivo.'
      }
      // if newNombre is empty, return true
      if (this.newNombre === '') {
        return 'El nuevo nombre no puede ser vacío.'
      }
      // if newNombre doesnt start with a letter or a digit
      var regex = /^[^A-Za-z0-9áéíóúÁÉÍÓÚüÜñÑ]/
      if (regex.test(this.newNombre)) {
        return 'El nuevo nombre debe comenzar con una letra o dígito.'
      }
      // if newNombre contains invalid characters
      regex = /[<>:;,?"*|\\/]+/
      if (regex.test(this.newNombre)) {
        return 'El nuevo nombre no puede contener ninguno de los siguientes caracteres < > : ; , ? “ * | /'
      }
      // if newNombre is not any of the above
      return false
    },
    getIcon: function () {
      return this.file.tipo_documento_id === 'carpeta' ? 'folder' : 'file'
    },
    file () {
      return this.$store.state.document.document
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
