<template>
<div class="modal-card">
    <div class="modal-card-head">
      <p class="modal-card-title">Cambiar propietario</p>
    </div>
    <div class="modal-card-body" v-if="selectedUser == null">
      <div class="field">
        <label for="" class="label">Buscar usuario</label>
        <div class="control">
          <input class="input" v-model="userInput" type="text" placeholder="Escriba aquí">
          <span class="help">Escriba el nombre o apellido del usuario</span>
        </div>
      </div>
      <div class="content">
        <p>{{status}}</p>
        <table class="table is-bordered is-narrow is-marginless" v-show="searchResult.length > 0">
          <tbody>
            <tr v-for="result in searchResult" :key="result.index">
              <td>
                <i class="fas fa-user"></i>&nbsp;<a @click="selectUser(result)">{{result.nombre}} {{result.apellido}}</a>
                <span class="disabled" v-if="tieneRolLyris">organizacion_id: {{result.organizacion_id}}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="modal-card-body" v-else>

       <b-message type="is-danger">
        <h1 class="title is-4 has-text-danger">
          <i class="fas fa-exclamation-triangle"></i>&nbsp;Atención
        </h1>
        <div class="content">
          <p>
            ¿Está seguro de ceder los permisos de propietario a <b>{{`${selectedUser.apellido.toUpperCase()}, ${selectedUser.nombre}`}}</b>?
          </p>
          <p>De proceder con la transferencia, dejará de ser propietario del documento.</p>
        </div>
      </b-message>
    </div>
    <div class="modal-card-foot is-justify-content-flex-end">
      <button @click="$parent.close()" class="button is-outlined is-dark">
        Cancelar</button>
        <div class="buttons">
        <button @click="submit()" :disabled="selectedUser == null" class="button is-primary">
        <i class="fas fa-exchange-alt fa-fw"></i>&nbsp;Cambiar propietario</button>
      </div>
    </div>
  </div>
</template>

<script>
import { debounce } from 'lodash'
// if the component initialization in a v-show="false" / display: none container or use
// transform / animation / margin to change the location of the component, there may be
// an exception ( The slider cannot be used, because the component can not initialize
// the size or slider position ).

export default {
  data () {
    return {
      userInput: '',
      status: '',
      searchResult: [],
      selectedUser: null
    }
  },

  methods: {
    getColor: function (nivel, top) {
      if (nivel >= top) {
        return {
          'is-primary': true
        }
      }
    },
    getIcon: function (nivel, top) {
      if (nivel >= top) {
        return 'check'
      }
      return 'times'
    },
    selectUser: function (user) {
      console.log(user)
      this.selectedUser = user
    },
    cleanModal: function () {
      this.selectedUser = null
      this.selectedLevel = 0
      this.searchResult = []
      this.userInput = ''
    },
    getUser: debounce(
      function () {
        this.status = 'Buscando...'
        this.$http
          .request({
            method: 'get',
            url: this.getURL
          })
          .then(response => {
            console.log(response.data)
            this.searchResult = response.data.data
            this.status = ''
          })
          .catch(error => {
            console.error('Fail', error)
            this.status = 'Error al solicitar el pedido al servidor...'
          })
      },
      // This is the number of milliseconds we wait for the
      // user to stop typing.
      500
    ),
    submit: function () {
      this.$emit(
        'cambiarPropietario',
        this.selectedUser.id
      )
      this.$parent.close()
    }
  },
  computed: {
    getURL: function () {
      return (this.tieneRolLyris
        ? '/usuario?s='
        : '/organizacion/' + this.organizacionId + '/usuario?s=') + this.userInput + '&acceso_habilitado=true'
    },
    tieneRolLyris: function () {
      return this.$store.getters.hasAnyRole(['lyris'])
    },
    organizacionId: function () {
      return this.$store.getters.getUser.organization
    },
    pieceWiseStyle: function () {
      return {
        backgroundColor: '#ccc',
        visibility: 'visible',
        width: '12px',
        height: '12px'
      }
    }
  },
  watch: {
    userInput: function (newUserInput, oldUserInput) {
      this.status = 'Tipeando...'
      this.searchResult = []
      if (newUserInput.trim().length >= 3) this.getUser()
      else this.status = 'Por favor, escriba más caracteres para la búsqueda.'
    }
  }
}
</script>

<style lang="scss" scoped>
.center-options {
  justify-content: center;
}
.disabled {
  color: gray
}
</style>
