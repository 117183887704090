<template>
 <section class="box">
    <h1 class="subtitle is-5">Mis datos</h1>
    <h1 class="title is-1"><span class="is-uppercase">{{user.apellido}}</span>, {{user.nombre}}</h1>
    <hr>
    <div class="columns">
      <div class="column">
        <h2 class="subtitle is-5">CUIL</h2>
        <div class="content">
          <p>{{user.cuil}}</p>
        </div>

      </div>
      <div class="column">
        <h2 class="subtitle is-5">Email</h2>
        <div class="content">
          <p>{{user.email}}</p>
        </div>
      </div>
    </div>
    <hr>
    <div class="columns">
      <div class="column">
        <h2 class="subtitle is-5">Mi organización</h2>
        <div class="content">
          <p>{{organization.razon_social}}</p>
        </div>
      </div>
      <div class="column">
        <h2 class="subtitle is-5">CUIT Org.</h2>
        <div class="content">
          <p>{{organization.cuit}}</p>
        </div>
      </div>
    </div>
    <hr>
    <div class="columns">
      <div class="column">
        <h2 class="subtitle is-5">Mis Roles</h2>
        <div class="tags">
          <p v-for="(role, index) in user.roles" :key="index" class="tag">{{role.descripcion}}</p>
          <p v-if="user.roles.length == 0"><i>No hay roles asignados</i></p>
        </div>
      </div>
      <div class="column">
         <h2 class="subtitle is-5">Mis areas</h2>
       <div class="field is-grouped is-grouped-multiline" v-if="user.areas.length > 0">
          <div class="control" v-for="(area, index) in user.areas" :key="index">
            <div class="tags has-addons">
              <p class="tag is-dark">{{area.nombre}}</p>
              <p class="tag">{{area.pivot.cargo}}</p>
            </div>
          </div>
         </div>
        <p v-else><i>No hay areas asignadas</i></p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  methods: {
    getRol: function (id) {
      const foundRole = this.rolesOrganizacion.find(r => r.id === id)
      if (foundRole) {
        return foundRole.descripcion
      }
      return `Otros ( ${id} )`
    },
    getArea: function (id) {
      const foundArea = this.areasOrganizacion.find(a => a.id === id)
      if (foundArea) {
        return foundArea.nombre
      }
      return `Otros ( ${id} )`
    }
  },
  computed: {
    user: function () {
      return this.$store.state.profile.user
    },
    organization: function () {
      return this.$store.state.organizacion.organizacion
    },
    rolesOrganizacion: function () {
      return this.$store.state.organizacion.roles
    },
    areasOrganizacion: function () {
      return this.$store.state.organizacion.areas
    }
  }
}
</script>

<style>

</style>
