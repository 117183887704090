<template>
  <div class="modal-card">
    <div class="modal-card-body has-text-centered">
        <img src="/statics/signar-logo.svg" class="image is-centered my-4" v-if="firmador.selected == 'signar' || firmador.selected == 'signar_v3'" width="100" alt>
        <img src="/statics/pfdrar-logo.png" class="image is-centered my-4"  v-else-if="firmador.selected == 'pfdrar'" width="100" alt>
      <h1 class="title is-4">{{ firmador.title }}</h1>
      <div class="notification" v-if="isLoading">
        <p class="animated flash infinite slower has-text-primary"><i class="fas fa-spin fa-sync"></i> Conectando con el servidor, un momento...</p>
      </div>
      <div class="content" v-if="firmador.selected == 'signar' || firmador.selected == 'signar_v3'">
        <p>Si el firmador
          <u>NO</u> se ejecutó, verifique si el navegador está bloqueando los pop-ups, deshabilitelos y <a @click="retrySignar(urlSignar)"><u>haga click aquí</u></a> para volver a intentar
        </p>
        <p>
          <b>Recomendamos habilitar los pop-ups en Signar®</b>
        </p>
        <p>¿No cuenta con {{ firmador.name }}?<br><a :href="firmador.downloadLink" target="_blank"><i class="fas fa-download"></i>&nbsp;Descárguelo aquí</a></p>
        <b-notification
          v-if="firmador.selected == 'signar_v3'"
          type="is-warning"
          has-icon
          :closable="false"
          role="alert">
          El firmador seleccionado se encuentra en una versión BETA.<br>Ante cualquier inconveniente recomendamos utilizar el firmador por defecto (Signar® Firmador).
        </b-notification>
        <p v-if="firmador.selected == 'signar'">¿Está ingresando desde un dispositivo Android?<br><a href="https://play.google.com/store/apps/details?id=ar.com.helenasoftware.signar" target="_blank"><i class="fas fa-download"></i>&nbsp;Descargue la Aplicación y vuelva a intentar</a></p>
        <p v-if="firmador.selected == 'signar'" class="is-size-7">Si desea, puede utilizar al versión Legacy (JNLP) <a @click="preparePilaJNLP">haciendo clic aquí</a></p>
      </div>
      <div class="content" v-else>
        <div class="notification is-warning">
          El firmador no se encuentra disponible para su uso
        </div>
        <p>Si el firmador
          <u>NO</u> se ejecutó, verifique si su navegador está bloqueando los pop-ups
        </p>
        <p>
          <b>Recomendamos habilitar los pop-ups en Signar®</b>
        </p>
      </div>
        <div class="buttons is-centered">
          <a @click="$parent.close()" class="button is-dark">
            Cerrar
          </a>
          <a @click="reloadUrl" class="button is-primary">
            <i class="fas fa-redo"></i>&nbsp;Recargar pagina
          </a>
        </div>
    </div>
  </div>
</template>

<script>
import fieldsUtils from '@/mixins/fieldsUtils'
import constants from '@/constants'
export default {
  mixins: [fieldsUtils],
  data () {
    return {
      isLoading: true,
      payloadClone: null
    }
  },
  mounted: function () {
    switch (this.firmador.selected) {
      case 'signar':
      case 'signar_v3':
        this.preparePilaSignar()
        break
      default:
        this.preparePilaGenerico()
        break
    }
  },
  beforeDestroy: function () {
    this.$store.dispatch('firmador/unload')
  },
  methods: {
    isErrorCodeKnown: function (code) {
      const knownCodes = ['keystoreNotFound', 'emptyScope', 'multipleScopeTypesSelected',
        'userIsNotFromOrganization', 'userWithoutRole', 'userIsDisabled',
        'areaIsNotFromOrganization', 'signAllOrganization', 'isGlobalDocumentWithoutSign',
        'maxReceiversWithAreas', 'keystoreNotFound', 'keystoreDisabled', 'invalidSoftwareStore']
      return knownCodes.includes(code)
    },
    reloadUrl: function () {
      location.reload()
    },
    retrySignar: function (url) {
      window.open(url, '_self')
    },
    preparePilaSignar: function () {
      this.isLoading = true
      const payload = Object.assign({}, this.firmadorStore.payload)
      this.payloadClone = payload
      this.$http.post(this.firmador.url, payload)
        .then(res => {
          this.urlSignar = res.data.link
          this.$store.dispatch('firmador/unloadPila')
          window.open(res.data.link, '_self')
        })
        .catch(err => {
          console.error(err)
          this.$buefy.snackbar.open({
            message: this.isErrorCodeKnown(err.codigo) ? err.mensaje : constants.STRINGS.ERRORS.DEFAULT,
            type: 'is-danger'
          })
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    preparePilaJNLP: function () {
      this.isLoading = true
      const jnlpPayload = {
        opcion: this.payloadClone.opcion,
        firmador: this.payloadClone.firmador
      }
      if (this.payloadClone.opcion !== 'recibos') {
        jnlpPayload.lista = this.payloadClone.parametros.documentos
      }
      this.$http.post('usuario/' + this.$store.getters.getUser.id + '/pila-documentos', jnlpPayload)
        .then((res) => {
          this.urlJnlp = location.href.replace(
            window.location,
            `/jnlp/firmador?token=${res.data.token}`
          )
        })
        .then(() => {
          window.open(this.urlJnlp, '_self')
        })
        .catch(err => {
          console.error(err)
          this.$buefy.snackbar.open({
            message: 'Ocurrió un error, por favor intentelo mas tarde',
            type: 'is-danger'
          })
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    preparePilaGenerico: function () {
      this.isLoading = false
    },
    continuarPostergados: function () {
      const payload = this.firmadorStore.payload
      payload.parametros.documentos = this.docStack.map(doc => doc.id)
      delete payload.firmador
      this.$store.commit('firmador/setPayload', payload)
    }
  },
  computed: {
    firmador () {
      const selected = this.$store.state.firmador.firmadorSelected || this.$store.state.firmador.payload.firmador
      let title, name, downloadLink, url
      switch (selected) {
        case 'signar':
          title = 'Abriendo Firmador'
          name = 'Signar® Firmador'
          downloadLink = 'https://firmador.lyris.com.ar'
          url = '/solicitud-firma'
          break
        case 'signar_v3':
          title = 'Abriendo Firmador 3.0 (beta)'
          name = 'Signar® Firmador 3.0 (beta)'
          downloadLink = 'https://firmador.signar.com.ar/FirmadorSignarIntegrable_Installer.exe'
          url = '/link-firmador'
          break
        default:
          title = 'Firmador no disponible'
          name = 'Firmador no disponible'
          downloadLink = 'https://firmador.lyris.com.ar'
          url = '/solicitud-firma'
          break
      }
      return {
        selected,
        title,
        name,
        downloadLink,
        url
      }
    },
    session () {
      return this.$store.state.session
    },
    user: function () {
      return this.$store.getters.getUser
    },
    angle: function () {
      return this.showTable ? 'fa-angle-up' : 'fa-angle-down'
    },
    firmadorStore: function () {
      return this.$store.state.firmador
    },
    docStack: function () {
      return this.firmadorStore.pila
    },
    currentUrl: function () {
      return window.location
    }
  }
}
</script>

<style lang="scss" scoped>
.firmador-select{
  cursor: pointer;
  padding: 20px 30px;
  border-radius: 5px;
  border: 1px solid #cacaca;
}
</style>
