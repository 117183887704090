// import http from '@/http'
import store from '@/store'

import Usuario from './Usuario'
import Informacion from './Informacion'
import Editar from './Editar'
import Roles from './Roles'
import Areas from './Areas'
import Certificado from './Certificado'
import ForcePassword from './ForcePassword'

// const BASE_PATH = '/organizacion/:idOrganization/usuario'

const routes = [{
  path: 'usuario/:idUser',
  component: Usuario,
  props: true,
  children: [
    {
      path: '',
      component: Informacion,
      name: 'usuarioInfo',
      props: true,
      meta: {
        requiresAuth: true,
        requiredRoles: ['lyris', 'admin', 'lyris-soporte']
      }
    },
    {
      path: 'editar',
      component: Editar,
      name: 'usuarioEditar',
      props: true,
      meta: {
        requiresAuth: true,
        requiredRoles: ['lyris', 'admin', 'lyris-soporte']
      }
    },
    {
      path: 'areas',
      component: Areas,
      name: 'usuarioAreas',
      props: true,
      meta: {
        requiresAuth: true,
        requiredRoles: ['lyris', 'admin', 'lyris-soporte']
      }
    },
    {
      path: 'roles',
      component: Roles,
      name: 'usuarioRoles',
      props: true,
      meta: {
        requiresAuth: true,
        requiredRoles: ['lyris', 'admin', 'lyris-soporte']
      }
    },
    {
      path: 'certificado',
      component: Certificado,
      name: 'usuarioBackupCertificado',
      props: true,
      meta: {
        requiresAuth: true,
        requiredRoles: ['lyris']
      }
    },
    {
      path: 'password',
      component: ForcePassword,
      name: 'usuarioForcePassword',
      props: true,
      meta: {
        requiresAuth: true,
        requiredRoles: ['lyris', 'lyris-soporte']
      }
    }
  ],
  beforeEnter: (to, from, next) => {
    console.info('[ROUTER] First time entering:', to.path)
    Promise.all([
      store.dispatch('organizacion/fetchSelectedUser', to.params.idUser)
    ])
      .then(responses => {
        console.info('[ROUTER] Data fetch success. Continue to route')
        next()
      })
      .catch(error => {
        console.error('[ROUTER] Data fetch failed', error)
        next({ name: 'error' })
      })
    // console.log('First time entering, getting user...')
    // http
    //   .get('/usuario/' + to.params.idUser)
    //   .then(response => {
    //     store.commit('organizacion/selectedUser', response.data)
    //     console.log('GET OK - Got usuario')
    //     next()
    //   })
    //   .catch(error => {
    //     console.log('Fetching data failed', error)
    //     next({ name: 'error' })
    //   })
  }
}]

export default routes
