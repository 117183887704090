<template>
  <div class="box p-4 has-background-white mb-0">
    <div class="buttons">
      <button v-if="hasPendientes"  @click="$emit('firmar-pendientes')" class="button is-primary" :class="{ 'is-loading' : isFetching}">
        <i class="fas fa-file-signature fa-fw"></i>&nbsp;Firmar mis pendientes</button >
      <a @click="$emit('download')" v-show="hasRecibosSelected" class="button is-light">
        <i class="fas fa-download fa-fw"></i>&nbsp;Descargar {{cantRecibos}} seleccionado{{cantRecibos > 1 ? 's' : ''}}</a>
      <a @click="refresh" class="button is-light">
        <i class="fas fa-sync fa-fw"></i>&nbsp;Recargar</a>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      isFetching: true,
      hasPendientes: false
    }
  },
  mounted: function () {
    this.fetch()
  },
  methods: {
    fetch: function () {
      this.isFetching = true
      this.$http.get(`/recibo?estado=pendiente&empleado=${this.user.id}&size=1`).then(res => {
        this.hasPendientes = res.data.data.length
      }).finally(() => {
        this.isFetching = false
      })
    },
    refresh: function () {
      this.$emit('refresh')
      this.fetch()
    }
  },
  computed: {
    user: function () {
      return this.$store.getters.getUser
    },
    hasRecibosSelected: function () {
      return this.$store.state.recibos.selected.length > 0
    },
    cantRecibos: function () {
      return this.$store.state.recibos.selected.length
    }
  }
}
</script>

<style lang="scss" scoped>
.box {
  margin-bottom: 1rem;
  padding: 1rem;
}
</style>
