<template>
  <div class="box">
    <div class="p-2">
      <h1 class="title is-4">Filtros de búsqueda</h1>
      <div class="is-flex is-flex-direction-column">
        <div class="is-flex is-flex-direction-row is-align-items-center mb-3">
          <div style="flex: 1;" v-if="this.hasRole([constants.STRINGS.ROLES.LYRIS.ID, constants.STRINGS.ROLES.LYRIS_OR.ID])">
            <div class="field">
              <label class="label">
                Organización
              </label>
              <b-autocomplete class="input-field" v-model="organizationInput" :data="searchOrganizationResults" placeholder="Escriba nombre o id de la organización" v-if="!selectedOrganization" field="razon_social" @input="getOrganizationsWithInstalledModule('aureg')" @select="option => selectOrganization(option)">
                <template slot-scope="props">
                  <i class="fas fa-building"></i> {{props.option.id}} - {{ props.option.razon_social.toUpperCase() }}
                  <br>
                  <span class="is-size-7">{{props.option.slug}} - {{props.option.cuit}}</span>
                </template>
                <template slot="empty">No hay resultados para {{organizationInput}}</template>
              </b-autocomplete>
            </div>
            <b-field>
              <b-tag v-if="selectedOrganization"
                  icon="fas fa-building"
                  size="is-medium"
                  attached
                  aria-close-label="Close tag"
                  :closable="this.canChangeOrganization"
                  @close="unselectOrganization()">
                  {{ selectedOrganization.razon_social.toUpperCase() }}
              </b-tag>
            </b-field>
          </div>
          <div style="flex: 1;" v-if="this.hasRole([constants.STRINGS.ROLES.LYRIS.ID, constants.STRINGS.ROLES.OFICIAL_DE_REGISTRO.ID, constants.STRINGS.ROLES.ADMINISTRADOR_DE_CERTIFICADOS.ID])">
            <div class="field mr-3">
              <label class="label">
                Usuario
              </label>
              <b-autocomplete class="input-field" v-model="userInput" :data="searchUserResults" placeholder="Escriba nombre o apellido del usuario" v-if="!selectedUser" field="apellido" @input="getUsersWithRol('suscriptor')" @select="option => selectUser(option)">
                <template slot-scope="props">
                  <i class="fas fa-user"></i> {{ props.option.apellido.toUpperCase() }}, {{ props.option.nombre }}
                  <br>
                  <span class="is-size-7">{{props.option.cuil}} - {{props.option.email}}</span>
                </template>
                <template slot="empty">No hay resultados para {{userInput}}</template>
              </b-autocomplete>
            </div>
            <b-field>
              <b-tag v-if="selectedUser"
                  icon="fas fa-user"
                  size="is-medium"
                  attached
                  aria-close-label="Close tag"
                  closable
                  @close="unselectUser()">
                  {{ selectedUser.apellido.toUpperCase() }}, {{ selectedUser.nombre }}
              </b-tag>
            </b-field>
          </div>
        </div>
        <div class="is-flex is-flex-direction-row is-align-items-center mb-5">
          <b-dropdown v-model="selectedStates" multiple aria-role="list" :disabled="!this.availableLabels.length">
            <template #trigger>
              <b-button type="is-white" icon-right="caret-down">
                Estados ({{ selectedStates.length }}/{{ states.length }})
              </b-button>
            </template>
            <b-dropdown-item :value="label" v-for="(label, i) in this.availableLabels" :key="`label-${i}`"
              aria-role="listitem" @click="addState(label)">
              <span>{{ label }}</span>
            </b-dropdown-item>
          </b-dropdown>
          <div class="" v-if="selectedStates.length">
            <b-field grouped group-multiline v-for="state of selectedStates" :key="state" class="group-tags">
              <div class="">
                  <b-tag type="is-primary"
                      attached
                      aria-close-label="Close tag"
                      closable
                      @close="removeState(state)">
                      {{ state }}
                  </b-tag>
              </div>
            </b-field>
          </div>
        </div>
        <div class="is-flex is-flex-direction-row is-align-items-center is-justify-content-space-between">
          <b-field class="mt-3 ml-3">
              <b-switch v-model="order"
              true-value="asc"
              false-value="desc"
              @input="updateListado()"
              >Ver más antiguos primero</b-switch>
          </b-field>
          <article class="field mr-3 mb-3">
            <label class="label">
              Mostrar certificados que vencen en
            </label>
            <b-dropdown
              v-model="selectedDaysUntilExpiration"
              aria-role="list">
              <template #trigger>
                <b-button
                  class="is-justify-content-space-between is-fullwidth"
                  expanded
                  :label="formattedExpirationDateLabel"
                  type='is-light'
                  icon-right="caret-down"/>
              </template>
              <b-dropdown-item :value="null" aria-role="listitem">
                <span>No establecido</span>
              </b-dropdown-item>
              <b-dropdown-item
                v-for="(days, i) in this.daysUntilExpires"
                :key="`selectedDaysUntilExpiration-${i}`"
                :value="days"
                aria-role="listitem">
                <span>{{ days }} días o menos</span>
              </b-dropdown-item>
            </b-dropdown>
          </article>
          <b-button class="is-primary" icon-right="fas fa-broom" @click="emptyFilters">Limpiar filtros</b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import debounce from 'lodash/debounce'
import constants from '@/constants'
export default {
  data () {
    return {
      constants: constants,

      selectedDaysUntilExpiration: null,
      daysUntilExpires: [
        '30',
        '60',
        '90'
      ],
      states: [
        'Pendiente',
        'Preaprobado',
        'Rechazado',
        'Vigente',
        'Revocado',
        'Vencido'
      ],
      userInput: null,
      searchUserResults: [],
      selectedUser: null,
      canChangeOrganization: true,
      organizationInput: null,
      searchOrganizationResults: [],
      selectedOrganization: this.getCurrentOrganizationId,
      selectedStates: [],
      order: 'desc'
    }
  },
  mounted: function () {
    if (this.isWithinOrganization) {
      this.selectOrganization(this.getCurrentOrganizationEntity)
      this.canChangeOrganization = false
      if (this.isCertAdminAndNotOR) {
        this.selectedStates = ['Pendiente']
      }
    }
  },
  methods: {
    selectUser: function (optionSelected) {
      this.selectedUser = optionSelected
      this.updateListado()
    },
    unselectUser: function () {
      this.selectedUser = null
      this.userInput = null
      this.updateListado()
    },
    selectOrganization: function (optionSelected) {
      this.selectedOrganization = optionSelected
      this.selectedUser = null
      this.userInput = null
      this.updateListado()
    },
    unselectOrganization: function () {
      this.selectedOrganization = null
      this.organizationInput = null
      this.updateListado()
    },
    updateListado: function () {
      this.$emit('set-query', this.query)
    },
    refreshListado: function () {
      this.$emit('refresh')
    },
    emptyFilters: function () {
      this.order = 'desc'
      this.userInput = null
      this.searchUserResults = []
      this.selectedUser = null
      this.organizationInput = null
      this.searchOrganizationResults = []
      this.selectedStates = []
      this.selectedDaysUntilExpiration = null
      if (this.isWithinOrganization) {
        this.selectedOrganization = this.getCurrentOrganizationEntity
        this.updateListado()
      } else {
        this.selectedOrganization = null
        this.refreshListado()
      }
    },
    addState: function (label) {
      if (this.selectedStates.indexOf(label) === -1) {
        this.selectedStates.push(label)
      }
      this.updateListado()
    },
    removeState: function (label) {
      this.selectedStates.splice(this.selectedStates.indexOf(label), 1)
      this.updateListado()
    },
    hasRole (roles) {
      return this.$store.getters.hasAnyRole(roles)
    },
    getUsersWithRol: debounce(
      function (rol) {
        this.$http
          .request({
            method: 'get',
            url: this.urlUser + '/usuario?s=' + this.userInput + '&rol=' + rol
          })
          .then(response => {
            this.searchUserResults = response.data.data
          })
          .catch(error => {
            console.error('Fail', error)
          })
      },
      // This is the number of milliseconds we wait for the user to stop typing.
      500
    ),
    getOrganizationsWithInstalledModule: debounce(
      function (module) {
        this.$http
          .request({
            method: 'get',
            url: '/organizacion' + this.urlOrganization + '?s=' + this.organizationInput + '&modulo_id=' + module
          })
          .then(response => {
            this.searchOrganizationResults = response.data.data
          })
          .catch(error => {
            console.error('Fail', error)
          })
      },
      // This is the number of milliseconds we wait for the user to stop typing.
      500
    )
  },
  computed: {
    query () {
      const obj = {}

      obj.order = this.order
      if (this.selectedUser != null) obj.selectedUserId = this.selectedUser.id
      if (this.selectedStates.length > 0) obj.selectedStates = this.selectedStates
      if (this.selectedOrganization != null) obj.selectedOrganizationId = this.selectedOrganization.id
      if (this.selectedDaysUntilExpiration != null) obj.selectedDaysUntilExpiration = this.selectedDaysUntilExpiration

      return obj
    },
    availableLabels () {
      return this.states.filter(s => !this.selectedStates.includes(s))
    },
    urlUser () {
      return (this.isWithinOrganization) ? '/organizacion/' + this.getCurrentOrganizationId : this.verifyIfOrganizationSelected
    },
    verifyIfOrganizationSelected () {
      return (this.selectedOrganization != null) ? '/organizacion/' + this.selectedOrganization.id : ''
    },
    urlOrganization () {
      return (this.isWithinOrganization) ? this.getCurrentOrganizationId : ''
    },
    getCurrentOrganizationId () {
      return this.hasRole([constants.STRINGS.ROLES.LYRIS.ID, constants.STRINGS.ROLES.LYRIS_OR.ID])
        ? this.$route.params.idOrganization
        : this.$store.getters.getUser.organization
    },
    isWithinOrganization () {
      return (this.getCurrentOrganizationId != null)
    },
    getCurrentOrganizationEntity () {
      return this.$store.state.organizacion.organizacion
    },
    isCertAdminAndNotOR () {
      return this.hasRole([constants.STRINGS.ROLES.ADMINISTRADOR_DE_CERTIFICADOS.ID]) && !this.hasRole([constants.STRINGS.ROLES.OFICIAL_DE_REGISTRO.ID])
    },
    formattedExpirationDateLabel () {
      return this.selectedDaysUntilExpiration ? `${this.selectedDaysUntilExpiration} días o menos` : 'No establecido'
    }
  },
  watch: {
    selectedDaysUntilExpiration (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.updateListado()
      }
    }
  }
}
</script>

<styles lang="scss" scoped>
.group-tags {
  display: inline-block;
  margin: 0px 5px;
}
.input-field {
  max-width: 300px;
}
.dropdown {
  display: block !important;
}
.dropdown-menu {
  width: -webkit-fill-available !important;
}
</styles>
