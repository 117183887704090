<template>
  <section class="box">
  <h1 class="subtitle is-5">Administrador Lyris</h1>
    <h1 class="title is-1">Dashboard</h1>
  </section>
</template>

<script>
export default {}
</script>

<style>

</style>
