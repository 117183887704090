import http from '@/http'

const documentModule = {
  namespaced: true,
  state: {
    document: null,
    upperFolder: null
  },
  mutations: {
    document (state, file) {
      state.document = file
    },
    upperFolder (state, file) {
      state.upperFolder = file
    },
    unload (state) {
      state.document = null
    }
  },
  getters: {},
  actions: {
    fetchDocument ({ commit }, id) {
      // Fetch, then store the value, then return the promise
      // you chan call this as store.dispatch('..').then(res => {})
      return http.get(`/documento/${id}`)
        .then(res => {
          console.info('[VUEX] GET OK - Got Documento')
          commit('document', res.data)
          return res
        })
    },
    fetchUpperFolder ({ commit }, id) {
      // Fetch, then store the value, then return the promise
      // you chan call this as store.dispatch('..').then(res => {})
      return http.get(`/documento/${id}`)
        .then(res => {
          console.info('[VUEX] GET OK - Got Upper Folder of Documento')
          commit('upperFolder', res.data)
          return res
        })
    },
    unload (context) {
      context.commit('unload')
    }
  }
}

export default documentModule
