<template>
  <section class="box">
        <h1 class="title is-5">Subunidades del documento</h1>
        <div class="content">
          <p>Puede editar el nombre de las subunidades haciendo clic en <span class="has-text-primary"><i class="fas fa-edit"></i></span>, o eliminarlas haciendo clic sobre <span class="has-text-primary"><i class="fas fa-times-circle"></i></span></p>
          <p><b>NOTA:</b> Solo puede eliminar una subunidad mientras no esté afectada por la ejecución de un evento.</p>
        </div>
        <!-- ===================== -->
        <div class="columns is-multiline">
          <div class="column is-6 is-4-desktop"  v-for="subdoc in subdocumentos" :key="`doc-${subdoc.key}`">
            <div class="box p-3 is-flex is-flex-direction-row">
              <div class="mr-4">
                <i class="far fa-2x has-text-primary" :class="{'fa-copy': subdoc.multiple, 'fa-file': !subdoc.multiple}"></i>
              </div>
              <div class="is-flex-grow-1">
                <h4 class="title is-6">{{subdoc.nombre}}</h4>
                <h4 class="subtitle is-7 mb-3">{{subdoc.key}}</h4>
                <div class="is-size-7">
                <p><b>Formatos</b>: {{subdoc.formatosSeleccionados.join(', ')}}</p>
                <p><b>¿Colección?</b>&nbsp;
                 <i class="fas" :class="{'fa-check': subdoc.multiple, 'fa-times': !subdoc.multiple}"></i>&nbsp;{{subdoc.multiple ? 'Si' : 'No'}}
                </p>
                </div>
              </div>
              <div class="ml-4">
                <p>

                <a @click="openConfirmEditSubunidad(subdoc)" class="has-text-primary">
                    <i class="fas fa-edit"></i>
                  </a>
                </p>
                <p>
                <a @click="openConfirmDeleteSubunidad(subdoc)" class="has-text-primary">
                    <i class="fas fa-times-circle"></i>
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
        <hr>
        <h1 class="subtitle is-5">Nueva subunidad</h1>
        <!-- ===================== -->
        <div class="field">
          <label class="label">Nombre y clave</label>
          <div class="field is-grouped">
            <div class="control is-expanded">
              <input
                v-model="input.subdocumento.nombre"
                name="Nombre del sub-documento"
                class="input"
                data-vv-scope="subdoc"
                :class="{'is-danger': errors.has('Nombre del sub-documento','subdoc') }"
                v-validate="{required:true}"
              >
              <span class="help">El nombre con el cual identificará la subunidad</span>

              <span
                class="help is-danger"
                v-if="errors.has('Nombre del sub-documento','subdoc')"
              >{{errors.first('Nombre del sub-documento','subdoc')}}</span>
            </div>
            <div class="control is-expanded">
              <input
                v-model="input.subdocumento.key"
                name="Clave del sub-documento"
                class="input is-mono"
                data-vv-scope="subdoc"
                :class="{'is-danger': errors.has('Clave del sub-documento','subdoc') }"
                v-validate="{ required: true, regex: /^[a-z0-9]+(?:[-_+][a-z0-9#]+)*$/ }"
              >
              <span class="help">Clave (autogenerada)</span>
              <span
                class="help is-danger"
                v-if="errors.has('Clave del sub-documento','subdoc')"
              >{{errors.first('Clave del sub-documento','subdoc')}}</span>
            </div>
          </div>
          <div class="columns is-centrered">
            <div class="column">
              <div class="field">
                <label class="label">Formatos aceptables</label>
                <div class="block">
                  <b-checkbox
                    v-model="input.subdocumento.formatosSeleccionados"
                    v-for="formato in formatosDisponibles"
                    :key="formato"
                    :native-value="formato"
                    disabled
                  >{{formato}}</b-checkbox>
                </div>
              </div>
            </div>
            <div class="column">
              <div class="field">
                <label class="label">¿Es colección de documentos?</label>
                <div class="block">
                  <b-radio v-model="input.subdocumento.multiple" :native-value="true">
                    <i class="fas fa-check"></i>&nbsp;Si
                  </b-radio>
                  <b-radio v-model="input.subdocumento.multiple" :native-value="false">
                    <i class="fas fa-times"></i>&nbsp;No
                  </b-radio>
                </div>
              </div>
            </div>
              <div class="column is-narrow">
                <button @click="submitSubunidad" class="button is-primary">
                  <i class="fas fa-plus"></i>&nbsp;Agregar
                </button>
              </div>
          </div>
          <!-- <div class="has-text-right">
          </div> -->
        </div>
  </section>
</template>

<script>
import slugify from 'slugify'
import validatorUtils from '@/mixins/validatorUtils'

export default {
  // props: {
  //   serieDocumental: {
  //     type: Object,
  //     default: null
  //   }
  // },
  mixins: [validatorUtils],
  data () {
    return {
      input: {
        subdocumento: {
          nombre: '',
          key: '',
          multiple: false,
          formatosSeleccionados: ['pdf']
        }
      },
      // subdocumentos: [],
      formatosDisponibles: ['pdf']
      // aspectos: []
    }
  },
  // beforeMount: function () {
  //   this.syncData()
  // },
  // mounted: function () {
  // },
  methods: {
    // syncData: function () {
    //   Object.keys(this.serieDocumental.estructura).forEach(k => {
    //     const subdoc = {
    //       nombre: this.serieDocumental.estructura[k].nombre,
    //       key: k,
    //       formatosSeleccionados: this.serieDocumental.estructura[k].formatos,
    //       multiple: this.serieDocumental.estructura[k].multiple
    //     }
    //     this.subdocumentos.push(subdoc)
    //   })
    // },
    submitSubunidad: function () {
      const exists = this.subdocumentos.find(subdoc => {
        return subdoc.key === this.input.subdocumento.key
      })
      if (exists) {
        this.$buefy.snackbar.open({
          message: 'El subdocumento a ingresar ya existe',
          type: 'is-danger'
        })
        return false
      }
      this.$validator.validateAll('subdoc').then(result => {
        if (!result) {
          this.$buefy.snackbar.open({
            message: 'Error al agregar un subdocumento',
            type: 'is-danger'
          })
          return
        }
        const subdoc = {
          subunidad: this.input.subdocumento.key,
          parametros: {
            nombre: this.input.subdocumento.multiple ? this.input.subdocumento.nombre + ' #f' : this.input.subdocumento.nombre,
            formatos: this.input.subdocumento.formatosSeleccionados,
            multiple: this.input.subdocumento.multiple
          }
        }
        this.$emit('submitSubunidad', subdoc)
      })
    },
    finalizarSubmitSubunidad: function (subdoc) {
      const doc = {
        nombre: subdoc.parametros.nombre,
        key: subdoc.subunidad,
        formatosSeleccionados: subdoc.parametros.formatos,
        multiple: subdoc.parametros.multiple
      }
      this.subdocumentos.push(doc)
      this.input.subdocumento.nombre = ''
      this.input.subdocumento.multiple = false
      this.clearValidatorErrors()
    },
    openConfirmEditSubunidad: function (subdoc) {
      this.$buefy.dialog.prompt({
        title: 'Cambiar nombre a subunidad',
        message: `</p>Escriba el nuevo nombre para la subunidad <b>${subdoc.nombre}</b><p class="is-size-7"></p>`,
        inputAttrs: {
          placeholder: 'Escriba aquí',
          required: true
        },
        trapFocus: true,
        confirmText: 'Cambiar nombre',
        onConfirm: (value) => {
          const payload = {
            nombre: subdoc.multiple ? value + ' #f' : value
          }
          this.$emit('submitEditarNombreSubunidad', subdoc, payload)
        }
      })
    },
    openConfirmDeleteSubunidad: function (subdoc) {
      this.$buefy.dialog.confirm({
        title: 'Eliminar subunidad',
        message: `<div class="content has-text-danger">
          <h1 class="title has-text-danger">Atención</h1>
          <p>
            Esta acción impactará a todos los documentos que pertenecen a esta serie.
          </p>
          <p>
            No puede eliminar subunidades que estén involucradas en la ejecución de un evento.
          </p>
          </div>
        <p>¿Esta seguro que quiere <b>eliminar</b> la subunidad <b>${subdoc.nombre}</b> de la serie documental?</p>`,
        confirmText: 'Eliminar',
        cancelText: 'Cancelar',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => this.$emit('deleteSubunidad', subdoc.key)
      })
    },
    finalizarDeleteSubunidad: function (key) {
      this.subdocumentos = this.subdocumentos.filter(subdoc => {
        return subdoc.key !== key
      })
    },
    finalizarSubmitEditarNombreSubunidad: function () {
      const index = this.subdocumentos.findIndex(s => {
        return s.key === this.editarNombre.subunidad.key
      })
      this.subdocumentos[index].nombre = this.editarNombre.nuevoNombre
      this.closeEditarNombre()
    }
  },
  watch: {
    'input.subdocumento.nombre': function (newVal) {
      if (!newVal) {
        this.input.subdocumento.key = null
        return
      }
      // if(this.input.subdocumento.multiple){
      //   let pos = newVal.lastIndexOf(' #f');
      //   let fixed = newVal.substring(0,pos) + newVal.substring(pos+3)
      //   console.log(fixed.toLowerCase())
      //   this.input.subdocumento.key = slugify(fixed.toLowerCase());
      //   return
      // }
      this.input.subdocumento.key = slugify(newVal.toLowerCase())
    }
  },
  computed: {
    serieDocumental: function () {
      return this.$store.state.serieDocumental.serieDocumental
    },
    subdocumentos: function () {
      return Object.keys(this.serieDocumental.estructura).map(k => {
        return {
          nombre: this.serieDocumental.estructura[k].nombre,
          key: k,
          formatosSeleccionados: this.serieDocumental.estructura[k].formatos,
          multiple: this.serieDocumental.estructura[k].multiple
        }
        // this.subdocumentos.push(subdoc)
      })
    },
    idOrganizacion: function () {
      return this.$store.state.organizacion.organizacion.id
    }
  }
}
</script>

<style lang="scss" scoped>
.table-bordered {
  padding: 1em;
  border: 1px solid #cacaca;
  border-radius: 0.3em;
  table.table {
    margin-bottom: 0;
  }
}
</style>
