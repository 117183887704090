import Login from './Login'
import Logout from './Logout'
import RecuperarClave from './RecuperarClave'
import LoginFlow from './LoginFlow'

const routes = [
  {
    path: '/loginFlow/:token',
    name: 'loginFlow',
    component: LoginFlow,
    props: true
  },
  {
    path: '/logout',
    name: 'logout',
    component: Logout,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/recuperar-clave/:token',
    name: 'recuperarClave',
    component: RecuperarClave,
    props: true
  }
]

export default routes
