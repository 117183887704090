<template>
  <div class="content">
    <b-table
      :data="subunidadesCargadas"
      ref="table"
      paginated
      narrowed
      per-page="15"
      :pagination-simple="true"
      aria-next-label="Siguiente Página"
      aria-previous-label="Página anterior"
      aria-page-label="Página"
      aria-current-label="Página actual"
    >
      <b-table-column field="nombre" label="Nombre" v-slot="props">
        <p>
          <a @click="openPreview(props.row)">{{ props.row.nombre }}</a>
        </p>
      </b-table-column>
      <b-table-column centered v-slot="props">
        <p>
          <a @click="descargar(props.row.id)">
            <span class="is-hidden-desktop">Descargar&nbsp;</span>
            <i class="fas fa-download"></i>
          </a>
        </p>
      </b-table-column>
      <b-table-column centered v-slot="props">
        <p>
          <a @click="openSideBySide(props.row)">
            <span class="is-hidden-desktop">Abrir side by side&nbsp;</span>
            <i class="fas fa-columns"></i>
          </a>
        </p>
      </b-table-column>
      <b-table-column centered v-slot="props">
        <p>
          <a @click="openPreview(props.row)">
            <span class="is-hidden-desktop">Ver&nbsp;</span>
            <i class="fas fa-eye"></i>
          </a>
        </p>
      </b-table-column>
      <template slot="empty">
        <p>
          <i>No hay subunidades cargadas</i>
        </p>
      </template>
    </b-table>
  </div>
</template>

<script>
import ModalPreviewSubunidad from './ModalPreviewSubunidad'

export default {
  methods: {
    descargar: function (docId) {
      this.$http
        .get('/documento/' + docId + '/descarga')
        .then((response) => {
          window.location.href = response.data.link
        })
        .catch((error) => {
          console.error('Fail', error)
          this.$buefysnackbar.open({
            message:
              'Error de conexión. Compruebe la conectividad con internet.',
            type: 'is-danger'
          })
        })
    },
    openPreview: function (resource) {
      this.$buefy.modal.open({
        parent: this,
        component: ModalPreviewSubunidad,
        props: {
          resource: resource,
          estructura: this.unidadCompuesta.serie_documental.estructura[
            resource.documento
          ],
          hasModalCard: true
        },
        events: {
          descargar: (docId) => {
            this.descargar(docId)
          }
        }
      })
    },
    openSideBySide: function (resource) {
      this.$emit('side-by-side', resource)
    }
  },
  computed: {
    unidadCompuesta: function () {
      return this.$store.state.unidadCompuesta.unidadCompuesta
    },
    subunidadesCargadas: function () {
      return this.unidadCompuesta.subunidades
    }
  }
}
</script>

<style>
</style>
