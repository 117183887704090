import http from '@/http'

const serieDocumentalModule = {
  namespaced: true,
  state: {
    serieDocumental: null
  },
  mutations: {
    serieDocumental (state, serie) {
      state.serieDocumental = serie
    },
    unload (state) {
      state.serieDocumental = null
    }
  },
  getters: {},
  actions: {
    fetchSerieDocumental ({ commit }, id) {
      return http.get(`/serie-documental/${id}`)
        .then(res => {
          console.info('[VUEX] GET OK - Got Serie')
          commit('serieDocumental', res.data)
          return res
        })
    },
    unload ({ commit }) {
      commit('unload')
    }
  }
}

export default serieDocumentalModule
