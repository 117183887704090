var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('div',{staticClass:"box"},[_vm._m(0),_vm._v(" "),_c('h1',{staticClass:"title is-2"},[_c('span',{staticClass:"is-uppercase"},[_vm._v(_vm._s(_vm.usuario.apellido))]),_vm._v(", "+_vm._s(_vm.usuario.nombre)+"\n  ")])]),_vm._v(" "),_c('div',{staticClass:"tabs is-boxed mb-0"},[_c('ul',[_c('router-link',{attrs:{"to":{ name: 'usuarioInfo', params: { idOrganization: _vm.usuario.organizacion_id, idUser: _vm.usuario.id }},"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
var isExactActive = ref.isExactActive;
return [_c('li',{class:{'is-active': isExactActive}},[_c('a',{attrs:{"role":"link"},on:{"click":navigate}},[_vm._v("Información")])])]}}])}),_vm._v(" "),_c('router-link',{attrs:{"to":{ name: 'usuarioEditar', params: { idOrganization: _vm.usuario.organizacion_id, idUser: _vm.usuario.id }},"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
var isExactActive = ref.isExactActive;
return [_c('li',{class:{'is-active': isExactActive}},[_c('a',{attrs:{"role":"link"},on:{"click":navigate}},[_vm._v("Editar")])])]}}])}),_vm._v(" "),_c('router-link',{attrs:{"to":{ name: 'usuarioAreas', params: { idOrganization: _vm.usuario.organizacion_id, idUser: _vm.usuario.id }},"exact":"","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
var isExactActive = ref.isExactActive;
return [_c('li',{class:{'is-active': isExactActive}},[_c('a',{attrs:{"role":"link"},on:{"click":navigate}},[_vm._v("Areas")])])]}}])}),_vm._v(" "),_c('router-link',{attrs:{"to":{ name: 'usuarioRoles', params: { idOrganization: _vm.usuario.organizacion_id, idUser: _vm.usuario.id }},"exact":"","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
var isExactActive = ref.isExactActive;
return [_c('li',{class:{'is-active': isExactActive}},[_c('a',{attrs:{"role":"link"},on:{"click":navigate}},[_vm._v("Roles")])])]}}])}),_vm._v(" "),(_vm.$store.getters.hasAnyRole(['lyris']))?_c('router-link',{attrs:{"to":{ name: 'usuarioBackupCertificado', params: { idOrganization: _vm.usuario.organizacion_id, idUser: _vm.usuario.id }},"exact":"","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
var isExactActive = ref.isExactActive;
return [_c('li',{class:{'is-active': isExactActive}},[_c('a',{attrs:{"role":"link"},on:{"click":navigate}},[_vm._v("Certificado "),_c('span',{staticClass:"tag is-success is-rounded is-small"},[_vm._v("Lyris")])])])]}}],null,false,1349093889)}):_vm._e(),_vm._v(" "),(_vm.$store.getters.hasAnyRole(['lyris', 'lyris-soporte']))?_c('router-link',{attrs:{"to":{ name: 'usuarioForcePassword', params: { idOrganization: _vm.usuario.organizacion_id, idUser: _vm.usuario.id }},"exact":"","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
var isExactActive = ref.isExactActive;
return [_c('li',{class:{'is-active': isExactActive}},[_c('a',{attrs:{"role":"link"},on:{"click":navigate}},[_vm._v("Cambiar contraseña "),_c('span',{staticClass:"tag is-success is-rounded is-small"},[_vm._v("Lyris")])])])]}}],null,false,994825818)}):_vm._e()],1)]),_vm._v(" "),_c('router-view',{on:{"updateResource":function($event){return _vm.updateUser()}}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h1',{staticClass:"subtitle is-5"},[_c('span',{},[_vm._v("Usuario")])])}]

export { render, staticRenderFns }