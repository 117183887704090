<template>
    <section>
      <div class="box">
      <!-- <router-link class="is-pulled-right" :to="{ name: 'usuarioEditar', params: { idOrganization: idOrganization, idUser: idUser }}" v-show="$store.getters.hasAnyRole(['lyris','admin'])"> -->
      <h1 class="subtitle is-5">
        <span class="">Usuario</span>
      </h1>
      <h1 class="title is-2">
        <span class="is-uppercase">{{usuario.apellido}}</span>, {{usuario.nombre}}
      </h1>

      </div>
      <div class="tabs is-boxed mb-0">
        <ul>
          <!-- <router-link :to="{ name: 'usuarioInfo', params: { idOrganization: usuario.organizacion_id, idUser: usuario.id }}" exact custom v-slot="{ navigate }">
            Datos del usuario
          </router-link>
          <router-link :to="{ name: 'usuarioAreas', params: { idOrganization: usuario.organizacion_id, idUser: usuario.id }}" exact custom v-slot="{ navigate }">
            Areas
          </router-link>
          <router-link :to="{ name: 'usuarioRoles', params: { idOrganization: usuario.organizacion_id, idUser: usuario.id }}" exact custom v-slot="{ navigate }">
            Roles
          </router-link> -->
          <router-link :to="{ name: 'usuarioInfo', params: { idOrganization: usuario.organizacion_id, idUser: usuario.id }}" custom v-slot="{ navigate, isExactActive }">
            <li :class="{'is-active': isExactActive}"><a @click="navigate" role="link">Información</a></li>
          </router-link>
          <router-link :to="{ name: 'usuarioEditar', params: { idOrganization: usuario.organizacion_id, idUser: usuario.id }}" custom v-slot="{ navigate, isExactActive }">
            <li :class="{'is-active': isExactActive}"><a @click="navigate" role="link">Editar</a></li>
          </router-link>
          <router-link :to="{ name: 'usuarioAreas', params: { idOrganization: usuario.organizacion_id, idUser: usuario.id }}" exact custom v-slot="{ navigate, isExactActive }">
            <li :class="{'is-active': isExactActive}"><a @click="navigate" role="link">Areas</a></li>
          </router-link>
          <router-link :to="{ name: 'usuarioRoles', params: { idOrganization: usuario.organizacion_id, idUser: usuario.id }}" exact custom v-slot="{ navigate, isExactActive }">
            <li :class="{'is-active': isExactActive}"><a @click="navigate" role="link">Roles</a></li>
          </router-link>
          <router-link :to="{ name: 'usuarioBackupCertificado', params: { idOrganization: usuario.organizacion_id, idUser: usuario.id }}" v-if="$store.getters.hasAnyRole(['lyris'])" exact custom v-slot="{ navigate, isExactActive }">
            <li :class="{'is-active': isExactActive}"><a @click="navigate" role="link">Certificado&nbsp;<span class="tag is-success is-rounded is-small">Lyris</span></a></li>
          </router-link>
          <router-link :to="{ name: 'usuarioForcePassword', params: { idOrganization: usuario.organizacion_id, idUser: usuario.id }}" v-if="$store.getters.hasAnyRole(['lyris', 'lyris-soporte'])" exact custom v-slot="{ navigate, isExactActive }">
            <li :class="{'is-active': isExactActive}"><a @click="navigate" role="link">Cambiar contraseña&nbsp;<span class="tag is-success is-rounded is-small">Lyris</span></a></li>
          </router-link>
        </ul>
      </div>
          <!-- <b-tabs expanded>
        <b-tab-item  label="Pictures"></b-tab-item>
        <b-tab-item  label="Music"></b-tab-item>
        <b-tab-item  label="Videos"></b-tab-item>
        <b-tab-item  label="Videos"></b-tab-item>
        <b-tab-item  label="Videos"></b-tab-item>
    </b-tabs> -->
          <router-view @updateResource="updateUser()"></router-view>
      <!-- <div class="columns">
        <div class="column">
          <sidebar v-if="usuario"></sidebar>
        </div>
        <div class="column is-9">
        </div>
      </div> -->
    </section>
</template>

<script>
// import Sidebar from '@/components/organizacion/usuario/Sidebar'
// import http from '@/http'
import store from '@/store'

export default {
  props: ['idOrganization', 'idUser'],
  components: {
    // Sidebar
  },
  data () {
    return {}
  },
  methods: {
    updateUser: function () {
      this.startLoading()
      this.$store.dispatch('organizacion/fetchSelectedUser', this.idUser)
        .catch(error => {
          console.error('Fetching data failed', error)
        })
        .finally(() => {
          this.stopLoading()
        })
    }
  },
  computed: {
    usuario: function () {
      return this.$store.state.organizacion.selectedUser
    }
  },
  beforeRouteUpdate: (to, from, next) => {
    // Save the next function so its visible for the promise..
    // by default this is pased to the promise.
    if (to.params.idUser === from.params.idUser) {
      console.info('[ROUTER] No need to fetch selected user, already fetched')
      next()
    } else {
      console.info('[ROUTER] Updated route - Getting user data')
      Promise.all([
        store.dispatch('organizacion/fetchSelectedUser', to.params.idUser)
      ])
        .then(responses => {
          console.info('[ROUTER] Data fetch success. Continue to route')
          next()
        })
        .catch(error => {
          console.error('[ROUTER] Fetching data failed', error)
          next({ name: 'error' })
        })
    }
  }
}
</script>
