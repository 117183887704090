const recibosModule = {
  namespaced: true,
  state: {
    selected: []
  },
  mutations: {
    setSelected (state, recibos) {
      state.selected = recibos
    },
    unload (state) {
      state.selected = []
    }
  },
  getters: {},
  actions: {
    unload (context) {
      context.commit('unload')
    }
  }
}

export default recibosModule
