<template>
  <div id="unidadCompuesta">
        <route-breadcrumb
          :routes="rutas"
          :ends-file="true"
        />
    <section class="py-5">
      <div class="container is-fluid">
        <h1 class="title is-3 is-marginless is-hidden-touch">
          <i class="far fa-file-archive"></i>
          &nbsp;{{unidadCompuesta.nombre && unidadCompuesta.nombre.length > 50 ? unidadCompuesta.nombre.substring(0,50) + '...' : unidadCompuesta.nombre}}
        </h1>
        <h5 class="title is-4 is-marginless is-hidden-desktop">
          <i class="far fa-file-archive fa-lg"></i>
           &nbsp;{{unidadCompuesta.nombre && unidadCompuesta.nombre.length > 50 ? unidadCompuesta.nombre.substring(0,50) + '...' : unidadCompuesta.nombre}}
        </h5>
        <br>
        <div class="notification is-primary" v-if="eventoEnEjecucion">
          <i class="fas fa-forward"></i>&nbsp;
          <b>ATENCIÓN:</b> Existe un evento en proceso en esta unidad.
        </div>
        <div class="box is-clearfix">
          <h1 class="subtitle is-5 has-text-primary is-pulled-left is-marginless">
            <i class="fas fa-info-circle"></i>&nbsp;Estado actual
          </h1>
          <div class="tags is-pulled-right is-marginless">
          <span
            class="tag is-warning is-medium animated flash slower infinite"
            v-if="eventoEnEjecucion"
          ><i class="fas fa-clock"></i>&nbsp;Evento en ejecución</span>
          <span
            class="tag is-primary is-medium"
          >{{unidadCompuesta.serie_documental.estados[unidadCompuesta.estado]}}</span>
          </div>
        </div>
        <!-- {{unidadCompuesta}} -->
        <div class="columns">
          <div class="column">
            <div class="box" v-if="!eventoEnEjecucion">
              <h1 class="subtitle is-5 has-text-primary is-marginless">
                <i class="fas fa-info-circle"></i>&nbsp;Eventos disponibles para ejecutar</h1>
                <CorrerEvento @updateDocument="updateDocument"/>
            </div>
            <div class="box animated flash slow"  v-if="userIsResponsable && eventoEnEjecucion">
              <h1 class="subtitle is-5 has-text-primary">
                <i class="fas fa-info-circle"></i>&nbsp;Acciones a realizar
              </h1>
              <accion-evento
              v-for="accion in accionesEventoEnEjecucion"
              :key="accion.id"
              :accion="accion"
               @updateDocument="updateDocument"
              ></accion-evento>
            </div>
              <div class="notification is-warning" v-if="!userIsResponsable && eventoEnEjecucion">
                <i class="fas fa-exclamation-triangle"></i>&nbsp;Hay un evento en ejecucion iniciado por otro usuario
              </div>
            <div class="box">
              <h1 class="subtitle is-5 has-text-primary">
                <i class="fas fa-info-circle"></i>&nbsp;Historial
              </h1>
              <historial-ejecuciones></historial-ejecuciones>
            </div>
            <div class="box">
              <h1 class="subtitle is-5 has-text-primary">
                <i class="fas fa-info-circle"></i>&nbsp;Información
              </h1>
              <informacion></informacion>
            </div>
           <div class="box">
              <h1 class="subtitle is-5 has-text-primary">
                <i class="fas fa-info-circle"></i>&nbsp;Metadatos</h1>
                <metadatos
                  v-for="aspecto in unidadCompuesta.aspectos" :key="`asp-meta-${aspecto.id}`"
                  :force-edit="false"
                  :hide-save-button="true"
                  :metadatos="unidadCompuesta.lista_metadatos"
                  :aspecto="aspecto"
                  :read-only="true"
                  :can-edit="false"
                />
            </div>
            <div class="box mb-0">
              <h1 class="subtitle is-5 has-text-primary">
                <i class="fas fa-users-cog"></i>&nbsp;Permisos</h1>
            </div>
              <permisos @updateDocument="updateDocument" :documento="unidadCompuesta" :nivel-permiso-user="nivelPermisoUser"></permisos>
          </div>
          <div class="column is-4" v-if="!openSideBySide">
            <div class="box">
              <h1 class="subtitle is-5 has-text-primary">
                <i class="far fa-file"></i>&nbsp;Subunidades
              </h1>
              <SubunidadesCargadas @side-by-side="toggleSideBySide" />
            </div>
            <div class="box">
              <InformacionSerieDocumental />
            </div>
          </div>
          <div class="column is-6 pl-0" v-else>
            <div class="is-flex file-side-to-side">
              <a @click="openSideBySide = false"><b-tooltip label="Cerrar" position="is-left"><i class="fas fa-lg fa-angle-double-right"></i></b-tooltip></a>
               <iframe :src="urlPreviewer" frameborder="0"></iframe>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Informacion from '@/components/unidad-compuesta/Informacion'
import InformacionSerieDocumental from '@/components/unidad-compuesta/InformacionSerieDocumental'
import Permisos from '@/components/globals/permisos/Permisos'
import CorrerEvento from '@/components/unidad-compuesta/CorrerEvento'
import AccionEvento from '@/components/unidad-compuesta/AccionEvento'
import HistorialEjecuciones from '@/components/unidad-compuesta/HistorialEjecuciones'
import SubunidadesCargadas from '@/components/unidad-compuesta/SubunidadesCargadas'
import Metadatos from '@/components/globals/aspectos/Metadatos'
import RouteBreadcrumb from '@/components/utils/RouteBreadcrumb'
import permisosUtils from '@/mixins/permisosUtils'

import store from '@/store'
import http from '@/http'

export default {
  mixins: [permisosUtils],
  props: ['id'],
  components: {
    Informacion,
    InformacionSerieDocumental,
    Permisos,
    RouteBreadcrumb,
    CorrerEvento,
    AccionEvento,
    HistorialEjecuciones,
    SubunidadesCargadas,
    Metadatos
  },
  data () {
    return {
      rutas: [],
      openSideBySide: false,
      urlPreviewer: false
    }
  },
  created () {
    // this.fetchAreasDisponibles()
    this.rutas = this.unidadCompuesta.ruta.split('/')
  },
  beforeRouteUpdate: (to, from, next) => {
    // Save the next function so its visible for the promise..
    // by default this is pased to the promise.
    const orgId = store.getters.getUser.organization
    if (to.params.id === from.params.id) {
      console.log('No need to fetch the unidad compuesta, already fetched')
      next()
    } else {
      console.log('Updated route - Getting Unidad compuesta data')
      Promise.all([
        http.get('/unidad-compuesta/' + to.params.id),
        http.get('/documento-operacion'),
        http.get('/organizacion/' + orgId + '/area?size=100')
      ]).then(responses => {
        console.log('GET OK - Unidad compuesta updated!')
        // store.commit('setUnidadCompuesta', responses[0].data)
        store.commit('unidadCompuesta/unidadCompuesta', responses[0].data)
        store.commit('unidadCompuesta/operacionesDisponibles', responses[1].data)
        store.commit('organizacion/areas', responses[2].data)
        console.log('GET OK - Got unidad compuesta')
        next()
      })
        .catch(error => {
          console.error('Fail', error)
          console.log('Fetching data failed')
          this.next(false)
        })
    }
  },
  methods: {
    // fetchAreasDisponibles () {
    //   this.fetchingAreas = true
    //   this.$http
    //     .get('/organizacion/' + this.organizacion + '/area')
    //     .then(response => {
    //       this.areasOrganizacion = response.data.data
    //       this.fetchedAreas = true
    //       this.fetchingAreas = false
    //     })
    //     .catch(error => {
    //       console.error('Fail', error)
    //       this.$buefysnackbar.open({
    //         message:
    //           'No se pudieron obtener las areas de la organización. Puede llegar a tener problemas la visualización del documento',
    //         type: 'is-danger'
    //       })
    //     })
    // },
    toggleSideBySide: function (selectedDocument) {
      if (this.openSideBySide) {
        this.openSideBySide = false
      } else {
        this.openSideBySide = true
        this.$http
          .get('/documento/' + selectedDocument.id + '/descarga')
          .then(response => {
            this.urlPreviewer = `${response.data.link}?inline=true`
            this.openSideBySide = true
          })
          .catch(error => {
            console.error('Fail', error)
            this.$buefy.snackbar.open({
              message:
                'Error de conexión. Compruebe la conectividad con internet.',
              type: 'is-danger'
            })
          })
      }
    },
    updateDocument: function () {
      this.startLoading()
      console.log('Updated route - Getting Unidad compuesta data')
      this.$http
        .get('/unidad-compuesta/' + this.id)
        .then(response => {
          console.log('GET OK - Unidad compuesta updated!')
          // this.$store.commit('setUnidadCompuesta', response.data)
          store.commit('unidadCompuesta/unidadCompuesta', response.data)
        })
        .catch(error => {
          console.error('Fail', error)
          console.log('Fetching data failed')
        }).finally(() => {
          this.stopLoading()
        })
    }
    // addToPila: function() {
    //   this.$store.commit("addPila", this.documento);
    // }
  },
  computed: {
    unidadCompuesta: function () {
      return this.$store.state.unidadCompuesta.unidadCompuesta
    },
    user: function () {
      return this.$store.getters.getUser
    },
    eventoEnEjecucion: function () {
      return this.unidadCompuesta.ejecuciones.find(ejecucion => {
        return ejecucion.finalizada === false
      })
    },
    accionesEventoEnEjecucion: function () {
      return this.eventoEnEjecucion.accion_ejecuciones
    },
    userIsResponsable: function () {
      if (this.unidadCompuesta.ejecuciones.length === 0) return true
      return this.unidadCompuesta.ejecuciones[this.unidadCompuesta.ejecuciones.length - 1].usuario.id === this.$store.getters.getUser.id
    },
    nivelPermisoUser: function () {
      if (this.$store.getters.hasAnyRole(['lyris'])) return 9
      return this.getNivelPermisoUser(this.unidadCompuesta.permisos, this.user)
    }
  }
}
</script>

<style lang="scss" scoped>
.file-side-to-side{
   position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  height:  100%;
  max-height: 100vh;
  width: 100%;
  iframe {
    width: 100%;
  }
  i {
    margin-right: 7px;
    margin-top: 8px;
  }
}
</style>
