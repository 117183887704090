<template>
   <div class="card">
          <div class="card-content has-text-centered">
            <h1 class="title is-3" :class="classTitle" v-if="title">{{title}}</h1>
            <h1 class="title is-3" v-else>Está por realizar una acción</h1>
            <h2 class="subtitle is-5" v-if="subtitle" v-html="subtitle"></h2>
            <h2 class="subtitle is-5" v-else>¿Está seguro?</h2>
          </div>
          <div class="card-footer">
            <a class="card-footer-item" type="button" @click="cancel">Cancelar</a>
        <a class="card-footer-item has-text-weight-bold is-uppercase" :class="actionClass" @click.prevent="accept">{{actionText || 'Aceptar'}}</a>
          </div>
        </div>
</template>

<script>
export default {
  props: ['title', 'classTitle', 'subtitle', 'actionText', 'actionClass'],
  methods: {
    accept () {
      this.$emit('accept')
      this.$parent.close()
    },
    cancel () {
      this.$emit('cancel')
      this.$parent.close()
    }
  }
}
</script>
