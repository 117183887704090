// import store from '@/store'
// import http from '@/http'

// Panel
import Recibos from './Recibos'

const BASE_PATH = '/recibos'

const routes = [
  {
    path: BASE_PATH,
    name: 'listarRecibos',
    meta: {
      requiresAuth: true,
      requiredRoles: ['lyris', 'user', 'empleado', 'empleador']
    },
    component: Recibos,
    props: true
  }
]
export default routes
