<template>
  <div class="content">
    <table class="table is-narrow is-fullwidth">
      <thead>
        <tr>
          <th>Fecha</th>
          <th>Evento</th>
          <th>Responsable</th>
          <th width="120" class="has-text-centered">Estado</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="accion in unidadCompuesta.ejecuciones" :key="accion.id">
          <td>{{new Date(accion.updated_at) | moment('DD/MM/YYYY')}}</td>
          <td>{{accion.documento_evento.nombre}}</td>
          <td>{{accion.usuario.nombre}} {{accion.usuario.apellido}}</td>
          <td class="has-text-centered">
            <span class="tag is-success" v-if="accion.finalizada"><i class="fas fa-check"></i></span>
            <span class="tag is-warning" v-else><i class="fas fa-clock"></i></span>
          </td>
        </tr>
        <tr v-if="unidadCompuesta.ejecuciones.length === 0">
          <td colspan="4">
            <div class="section has-text-centered">Aún no se han ejecutado eventos</div>
            </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  methods: {
    getOperacion: function (id) {
      return this.$store.getters.getOperacion(id)
    }
  },
  computed: {
    unidadCompuesta: function () {
      return this.$store.state.unidadCompuesta.unidadCompuesta
    },
    bolsaEjecuciones: function () {
      const bolsa = this.unidadCompuesta.ejecuciones.map(e => {
        return e.accion_ejecuciones
      })
      return [].concat.apply([], bolsa)
    }
  }
}
</script>

<style>
</style>
