<template>
  <div class="box">
    <div class="is-clearfix">
      <a class="is-pulled-right" @click="deleteAccion"><i class="fas fa-times-circle fa-lg"></i></a>
      <div class="is-pulled-left">
        <h3 class="subtitle is-5">Parametros</h3>
        <h3 class="title is-6">{{accion.nombre}}</h3>
      </div>
    </div>
    <br>
    <div class="field">
      <label class="label">Descripción de la acción</label>
      <div class="control">
        <input
          v-model="descripcion"
          name="Descripcion de la acción"
          v-validate="{required: true}"
          class="input"
          :class="{'is-danger': errors.has('Descripcion de la acción') }"
        />
        <span class="help">Describa en detalle de que se trata la acción</span>
        <span
          class="help is-danger"
          v-if="errors.has('Descripcion de la acción')"
        >{{errors.first('Descripcion de la acción')}}</span>
      </div>
    </div>
    <div class="field" v-if="parametrosArray.includes('subunidad')">
      <label class="label">Subunidad</label>
      <div class="control">
        <div class="select is-fullwidth">
          <select v-model="subunidadSelected" name="Subunidad" v-validate="'required'">
            <option :value="null" disabled>- Seleccione una subunidad -</option>
            <option :value="index" v-for="(s,index) in serieDocumental.estructura" :key="index">{{s.nombre}}</option>
          </select>
        </div>
      </div>
    </div>
    <span class="help is-danger" v-if="errors.has('Subunidad')">{{errors.first('Subunidad')}}</span>
    <div class="field" v-if="parametrosArray.includes('aspecto_id')">
      <div class="control">
        <div class="select is-fullwidth">
          <select v-model="aspectoSelected" name="Aspecto" v-validate="'required'">
            <option :value="null" disabled>- Seleccione un aspecto -</option>
            <option :value="a.id" v-for="(a,index) in aspectosSerie" :key="index">{{a.nombre}}</option>
          </select>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['accion'],
  data () {
    return {
      // slug: null,
      descripcion: null,
      subunidadSelected: null,
      aspectoSelected: null
      // aspectos: null,
      // fetchingAspectos: false
    }
  },
  mounted: function () {
    // if (this.parametrosArray.includes('aspecto_id')) {
    //   this.fetchAspectos()
    // }
  },
  methods: {
    // fetchAspectos: function () {
    //   this.fetchingAspectos = true
    //   this.$http.get(`/organizacion/${this.idOrganizacion}/aspecto?global=true`)
    //     .then(response => {
    //       this.aspectos = response.data.filter(a => this.aspectosIniciales.includes(a.id))
    //     }).catch(err => {
    //       console.error(err)
    //       this.$buefy.snackbar.open({
    //         message: 'El estado a ingresar es invalido',
    //         type: 'is-danger'
    //       })
    //     }).finally(() => {
    //       this.fetchingAspectos = false
    //     })
    // },
    validateForm: function () {
      const promise = new Promise((resolve, reject) => {
        this.$validator.validateAll().then(result => {
          if (!result) {
            console.log('Hay errores en los datos')
            return reject(result)
          }
          console.log('OK')
          return resolve(result)
        })
      })
      return promise
    },
    getPayload: function () {
      const payload = {
        // slug: this.slug,
        descripcion: this.descripcion,
        parametros: {}
      }
      if (this.subunidadSelected) {
        payload.parametros.subunidad = this.subunidadSelected
      }
      if (this.aspectoSelected) {
        payload.parametros.aspecto_id = this.aspectoSelected
      }
      return payload
    },
    deleteAccion: function () {
      this.$emit('delete')
    }
  },
  computed: {
    serieDocumental: function () {
      return this.$store.state.serieDocumental.serieDocumental
    },
    aspectos: function () {
      return this.$store.getters['organizacion/allAspectos']
    },
    aspectosSerie: function () {
      if (!this.aspectos) return []
      return this.aspectos.filter((a) =>
        Object.values(this.serieDocumental.aspectos).includes(a.id)
      )
    },
    parametrosArray: function () {
      return Object.keys(this.accion.parametros)
    },
    idOrganizacion: function () {
      return this.$store.state.organizacion.organizacion.id
    }
  },
  watch: {
    habilitarRestringirAreas: function (newVal) {
      console.log(newVal)
      if (newVal === true && !this.fetchedAreas) {
        this.fetchAreas()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.box {
  border: 2px solid $primary;
  border-radius: 0.4em;
}
</style>
