<template>
  <section>
    <div class="box">
      <h1 class="subtitle is-4">Certificados</h1>
      <h1 class="title is-3">Aprobar certificado</h1>
    </div>
    <div class="box pl-5">
      <div class="is-flex is-flex-direction-row is-justify-content-space-between">
        <h1 class="subtitle is-5">Datos de la solicitud</h1>

        <b-tooltip label="Fecha de solicitud">
            <b-field>
              <b-tag size="is-medium" type="is-warning is-light" icon="fas fa-calendar">{{ this.dateFormat(this.requestInformation.date) }}</b-tag>
            </b-field>
        </b-tooltip>
      </div>

      <p class="mb-4">Verifique los datos de la solicitud y la información actual del empleado.</p>

      <b-table
        :data="tableInfo"
        :loading="isLoading">

        <b-table-column field="field" label="" v-slot="props">
            <span class="has-text-weight-semibold">
              {{ props.row.field }}
            </span>
        </b-table-column>

        <b-table-column field="request_info" centered label="Datos solicitud" v-slot="props" class="has-text-weight-bold">
            {{ props.row.request_info }}
        </b-table-column>

        <b-table-column field="actual_info" centered label="Datos empleado" v-slot="props">
            {{ props.row.actual_info }}
        </b-table-column>

        <b-table-column field="is_equal" label="Coincidencia" centered v-slot="props">
            <span>
                <b-icon
                    pack="fas"
                    :icon="props.row.is_equal? 'check' : 'times'"
                    :type="props.row.is_equal? 'is-success' : 'is-danger'"
                    size="is-medium">
                </b-icon>
            </span>
        </b-table-column>
      </b-table>

      <b-notification type="is-primary is-light mt-5" has-icon :closable="false">
        <i class="fas fa-info-circle fa-lg mr-4"></i>
        Al continuar, confirma que los datos son correctos y el solicitante <b>firmó</b> el acuerdo de suscriptores.
      </b-notification>
      <div class="buttons is-right mt-6">
        <b-button class="is-light mr-2" @click="goBack">
          Volver
        </b-button>
        <b-button class="is-primary" @click="approveCert">
          Continuar
        </b-button>
      </div>
    </div>
  </section>
</template>
<script>
import ModalLaunchFirmador from '@/components/utils/ModalLaunchFirmador'
export default {
  props: ['idCert'],
  data () {
    return {
      isLoading: false,
      tableInfo: [
        { field: 'Nombre y apellido', request_info: '', actual_info: '', is_equal: '' },
        { field: 'CUIL', request_info: '', actual_info: '', is_equal: '' },
        { field: 'Email', request_info: '', actual_info: '', is_equal: '' }
      ],
      requestInformation: {
        name: null,
        cuil: null,
        email: null,
        date: null
      },
      actualInformation: {
        name: null,
        cuil: null,
        email: null
      }
    }
  },
  beforeMount: function () {
    this.isLoading = true
    this.$http
      .get(`/certificado/${this.idCert}`)
      .then(res => {
        const certificado = res.data.certificado
        const csr = this.$forge.pki.certificationRequestFromPem(this.$forge.util.decode64(certificado.csr))

        this.requestInformation.name = this.$forge.util.decodeUtf8(csr.subject.attributes[1].value)
        this.requestInformation.cuil = this.$forge.util.decodeUtf8(csr.subject.attributes[2].value).slice(5)
        this.requestInformation.email = csr.getAttribute({ name: 'extensionRequest' }).extensions[0].altNames[0].value
        this.requestInformation.date = certificado.created_at

        this.assignRequestInfo()

        this.obtenerUsuario(certificado.usuario.id)
      })
      .catch(() => {
        this.$buefy.snackbar.open({
          message: 'Ocurrió un error al recuperar la solicitud de certificado',
          type: 'is-danger'
        })
      })
  },
  methods: {
    obtenerUsuario: function (userId) {
      this.$http
        .get(`/usuario/${userId}`)
        .then(res => {
          const usuario = res.data

          this.actualInformation.name = usuario.nombre + ' ' + usuario.apellido
          this.actualInformation.cuil = usuario.cuil
          this.actualInformation.email = usuario.email

          this.assignActualInfo()
          this.assignEqualValue()
        })
        .catch(() => {
          this.$buefy.snackbar.open({
            message: 'Ocurrió un error al recuperar el usuario desde el certificado',
            type: 'is-danger'
          })
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    approveCert: function () {
      const suscriptores = [{ certificado_id: this.idCert, accion: 'aprobacion' }]
      const payload = {
        opcion: 'acta-cert',
        firmador: 'signar',
        parametros: { suscriptores: suscriptores }
      }
      this.$store.dispatch('firmador/unload')
      this.$store.commit('firmador/setPayload', payload)
      this.$buefy.modal.open({
        parent: this,
        canCancel: false,
        hasModalCard: true,
        component: ModalLaunchFirmador
      })
      this.$router.go(-1)
    },
    goBack: function () {
      this.$router.go(-1)
    },
    dateFormat: function (date) {
      return new Date(date).toLocaleDateString('fr')
    },
    assignRequestInfo: function () {
      this.tableInfo[0].request_info = this.requestInformation.name
      this.tableInfo[1].request_info = this.requestInformation.cuil
      this.tableInfo[2].request_info = this.requestInformation.email
    },
    assignActualInfo: function () {
      this.tableInfo[0].actual_info = this.actualInformation.name
      this.tableInfo[1].actual_info = this.actualInformation.cuil
      this.tableInfo[2].actual_info = this.actualInformation.email
    },
    assignEqualValue: function () {
      this.tableInfo[0].is_equal = this.isNameEqual
      this.tableInfo[1].is_equal = this.isCuilEqual
      this.tableInfo[2].is_equal = this.isEmailEqual
    }
  },
  computed: {
    isNameEqual: function () {
      return (this.requestInformation.name === this.actualInformation.name)
    },
    isCuilEqual: function () {
      return (this.requestInformation.cuil === this.actualInformation.cuil)
    },
    isEmailEqual: function () {
      return (this.requestInformation.email === this.actualInformation.email)
    }
  }
}
</script>
