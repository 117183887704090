<template>
  <div class="box">
    <h1 class="subtitle is-5">Administrar organización</h1>
    <article class="is-flex is-justify-content-space-between is-align-items-start">
      <h1 class="title is-1">Usuarios</h1>
      <b-dropdown aria-role="list" position="is-bottom-left">
        <template #trigger>
          <b-button icon-left="fas fa-ellipsis-h" class="is-small mb-2"></b-button>
        </template>
        <b-dropdown-item aria-role="listitem" @click="showDialogNumberOfUsers">
          <b-icon icon="fas fa-users"></b-icon>
          <span>Verificar cantidad de usuarios</span>
        </b-dropdown-item>
        <b-dropdown-item aria-role="listitem" v-if="$store.getters.hasAnyRole([constants.STRINGS.ROLES.LYRIS.ID, constants.STRINGS.ROLES.LYRIS_SOPORTE.ID])" @click="openModalCargaMasiva">
          <b-icon icon="fas fa-upload"></b-icon>
          <span>Carga masiva de usuarios</span>
        </b-dropdown-item>
      </b-dropdown>
    </article>
    <hr>
    <Filtros v-if="!fetchingQueryParameters" :roles="roles" :areas="areas" @update:queryChanged="handleFilters"/>
    <hr />
    <table class="table is-fullwidth">
      <thead>
        <tr>
          <th>Usuario</th>
          <th>CUIL</th>
          <th class="has-text-centered"><i class="fas fa-cloud"></i> Cert.</th>
          <th>Email</th>
          <th width="100" class="has-text-right">Acciones</th>
        </tr>
      </thead>
      <tbody v-if="loading">
        <tr>
          <td colspan="4" class="has-text-centered">
            <loading-spin></loading-spin>
          </td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr :id="usuario.id" v-for="usuario in usuarios" :key="usuario.id">
          <td>
            <i v-if="usuario.acceso_habilitado" class="fas fa-user fa-fw"></i>
            <i v-else class="fas fa-user-slash fa-fw"></i>
            {{usuario.apellido}}, {{usuario.nombre}}
          </td>
          <td>{{usuario.cuil}}</td>
          <td class="has-text-centered">
            <router-link v-if="hasRole([constants.STRINGS.ROLES.LYRIS.ID])"
              :to="{ name: 'usuarioBackupCertificado', params: { idOrganization: idOrganization, idUser: usuario.id }}">{{usuario.keystore ? 'Si' : 'No'}}</router-link>
            <span v-else>{{usuario.keystore ? 'Si' : 'No'}}</span>
            <b-tooltip label="El certificado no está aprobado" type="is-warning">
              <b-icon v-if="usuario.keystore && !usuario.keystore_habilitado" type="is-warning" pack="fas" icon="exclamation-triangle"></b-icon>
            </b-tooltip>
          </td>
          <td>{{usuario.email}}</td>
          <td>
            <div class="field has-addons is-pulled-right">
              <p class="control">
                <router-link
                  :to="{ name: 'usuarioInfo', params: { idOrganization: idOrganization, idUser: usuario.id }}"
                  class="button is-small"
                >
                  <span class="icon is-small">
                    <i class="fas fa-fw fa-eye fa-lg"></i>
                  </span>
                </router-link>
              </p>
              <p class="control">
                <router-link
                  :to="{ name: 'usuarioEditar', params: { idOrganization: idOrganization, idUser: usuario.id }}"
                  class="button is-small"
                >
                  <span class="icon is-small">
                    <i class="fas fa-fw fa-edit fa-lg"></i>
                  </span>
                </router-link>
              </p>
              <p class="control">
                <button
                  v-if="!usuario.acceso_habilitado && hasRole([constants.STRINGS.ROLES.LYRIS_SOPORTE.ID, constants.STRINGS.ROLES.LYRIS.ID])"
                  class="button is-small is-info is-outlined"
                  @click="openModalRehabilitarUsuario(usuario)">
                  <span class="icon is-small">
                    <i class="fas fa-fw fa-user-plus fa-lg"></i>
                  </span>
                </button>
                <button
                  v-else
                  class="button is-small is-danger is-outlined"
                  :disabled="!usuario.acceso_habilitado"
                  @click="openModalBajaUsuario(usuario)">
                  <span class="icon is-small">
                    <i class="fas fa-fw fa-user-minus fa-lg"></i>
                  </span>
                </button>
              </p>
            </div>
          </td>
        </tr>
        <tr v-if="usuarios.length==0">
          <td class="has-text-centered" colspan="4">
            <section class="section is-mono">
              <span class="tag is-rounded is-medium">
                <i class="fas fa-info fa-fw"></i>&nbsp;Sin usuarios para mostrar
              </span>
            </section>
          </td>
        </tr>
      </tbody>
    </table>
    <paginator :resource="url" ref="paginator" @update="updateResource" :loading.sync="loading" />

  </div>
</template>

<script>
import Paginator from '@/components/utils/Paginator'
import LoadingSpin from '@/components/utils/LoadingSpin'
import ModalCargaMasiva from '@/components/organizacion/usuario/ModalCargaMasiva'
import ModalAccionUsuario from '@/components/organizacion/usuario/ModalAccionUsuario'
import constants from '@/constants'
import Filtros from './Filtros.vue'

export default {
  props: ['idOrganization', 'idUser'],
  components: {
    Paginator,
    LoadingSpin,
    Filtros
  },
  data () {
    return {
      constants: constants,

      usuarios: [],
      loading: true,
      areas: [],
      roles: [],
      queryRole: null,
      queryArea: null,
      queryName: null,
      queryActive: true,
      fetchingQueryParameters: true
    }
  },
  mounted: function () {
    this.fetchQueryParameters()
  },
  computed: {
    queryObject: function () {
      const obj = {}
      if (this.queryRole != null) {
        obj.rol = this.queryRole
      }
      if (this.queryArea != null) {
        obj.area = this.queryArea
      }
      if (this.queryName != null) {
        obj.s = this.queryName
      }
      if (this.queryActive != null) {
        obj.acceso_habilitado = this.queryActive
      }
      return obj
    },
    url: function () {
      const queryString = Object.keys(this.queryObject)
        .map(i => {
          return i + '=' + this.queryObject[i]
        })
        .join('&')
      if (queryString.length) { return '/organizacion/' + this.idOrganization + '/usuario?' + queryString }
      return '/organizacion/' + this.idOrganization + '/usuario'
    },
    organizacion: function () {
      return this.$store.state.organizacion.organizacion
    }
  },
  methods: {
    handleFilters (filterName, filterValue) {
      this.isLoading = true
      this[filterName] = filterValue
      this.$refs.paginator.fetchData()
    },
    showDialogNumberOfUsers () {
      this.$buefy.dialog.alert({
        title: 'Cantidad de usuarios',
        message: `
          <table class="table is-fullwidth">
          <tbody style="font-weight: bold;">
            <tr style="color: green;">
              <td ><i class="fas fa-user-check"></i> Activos</td>
              <td style="text-align: center;">${this.organizacion.cantidad_usuarios - this.organizacion.cantidad_usuarios_inactivos}</td>
            </tr>
            <tr style="color: red;">
              <td > <i class="fas fa-user-minus"></i> Inactivos</td>
              <td style="text-align: center;">${this.organizacion.cantidad_usuarios_inactivos}</td>
            </tr>
            <tr style="border-top: 2px solid #bbb;">
              <td><i class="fas fa-users"></i> Total</td>
              <td style="text-align: center;">${this.organizacion.cantidad_usuarios}</td>
            </tr>
          </tbody>
          </table>
        `,
        confirmText: 'Cerrar',
        type: '',
        canCancel: ['escape', 'outside']
      })
    },
    hasRole (roles) {
      return this.$store.getters.hasAnyRole(roles)
    },
    updateResource (data) {
      this.usuarios = data
    },
    fetchQueryParameters: function () {
      this.fetchingQueryParameters = true
      Promise.all([
        this.$http.get(`organizacion/${this.idOrganization}/rol`),
        this.$http.get(`organizacion/${this.idOrganization}/area?size=100`)
      ])
        .then(responses => {
          this.roles = responses[0].data
          this.areas = responses[1].data.data
        })
        .catch(error => {
          console.log(error)
          this.$buefy.snackbar.open({
            message: 'Error en recuperar los roles y areas de la organización',
            type: 'is-danger'
          })
        })
        .finally(() => {
          this.fetchingQueryParameters = false
        })
    },
    openModalCargaMasiva: function () {
      if (!this.$store.getters.hasAnyRole(['lyris', constants.STRINGS.ROLES.LYRIS_SOPORTE.ID])) {
        return false
      }
      this.$buefy.modal.open({
        parent: this,
        component: ModalCargaMasiva,
        events: {
          reload: (resource, file) => {
            this.loading = true
            this.$refs.paginator.fetchData()
          }
        },
        props: {
          idOrganization: this.idOrganization
        }
      })
    },
    refresh: function () {
      this.loading = true
      this.$refs.paginator.fetchData()
    },
    openModalUsuario: function (options) {
      const modal = this.$buefy.modal.open({
        parent: this,
        component: ModalAccionUsuario,
        width: 720,
        props: options,
        events: {
          confirm: (body) => {
            body.habilitar = options.habilitar
            this.$http
              .patch(`/usuario/${options.userData.id}/acceso`, body)
              .then(res => {
                this.$buefy.snackbar.open({
                  message: options.successMessage,
                  type: 'is-success'
                })
                modal.close()
                this.refresh()
              })
              .catch(error => {
                console.error('Fail', error)
                this.$buefy.snackbar.open({
                  message: 'Error inesperado.',
                  type: 'is-danger'
                })
              })
          }
        }
      })
    },
    openModalBajaUsuario: function (usuario) {
      this.openModalUsuario({
        userData: usuario,
        title: 'Dar de baja usuario',
        subtitle: '¿Está seguro de que desea dar de baja al usuario ' + usuario.acceso + '?',
        showReason: true,
        loadingText: 'Dando de baja al usuario',
        footerText: 'El usuario ya no tendrá acceso pero sus datos e información relacionada <b>perdurarán en el sistema</b>. Si cuenta con un certificado en estado pendiente, preaprobado o vigente, este <b>será revocado</b>. Para el borrado definitivo de los datos o re-activación del usuario, contáctese con personal de Lyris.',
        action: 'Dar de baja',
        buttonDangerColor: true,
        successMessage: 'El usuario ha sido dado de baja exitosamente.',
        habilitar: false
      })
    },
    openModalRehabilitarUsuario: function (usuario) {
      this.openModalUsuario({
        userData: usuario,
        title: 'Rehabilitar usuario',
        subtitle: '¿Está seguro de que desea rehabilitar al usuario ' + usuario.acceso + '?',
        showReason: false,
        loadingText: 'Rehabilitando al usuario',
        footerText: 'El usuario <b>volverá a ser un usuario habilitado del sistema</b>. Esto quiere decir que podrá acceder a la plataforma y realizar acciones como si nunca hubiese estado deshabilitado.',
        action: 'Rehabilitar',
        buttonDangerColor: false,
        successMessage: 'El usuario ha sido rehabilitado exitosamente.',
        habilitar: true
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.user-query {
  li {
    margin: 0 10px;
    &:first-child {
      margin-left: 0;
    }
  }
}
.text-input-query {
  height: 22px;
  font-size: 0.75rem;
  border-radius: 3px;
  border-left: 1px solid #ccc;
  &:focus {
    border-left: 1px solid #2669a0;
  }
}
</style>
