<template>
  <section>
    <p>Haga clic en el evento. Obtendrá mas informacion acerca del mismo antes de ejecutarlo.</p>
    <p class="is-size-7" v-if="eventosDisponibles.length < cantEventosDisponiblesSinFiltroAreas && eventosDisponibles.length == 0 && cantEventosDisponiblesSinFiltroAreas > 0">
      <i class="fas fa-info-circle"></i>&nbsp;
      <b>Importante:</b>
      Este area no tiene mas eventos para ejecutar. Debe continuar otra area ejecutando eventos
    </p>
    <p class="is-size-7" v-if="eventosDisponibles.length < cantEventosDisponiblesSinFiltroAreas && eventosDisponibles.length > 0">
      <i class="fas fa-info-circle"></i>&nbsp;
      <b>Importante:</b>
      Mostrando {{eventosDisponibles.length}} de {{cantEventosDisponiblesSinFiltroAreas}} acciones. Algunos no se muestran porque han sido reestringidas para ser ejecutadas por areas específicas.
    </p>
    <br />
    <div class="buttons is-centered" v-if="eventosDisponibles.length > 0">
      <button
        class="button is-primary is-outlined"
        @click="selectEvento(evento)"
        v-for="(evento,index) in eventosDisponibles"
        :key="index"
      ><i class="fas fa-play-circle fa-fw"></i>&nbsp;{{evento.nombre}}</button>
    </div>
    <div class="notification is-light" v-else>
      <i class="fas fa-exclamation-circle"></i> Sin eventos disponibles para ejecutar
    </div>
  </section>
</template>

<script>
import ModalPreviewAccionEvento from './ModalPreviewAccionEvento'

export default {
  data () {
    return {
    }
  },
  methods: {
    selectEvento: function (evento) {
      this.openPreview(evento)
    },
    openPreview: function (resource) {
      this.$buefy.modal.open({
        parent: this,
        component: ModalPreviewAccionEvento,
        props: {
          resource: resource,
          unidadCompuesta: this.unidadCompuesta,
          hasModalCard: true,
          areasOrganizacion: this.areasOrganizacion
        },
        events: {
          ejecutarEvento: (eventoId) => {
            this.ejecutarEvento(eventoId)
          }
        }
      })
    },
    ejecutarEvento: function (id) {
      this.$http
        .post(`/unidad/${this.unidadCompuesta.id}/evento/${id}`)
        .then(response => {
          this.$buefy.snackbar.open({
            message: response.data.mensaje,
            type: 'is-success'
          })
          this.$emit('updateDocument')
        })
        .catch(err => {
          console.error(err)
          this.$buefy.snackbar.open({
            message: 'Error al ejecutar el evento',
            type: 'is-warning'
          })
        })
    }
  },
  computed: {
    unidadCompuesta: function () {
      return this.$store.state.unidadCompuesta.unidadCompuesta
    },
    areasOrganizacion: function () {
      return this.$store.state.organizacion.areas
    },
    eventosDisponibles: function () {
      if (this.unidadCompuesta) {
        return this.unidadCompuesta.serie_documental.eventos.filter(e => {
          const conditionOne = e.estados_iniciales.includes(
            this.unidadCompuesta.estado
          )
          let conditionTwo = null
          if (e.areas_habilitadas == null) conditionTwo = true
          else {
            conditionTwo = e.areas_habilitadas.some(a =>
              this.theUserAreas.includes(a)
            )
          }
          return conditionOne && conditionTwo
        })
      }
      return []
    },
    cantEventosDisponiblesSinFiltroAreas: function () {
      if (this.unidadCompuesta) {
        const yes = this.unidadCompuesta.serie_documental.eventos.filter(e => {
          return e.estados_iniciales.includes(this.unidadCompuesta.estado)
        })
        return yes.length
      }
      return 0
    },
    operacionesDisponibles: function () {
      return this.$store.state.unidadCompuesta.operacionesDisponibles
    },
    theUserAreas: function () {
      return this.$store.getters.getUser.areas
    }
  }
}
</script>

<style>
</style>
