import store from '@/store'
import router from '@/router'
import Documento from './Documento'

const BASE_PATH = '/documento'

const routes = [
  {
    path: BASE_PATH + '/:id',
    component: Documento,
    name: 'documento',
    props: true,
    meta: {
      requiresAuth: true
    },
    beforeEnter: (to, from, next) => {
      // console.info('[ROUTER] First time entering:', to.path)
      // const idOrganizacion = store.getters.getUser.organization
      // store.dispatch('document/fetchDocument', to.params.id)
      //   .then((docResponse) => {
      //     Promise.all([
      //       store.dispatch('document/fetchUpperFolder', docResponse.data.origen_id),
      //       store.dispatch('signar/fetchAspectosGlobales'),
      //       store.dispatch('organizacion/fetchAspectos', idOrganizacion)
      //     ])
      //       .then(responses => {
      //         console.info('[ROUTER] Data fetch success. Continue to route')
      //         next()
      //       })
      //       .catch(error => {
      //         console.error('[ROUTER] Data fetch failed', error)
      //         next()
      //       })
      //   })
      console.info('[ROUTER] First time entering:', to.path)
      // Lyris should access to aspectos of the organization
      if (store.getters.hasAnyRole(['lyris'])) {
        console.info('[ROUTER] ENTERING AS LYRIS')
        // First fetch the document
        store.dispatch('document/fetchDocument', to.params.id)
          .then(response => {
            // Get the organization from the owner of the document
            console.log(response)
            const idOrganizacion = response.data.propietario.organizacion_id
            // Fetch using the id of the org of the owner
            Promise.all([
              store.dispatch('signar/fetchAspectosGlobales'),
              store.dispatch('organizacion/fetchAspectos', idOrganizacion)
            ])
              .then(responses => {
                // Success
                console.info('[ROUTER] Data fetch success. Continue to route')
                next()
              })
              .catch(error => {
                // Fail. Go to 404
                console.error('[ROUTER] Data fetch failed', error)
                router.push({ name: '404' })
              })
          })
          .catch(error => {
            console.error('[ROUTER] Data fetch failed', error)
            router.push({ name: '404' })
          })
      } else {
        // Normal flow
        const idOrganizacion = store.getters.getUser.organization
        Promise.all([
          store.dispatch('document/fetchDocument', to.params.id),
          store.dispatch('signar/fetchAspectosGlobales'),
          store.dispatch('organizacion/fetchAspectos', idOrganizacion)
        ])
          .then(responses => {
            console.info('[ROUTER] Data fetch success. Continue to route')
            next()
          })
          .catch(error => {
            console.error('[ROUTER] Data fetch failed', error)
            router.push({ name: '404' })
          })
      }
    }
  }
]
export default routes
