<template>
   <div class="card">
     <header class="card-header">
        <p class="card-header-title">Mover {{files.length}} documentos</p>
    </header>
    <div class="card-content" v-if="!isUploading && !isDone">
      <p>Elija la nueva ubicacion de los {{files.length}} documentos</p>
      <br>
      <explorer :initFolder="pivotFile.origen_id || slugRoot" :slug="slugRoot"  ref="listado" :rutas.sync="rutas" :selected.sync="selected"></explorer>
      <br>
      <p class="has-text-left"><b>Nueva ubicación:</b>&nbsp;&nbsp;&nbsp;&nbsp;{{selected && selected.ruta || '/'}}</p>
      <br>
      <div class="notification is-warning is-size-7" v-if="(pivotFile && this.selected && pivotFile.id == this.selected.id)">
        <i class="fas fa-exclamation-triangle"></i>&nbsp;Esta intentando mover una carpeta a si misma. Elija otra ubicación para mover la carpeta
      </div>
      <div class="notification is-warning is-size-7" v-if="(pivotFile && this.selected && pivotFile.origen_id == this.selected.id)">
        <i class="fas fa-exclamation-triangle"></i>&nbsp;La carpeta ya se encuentra en esta ubicacion
      </div>
    </div>
    <div class="card-content" v-else>
      <table class="table is-narrow is-fullwidth">
            <thead>
              <th>Nombre</th>
            </thead>
            <tbody>
              <tr v-for="(file,i) in filesCopy" :key="`file-${i}`">
                <td><p>{{file.nombre}}
                  <span v-if="filesSucceded.includes(i)" class="has-text-success">&nbsp;<i class="fas fa-check-circle"></i></span>
                  <span v-if="filesFailed.includes(i)" class="has-text-danger">&nbsp;<i class="fas fa-times-circle"></i></span></p>
                  <p v-if="filesErrors[i]" class="has-text-danger is-size-7">{{filesErrors[i]}}</p>
                </td>
              </tr>
              <tr v-if="files.length == 0">
                <td colspan="3">
                  <i>- No hay archivos para subir -</i>
                </td>
              </tr>
            </tbody>
          </table>
    </div>
      <footer class="card-footer" v-if="!isUploading && !isDone">
            <a class="card-footer-item" type="button" @click="$parent.close()">Cancelar</a>
        <a class="card-footer-item has-text-weight-bold is-uppercase" v-if="!moverDisabled" @click.prevent="submit">Mover</a>
      </footer>
      <footer class="card-footer" v-else-if="!isUploading && isDone">
        <a class="card-footer-item" type="button" @click="$parent.close()">Cerrar</a>
    </footer>
      <footer class="card-footer" v-else-if="isUploading">
        <span class="card-footer-item animated flash slow" type="button"><i class="fas fa-sync fa-spin"></i>&nbsp;Moviendo documentos</span>
    </footer>
  </div>
</template>

<script>
import Explorer from '@/components/utils/Explorer'
export default {
  props: ['slugRoot'],
  data () {
    return {
      filesCopy: [],
      rutas: null,
      selected: null,
      explorerLoading: true,
      initRoute: null,
      activeTab: 0,
      // -------
      isUploading: false,
      isDone: false,
      filesSucceded: [],
      filesFailed: [],
      filesErrors: {}
    }
  },
  components: {
    Explorer
  },
  mounted: function () {

  },
  methods: {
    submit () {
      this.filesCopy = this.files
      if (this.filesCopy.length === 0) return
      const dataArr = []
      this.filesCopy.map((f, i) => {
        dataArr.push({
          file_id: f.id,
          index: i
        })
      })
      this.isUploading = true
      this.send(dataArr.reverse(), 0, 0)
    },
    send (dataArr, successCount, errorCount) {
      if (!dataArr.length) {
        this.isUploading = false
        this.isDone = true
        this.$buefy.snackbar.open({
          message: `Se han movido ${successCount} de ${this.filesCopy.length} documentos correctamente`,
          type: successCount === this.filesCopy.length ? 'is-success' : 'is-warning'
        })
        if (successCount === this.filesCopy.length) {
          this.$parent.close()
          this.$router.push('/repositorio/' + this.selected.id)
        }
        this.$emit('refresh')
        return
      }
      const fileToSend = dataArr.pop()
      this.$http.request({
        method: 'post',
        url: `/documento/${fileToSend.file_id}/origen`,
        data: {
          origen_id: this.selected.id
        }
      }).then((response) => {
        successCount++
        this.filesSucceded.push(fileToSend.index)
        this.send(dataArr, successCount, errorCount)
      }).catch((err) => {
        console.error(err)
        errorCount++
        this.filesErrors[fileToSend.index] = err.mensaje
        this.filesFailed.push(fileToSend.index)
        this.send(dataArr, successCount, errorCount)
      })
    }
  },
  computed: {
    // getIcon: function () {
    //   return (this.file.tipo_documento_id === 'carpeta' ? 'fa-folder' : 'fa-file')
    // return 'folder'
    // },
    files () {
      return this.$store.state.repository.selectedDocuments
    },
    pivotFile () {
      return this.files[0]
    },
    organizacion: function () {
      return this.$store.state.organizacion.organizacion
    },
    moverDisabled: function () {
      if (this.pivotFile && this.selected) {
        if (this.pivotFile.id === this.selected.id) {
          return true
        }
        if (this.pivotFile.origen_id === this.selected.id) {
          return true
        }
        return false
      }
      return false
    }
  },
  watch: {
    // selected: function (newVal, oldVal) {
    //   if (newVal) this.query.origen_id = newVal.id
    //   else this.query.origen_id = null
    // }
  }
}
</script>

<style lang="scss" scoped>
.card-content{
  max-height: 70vh;
  overflow-y: scroll;
}
</style>
