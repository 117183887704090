<template>
  <section class="box">
    <i class="fas fa-check-circle fa-lg has-text-success is-pulled-right" v-if="ok"></i>
    <h1 class="subtitle is-5">Mi cuenta</h1>
    <h1 class="title is-1">Cambiar clave</h1>
    <p class=""> Ingrese su clave actual y la que desee utilizar a partir de este momento</p>
      <hr>
    <b-message size="is-small">
    </b-message>
      <div class="notification is-warning" v-if="error">
        {{error}}
      </div>
      <div class="columns">
        <div class="column is-5-desktop is-8-tablet">

          <div class="field">
            <label class="label">Clave actual</label>
          <b-input type="password" v-model="model.clave_actual" placeholder="Su contraseña actual" password-reveal></b-input>
          </div>
          <div class="field">
            <label class="label">Clave Nueva</label>
          <b-input type="password" v-model="model.clave_nueva" placeholder="Su nueva contraseña" password-reveal></b-input>
          </div>
          <div class="field">
            <label class="label">Repita la nueva clave</label>
            <b-input type="password" v-model="model.clave_control" placeholder="Reescriba su nueva conraseña" password-reveal></b-input>
          </div>

        </div>
      </div>
      <hr>
      <div class="buttons is-right">
        <button @click.prevent="submit()" class="button is-primary">
         Guardar</button>
      </div>
  </section>
</template>

<script>

export default {
  data () {
    return {
      model: {
        clave_actual: '',
        clave_nueva: '',
        clave_control: ''
      },
      error: '',
      ok: false
    }
  },
  methods: {
    submit () {
      this.ok = false
      this.error = ''
      this.startLoading()
      const payload = {}
      for (const prop in this.model) {
        // eslint-disable-next-line no-prototype-builtins
        if (this.model.hasOwnProperty(prop)) {
          if (this.model[prop]) {
            payload[prop] = this.model[prop]
          }
        }
      }
      this.$http
        .put(`/usuario/${this.user.id}/clave`, payload)
        .then(response => {
          this.ok = true
          this.$buefy.snackbar.open({
            message: 'Clave cambiada con éxito',
            type: 'is-success'
          })
        })
        .catch(error => {
          console.log(error)
          this.error = error.mensaje
          this.$buefy.snackbar.open({
            message: 'Error al cambiar la clave',
            type: 'is-danger'
          })
        }).finally(() => {
          this.stopLoading()
        })
    }
  },
  computed: {
    user: function () {
      return this.$store.state.profile.user
    }
  }
}
</script>
