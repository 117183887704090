<template>
  <b-modal :active.sync="showInstaller" :can-cancel="false" scroll="keep" @close="cancelar">
    <div class="card">
      <div class="card-content">
        <h1 class="title is-4">
          <i class="fas fa-cube"></i>&nbsp;Instalación: Administración</h1>
        <h1 class="subtitle is-6">Configuración de parametros</h1>
         <div class="field">
          <div class="notification is-primary">
            <i class="fas fa-info-circle fa-fw"></i> Recuerde asignar los roles <span class="tag is-white is-rounded">Administrador</span> a los usuarios.
          </div>
        </div>
        <div class="field is-clearfix">
          <div class="buttons is-pulled-right">
            <button @click="cancelar" class="button is-dark is-outlined">
              Cancelar
            </button>
            <button @click="instalar" :disabled="errors.count() > 0" class="button is-primary">
              Instalar módulo
            </button>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  props: ['showInstaller', 'idOrganization', 'idModulo'],
  data () {
    return {
      isLoading: false
    }
  },
  created: function () {
    this.isLoading = false
  },
  methods: {
    cancelar: function () {
      this.$emit('cancelarInstalar')
    },
    instalar: function () {
      this.isLoading = true
      this.$http
        .request({
          method: 'post',
          url: '/organizacion/' + this.idOrganization + '/modulo/' + this.idModulo
        })
        .then(response => {
          this.$buefy.snackbar.open({
            message: 'El modulo ha sido instalado con éxito',
            duration: 8000
          })
          this.isLoading = false
          this.$emit('instalado')
        })
        .catch(error => {
          this.errorMessage = error.message
          this.$buefy.snackbar.open({
            message: 'Error al instalar el modulo',
            type: 'is-warning',
            actionText: 'Cerrar',
            duration: 8000
          })
          this.isLoading = false
        })
    }
  }
}
</script>
