<template>
  <b-modal  v-model="mutableShowModal"
            scroll="keep"
            auto-focus
            trap-focus
            :destroy-on-hide="false"
            @close="closeModal"
            @after-enter="focusUsersInput()"
            @after-leave="clearAttributes()">
    <div class="card">
      <div class="card-content">
        <h1 class="title is-4"> Subir documentos a uno o más empleados</h1>
        <h1 class="subtitle is-6">Seleccionar por usuario, área o toda la organización</h1>
        <section class="box">
          <div class="card-body mb-5">
            <div class="field">
              <b-tooltip :active="!this.isUsersEnabled" multilined size="is-large" :label=Constants.STRINGS.TOOLTIPS.MODAL_UPLOAD_OTRO_DOCUMENTO.USERS_DISABLED position="is-right">
                <label :class="this.isUsersEnabled ? 'label' : 'disabled'">
                  <i class="fas fa-users fa-fw"></i>&nbsp;Seleccionar usuarios
                </label>
              </b-tooltip>
              <div class="control">
                <input :disabled="!this.isUsersEnabled" class="input" ref="inputUser" v-model="userInputForUsers" type="text" placeholder="Escriba el nombre o apellido del usuario">
                <span class="help">Sólo se pueden seleccionar usuarios con rol Usuario de Notificaciones</span>
              </div>
            </div>
            <div class="content">
              <p>{{statusUsers}}</p>
              <table class="table is-bordered is-narrow is-marginless" v-show="searchResultUsers.length > 0">
                <tbody>
                  <tr v-for="result in searchResultUsers.slice(0, FRONT_LIMIT)" :key="result.index">
                    <td>
                      <i class="fas fa-user"></i>&nbsp;
                      <a :class="isUserAlreadySelected(result) ? 'disabled' : ''" @click="selectUser(result)">{{result.nombre}} {{result.apellido}}</a>
                      <span class="disabled" v-if="tieneRolLyris">organizacion_id: {{result.organizacion_id}}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="card-body">
            <label v-if="this.isUsersEnabled" class="label">{{ this.selectedUsersText }}</label>
            <b-field grouped group-multiline class="is-flex is-justify-content-start">
                <b-tag v-for="user in this.selectedUsers" :key="user.id"
                  class="m-0 mr-2"
                  type="is-primary"
                  icon="fas fa-user"
                  attached
                  closable
                  @close="removeUser(user)">
                  {{ user.apellido.toUpperCase() }}, {{ user.nombre }}
                </b-tag>
            </b-field>
          </div>
        </section>
        <section class="box">
          <div class="card-body mb-5">
            <div class="field">
              <b-tooltip :active="!this.isAreasEnabled" multilined size="is-large" :label=Constants.STRINGS.TOOLTIPS.MODAL_UPLOAD_OTRO_DOCUMENTO.AREAS_DISABLED position="is-right">
                <label :class="this.isAreasEnabled ? 'label' : 'disabled'">
                  <i class="fas fa-tags fa-fw"></i>&nbsp;Seleccionar áreas
                </label>
              </b-tooltip>
              <div class="control">
                <input :disabled="!this.isAreasEnabled" class="input" ref="inputArea" v-model="userInputForAreas" type="text" placeholder="Escriba el nombre del área">
              </div>
              </div>
            <div class="content">
              <p>{{statusAreas}}</p>
              <table class="table is-narrow is-marginless" v-show="searchResultAreas.length > 0">
                <tbody>
                  <tr v-for="result in searchResultAreas.slice(0, FRONT_LIMIT)" :key="result.index">
                    <td>
                      <i class="fas fa-tags"></i>&nbsp;
                      <a :class="isAreaAlreadySelected(result) ? 'disabled' : ''" @click="selectArea(result)">{{ result.id }} - {{ result.nombre }}</a>
                      <span class="disabled" v-if="tieneRolLyris">organizacion_id: {{result.organizacion_id}}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="card-body">
            <label v-if="this.isAreasEnabled" class="label">{{ this.selectedAreasText }}</label>
            <b-field grouped group-multiline class="is-flex is-justify-content-start">
              <b-tag v-for="area in this.selectedAreas" :key="area.id"
                class="m-0 mr-2"
                type="is-link"
                icon="fas fa-tag"
                attached
                closable
                @close="removeArea(area)">
                {{ area.id }} - {{ area.nombre.toUpperCase() }}
              </b-tag>
            </b-field>
          </div>
        </section>
        <section class="box">
          <div class="card-body">
            <div class="field">
              <div class="control">
                <b-field>
                  <b-tooltip :active="!this.isAllOrganizationEnabled" multilined size="is-large" :label=Constants.STRINGS.TOOLTIPS.MODAL_UPLOAD_OTRO_DOCUMENTO.ALL_ORGANIZATION_DISABLED>
                    <b-switch :disabled="!this.isAllOrganizationEnabled" v-model="allOrganization" type="is-danger" :left-label="true">
                      <label :class="this.isAllOrganizationEnabled ? 'label' : 'disabled'">
                        <i class="fas fa-building fa-fw"></i>&nbsp;Seleccionar todos los usuarios de la organización
                      </label>
                    </b-switch>
                  </b-tooltip>
                </b-field>
              </div>
            </div>
          </div>
        </section>
        <div class="card-body mt-5 is-flex is-align-items-center">
          <b-tooltip multilined size="is-large" :label="this.getSignRequiredTooltipText">
            <b-checkbox v-model="isSignRequired" :disabled="allOrganization">
                <span>Los documentos requieren ser firmados</span>
            </b-checkbox>
          </b-tooltip>
          <div v-if="isSignRequired && !this.isSimpleCase" class="ml-6 is-flex is-flex-direction-column">
            <b-radio v-model="isGlobalDocument"
                class="mb-2"
                name="is_global_document"
                native-value="true">
                Crear un único documento para todos los destinatarios (global)
            </b-radio>
            <b-radio v-model="isGlobalDocument"
                name="is_not_global_document"
                native-value="false">
                Crear un documento por cada usuario destinatario
            </b-radio>
          </div>
        </div>
        <div class="field is-clearfix">
          <div class="buttons is-pulled-right">
            <b-button @click="closeModal()" class="button is-outlined is-dark m-1">Cancelar</b-button>
            <b-button @click="submit()" :disabled="this.disableConfirmButton" class="button is-primary m-1">Continuar</b-button>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { debounce } from 'lodash'
import Constants from '../../constants'

export default {
  props: ['showModal'],
  data () {
    return {
      Constants: Constants,
      FRONT_LIMIT: 10,
      mutableShowModal: false,
      MAX_USERS: 10,
      MAX_AREAS: 10,
      allOrganization: false,
      isGlobalDocument: null,
      isSignRequired: false,
      searchResultAreas: [],
      searchResultUsers: [],
      selectedAreas: [],
      selectedAreasText: 'No ha seleccionado ningún área',
      selectedUsers: [],
      selectedUsersText: 'No ha seleccionado ningún usuario',
      statusUsers: '',
      statusAreas: '',
      userInputForAreas: '',
      userInputForUsers: ''
    }
  },
  methods: {
    cleanAreaSection: function () {
      this.searchResultAreas = []
      this.selectedAreas = []
      this.selectedAreasText = this.updateAreasText
      this.statusAreas = ''
      this.userInputForAreas = ''
    },
    cleanUserSection: function () {
      this.searchResultUsers = []
      this.selectedUsers = []
      this.selectedUsersText = this.updateUsersText
      this.statusUsers = ''
      this.userInputForUsers = ''
    },
    clearAttributes: function () {
      this.allOrganization = false
      this.isGlobalDocument = null
      this.isSignRequired = false
      this.searchResultAreas = []
      this.searchResultUsers = []
      this.selectedAreas = []
      this.selectedAreasText = this.updateAreasText
      this.selectedUsers = []
      this.selectedUsersText = this.updateUsersText
      this.statusUsers = ''
      this.statusAreas = ''
      this.userInputForAreas = ''
      this.userInputForUsers = ''
    },
    closeModal: function () {
      this.$emit('close-modal')
    },
    focusUsersInput () {
      setTimeout(() => { this.$refs.inputUser.focus() }, 200)
    },
    selectUser: function (user) {
      if (this.selectedUsers.length < this.MAX_USERS && !this.selectedUsers.find(u => u.id === user.id)) {
        this.selectedUsers.unshift(user)
      }
      this.selectedUsersText = this.updateUsersText
      this.cleanAreaSection()
    },
    removeUser: function (user) {
      this.selectedUsers = this.selectedUsers.filter(u => u.id !== user.id)
      this.selectedUsersText = this.updateUsersText
    },
    selectArea: function (area) {
      if (this.selectedAreas.length < this.MAX_AREAS && !this.selectedAreas.find(a => a.id === area.id)) {
        this.selectedAreas.unshift(area)
      }
      this.selectedAreasText = this.updateAreasText
      this.cleanUserSection()
    },
    removeArea: function (area) {
      this.selectedAreas = this.selectedAreas.filter(a => a.id !== area.id)
      this.selectedAreasText = this.updateAreasText
    },
    getUsers: debounce(
      function () {
        this.statusUsers = 'Buscando...'
        this.$http
          .request({
            method: 'get',
            url:
              '/organizacion/' +
              this.$store.getters.getUser.organization +
              '/usuario?s=' +
              this.userInputForUsers +
              '&rol=otros-user&acceso_habilitado=true'
          })
          .then(response => {
            this.searchResultUsers = response.data.data
            this.statusUsers = this.searchResultUsers.length === 0 ? 'No se encontraron usuarios' : ''
          })
          .catch(error => {
            console.error('Fail', error)
            this.statusUsers = 'Error al solicitar el pedido al servidor...'
          })
      },
      // This is the number of milliseconds we wait for the user to stop typing.
      500
    ),
    getAreas: debounce(
      function () {
        this.statusAreas = 'Buscando...'
        this.$http
          .request({
            method: 'get',
            url:
              '/organizacion/' +
              this.$store.getters.getUser.organization +
              '/area?s=' +
              this.userInputForAreas
          })
          .then(response => {
            this.searchResultAreas = response.data.data
            this.statusAreas = this.searchResultAreas.length === 0 ? 'No se encontraron áreas' : ''
          })
          .catch(error => {
            console.error('Fail', error)
            this.statusAreas = 'Error al solicitar el pedido al servidor...'
          })
      },
      // This is the number of milliseconds we wait for the user to stop typing.
      500
    ),
    openWarningSnackBar (text) {
      this.$buefy.snackbar.open({
        message: text,
        type: 'is-danger'
      })
    },
    submit: function () {
      if (!this.selectedAtLeastOne) {
        this.openWarningSnackBar(Constants.STRINGS.WARNINGS.NO_RECEIVER_SELECTED)
        return
      }
      if (!this.isSimpleCase) {
        if (this.selectedMultipleScope()) {
          this.openWarningSnackBar(Constants.STRINGS.WARNINGS.MULTIPLE_SCOPE_TYPES_SELECTED)
          return
        }
        if (this.isSignRequired && this.isGlobalDocument === null) {
          this.openWarningSnackBar(Constants.STRINGS.WARNINGS.SIGN_CANT_BE_REQUIRED_WITHOUT_GLOBAL_DOCUMENT_FLAG)
          return
        }
        if (this.allOrganization && (this.isSignRequired || this.isGlobalDocument)) {
          this.openWarningSnackBar(Constants.STRINGS.WARNINGS.ALL_ORGANIZATION_CANT_ENABLE_IF_SIGN_IS_REQUIRED)
          return
        }
      }

      this.$emit('launch-firmador', this.firmadorObjectParams())
      this.closeModal()
    },
    firmadorObjectParams: function () {
      return {
        areas: this.selectedAreasIds,
        esDocumentoGlobal: (this.isGlobalDocument === 'true'),
        organizacionCompleta: this.allOrganization,
        requiereFirma: this.isSignRequired,
        usuarios: this.selectedUsersIds
      }
    },
    isAreaAlreadySelected: function (area) {
      return (this.selectedAreas && this.selectedAreas.find(a => a.id === area.id))
    },
    isUserAlreadySelected: function (user) {
      return (this.selectedUsers && this.selectedUsers.find(u => u.id === user.id))
    },
    selectedMultipleScope: function () {
      return (this.selectedAtLeastOneUser && this.selectedAtLeastOneArea) ||
        (this.selectedAtLeastOneUser && this.allOrganization) ||
        (this.selectedAtLeastOneArea && this.allOrganization)
    },
    confirmCustomDelete: function () {
      this.$buefy.dialog.confirm({
        title: Constants.STRINGS.DIALOGS.SELECT_ALL_ORGANIZATION.TITLE,
        message: Constants.STRINGS.DIALOGS.SELECT_ALL_ORGANIZATION.MESSAGE,
        confirmText: Constants.STRINGS.DIALOGS.SELECT_ALL_ORGANIZATION.CONFIRM_BUTTON,
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.cleanAreaSection()
          this.cleanUserSection()
          this.isSignRequired = false
        },
        onCancel: () => {
          this.allOrganization = false
        }
      })
    }
  },
  computed: {
    isAreasEnabled: function () {
      return (!this.selectedAtLeastOneUser && !this.allOrganization)
    },
    isAllOrganizationEnabled: function () {
      return (!this.selectedAtLeastOneUser && !this.selectedAtLeastOneArea)
    },
    isUsersEnabled: function () {
      return (!this.selectedAtLeastOneArea && !this.allOrganization)
    },
    isSimpleCase: function () {
      return (this.selectedUsers.length === 1 && !this.selectedAtLeastOneArea && !this.allOrganization)
    },
    selectedAtLeastOneUser: function () {
      return (this.selectedUsers.length > 0)
    },
    selectedAtLeastOneArea: function () {
      return (this.selectedAreas.length > 0)
    },
    selectedUsersIds: function () {
      return this.selectedUsers.map(user => user.id)
    },
    selectedAreasIds: function () {
      return this.selectedAreas.map(area => area.id)
    },
    selectedAtLeastOne: function () {
      return (this.selectedAtLeastOneUser || this.selectedAtLeastOneArea || this.allOrganization)
    },
    tieneRolLyris: function () {
      return this.$store.getters.hasAnyRole(['lyris'])
    },
    updateUsersText: function () {
      if (!this.selectedAtLeastOneUser) return 'No ha seleccionado ningún usuario'
      if (this.selectedUsers.length === 1) return 'Usuario seleccionado'
      return `Usuarios seleccionados (${this.selectedUsers.length}/${this.MAX_USERS})`
    },
    updateAreasText: function () {
      if (!this.selectedAtLeastOneArea) return 'No ha seleccionado ningún área'
      if (this.selectedAreas.length === 1) return 'Área seleccionada'
      return `Áreas seleccionadas (${this.selectedAreas.length}/${this.MAX_AREAS})`
    },
    getSignRequiredTooltipText: function () {
      return this.allOrganization ? Constants.STRINGS.TOOLTIPS.MODAL_UPLOAD_OTRO_DOCUMENTO.ALL_ORGANIZATION_CANT_ENABLE_IF_SIGN_IS_REQUIRED : Constants.STRINGS.TOOLTIPS.MODAL_UPLOAD_OTRO_DOCUMENTO.IS_SIGN_REQUIRED
    },
    disableConfirmButton: function () {
      return !this.selectedAtLeastOne || (!this.isSimpleCase && ((this.isSignRequired && this.isGlobalDocument === null) || (this.allOrganization && (this.isSignRequired || this.isGlobalDocument))))
    }
  },
  watch: {
    allOrganization: function (newVal) {
      if (newVal) {
        this.confirmCustomDelete()
      }
    },
    isSignRequired: function (newVal) {
      if (!newVal) {
        this.isGlobalDocument = null
      }
    },
    showModal: function (newVal) {
      this.mutableShowModal = newVal
    },
    userInputForAreas: function (newUserInput) {
      this.statusAreas = 'Tipeando...'
      this.searchResultAreas = []
      if (newUserInput.length === 0) this.statusAreas = ''
      else if (newUserInput.trim().length >= 3) this.getAreas()
      else this.statusAreas = 'Por favor, escriba más caracteres para la búsqueda de áreas.'
    },
    userInputForUsers: function (newUserInput) {
      this.statusUsers = 'Tipeando...'
      this.searchResultUsers = []
      if (newUserInput.length === 0) this.statusUsers = ''
      else if (newUserInput.trim().length >= 3) this.getUsers()
      else this.statusUsers = 'Por favor, escriba más caracteres para la búsqueda de usuarios.'
    }
  }
}
</script>

<style lang="scss" scoped>
.disabled {
  cursor: not-allowed;
  color: #808080;
}

.box {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}
</style>
