<template>
  <div class="box">
    <h1 class="subtitle is-5">Administrar organización</h1>
      <h1 class="title is-1">Nuevo aspecto</h1>
      <hr>
    <aspectos-form @submit="submit"></aspectos-form>
  </div>
</template>

<script>
import AspectosForm from '@/components/globals/aspectos/Form'

export default {
  props: ['idOrganization'],
  components: {
    AspectosForm
  },
  methods: {
    submit: function (payload) {
      this.startLoading()
      this.$http
        .post(`/organizacion/${this.idOrganization}/aspecto`, payload)
        .then(result => {
          this.stopLoading()
          this.$buefy.snackbar.open({
            message: 'El aspecto ha sido creado exitosamente',
            type: 'is-success'
          })
          this.$router.push({ name: 'organizacionAspectos', params: { idOrganization: this.idOrganization } })
        })
        .catch(err => {
          this.stopLoading()
          console.error(err)
          this.$buefy.snackbar.open({
            message: 'El servidor respondió con un error.',
            type: 'is-danger'
          })
        })
    }
  }
}
</script>

<style>
</style>
