<template>
  <section class="box" v-if="$store.getters.hasAnyRole(['aspec-admin','lyris'])">
      <h1 class="title is-5">Aspectos iniciales</h1>
    <!-- ===================== -->
      <div class="content">
        <p>Puede eliminarlos de la serie haciendo clic en <i class="fas fa-times-circle"></i></p>
      </div>
    <div class="field">
      <p v-if="aspectosSerie.length === 0" class="is-italic is-mono">Esta serie no tiene aspectos iniciales</p>
      <div class="tags" v-else>
        <p class="tag is-info is-medium" v-for="aspecto in aspectosSerie" :key="aspecto.id">
          {{aspecto.nombre}}
          <button
            @click="openModalDeleteAspecto(aspecto)"
            class="delete is-small"
          ></button>
        </p>
      </div>
    </div>
    <hr>
     <h1 class="subtitle is-5">Agregar aspectos iniciales</h1>
    <div class="field is-grouped" v-if="$store.getters.hasAnyRole(['aspec-admin','lyris'])">
      <div class="control is-expanded">
        <div class="select is-fullwidth">
          <select
            v-model="aspectoNuevo"
            name="aspecto-nuevo"
            v-validate="{required:true}"
            data-vv-scope="aspecto-serie"
            :class="{'is-danger': errors.has('aspecto-nuevo','aspecto-serie') }"
          >
            <option :value="null" disabled>- Seleccione un aspecto -</option>
            <option
              :value="aspecto"
              v-for="(aspecto, index) in aspectosDisponibles"
              :key="index"
            >{{aspecto.nombre}}</option>
          </select>
        </div>
      </div>
      <p class="control">
        <button @click="openModalAddAspecto()" :disabled="errors.has('aspecto-nuevo','aspecto-serie') || this.aspectoNuevo == null" class="button is-primary">
          <i class="fas fa-plus"></i>&nbsp;Agregar
        </button>
      </p>
    </div>
  </section>
</template>

<script>
import validatorUtils from '@/mixins/validatorUtils'

export default {
  // props: {
  //   serieDocumental: {
  //     type: Object,
  //     default: null
  //   }
  // },
  mixins: [validatorUtils],
  data () {
    return {
      aspectoNuevo: null
      // model: {
      // nombre: '',
      // descripcion: '',
      // aspectosSeleccionados: [],
      // },
      // fetchingAspectos: false,
      // aspectos: []
    }
  },
  // beforeMount: function () {
  //   this.syncData()
  // },
  // mounted: function () {
  //   this.fetchAspectos()
  // },
  methods: {
    // syncData: function () {
    //   this.model.nombre = this.serieDocumental.nombre
    //   this.model.descripcion = this.serieDocumental.descripcion
    //   // this.model.aspectosSeleccionados = this.serieDocumental.aspectos
    // },
    // fetchAspectos: function () {
    //   this.fetchingAspectos = true
    //   Promise.all([
    //     this.$http.get(`/organizacion/${this.idOrganizacion}/aspecto`),
    //     this.$http.get('/aspecto')
    //   ]).then(responses => {
    //     this.aspectos = responses[0].data.concat(responses[1].data)
    //     // console.log(this.serieDocumental.aspectos)
    //     this.model.aspectosSeleccionados = Object.values(this.serieDocumental.aspectos).map(k => {
    //       return this.aspectos.find(a => a.id === k)
    //     })
    //     this.fetchingAspectos = false
    //   }).catch(err => {
    //     console.error(err)
    //     this.$buefy.snackbar.open({
    //       message: 'El estado a ingresar es invalido',
    //       type: 'is-danger'
    //     })
    //   })
    // },
    openModalDeleteAspecto: function (aspecto) {
      if (this.idsAspectosNoEliminables.includes(aspecto.id)) {
        this.$buefy.dialog.confirm({
          title: 'Eliminando aspecto',
          message: `El aspecto <b>${aspecto.nombre}</b> no se puede eliminar.<br>Es parte de una acción de un evento de la serie.`,
          // confirmText: 'Eliminar',
          // cancelText: 'Cancelar',
          canCancel: false,
          type: 'is-primary',
          icon: 'info',
          hasIcon: true
          // onConfirm: () => this.$emit('delete-aspecto', aspecto)
        })
        return
      }
      this.$buefy.dialog.confirm({
        title: 'Eliminando aspecto',
        message: `¿Esta seguro que quiere <b>eliminar</b> el aspecto <b>${aspecto.nombre}</b> de la serie documental?.`,
        confirmText: 'Eliminar',
        cancelText: 'Cancelar',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => this.$emit('deleteAspecto', aspecto)
      })
    },
    openModalAddAspecto: function () {
      this.$buefy.dialog.confirm({
        title: 'Agregar aspecto a serie documental',
        message: `¿Esta seguro que quiere <b>agregar</b> el aspecto <b>${this.aspectoNuevo.nombre}</b> a la serie documental?`,
        confirmText: 'Agregar',
        cancelText: 'Cancelar',
        type: 'is-primary',
        icon: 'question',
        hasIcon: true,
        onConfirm: () => {
          this.$emit('addAspecto', this.aspectoNuevo)
          this.aspectoNuevo = null
        }
      })
    }

  },
  computed: {
    serieDocumental: function () {
      return this.$store.state.serieDocumental.serieDocumental
    },
    idOrganizacion: function () {
      return this.$store.state.organizacion.organizacion.id
    },
    idsAspectosNoEliminables: function () {
      if (!this.serieDocumental) return []
      const arr = []
      this.serieDocumental.eventos.forEach(e => {
        e.acciones.forEach(a => {
          if (a.documento_operacion_id === 'cargarAspectoCompuesta') {
            if (!arr.includes(a.parametros.aspecto_id)) arr.push(a.parametros.aspecto_id)
          }
        })
      })
      return arr
    },
    aspectos: function () {
      return this.$store.getters['organizacion/allAspectos']
    },
    aspectosSerie: function () {
      if (!this.aspectos) return []
      return this.aspectos.filter((a) =>
        Object.values(this.serieDocumental.aspectos).includes(a.id)
      )
    },
    aspectosDisponibles: function () {
      if (!this.serieDocumental) return []
      // if (!this.model) return []
      if (!this.aspectos) return []
      if (!this.aspectosSerie) return []
      // console.log(this.model.aspectosSeleccionados)
      const idsAspectosSerie = this.aspectosSerie.map(a => a.id)
      const arr = this.aspectos.filter(a => !idsAspectosSerie.includes(a.id))
      return arr
    }
  }
}
</script>

<style lang="scss" scoped>
.table-bordered {
  padding: 1em;
  border: 1px solid #cacaca;
  border-radius: 0.3em;
  table.table {
    margin-bottom: 0;
  }
}
</style>
