<template>
  <div v-if="!isLoading">

  <div class="select is-small is-fullwidth">
    <select v-model="serieDocumentalSeleccionado">
      <option :value="null">- Desactivado -</option>
      <option :value="serie" v-for="serie in seriesDoc" :key="serie.id">{{serie.nombre}}</option>
    </select>
  </div>
  <div v-if="estados">
    <br>

  <div class="select is-small is-fullwidth">
    <select v-model="estadoSeleccionado">
      <option :value="null">- Cualquier estado -</option>
      <option :value="key" v-for="(e,key) in estados" :key="key">{{e}}</option>
    </select>
  </div>
  </div>
  </div>
  <div v-else>
    <div class="select is-small is-fullwidth">
      <select v-model="serieDocumentalSeleccionado">
        <option :value="null">Cargando...</option>
      </select>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      isLoading: false,
      seriesDoc: [],
      serieDocumentalSeleccionado: null,
      estadoSeleccionado: null
    }
  },
  mounted: function () {
    this.getSeries()
  },
  methods: {
    getSeries: function () {
      this.isLoading = true
      this.$http.get(`/organizacion/${this.organizacion}/serie-documental`)
        .then(res => {
          this.seriesDoc = res.data
        })
        .catch(err => {
          console.error(err)
          this.$buefy.snackbar.open({
            message: 'Error al obtener listado de series documentales',
            type: 'is-warning'
          })
        })
        .finally(() => {
          this.isLoading = false
        })
    }
  },
  computed: {
    organizacion: function () {
      return this.$store.getters.getUser.organization
    },
    estados: function () {
      if (!this.serieDocumentalSeleccionado) return null
      return this.serieDocumentalSeleccionado.estados
    }
  },
  watch: {
    serieDocumentalSeleccionado: function (newVal, oldVal) {
      this.estadoSeleccionado = null
      this.$emit('setEstadoSerie', null)
      this.$emit('setSerie', newVal)
    },
    estadoSeleccionado: function (newVal, oldVal) {
      this.$emit('setEstadoSerie', newVal)
    }
  }
}
</script>

<style>
</style>
