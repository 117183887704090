<template>
   <div class="card">
     <header class="card-header">
        <p class="card-header-title">Mover documento</p>
    </header>
    <div class="card-content">
      <p>Elija la nueva ubicacion del documento <span class="is-size-6 has-text-info"><i class="fas" :class="getIcon"></i>&nbsp;{{file.nombre}}</span></p>
      <br>
      <explorer :initFolder="file.origen_id || slugRoot" :slug="slugRoot"  ref="listado" :rutas.sync="rutas" :selected.sync="selected"></explorer>
      <br>
      <p class="has-text-left"><b>Nueva ubicación:</b>&nbsp;&nbsp;&nbsp;&nbsp;{{selected && selected.ruta || '/'}}</p>
      <br>
      <div class="notification is-warning is-size-7" v-if="(this.file && this.selected && this.file.id == this.selected.id)">
        <i class="fas fa-exclamation-triangle"></i>&nbsp;Esta intentando mover una carpeta a si misma. Elija otra ubicación para mover la carpeta
      </div>
      <div class="notification is-warning is-size-7" v-if="(this.file && this.selected && this.file.origen_id == this.selected.id)">
        <i class="fas fa-exclamation-triangle"></i>&nbsp;La carpeta ya se encuentra en esta ubicacion
      </div>
    </div>
      <footer class="card-footer">
            <a class="card-footer-item" type="button" @click="$parent.close()">Cancelar</a>
        <a class="card-footer-item has-text-weight-bold is-uppercase" v-if="!moverDisabled" @click.prevent="moverDocumento">Mover</a>
      </footer>
  </div>
</template>

<script>
import Explorer from '@/components/utils/Explorer'
export default {
  props: ['slugRoot'],
  data () {
    return {
      rutas: null,
      selected: null,
      explorerLoading: true,
      initRoute: null
    }
  },
  components: {
    Explorer
  },
  mounted: function () {

  },
  methods: {
    moverDocumento: function () {
      if (this.file && this.selected && this.file.id === this.selected.id) {
        this.$buefy.snackbar.open({
          message: 'No puede mover una carpeta dentro de si misma',
          type: 'is-danger'
        })
      }
      if (this.file && this.selected && this.file.origen_id === this.selected.id) {
        this.$buefy.snackbar.open({
          message: 'La carpeta ya se encuentra en esta ubicación',
          type: 'is-danger'
        })
      }
      this.$http
        .post(`/documento/${this.file.id}/origen`, {
          origen_id: this.selected.id
        })
        .then(response => {
          this.$buefy.snackbar.open({
            message: 'El archivo ha sido movido exitosamente.',
            type: 'is-success'
          })
          this.$emit('refresh')
          this.$parent.close()
        })
        .catch(error => {
          console.error('Fail', error)
          this.$buefy.snackbar.open({
            message: 'Error inesperado.',
            type: 'is-danger'
          })
        })
    }
  },
  computed: {
    getIcon: function () {
      if (!this.file) return 'fa-file'
      return (this.file.tipo_documento_id === 'carpeta' ? 'fa-folder' : 'fa-file')
    },
    organizacion: function () {
      return this.$store.state.organizacion.organizacion
    },
    moverDisabled: function () {
      if (this.file && this.selected) {
        if (this.file.id === this.selected.id) {
          return true
        }
        if (this.file.origen_id === this.selected.id) {
          return true
        }
        return false
      }
      return false
    },
    file () {
      return this.$store.state.repository.selectedDocument
    }
  },
  watch: {

  }
}
</script>

<style lang="scss" scoped>

</style>
