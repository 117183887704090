<template>
  <div class="card folders-container">
    <div class="card-content px-0 pt-2 pb-3">
      <div class="row-folder-header is-clearfix">
          <a class="is-size-7 is-pulled-left" @click="toPreviousFolder"><i class="fas fa-reply fa-fw"></i>&nbsp;Volver atrás</a>
          <a class="is-size-7 is-pulled-right" @click="showDialogNewFolder"><i class="fas fa-folder-plus fa-fw"></i>&nbsp;Nueva carpeta</a>
      </div>
      <div class="folder-rows-container">

      <div class="row-folder"
      v-for="(route, index) in routes"
      :key="`folder-route-${index}`"
      @click.exact="selectPreviousFolder(index)"
      @dblclick="dobleClickPreviousFolders(routes.slice(0,index+1).join('/'))"
      :class="{'selected': selectedFolder != null && selectedFolder.nombre == currentFolder.nombre && index == routes.length-1}"
      :style="{paddingLeft: `${20 + (12*index)}px`}">
        <i class="far fa-fw fa-lg" :class="{'fa-folder-open': index > 0, 'fa-building': index == 0}"></i>&nbsp;&nbsp;<b>{{route}}</b>
        <!-- <i class="far fa-fw fa-lg fa-folder-open"></i>&nbsp;&nbsp;<b>{{getCurrentFolderReadableName(currentFolder)}}</b> -->
      </div>
      <div
        v-for="(folder,indexFolder) in folders"
        :key="`folder-${indexFolder}-${folder.id}`"
        @click.exact="selectOneFolder(folder, indexFolder)"
        @dblclick="dobleClick(folder)"
        :class="{'selected': selectedFolder != null && folder.id == selectedFolder.id}"
        class="row-folder"
        :style="{paddingLeft: `${20 + (12* routes.length )}px`}"
        >
        <i class="fa-fw fa-lg" :class="getIconFile(folder.tipo_documento_id)"></i>&nbsp;&nbsp;{{folder.nombre}}

      </div>
      <infinite-loading @infinite="infiniteHandlerFolders" :identifier="identifierLoaderFolders">
        <span slot="no-more"></span>
        <span slot="no-results"></span>
      </infinite-loading>
      </div>
    </div>
  </div>
</template>

<script>
import InfiniteLoading from 'vue-infinite-loading'

export default {
  props: ['idFolder'],
  components: {
    InfiniteLoading
  },
  data () {
    return {
      currentFolderId: this.idFolder,
      loadingFolders: true,
      folders: [],
      paginatorFolders: {
        current_page: 1,
        last_page: null,
        next_page_url: null,
        prev_page_url: null
      },
      identifierLoaderFolders: Math.random(),
      selectedFolder: null,
      routes: [],
      queryFolders: {
        sort: null,
        direction: null,
        carpetas: 1,
        page: 1,
        size: 10
      }
    }
  },
  methods: {
    resetAll () {
      this.selectedFolder = null
      this.folders = []
      this.routes = []
      this.queryFolders.page = 1
      this.identifierLoaderFolders = Math.random()
    },
    selectPreviousFolder (index) {
      if (this.routes.length - 1 === index) {
        this.selectedFolder = this.currentFolder
      }
    },
    selectOneFolder (folder, indexFolder) {
      this.selectedFolder = folder
    },
    updatePaginator (cual, data) {
      const aux = {
        current_page: data.current_page,
        last_page: data.last_page,
        next_page_url: data.next_page_url,
        prev_page_url: data.prev_page_url
      }
      switch (cual) {
        case 'folders':
          this.paginatorFolders = Object.assign({}, this.paginatorFolders, aux)
          break
        case 'documents':
          this.paginatorDocuments = Object.assign({}, this.paginatorDocuments, aux)
          break
      }
    },
    infiniteHandlerFolders ($state) {
      this.$http.get(this.urlFolders, { params: this.queryFolders })
        .then(({ data }) => {
          if (data.current_page === data.last_page) {
            const arrayFolders = this.prepareFolders(data.data)
            this.folders.push(...arrayFolders)
            $state.complete()
          }
          if (data.current_page < data.last_page) {
            this.queryFolders.page += 1
            const arrayFolders = this.prepareFolders(data.data)
            this.folders.push(...arrayFolders)
            $state.loaded()
          }
        })
    },
    dobleClick (document) {
      this.currentFolderId = document.id
      this.resetAll()
    },
    dobleClickPreviousFolders (route) {
      this.currentFolderId = route
      this.resetAll()
    },
    toPreviousFolder () {
      this.currentFolderId = this.currentFolder.origen_id
      this.resetAll()
    },
    showDialogNewFolder () {
      this.$buefy.dialog.prompt({
        title: 'Nueva carpeta',
        message: 'Ingrese el nuevo nombre de la carpeta',
        inputAttrs: { placeholder: 'Escriba aquí' },
        trapFocus: true,
        onConfirm: (value) => this.newFolder(value)
      })
    },
    newFolder (newFolderName) {
      const data = {
        nombre: newFolderName,
        tipo_documento_id: 'carpeta',
        permisos_heredados: true
      }
      if (this.currentFolderId) {
        data.origen_id = parseInt(this.currentFolderId)
      } else {
        data.origen_id = null
      }
      this.$http
        .request({
          method: 'post',
          url: '/documento',
          data: data
        })
        .then(response => {
          this.$buefy.snackbar.open({
            message: 'Carpeta creada exitosamente.',
            type: 'is-success'
          })
          this.currentFolderId = response.data.documento.id
          this.resetAll()
        })
        .catch(error => {
          console.log(error)
        })
    },
    getCurrentFolderReadableName (currentFolder) {
      if (!this.currentFolder) return '?'
      const arrRoute = this.currentFolder.ruta.split('/')
      return arrRoute.pop()
    },
    getIconFile (tipo) {
      switch (tipo) {
        case 'carpeta':
          return 'far fa-folder'
        case 'recibo':
          return 'fas fa-dollar-sign'
        case 'unidadCompuesta':
          return 'far fa-file-archive'
        default:
          return 'far fa-file'
      }
    },
    prepareFolders (arrayFolders) {
      const anchor = arrayFolders.find(e => e.nombre === '.')
      this.$emit('update:selected', anchor)
      // this.rutas = anchor ? anchor.ruta.split('/') : null;
      this.$emit('update:rutas', anchor ? anchor.ruta.split('/') : null)
      this.routes = anchor ? anchor.ruta.split('/') : null
      this.currentFolder = arrayFolders.find(doc => {
        return doc.nombre === '.'
      })
      return arrayFolders.filter(e => e.nombre !== '.')
    }
  },
  computed: {
    urlFolders () {
      let theUrl = null
      if (!this.currentFolderId) {
        theUrl = '/carpeta'
      } else if (isNaN(parseInt(this.currentFolderId))) {
        theUrl = '/carpeta/' + this.currentFolderId
      } else {
        theUrl = '/carpeta/' + this.currentFolderId
      }
      return theUrl
    }
  },
  watch: {
    selectedFolder: function (newValue, oldValue) {
      this.$emit('update:selected', newValue)
    }
  }
}
</script>

<style lang="scss" scoped>
.row-folder{
  padding: 5px 20px 5px 0px;
  margin: 0px 0;
  background-color: transparent;
  // transition: background-color .2s ease-in;
  white-space: nowrap;
  user-select: none;
  overflow: hidden !important;
  text-overflow: ellipsis;
  &:hover{
    background-color: #f3f3f3;
  }
  &.selected{
    background-color: $primary;
    color: #FFF;
    // transition: background-color 0 ease-in;
  }
}
.row-folder-current{
  padding: 5px 20px;
  margin: 0px 0;
  background-color: transparent;
  // transition: background-color .2s ease-in;
  white-space: nowrap;
  user-select: none;
  overflow: hidden !important;
  text-overflow: ellipsis;
}
.row-folder-header{
  padding: 5px 20px 10px;
  border-bottom: 1px solid #f3f3f3;
  margin-bottom: 6px;
  background-color: transparent;
  user-select: none;
}

.has-text-kinda-dark{
  color: #CACACA;
}

.sidebar-container {
  @media screen and (min-width: $tablet){
    width: 270px;
  }
}
.folders-container{
  box-shadow: none;
  border: 1px solid #f3f3f3;
}
.folder-rows-container{
  max-height: 200px;
  height: 200px;
  overflow-y: scroll;
}
</style>
