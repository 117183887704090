<template>
  <b-modal :active.sync="showInstaller" :can-cancel="false" scroll="keep" @close="cancelar">
    <div class="card">
      <div class="card-content">
        <h1 class="title is-4">
          <i class="fas fa-cube"></i>&nbsp;Instalación: Repositorio</h1>
        <h1 class="subtitle is-6">Configuración de parametros</h1>
         <div class="field">
          <label class="label">Donde se guardarán las carpetas de los usuarios</label>
          <div class="field">
            <b-radio v-model="showExplorer"
                :native-value="false">
                En la carpeta raiz de la organización (<span class="is-mono">/{{organizacion.slug}}</span>)
            </b-radio>
        </div>
        <div class="field">
            <b-radio v-model="showExplorer"
                :native-value="true">
                En una carpeta especifica de la organización
            </b-radio>
        </div>
          <div class="field" v-if="showExplorer">
            <div class="control">
              <a class="button is-static is-fullwidth has-text-left">{{getRuta}}</a>
            </div>
          <span class="help">Elija la carpeta donde se guardarán las carpetas de los usuarios</span>
          <explorer :initFolder="settings.origen_raiz || organizacion.slug" :slug="organizacion.slug" ref="listado" :rutas.sync="rutas" :selected.sync="selected"></explorer>
          </div>
        </div>
        <div class="columns">
          <div class="column is-8">
            <div class="field">
              <label class="label">Patrón carpeta del usuario</label>
              <div class="field">
                <div class="control">
                  <input class="input" type="text" name="patronCarga" v-validate="'required'" :class="{'is-danger': errors.has('patronCarga')}" v-model="settings.patron_carpeta">
                </div>
              </div>
            </div>
          </div>
          <div class="column content is-small">
            <table class="table is-narrow">
              <thead>
                <tr>
                  <th colspan="2">Patrones disponibles</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>#ua</th>
                  <td>Nombre del usuario</td>
                </tr>
                <tr>
                  <th>#uc</th>
                  <td>CUIL del usuario</td>
                </tr>
                <tr>
                  <th>#ui</th>
                  <td>Id del usuario</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="field is-clearfix">
          <div class="buttons is-pulled-right">
            <button @click="cancelar" class="button is-dark is-outlined">
              Cancelar
            </button>
            <button v-if="params" @click="reinstalar" :disabled="errors.count() > 0" class="button is-primary">
              Guardar
            </button>
            <button v-else @click="instalar" :disabled="errors.count() > 0" class="button is-primary">
              Instalar módulo
            </button>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import Explorer from '@/components/utils/Explorer'

export default {
  props: ['showInstaller', 'idModulo', 'params'],
  data () {
    return {
      settings: {
        origen_raiz: null,
        patron_carpeta: '#ua-#uc'
      },
      rutas: null,
      selected: null,
      showExplorer: false,
      isLoading: false
    }
  },
  components: {
    Explorer
  },
  created: function () {
    this.isLoading = false
    if (this.params) {
      this.settings.patron_carpeta = this.params.parametros.patron_raiz
      if (this.params.parametros.origen_raiz) {
        this.showExplorer = true
        this.settings.origen_raiz = this.params.parametros.origen_raiz
      }
    }
  },
  methods: {
    cancelar: function () {
      if (this.params) { this.$emit('cancelarReinstalar') } else { this.$emit('cancelarInstalar') }
    },
    instalar: function () {
      this.isLoading = true
      this.$http
        .request({
          method: 'post',
          url: '/organizacion/' + this.organizacion.id + '/modulo/' + this.idModulo,
          data: this.payload
        })
        .then(response => {
          this.$buefy.snackbar.open({
            message: 'El modulo ha sido instalado con éxito',
            duration: 8000
          })
          this.isLoading = false
          this.$emit('instalado')
        })
        .catch(error => {
          this.errorMessage = error.message
          this.$buefy.snackbar.open({
            message: 'Error al instalar el modulo',
            type: 'is-warning',
            actionText: 'Cerrar',
            duration: 8000
          })
          this.isLoading = false
        })
    },
    reinstalar: function () {
      this.isLoading = true
      this.$http
        .request({
          method: 'post',
          url: '/instalacion/' + this.params.id,
          data: this.payload
        })
        .then(response => {
          this.$buefy.snackbar.open({
            message: 'El modulo ha sido reinstalado con éxito',
            duration: 8000
          })
          this.isLoading = false
          this.$emit('instalado')
        })
        .catch(error => {
          this.errorMessage = error.message
          this.$buefy.snackbar.open({
            message: 'Error al reinstalar el modulo',
            type: 'is-warning',
            actionText: 'Cerrar',
            duration: 8000
          })
          this.isLoading = false
        })
    }
  },
  computed: {
    organizacion: function () {
      return this.$store.state.organizacion.organizacion
    },
    getRuta: function () {
      if (this.selected == null) {
        return this.organizacion.slug
      } else {
        return this.selected.ruta
      }
    },
    getIdRuta: function () {
      if (this.showExplorer) {
        return this.selected != null ? this.selected.id : null
      } else {
        return null
      }
    },
    payload: function () {
      return {
        origen_raiz: this.getIdRuta,
        patron_raiz: this.settings.patron_carpeta
      }
    }
  }
}
</script>
