import store from '@/store'
import router from '@/router'
import OtroDocumento from './OtroDocumento'

const BASE_PATH = '/otro-documento'

const routes = [
  {
    path: BASE_PATH + '/:id',
    component: OtroDocumento,
    name: 'otro-documento',
    props: true,
    meta: {
      requiresAuth: true
    },
    beforeEnter: (to, from, next) => {
      console.info('[ROUTER] First time entering:', to.path)
      Promise.all([
        store.dispatch('otroDocumento/fetchOtroDocumento', to.params.id)
      ])
        .then(() => {
          console.info('[ROUTER] Data fetch success. Continue to route')
          next()
        })
        .catch(error => {
          console.error('[ROUTER] Data fetch failed', error)
          next()
          router.push({ name: '404' })
        })
    }
  }
]
export default routes
