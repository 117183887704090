<template>
  <section>
    <div class="box">
    <h1 class="subtitle is-5">Administrar organización</h1>
    <h1 class="title is-1">Auditorías</h1>
    </div>
    <filtros class="mb-5" v-on:filtrar="onFiltrarClick" :model="dataInputs" :idOrganization="this.$props.idOrganization"></filtros>
    <div class="box p-3">
    <b-table
          :narrowed="true"
          :data="auditorias"
          :hoverable="true"
                    >
          <b-table-column v-slot="props" field="created_at" label="Fecha acción" centered>
            {{(new Date(props.row.created_at)).toLocaleString('en-GB')}}
          </b-table-column>
          <b-table-column v-slot="props" field="user_id" label="Usuario ID" centered>
            {{props.row.usuario.id}}
          </b-table-column>
          <b-table-column v-slot="props" field="user_name" label="Nombre" centered>
              {{props.row.usuario.apellido + ", " + props.row.usuario.nombre}}
          </b-table-column>
          <b-table-column v-slot="props" field="ip" label="IP Origen" centered>
              {{props.row.ip}}
          </b-table-column>
          <b-table-column v-slot="props" field="action" label="Acción" centered >
              {{props.row.accion}}
          </b-table-column>
          <b-table-column v-slot="props" field="entity" label="Entidad" centered>
              {{props.row.entidad}}
          </b-table-column>
          <b-table-column v-slot="props" field="entity_id" label="Entidad ID" centered>
              {{props.row.objeto_id}}
          </b-table-column>
          <template #empty>
              <div class="has-text-centered">~ Sin resultados ~</div>
          </template>
      </b-table>
      <infinite-loading @infinite="infiniteHandlerAudits" :identifier="identifierLoaderAuditorias">
        <div slot="no-more" class=" py-5 is-size-7 is-family-monospace"><span v-if="isLoading">Cargando...</span><span v-else>~ Fin del listado ~</span></div>
        <div slot="no-results" class=" py-5 is-size-7 is-family-monospace"><span v-if="isLoading">Cargando...</span></div>
      </infinite-loading>
  </div>
  </section>
</template>

<script>
import InfiniteLoading from 'vue-infinite-loading'
import Filtros from '@/components/organizacion/auditorias/Filtros'

export default {
  props: ['idOrganization'],
  components: {
    InfiniteLoading,
    Filtros
  },
  data () {
    return {
      isLoading: false,
      auditorias: [],
      identifierLoaderAuditorias: Math.random(),
      queryString: {
        page: 1,
        fechaDesde: null,
        fechaHasta: null,
        usuarioId: null,
        nombre: null,
        ipOrigen: null,
        entidad: null,
        entidadId: null,
        accion: null
      },
      dataInputs: {
        fechaDesde: null,
        fechaHasta: null,
        usuarioId: null,
        nombre: null,
        ipOrigen: null,
        entidad: null,
        entidadId: null,
        accion: null
      },
      checkboxGroup: []
    }
  },
  methods: {
    infiniteHandlerAudits ($state) {
      this.isLoading = true
      this.$http.get(`/organizacion/${this.$props.idOrganization}/pista-auditoria`, { params: this.queryString })
        .then(({ data }) => {
          this.isLoading = false
          this.error = false
          if (data.current_page === data.last_page) {
            this.auditorias.push(...data.data)
            $state.complete()
          }
          if (data.current_page < data.last_page) {
            this.queryString.page += 1
            this.auditorias.push(...data.data)
            $state.loaded()
          }
        })
        .catch((error) => {
          this.isLoading = false
          $state.complete()
          this.errorMsg = error.mensaje
          console.log('Error: ' + error.mensaje)
          this.$buefy.dialog.alert({
            title: 'Error',
            message: error.mensaje,
            type: 'is-danger',
            hasIcon: true,
            icon: 'times-circle',
            iconPack: 'fa',
            ariaRole: 'alertdialog',
            ariaModal: true
          })
        })
    },
    onFiltrarClick (checkboxGroup) {
      // Este metodo se llama desde Filtros.
      if (this.dataInputs.fechaDesde && checkboxGroup.includes('fecha-accion')) {
        // el atributo tiene algun dato y no esta vacio
        this.queryString.fechaDesde = this.dataInputs.fechaDesde.toISOString() // Quito la hora del string
        this.queryString.fechaDesde = this.queryString.fechaDesde.substring(0, this.queryString.fechaDesde.indexOf('T'))
      } else {
        // En caso de que se haya borrado el contenido, lo vuelvo a setear en NULL para que no se agregue en el queryString de la consulta
        this.queryString.fechaDesde = null
      }
      if (this.dataInputs.fechaHasta && checkboxGroup.includes('fecha-accion')) {
        this.queryString.fechaHasta = this.dataInputs.fechaHasta.toISOString()
        this.queryString.fechaHasta = this.queryString.fechaHasta.substring(0, this.queryString.fechaHasta.indexOf('T'))
      } else {
        this.queryString.fechaHasta = null
      }
      Object.keys(this.dataInputs).forEach(k => {
        // Object.keys(this.inputs) --> ['user','accion']
        if (k !== 'fechaDesde' && k !== 'fechaHasta') {
          if (this.dataInputs[k] && checkboxGroup.includes(k)) {
            this.queryString[k] = this.dataInputs[k]
          } else {
            console.log(this.dataInputs.usuarioId)
            this.queryString[k] = null
          }
        }
      })
      console.log(this.queryString)
      this.refreshInfiniteLoading()
    },
    refreshInfiniteLoading () {
      // Metodo para volver a buscar los datos segun los filtros establecidos
      this.queryString.page = 1
      this.auditorias = []
      this.identifierLoaderAuditorias = Math.random()
      // this.infiniteHandlerAudits()
      console.log(this.identifierLoaderAuditorias)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
