// import store from '@/store'
// import http from '@/http'

import Firmador from './Firmador'

const BASE_PATH = '/firmador'

const routes = [
  {
    path: BASE_PATH,
    name: 'launchFirmador',
    component: Firmador,
    props: true,
    meta: {
      requiresAuth: true
    }
  }
]
export default routes
