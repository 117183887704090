<template>
  <section class="box">
    <h1 class="title is-5">Datos básicos</h1>
    <!-- ===================== -->
    <div class="field">
      <label class="label">Nombre</label>
      <div class="control">
        <input
          v-model="model.nombre"
          name="nombre"
          class="input"
          data-vv-scope='serie'
          :class="{'is-danger': errors.has('nombre','serie') }"
          v-validate="{required:true}"
        >
      </div>
      <span class="help is-danger" v-if="errors.has('nombre','serie')">{{errors.first('nombre','serie')}}</span>
    </div>
    <!-- ===================== -->
    <div class="field">
      <label class="label">Descripcion</label>
      <div class="control">
        <textarea
          v-model="model.descripcion"
          name="descripcion"
          class="textarea"
          data-vv-scope='serie'
          :class="{'is-danger': errors.has('descripcion','serie') }"
          v-validate="{required:true}"
          rows="2"
        />
      </div>
      <span class="help is-danger" v-if="errors.has('descripcion','serie')">{{errors.first('descripcion','serie')}}</span>
    </div>
    <div class="has-text-right">
    <button class="button is-primary"  @click="submitEdit">
      <i class="fas fa-save fa-fw"></i>&nbsp;Guardar
    </button>
    </div>
  </section>
</template>

<script>
import validatorUtils from '@/mixins/validatorUtils'

export default {
  // props: {
  //   serieDocumental: {
  //     type: Object,
  //     default: null
  //   }
  // },
  mixins: [validatorUtils],
  data () {
    return {
      model: {
        nombre: '',
        descripcion: ''
      }
    }
  },
  mounted () {
    this.model.nombre = this.serieDocumental.nombre
    this.model.descripcion = this.serieDocumental.descripcion
  },
  methods: {
    syncData: function () {
      this.model.nombre = this.serieDocumental.nombre
      this.model.descripcion = this.serieDocumental.descripcion
    },
    submitEdit: function () {
      this.$validator.validateAll('serie').then(result => {
        if (!result) {
          this.$buefy.snackbar.open({
            message: 'Hay errores en el formulario',
            type: 'is-danger'
          })
          return
        }
        const payload = {
          nombre: this.model.nombre,
          descripcion: this.model.descripcion
        }
        this.$emit('submit', payload)
        this.clearValidatorErrors()
      }).catch(err => {
        console.error(err)
      })
    }
  },
  computed: {
    serieDocumental: function () {
      return this.$store.state.serieDocumental.serieDocumental
    },
    idOrganizacion: function () {
      return this.$store.state.organizacion.organizacion.id
    }
  }
}
</script>

<style lang="scss" scoped>
.table-bordered {
  padding: 1em;
  border: 1px solid #cacaca;
  border-radius: 0.3em;
  table.table {
    margin-bottom: 0;
  }
}
</style>
