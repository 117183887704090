<template>
<div class="modal-card">
    <div class="modal-card-head">
      <p class="modal-card-title">Asignar nuevo permiso (Usuario)</p>
    </div>
    <div class="modal-card-body" v-if="selectedUser == null">
      <div class="field">
        <label for="" class="label">Buscar usuario</label>
        <div class="control">
          <input class="input" v-model="userInput" type="text" placeholder="Escriba aquí">
          <span class="help">Escriba el nombre o apellido del usuario</span>
        </div>
      </div>
      <div class="content">
        <p>{{status}}</p>
        <table class="table is-bordered is-narrow is-marginless" v-show="searchResult.length > 0">
          <tbody>
            <tr v-for="result in searchResult" :key="result.index">
              <td>
                <i class="fas fa-user"></i>&nbsp;<a @click="selectUser(result)">{{result.nombre}} {{result.apellido}}</a>
                <span class="disabled" v-if="tieneRolLyris">organizacion_id: {{result.organizacion_id}}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="modal-card-body" v-else>
      <div class="field">
        <label for="" class="label">Usuario</label>
        <div class="control">
          <input type="text" class="input is-static" readonly :value="`${selectedUser.apellido.toUpperCase()}, ${selectedUser.nombre}`">
        </div>
      </div>
      <b-field label="Seleccione el nivel de permiso">
        <b-slider v-model="selectedLevel" size="is-medium" :min="0" :max="8" ticks type="is-success"  rounded>
          <template v-for="(v,i) in 9">
            <b-slider-tick :value="i" :key="`tick-${i}`">{{ i }}</b-slider-tick>
          </template>
        </b-slider>
      </b-field>
      <br>
      <listado-permisos :level="selectedLevel" />
    </div>
    <div class="modal-card-foot is-justify-content-flex-end">
      <button @click="$parent.close()" class="button is-outlined is-dark">
        Cancelar</button>
      <button @click="submit()" :disabled="selectedUser == null" class="button is-primary">
        <i class="fas fa-save fa-fw"></i>&nbsp;Guardar</button>
    </div>
  </div>
</template>

<script>
import { debounce } from 'lodash'
// if the component initialization in a v-show="false" / display: none container or use
// transform / animation / margin to change the location of the component, there may be
// an exception ( The slider cannot be used, because the component can not initialize
// the size or slider position ).
import ListadoPermisos from '@/components/globals/permisos/ListadoPermisos'

export default {
  data () {
    return {
      userInput: '',
      status: '',
      searchResult: [],
      selectedUser: null,
      selectedLevel: 0
    }
  },
  components: {
    ListadoPermisos
  },
  methods: {
    getColor: function (nivel, top) {
      if (nivel >= top) {
        return {
          'is-primary': true
        }
      }
    },
    getIcon: function (nivel, top) {
      if (nivel >= top) {
        return 'check'
      }
      return 'times'
    },
    selectUser: function (user) {
      console.log(user)
      this.selectedUser = user
    },
    cleanModal: function () {
      this.selectedUser = null
      this.selectedLevel = 0
      this.searchResult = []
      this.userInput = ''
    },
    getUser: debounce(
      function () {
        this.status = 'Buscando...'
        this.$http
          .request({
            method: 'get',
            url: this.getURL
          })
          .then(response => {
            console.log(response.data)
            this.searchResult = response.data.data
            this.status = ''
          })
          .catch(error => {
            console.error('Fail', error)
            this.status = 'Error al solicitar el pedido al servidor...'
          })
      },
      // This is the number of milliseconds we wait for the
      // user to stop typing.
      500
    ),
    submit: function () {
      this.$emit(
        'crearPermiso',
        'usuario_id',
        this.selectedUser.id,
        this.selectedLevel
      )
      this.$parent.close()
    }
  },
  computed: {
    getURL: function () {
      return (this.tieneRolLyris
        ? '/usuario?s=' + this.userInput
        : '/organizacion/' + this.organizacionId + '/usuario?s=' + this.userInput) + '&acceso_habilitado=true'
    },
    tieneRolLyris: function () {
      return this.$store.getters.hasAnyRole(['lyris'])
    },
    organizacionId: function () {
      return this.$store.getters.getUser.organization
    },
    pieceWiseStyle: function () {
      return {
        backgroundColor: '#ccc',
        visibility: 'visible',
        width: '12px',
        height: '12px'
      }
    }
  },
  watch: {
    userInput: function (newUserInput, oldUserInput) {
      this.status = 'Tipeando...'
      this.searchResult = []
      if (newUserInput.trim().length >= 3) this.getUser()
      else this.status = 'Por favor, escriba más caracteres para la búsqueda.'
    }
  }
}
</script>

<style lang="scss" scoped>
.center-options {
  justify-content: center;
}
.disabled {
  color: gray
}
</style>
