<template>
  <section>
    <div class="box">
      <h1 class="subtitle is-4">{{getTitle}}</h1>
      <h1 class="title is-3">Certificados de la organización</h1>
      <div class="content">
        <p>Solicitudes y certificados de los usuarios de la organización.</p>
      </div>
    </div>
    <Filtros @set-query="setQuery" @refresh="refresh"/>
    <Listado ref="listado" />
  </section>
</template>
<script>
import Filtros from '@/views/certificados/oficial-registro/Filtros'
import Listado from '@/views/certificados/oficial-registro/Listado'
import constants from '@/constants'
export default {
  components: {
    Filtros,
    Listado
  },
  data () {
    return {
      constants: constants
    }
  },
  methods: {
    setQuery: function (query) {
      this.$refs.listado.setQuery(query)
    },
    refresh: function () {
      this.$refs.listado.refresh()
    },
    hasRole (roles) {
      return this.$store.getters.hasAnyRole(roles)
    }
  },
  computed: {
    getTitle: function () {
      return this.hasRole([constants.STRINGS.ROLES.ADMINISTRADOR_DE_CERTIFICADOS.ID]) ? 'Administrador de certificados' + (this.hasRole([constants.STRINGS.ROLES.OFICIAL_DE_REGISTRO.ID]) ? ' y Oficial de registro' : '') : 'Oficial de registro'
    }
  }
}
</script>
