<template>
  <section class="section">
    <div class="container">
      <div class="columns is-centered">
        <div class="column is-6">
          <div class="box">

          <h1 class="subtitle is-5">Signar</h1>
          <h1 class="title is-1">Mi configuración</h1>
          <div class="content">

          <p class="">Personalice el comportamiento de Signar en su navegador.</p>
          <p class="has-text-primary is-size-7">Nota: La siguientes opciones solo existen en este navegador. Una ventana de navegacion privada, u otro navegador, la experiencia sera por default</p>
          </div>
          <hr>
          <h2 class="title is-5"><i class="fas fa-cube"></i>&nbsp;Inicio / Buscador</h2>
          <div class="media">
            <div class="media-right pt-2">
              <b-field>
                  <b-switch v-model="editedSettings.homeSearch.showThumbnails" @input="submit">
                  </b-switch>
              </b-field>
            </div>
            <div class="media-content">
                <h6 class="has-text-primary"><b>Previsualizar resultados</b></h6>
                <div class="is-size-7">
                  <p class="">Mostrar los resultados de las busquedas con una previsualizacion (en el caso que tenga)</p>
                  <p class=""><b>Valor por defecto:</b> Desactivado</p>
                  </div>
            </div>
          </div>
          <hr>
          <h2 class="title is-5"><i class="fas fa-cube"></i>&nbsp;Inicio / Últimos documentos</h2>
          <div class="media">
            <div class="media-right pt-2">
              <b-field>
                  <b-switch v-model="editedSettings.recentDocuments.alwaysShowOpen" @input="submit">
                  </b-switch>
              </b-field>
            </div>
            <div class="media-content">
                <h6 class="has-text-primary"><b>Siempre desplegado</b></h6>
                <div class="is-size-7">
                  <p class="">Siempre al entrar a la pagina de inicio, mostrar desplegada la fila de documentos recientes</p>
                  <p class=""><b>Valor por defecto:</b> Activado</p>
                  </div>
            </div>
          </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data () {
    return {
      editedSettings: {
        homeSearch: {
          showThumbnails: null
        },
        recentDocuments: {
          alwaysShowOpen: null
        }
      }
    }
  },
  beforeMount () {
    this.editedSettings = Object.assign({}, this.editedSettings, this.settings)
  },
  methods: {
    submit () {
      this.$store.dispatch('customSettings/save', this.editedSettings)
      this.$buefy.snackbar.open({
        message: '<i class="fas fa-check animated bounceIn"></i>&nbsp;¡Guardado!',
        type: 'is-success'
      })
    }
  },
  computed: {
    settings () {
      return this.$store.getters['customSettings/userSettings']
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
