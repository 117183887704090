<template>
  <section>
    <div class="box">
      <h1 class="title is-4">{{ constants.STRINGS.TITLES.SEARCH_FILTERS }}</h1>
      <section>
        <div class="field mb-0" v-if="hasLyrisRole">
          <div class="field">
            <label class="label">
              {{ constants.STRINGS.SUBTITLES.FILTERS_OTROS_DOCUMENTOS.PER_ORGANIZATION }}
              <span class="is-pulled-right">
                <b-tooltip label="Opción para Lyris" position="is-left">
                  <i class="fas fa-star is-pulled-right"></i>
                </b-tooltip>
              </span>
            </label>
            <b-autocomplete class="input-field" v-model="organizationInput" :data="searchOrganizationResults" :loading="isFetching" :placeholder="constants.STRINGS.PLACEHOLDERS.WRITE_NAME_OR_ID" v-if="!selectedOrganization" field="razon_social" @input="getOrganizationsWithInstalledModule(constants.STRINGS.MODULES.NOTIFICACIONES.ID)" @select="option => selectOrganization(option)">
              <template slot-scope="props">
                <i class="fas fa-building"></i> {{props.option.id}} - {{ props.option.razon_social.toUpperCase() }}
                <br>
                <span class="is-size-7">{{props.option.slug}} - {{props.option.cuit}}</span>
              </template>
              <template slot="empty">{{ constants.STRINGS.ERRORS.NO_RESULTS_FOR }} {{organizationInput}}</template>
            </b-autocomplete>
          </div>
          <b-field class="mb-5">
            <b-tag v-if="selectedOrganization"
              icon="fas fa-building"
              size="is-medium"
              attached
              aria-close-label="Close tag"
              closable
              @close="unselectOrganization()">
              {{ selectedOrganization.razon_social.toUpperCase() }}
            </b-tag>
          </b-field>
        </div>
      </section>
      <b-field class="mb-0" :label="constants.STRINGS.SUBTITLES.FILTERS_OTROS_DOCUMENTOS.FOR_DOCUMENT_NAME">
          <b-input v-model="documents.inputName" @keyup="updateList"
          :placeholder="constants.STRINGS.PLACEHOLDERS.WRITE_DOCUMENT_NAME" maxlength="30"></b-input>
      </b-field>
      <div class="field" v-if="hasLyrisRole || (hasOtrosAdminRole && hasOtrosUserRole)" v-show="showOnlyMyDocuments">
        <b-checkbox v-model="onlyMyDocuments">
          <label class="label">
            {{ constants.STRINGS.SUBTITLES.FILTERS_OTROS_DOCUMENTOS.SEE_ONLY_MY_DOCUMENTS }}
          </label>
        </b-checkbox>
      </div>
      <div class="field mb-0" v-if="hasLyrisRole || hasOtrosAdminRole" v-show="showSearchUser">
        <label class="label">
          {{ constants.STRINGS.SUBTITLES.FILTERS_OTROS_DOCUMENTOS.FOR_EMPLOYEE }}
        </label>
        <b-autocomplete class="input-field" v-model="users.input" :data="users.searchResults"
          :placeholder="constants.STRINGS.PLACEHOLDERS.WRITE_NAME_OR_SURNAME"
          v-if="!users.selected" field="userSurname" :loading="isFetching" @input="getUser"
          @select="option => this.selectUser(option)" maxlength="30">
          <template slot-scope="props">
            <i class="fas fa-user"></i> {{ props.option.apellido.toUpperCase() }}, {{ props.option.nombre }}
            <br>
            <span class="is-size-7">{{props.option.cuil}} - {{props.option.email}}</span>
          </template>
          <template slot="empty">{{ constants.STRINGS.ERRORS.NO_RESULTS_FOR }} {{ users.input }}</template>
        </b-autocomplete>
      </div>
      <b-field class="my-2">
        <b-tag v-if="users.selected"
            icon="fas fa-user"
            size="is-medium"
            attached
            aria-close-label="Close tag"
            closable
            @close="clearUserSection()">
            {{ users.selected.apellido.toUpperCase() }}, {{ users.selected.nombre }}
        </b-tag>
      </b-field>
      <div class="field mb-0" v-if="hasLyrisRole || hasOtrosAdminRole" v-show="showSearchArea">
        <label class="label">
          {{ constants.STRINGS.SUBTITLES.FILTERS_OTROS_DOCUMENTOS.FOR_AREA }}
        </label>
        <b-autocomplete class="input-field" v-model="areas.input" :data="areas.searchResults"
          :placeholder="constants.STRINGS.PLACEHOLDERS.WRITE_NAME"
          v-if="!areas.selected" field="areaName" :loading="isFetching" @input="getAreas"
          @select="option => this.selectArea(option)" maxlength="30">
          <template slot-scope="props">
            <i class="fas fa-tag"></i> {{ props.option.id }} - {{ props.option.nombre }}
            <br>
          </template>
          <template slot="empty">{{ constants.STRINGS.ERRORS.NO_RESULTS_FOR }} {{areas.input}}</template>
        </b-autocomplete>
      </div>
      <b-field class="my-2">
        <b-tag v-if="areas.selected"
            icon="fas fa-tag"
            size="is-medium"
            attached
            aria-close-label="Close tag"
            closable
            @close="clearAreaSection()">
            {{ areas.selected.id }} - {{ areas.selected.nombre }}
        </b-tag>
      </b-field>
      <div class="field" v-if="hasLyrisRole || hasOtrosAdminRole" v-show="showAllOrganization">
        <b-checkbox v-model="allOrganization">
          <label class="label">
            {{ constants.STRINGS.SUBTITLES.FILTERS_OTROS_DOCUMENTOS.FOR_ALL_ORGANIZATION }}
          </label>
        </b-checkbox>
      </div>
      <div class="field">
        <label class="label">
          {{ constants.STRINGS.SUBTITLES.FILTERS_OTROS_DOCUMENTOS.FOR_DATE }}
        </label>
        <div class="help">{{ constants.STRINGS.LABELS.BLANK_FIELD_REPRESENTS_ANY_DAY_MONTH_OR_YEAR }}</div>
      </div>
      <div class="field is-horizontal is-mobile">
        <div class="field-body">
          <div class="field">
            <div class="control">
              <input class="input has-text-centered" name="año" v-model="date.year"
              :placeholder="constants.STRINGS.PLACEHOLDERS.YEAR"
              v-validate="'min:4|max:4|min_value:1920|numeric'" :class="{'is-danger': errors.has('año')}">
            </div>
          </div>
          <div class="field">
            <div class="control">
              <input class="input has-text-centered" name="mes" v-model="date.month"
              :disabled="date.year == '' || errors.has('año')" :placeholder="constants.STRINGS.PLACEHOLDERS.MONTH"
              v-validate="'max:2|min_value:1|max_value:12|numeric'" :class="{'is-danger': errors.has('mes')}">
            </div>
          </div>
          <div class="field">
            <div class="control">
              <input class="input has-text-centered" name="dia" v-model="date.day"
              :disabled="date.month == '' || errors.has('mes')" :placeholder="constants.STRINGS.PLACEHOLDERS.DAY"
              v-validate="'max:2|min_value:1|max_value:31|numeric'" :class="{'is-danger': errors.has('dia')}">
            </div>
          </div>
        </div>
      </div>
      <span class="help is-danger" v-if="errors.has('año')">{{ errors.first('año') }}</span>
      <span class="help is-danger" v-if="errors.has('mes')">{{ errors.first('mes') }}</span>
      <span class="help is-danger" v-if="errors.has('dia')">{{ errors.first('dia') }}</span>
      <div class="field">
        <label class="label">{{ constants.STRINGS.SUBTITLES.FILTERS_OTROS_DOCUMENTOS.FOR_DOCUMENT_TYPE }}</label>
        <div class="control">
          <input type="text" class="input" :value="constants.STRINGS.LOADING.LOADING_TEXT_WITH_SLASHES" v-if="documents.loadingTypes">
          <div class="select is-fullwidth" v-else>
            <select name="" v-model="documents.selectedType">
              <option :value="null">{{ constants.STRINGS.DOCUMENTS.TYPES.ANY_TYPE }}</option>
              <option :value="type.id" v-for="(type, i) in documents.types" :key="i">{{ type.nombre }}</option>
            </select>
          </div>
        </div>
      </div>
      <div class="field" v-if="hasLyrisRole || hasOtrosAdminRole">
        <label for="" class="label">{{ constants.STRINGS.SUBTITLES.FILTERS_OTROS_DOCUMENTOS.FOR_DOCUMENT_STATUS }}</label>
        <div class="control">
          <div class="select is-fullwidth">
            <select name="" v-model="documents.selectedStatus">
              <option :value="i === 0 ? null : status" v-for="(status, i) in documents.statuses" :key="i">{{ status }}</option>
          </select>
          </div>
        </div>
      </div>
      <div class="field">
        <label for="" class="label">{{ this.getTextDependingRolesAssigned }}</label>
        <div class="control">
          <div class="select is-fullwidth">
            <select name="" v-model="documents.selectedSignStatus">
              <option :value="i === 0 ? null : signStatus" v-for="(signStatus, i) in documents.signStatuses" :key="i">{{ signStatus }}</option>
            </select>
          </div>
        </div>
      </div>
      <div class="buttons mt-4">
        <button @click="setDefaults" class="button is-light"><i class="fas fa-broom"></i>&nbsp;{{ constants.STRINGS.BUTTONS.CLEAN_FILTERS }}</button>
        <button @click="updateList" class="button is-primary" :disabled="errors.count() > 0"><i class="fas fa-search"></i>&nbsp;{{ constants.STRINGS.BUTTONS.SEARCH }}</button>
      </div>
    </div>
  </section>
</template>

<script>
import debounce from 'lodash/debounce'
import constants from '../../constants'

export default {
  data () {
    return {
      constants: constants,
      isFetching: false,

      organizationInput: null,
      searchOrganizationResults: [],
      selectedOrganization: null,
      onlyMyDocuments: false,
      users: {
        input: null,
        searchResults: [],
        selected: null
      },
      areas: {
        input: null,
        searchResults: [],
        selected: null
      },
      allOrganization: false,
      date: {
        day: '',
        month: '',
        year: ''
      },
      documents: {
        loadingTypes: false,
        inputName: null,
        types: [],
        selectedType: null,
        statuses: [
          constants.STRINGS.DOCUMENTS.STATUS.ANY_STATE,
          constants.STRINGS.DOCUMENTS.STATUS.AVAILABLE,
          constants.STRINGS.DOCUMENTS.STATUS.PENDING_SIGNATURE,
          constants.STRINGS.DOCUMENTS.STATUS.SIGNED
        ],
        selectedStatus: null,
        signStatuses: [
          constants.STRINGS.DOCUMENTS.STATUS.ANY_STATE,
          constants.STRINGS.DOCUMENTS.STATUS.AVAILABLE,
          constants.STRINGS.DOCUMENTS.STATUS.PENDING_SIGNATURE,
          constants.STRINGS.DOCUMENTS.STATUS.SIGNED
        ],
        selectedSignStatus: null
      }
    }
  },
  beforeMount () {
    console.log(constants.STRINGS.LOG_EVENTS.NOTIFICATIONS.ADDED_KEYDOWN_EVENT_LISTENER)
    window.addEventListener(constants.STRINGS.COMMON_WORDS.KEYDOWN_LOWERCASE, this.keydownEventHandler)
  },
  beforeDestroy () {
    console.log(constants.STRINGS.LOG_EVENTS.NOTIFICATIONS.REMOVED_KEYDOWN_EVENT_LISTENER)
    window.removeEventListener(constants.STRINGS.COMMON_WORDS.KEYDOWN_LOWERCASE, this.keydownEventHandler)
  },
  mounted () {
    this.getOtrosDocumentosTypes()
  },
  methods: {
    keydownEventHandler: function (event) {
      if (event.key === 'Enter') {
        console.log(constants.STRINGS.LOG_EVENTS.NOTIFICATIONS.ENTER_KEY_PRESSED_UPDATING_LIST)
        this.updateList()
      }
    },
    updateList: function () {
      this.$emit('set-query', this.query)
    },
    selectOrganization: function (optionSelected) {
      this.selectedOrganization = optionSelected
      this.clearUserSection()
      this.clearAreaSection()
    },
    unselectOrganization: function () {
      this.selectedOrganization = null
      this.organizationInput = null
      this.updateList()
    },
    clearUserSection: function () {
      this.users.input = null
      this.users.searchResults = []
      this.users.selected = null
      this.updateList()
    },
    clearAreaSection: function () {
      this.areas.input = null
      this.areas.searchResults = []
      this.areas.selected = null
      this.updateList()
    },
    selectUser: function (user) {
      this.users.selected = user
      this.clearAreaSection()
      this.updateList()
    },
    selectArea: function (area) {
      this.areas.selected = area
      this.clearUserSection()
      this.updateList()
    },
    setDefaults: function () {
      this.selectedOrganization = null
      this.organizationInput = null
      this.documents.inputName = null
      this.onlyMyDocuments = false
      this.users.input = null
      this.users.searchResults = []
      this.users.selected = null
      this.areas.input = null
      this.areas.searchResults = []
      this.areas.selected = null
      this.allOrganization = false
      this.date.day = ''
      this.date.month = ''
      this.date.year = ''
      this.documents.selectedType = null
      this.documents.selectedStatus = null
      this.documents.selectedSignStatus = null
      this.updateList()
    },
    getOtrosDocumentosTypes () {
      this.documents.loadingTypes = true
      this.$http.get('/tipo-otro-documento')
        .then(res => {
          this.documents.types = res.data
          this.documents.loadingTypes = false
        }).catch(err => {
          console.error(err)
          this.$buefy.snackbar.open({
            message: constants.STRINGS.ERRORS.NO_RESULTS_FOR,
            type: 'is-warning',
            actionText: constants.STRINGS.BUTTONS.RETRY,
            indefinite: true,
            onAction: () => {
              location.reload()
            }
          })
        })
    },
    getUser: debounce(
      function () {
        this.isFetching = true
        this.$http
          .request({
            method: 'get',
            url:
              this.urlUserAndArea +
              '/usuario?s=' +
              this.users.input +
              '&rol=otros-user&acceso_habilitado=true'
          })
          .then(response => {
            this.users.searchResults = response.data.data
            this.isFetching = false
          })
          .catch(error => {
            console.error('Fail', error)
            this.isFetching = false
          })
      },
      // This is the number of milliseconds we wait for the user to stop typing.
      500
    ),
    getAreas: debounce(
      function () {
        this.isFetching = true
        this.$http
          .request({
            method: 'get',
            url:
              this.urlUserAndArea +
              '/area?s=' +
              this.areas.input
          })
          .then(response => {
            this.areas.searchResults = response.data.data
            this.isFetching = false
          })
          .catch(error => {
            console.error('Fail', error)
            this.isFetching = false
          })
      },
      // This is the number of milliseconds we wait for the user to stop typing.
      500
    ),
    getOrganizationsWithInstalledModule: debounce(
      function (module) {
        this.$http
          .request({
            method: 'get',
            url: '/organizacion?s=' + this.organizationInput + '&modulo_id=' + module
          })
          .then(response => {
            this.searchOrganizationResults = response.data.data
          })
          .catch(error => {
            console.error('Fail', error)
          })
      },
      // This is the number of milliseconds we wait for the user to stop typing.
      500
    )
  },
  computed: {
    getTextDependingRolesAssigned: function () {
      return (this.hasLyrisRole || this.hasOtrosAdminRole)
        ? constants.STRINGS.SUBTITLES.FILTERS_OTROS_DOCUMENTOS.FOR_MY_STATE_WITH_THE_DOCUMENT
        : constants.STRINGS.SUBTITLES.FILTERS_OTROS_DOCUMENTOS.FOR_DOCUMENT_STATUS
    },
    showOnlyMyDocuments: function () {
      return this.users.selected === null && this.areas.selected === null && !this.allOrganization
    },
    showSearchUser: function () {
      return !this.onlyMyDocuments && this.areas.selected === null && !this.allOrganization
    },
    showSearchArea: function () {
      return !this.onlyMyDocuments && this.users.selected === null && !this.allOrganization
    },
    showAllOrganization: function () {
      return !this.onlyMyDocuments && this.users.selected === null && this.areas.selected === null
    },
    hasLyrisRole: function () {
      return this.$store.getters.hasAnyRole([constants.STRINGS.ROLES.LYRIS.ID])
    },
    hasOtrosAdminRole: function () {
      return this.$store.getters.hasAnyRole([constants.STRINGS.ROLES.ADMINISTRADOR_DE_OTROS_DOCUMENTOS.ID])
    },
    hasOtrosUserRole: function () {
      return this.$store.getters.hasAnyRole([constants.STRINGS.ROLES.USUARIO_DE_OTROS_DOCUMENTOS.ID])
    },
    query: function () {
      const obj = {}
      obj.documentName = this.documents.inputName ? this.documents.inputName : null
      obj.selectedUserId = this.users.selected ? this.users.selected.id : null
      obj.selectedAreaId = this.areas.selected ? this.areas.selected.id : null
      obj.allOrganization = this.allOrganization
      obj.date = this.formatDate
      obj.selectedDocumentTypeId = this.documents.selectedType ? this.documents.selectedType : null
      obj.selectedDocumentStatus = this.documents.selectedStatus ? this.documents.selectedStatus : null
      obj.selectedDocumentSignStatus = this.documents.selectedSignStatus ? this.documents.selectedSignStatus : null
      if (this.onlyMyDocuments) {
        obj.selectedUserId = this.$store.getters.getUser.id
      }
      if (this.selectedOrganization != null) {
        obj.organizacionId = this.selectedOrganization.id
      }
      return obj
    },
    formatDate: function () {
      let formattedDate = ''
      if (this.date.year) {
        formattedDate += this.date.year + '-'
      } else {
        formattedDate += '0000-'
      }
      if (this.date.month) {
        formattedDate += this.date.month + '-'
      } else {
        formattedDate += '00-'
      }
      if (this.date.day) {
        formattedDate += this.date.day
      } else {
        formattedDate += '00'
      }
      return formattedDate
    },
    urlUserAndArea () {
      return this.hasLyrisRole ? this.verifyIfOrganizationSelected : this.urlCurrentOrganization
    },
    verifyIfOrganizationSelected () {
      return (this.selectedOrganization != null) ? this.urlSelectedOrganization : ''
    },
    urlSelectedOrganization () {
      return '/organizacion/' + this.selectedOrganization.id
    },
    urlCurrentOrganization () {
      return '/organizacion/' + this.$store.getters.getUser.organization
    }
  },
  watch: {
    'date.year': function () {
      if (this.date.year) {
        this.date.month = ''
        this.date.day = ''
      }
    },
    'date.month': function () {
      if (this.date.month) {
        this.date.day = ''
      }
    },
    onlyMyDocuments: function () {
      if (this.onlyMyDocuments) {
        this.clearUserSection()
        this.clearAreaSection()
        this.allOrganization = false
      }
      this.updateList()
    },
    allOrganization: function () {
      if (this.allOrganization) {
        this.clearUserSection()
        this.clearAreaSection()
        this.onlyMyDocuments = false
      }
      this.updateList()
    }
  }
}
</script>
