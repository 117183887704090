<template>
  <div class="card">
    <div class="card-content">
      <h1 class="title is-3 has-text-primary"><i class="fas fa-play-circle"></i>&nbsp;Evento: {{resource.nombre}}</h1>
          <h1 class="subtitle is-5">Por favor, revise los datos del evento antes de confirmar su ejecución.</h1>
        <table class="table is-narrow is-fullwidth is-marginless">
          <tbody>
            <tr>
              <th>Nombre del evento</th>
              <td>{{resource.nombre}}</td>
            </tr>
            <tr>
              <th>Posibles estados iniciales</th>
              <td>
                <div class="tags">
                  <p
                    class="tag is-small is-primary"
                    v-for="estado in resource.estados_iniciales"
                    :key="estado"
                  >{{unidadCompuesta.serie_documental.estados[estado]}}</p>
                </div>
              </td>
            </tr>
            <tr>
              <th>Estado final</th>
              <td>
                <p
                  class="tag is-primary"
                  v-if="unidadCompuesta.serie_documental.estados[resource.estado_final]"
                >{{unidadCompuesta.serie_documental.estados[resource.estado_final]}}</p>
                <p v-else>
                  <span class="tag is-primary">
                    <i class="fas fa-asterisk"></i>
                  </span>&nbsp;
                  <span class="is-size-7">Se mantiene el mismo estado</span>
                </p>
              </td>
            </tr>
            <tr>
              <th>Areas habilitadas</th>
              <td>
                <p class="is-size-7" v-if="resource.areas_habilitadas == null">
                  <i class="fas fa-check has-text-primary"></i>&nbsp;Todas las areas pueden ejecutar el evento
                </p>
                <p
                  class="tag is-primary"
                  v-for="area in resource.areas_habilitadas"
                  :key="area"
                >
                  <i class="fas fa-tag"></i>
                  &nbsp;&nbsp;{{areasOrganizacion.find(a => a.id == area).nombre}}
                </p>
              </td>
            </tr>
          </tbody>
        </table>
        <br />
        <table class="table is-narrow is-fullwidth is-marginless is-bordered">
          <tbody>
            <tr>
              <th class="is-selected">Acciones requeridas</th>
              <th class="is-selected">Parametros</th>
            </tr>
            <tr v-for="(accion, index) in resource.acciones" :key="index">
              <td>
                <p>
                  <b>{{accion.descripcion}}</b>
                </p>
                <p class="is-size-7">{{getOperacion(accion.documento_operacion_id).nombre}}</p>
              </td>
              <td>
                <p v-for="(p,index) in accion.parametros" :key="index" class="is-size-7">
                  <b>{{index}}</b>
                  : {{p}}
                </p>
              </td>
            </tr>
            <tr v-if="resource.acciones.length === 0">
              <td colspan="2" class="is-mono">El evento no requiere de acciones en particular</td>
            </tr>
          </tbody>
        </table>
        <br />
        <div class="notification is-dark has-text-centered">
          <h1
            class="subtitle is-5 has-text-white is-marginless"
          >¿Desea ejecutar el evento {{resource.nombre}}?</h1>
          <p class="help has-text-warning">
            <i class="fas fa-info-circle"></i>&nbsp;IMPORTANTE: Durante la ejecución de un evento, no habra marcha atras, siendo obligatorio la finalización del mismo.
          </p>
          <br />
          <div class="buttons is-centered">
            <button @click="ejecutarEvento(resource.id)" class="button is-primary">
              <i class="fas fa-play-circle fa-fw"></i>&nbsp;Ejecutar evento
            </button>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['resource', 'unidadCompuesta', 'areasOrganizacion'],
  data () {
    return {
      previewModalActive: false
    }
  },
  components: {
  },
  methods: {
    getOperacion: function (id) {
      return this.$store.getters['unidadCompuesta/getOperacion'](id)
    },
    ejecutarEvento: function () {
      this.$emit('ejecutarEvento', this.resource.id)
      this.$parent.close()
    }
  }
}
</script>
