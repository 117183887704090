<template>
   <div class="card">
    <div class="card-content has-text-centered" v-if="!isUploading && !isDone">
        <h1 class="title is-3">Eliminar {{files.length}} documento{{files.length ? 's' : ''}}</h1>
            <h2 class="subtitle is-5">¿Está seguro que desea eliminar los documentos?</h2>
    </div>
    <div class="card-content" v-else>
      <table class="table is-narrow is-fullwidth">
            <thead>
              <th>Nombre</th>
            </thead>
            <tbody>
              <tr v-for="(file,i) in filesCopy" :key="`file-${i}`">
                <td><p>{{file.nombre}}
                  <span v-if="filesSucceded.includes(i)" class="has-text-success">&nbsp;<i class="fas fa-check-circle"></i></span>
                  <span v-if="filesFailed.includes(i)" class="has-text-danger">&nbsp;<i class="fas fa-times-circle"></i></span></p>
                  <p v-if="filesErrors[i]" class="has-text-danger is-size-7">{{filesErrors[i]}}</p>
                </td>
              </tr>
              <tr v-if="filesCopy.length == 0">
                <td colspan="3">
                  <i>- No hay archivos para eliminar -</i>
                </td>
              </tr>
            </tbody>
          </table>
    </div>
      <footer class="card-footer" v-if="!isUploading && !isDone">
            <a class="card-footer-item" type="button" @click="$parent.close()">Cancelar</a>
        <a class="card-footer-item has-text-weight-bold has-text-danger is-uppercase" @click.prevent="submit">Eliminar</a>
      </footer>
      <footer class="card-footer" v-else-if="!isUploading && isDone">
        <a class="card-footer-item" type="button" @click="$parent.close()">Cerrar</a>
    </footer>
      <footer class="card-footer" v-else-if="isUploading">
        <span class="card-footer-item animated flash slow" type="button"><i class="fas fa-sync fa-spin"></i>&nbsp;Eliminando archivos</span>
    </footer>
  </div>
</template>

<script>
export default {
  data () {
    return {
      filesCopy: [],
      rutas: null,
      selected: null,
      explorerLoading: true,
      initRoute: null,
      activeTab: 0,
      // -------
      isUploading: false,
      isDone: false,
      filesSucceded: [],
      filesFailed: [],
      filesErrors: {}
    }
  },
  components: {
  },
  mounted: function () {

  },
  methods: {
    submit () {
      this.filesCopy = this.files
      if (this.filesCopy.length === 0) return
      const dataArr = []
      this.filesCopy.map((f, i) => {
        dataArr.push({
          file_id: f.id,
          index: i
        })
      })
      this.isUploading = true
      this.send(dataArr.reverse(), 0, 0)
    },
    send (dataArr, successCount, errorCount) {
      if (!dataArr.length) {
        this.isUploading = false
        this.isDone = true
        this.$buefy.snackbar.open({
          message: `Se han eliminado ${successCount} de ${this.filesCopy.length} documentos correctamente`,
          type: successCount === this.filesCopy.length ? 'is-success' : 'is-warning'
        })
        if (successCount === this.filesCopy.length) {
          this.$parent.close()
        }
        this.$emit('refresh')
        return
      }
      const fileToSend = dataArr.pop()
      this.$http.request({
        method: 'delete',
        url: `/documento/${fileToSend.file_id}`
      }).then((response) => {
        successCount++
        this.filesSucceded.push(fileToSend.index)
        this.send(dataArr, successCount, errorCount)
      }).catch((err) => {
        console.error(err)
        errorCount++
        this.filesErrors[fileToSend.index] = err.mensaje
        this.filesFailed.push(fileToSend.index)
        this.send(dataArr, successCount, errorCount)
      })
    }
  },
  computed: {
    files () {
      return this.$store.state.repository.selectedDocuments
    }
  }
}
</script>

<style lang="scss" scoped>
.card-content{
  max-height: 70vh;
  overflow-y: scroll;
}
</style>
