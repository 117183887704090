<template>
<section>

  <div class="box">
    <h1 class="subtitle is-5">Series documentales</h1>
    <h1 class="title is-1">Nueva serie documental</h1>
  </div>
  <serie-documental-form @submit="submit"></serie-documental-form>
</section>
</template>

<script>
import SerieDocumentalForm from '@/components/organizacion/series-documentales/Form'
export default {
  props: ['idOrganization'],
  components: {
    SerieDocumentalForm
  },
  methods: {
    submit: function (payload) {
      this.startLoading()
      this.$http
        .post(`/organizacion/${this.idOrganization}/serie-documental`, payload)
        .then(result => {
          this.stopLoading()
          this.$buefy.snackbar.open({
            message: 'La serie documental ha sido creado exitosamente',
            type: 'is-success'
          })
          this.$router.push({ name: 'organizacionEditarSerieDocumental', params: { id: this.id, idSerie: result.data.serie_documental.id }, query: { creado: true } })
        })
        .catch(err => {
          this.stopLoading()
          console.error(err)
          this.$buefy.snackbar.open({
            message: 'El servidor respondió con un error.',
            type: 'is-danger'
          })
        })
    }
  }
}
</script>
