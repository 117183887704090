import http from '@/http'

const reciboModule = {
  namespaced: true,
  state: {
    recibo: null
  },
  mutations: {
    recibo (state, recibo) {
      state.recibo = recibo
    },
    unload (state) {
      state.recibo = null
    }
  },
  getters: {},
  actions: {
    fetchRecibo ({ commit }, id) {
      // Fetch, then store the value, then return the promise
      // you chan call this as store.dispatch('..').then(res => {})
      return http.get(`/recibo/${id}`)
        .then(res => {
          console.info('[VUEX] GET OK - Got Recibo')
          commit('recibo', res.data)
          return res
        })
    },
    unload (context) {
      context.commit('unload')
    }
  }
}

export default reciboModule
