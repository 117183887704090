<template>
  <table class="table is-narrow is-fullwidth is-marginless" v-if="otroDocumento">
    <tbody>
      <tr>
        <th>{{ constants.STRINGS.TABLES.OTRO_DOCUMENTO_INFORMATION.TYPE }}</th>
        <td>{{ otroDocumento.tipo_otro_documento.nombre }}</td>
      </tr>
      <tr>
        <th>{{ constants.STRINGS.TABLES.OTRO_DOCUMENTO_INFORMATION.UPLOAD_DATE }}</th>
        <td>{{ new Date(otroDocumento.created_at) | moment("dddd, DD/MM/YYYY, HH:mm:ss") }} </td>
      </tr>
      <tr>
        <th>{{ constants.STRINGS.TABLES.OTRO_DOCUMENTO_INFORMATION.STATE }}</th>
        <td>{{ otroDocumento.estado }} </td>
      </tr>
      <tr>
        <th width="250px">{{ constants.STRINGS.TABLES.OTRO_DOCUMENTO_INFORMATION.SCOPE }}</th>
        <td>{{ this.getScopeText }} </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import constants from '../../constants'

export default {
  props: ['id'],
  data () {
    return {
      constants: constants,
      cargando: true
    }
  },

  methods: {},
  computed: {
    isDocumentForUsers: function () {
      return this.getAllReceivers.find(permiso => permiso.usuario)
    },
    isDocumentForAreas: function () {
      return this.getAllReceivers.find(permiso => permiso.area)
    },
    isDocumentForAllOrganization: function () {
      return this.getAllReceivers.find(permiso => permiso.organizacion)
    },
    isUploaderReceiver: function () {
      return this.otroDocumento.otro_documento_usuario.find(firma => firma.usuario && firma.usuario.id === this.otroDocumento.administrador.id)
    },
    getAllReceivers: function () {
      return this.otroDocumento.permisos.filter(permiso => permiso.estado === constants.STRINGS.PERMISSIONS.STATUS.OTROS_DOCUMENTOS_RECEIVER)
    },
    getAllSigners: function () {
      return this.otroDocumento.otro_documento_usuario
    },
    getScopeText: function () {
      let text = ''

      if (this.isDocumentForUsers) {
        text = this.getAllSigners.map(sign => sign.usuario.apellido.toUpperCase() + ' ' + sign.usuario.nombre).join(', ')
      } else if (this.isDocumentForAreas) {
        text = this.getAllReceivers.map(permiso => permiso.area.nombre).join(', ')
      } else if (this.isDocumentForAllOrganization) {
        text = constants.STRINGS.LABELS.ALL_USERS_FROM + ` ${this.getOrganizationName}`
      } else if (this.isUploaderReceiver) {
        text = `${this.otroDocumento.administrador.apellido.toUpperCase() + ' ' + this.otroDocumento.administrador.nombre}`
      } else {
        text = constants.STRINGS.ERRORS.RECEIVER_COULD_NOT_BE_RECOVERED
      }

      return text
    },
    getOrganizationName: function () {
      return this.otroDocumento.permisos.find(permiso => permiso.organizacion).organizacion.razon_social
    },
    otroDocumento: function () {
      return this.$store.state.otroDocumento.otroDocumento
    }
  }
}
</script>

<style lang="scss" scoped>
img {
  border-radius: 100px;
}
</style>
