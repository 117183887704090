<template>
  <div>
    <div class="box">
    <!-- <hr> -->
      <router-link class="is-pulled-right" :to="{ name: 'usuarioEditar', params: { idOrganization: idOrganization, idUser: idUser }}" v-show="$store.getters.hasAnyRole(['lyris','admin'])">
        <i class="fas fa-edit"></i> Editar
      </router-link>
    <div class="columns">
      <div class="column">
        <h2 class="subtitle is-5">CUIL</h2>
        <div class="content">
          <p>{{usuario.cuil}}</p>
        </div>
      </div>
      <div class="column">
        <h2 class="subtitle is-5">DNI</h2>
        <div class="content">
          <p>{{usuario.cuil.substring(2, usuario.cuil.length-1)}}</p>
        </div>
      </div>
    </div>
    <hr>
    <div class="columns">
            <div class="column">
        <h2 class="subtitle is-5">Email</h2>
        <div class="content">
          <p><a :href="'mailto:' + usuario.email">{{usuario.email}}</a></p>
        </div>
      </div>
      <div class="column">
        <h2 class="subtitle is-5">Acceso</h2>
        <div class="content">
          <p class="is-mono">{{usuario.acceso}}</p>
        </div>
      </div>
      <div class="column">
        <h2 class="subtitle is-5">Espacio asignado</h2>
        <div class="content">
          <p>{{usuario.espacio_asignado == null ? 'Sin limites' : usuario.espacio_asignado }}</p>
        </div>
      </div>
    </div>
    <hr>
    <div class="columns">
      <div class="column">
        <h2 class="subtitle is-5">Roles</h2>
        <div class="tags">
          <p v-for="(role, index) in usuario.roles" :key="index" class="tag">{{role.descripcion}}</p>
          <p v-if="usuario.roles.length == 0"><i>No hay areas asignadas</i></p>
        </div>
      </div>
      <div class="column">
         <h2 class="subtitle is-5">Areas</h2>
         <div class="field is-grouped is-grouped-multiline" v-if="usuario.areas.length > 0">
          <div class="control" v-for="(area, index) in usuario.areas" :key="index">
            <div class="tags has-addons">
              <p class="tag is-dark">{{area.nombre}}</p>
              <p class="tag">{{area.pivot.cargo}}</p>
            </div>
          </div>
         </div>
        <p v-else><i>No hay areas asignadas</i></p>
      </div>
    </div>
    </div>
    <div class="notification is-danger" v-if="!usuario.acceso_habilitado">
      <i class="fas fa-user-slash"></i>&nbsp;El usuario ha sido dado de baja
      <span v-if="usuario.motivo_baja" class="is-italic">({{usuario.motivo_baja}})</span>
    </div>
    <div class="notification is-link" v-if="usuario.keystore">
      <i class="fas fa-info-circle"></i>&nbsp;El usuario <u>HA SUBIDO</u> con una copia de seguridad de su certificado
    </div>
    <div class="notification is-dark" v-else>
      <i class="fas fa-info-circle"></i>&nbsp;El usuario <u>NO HA SUBIDO</u> con una copia de seguridad de su certificado
    </div>
  </div>
</template>

<script>
export default {
  props: ['idOrganization', 'idUser'],
  computed: {
    usuario: function () {
      return this.$store.state.organizacion.selectedUser
    },
    organization: function () {
      return this.$store.state.organizacion.organizacion
    }
  }

}
</script>

<style lang="scss" scoped>
img{
  border-radius: 100px;
}
</style>
