const attributesUtils = {
  methods: {
    getNombreAtributo: function (tipo) {
      switch (tipo) {
        case 'string':
          return 'Texto'
        case 'number':
          return 'Número'
        case 'integer':
          return 'Número entero'
        case 'boolean':
          return 'Binario'
        case 'date':
          return 'Fecha'
        case 'enum':
          return 'Opción'
      }
    },
    isAtributoOptional: function (schema) {
      if (schema.type !== undefined) {
        return false
      }
      if (schema.oneOf !== undefined) {
        return true
      }
      return false
    },
    getPlaceholderAtributo: function (tipo) {
      switch (tipo) {
        case 'string':
          return 'Haga clic para ingresar texto'
        case 'number':
          return 'Haga clic para ingresar un número (decimales con punto)'
        case 'integer':
          return 'Haga clic para ingresar un número entero'
        case 'boolean':
          return 'Haga clic para elegir entre verdadero o falso'
        case 'date':
          return 'Haga clic para ingresar una fecha'
        case 'enum':
          return 'Haga clic para elegir entre las opciones'
      }
    }
  }
}

export default attributesUtils
