<template>
  <b-modal :active.sync="showInstaller" :can-cancel="false" scroll="keep" @close="cancelar">
    <div class="card">
      <div class="card-content">
        <h1 class="title is-4">
          <i class="fas fa-cube"></i>&nbsp;Instalación: Recibos de sueldo</h1>
        <h1 class="subtitle is-6">Configuración de parametros</h1>
        <div class="field">
          <label class="label">Carpeta de recibos</label>
          <div class="field">
            <div class="control">
              <a class="button is-static is-fullwidth has-text-left">{{getRuta}}</a>
            </div>
          </div>
          <explorer :initFolder="settings.origen_id || organizacion.slug" :slug="organizacion.slug" ref="listado" :rutas.sync="rutas" :selected.sync="selected"></explorer>
          <span class="help">Elija la carpeta donde se guardaran los recibos.</span>
        </div>
        <div class="field">
          <label class="label">Flujo de firmas del empeleado</label>
          <div class="control">
            <div class="select">
              <select v-model="settings.firmaEmpleado">
                <option value="0">Sin firma</option>
                <option value="1">Firma debil</option>
                <option value="2">Firma fuerte</option>
              </select>
            </div>
          </div>
        </div>
        <div class="field">
          <label class="label">Almacenes de software</label>
          <div class="control">
            <label class="checkbox">
              <input type="checkbox" value="path" v-model="settings.almacenesSoft">
              En PC central
            </label>
            <label class="checkbox">
              <input type="checkbox" value="file" v-model="settings.almacenesSoft">
              Certificado en archivo
            </label>
            <label class="checkbox">
              <input type="checkbox" value="mscapi" v-model="settings.almacenesSoft">
              Almacen de Windows
            </label>
            <label class="checkbox">
              <input type="checkbox" value="backup" v-model="settings.almacenesSoft">
              Almacen en Signar
            </label>
          </div>
        </div>
        <div class="field">
          <label class="label">(Opcional) Directorio local de los certificados</label>
          <div class="control">
            <input class="input" name="path" type="text" v-model="settings.pathCertificados" placeholder="Ej: /ruta/de/certificados" :class="{'is-danger': errors.has('path')}">
          </div>
        </div>
        <div class="field">
          <label class="label">(Opcional) Area de empleadores</label>
          <div class="control">
            <input class="input" name="area" type="number" v-model="settings.areaEmpleadores">
          </div>
        </div>
        <div class="field">
          <label class="label">Coordenadas de firma en conformidad</label>
          <div class="field is-horizontal">
            <div class="field-body">
              <div class="field">
                <div class="control">
                  <input class="input has-text-centered" type="text" name="coorConf0" v-model.number="settings.coorConf[0]" v-validate="'integer'" :class="{'is-danger': errors.has('coorConf0')}" placeholder="Número">
                </div>
              </div>
              <div class="field">
                <div class="control">
                  <input class="input has-text-centered" type="text" name="coorConf1" v-model.number="settings.coorConf[1]" v-validate="'integer'" :class="{'is-danger': errors.has('coorConf1')}" placeholder="Número">
                </div>
              </div>
              <div class="field">
                <div class="control">
                  <input class="input has-text-centered" type="text" name="coorConf2" v-model.number="settings.coorConf[2]" v-validate="'integer'" :class="{'is-danger': errors.has('coorConf2')}" placeholder="Número">
                </div>
              </div>
              <div class="field">
                <div class="control">
                  <input class="input has-text-centered" type="text" name="coorConf3" v-model.number="settings.coorConf[3]" v-validate="'integer'" :class="{'is-danger': errors.has('coorConf3')}" placeholder="Número">
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="field">
          <label class="label">Coordenadas de firma en disconformidad</label>
          <div class="field is-horizontal">
            <div class="field-body">
              <div class="field">
                <div class="control">
                  <input class="input has-text-centered" type="text" name="coorDisc0" v-model.number="settings.coorDisc[0]" v-validate="'integer'" :class="{'is-danger': errors.has('coorDisc0')}" placeholder="Número">
                </div>
              </div>
              <div class="field">
                <div class="control">
                  <input class="input has-text-centered" type="text" name="coorDisc1" v-model.number="settings.coorDisc[1]" v-validate="'integer'" :class="{'is-danger': errors.has('coorDisc1')}" placeholder="Número">
                </div>
              </div>
              <div class="field">
                <div class="control">
                  <input class="input has-text-centered" type="text" name="coorDisc2" v-model.number="settings.coorDisc[2]" v-validate="'integer'" :class="{'is-danger': errors.has('coorDisc2')}" placeholder="Número">
                </div>
              </div>
              <div class="field">
                <div class="control">
                  <input class="input has-text-centered" type="text" name="coorDisc3" v-model.number="settings.coorDisc[3]" v-validate="'integer'" :class="{'is-danger': errors.has('coorDisc3')}" placeholder="Número">
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="field">
              <label class="label">Coordenada empleador</label>
          <div class="field is-horizontal">
            <div class="field-body">
              <div class="field">
              <div class="control">
                <input class="input has-text-centered" type="text" name="coorEmpleador0" v-model.number="settings.coorEmpleador[0]" v-validate="'integer'" :class="{'is-danger': errors.has('coorEmpleador0')}" placeholder="Número">
              </div>
              </div>
              <div class="field">
               <div class="control">
                <input class="input has-text-centered" type="text" name="coorEmpleador1" v-model.number="settings.coorEmpleador[1]" v-validate="'integer'" :class="{'is-danger': errors.has('coorEmpleador1')}" placeholder="Número">
              </div>
              </div>
              <div class="field">
               <div class="control">
                <input class="input has-text-centered" type="text" name="coorEmpleador2" v-model.number="settings.coorEmpleador[2]" v-validate="'integer'" :class="{'is-danger': errors.has('coorEmpleador2')}" placeholder="Número">
              </div>
              </div>
              <div class="field">
               <div class="control">
                <input class="input has-text-centered" type="text" name="coorEmpleador3" v-model.number="settings.coorEmpleador[3]" v-validate="'integer'" :class="{'is-danger': errors.has('coorEmpleador3')}" placeholder="Número">
              </div>
              </div>
            </div>
            </div>
        </div>
        <div class="columns">
          <div class="column is-8">
            <div class="field">
              <label class="label">Patrón carga</label>
              <div class="field has-addons">
                <div class="control is-expanded">
                  <input class="input" type="text" name="patronCarga" v-validate="'required'" :class="{'is-danger': errors.has('patronCarga')}" v-model="settings.patron_carga">
                </div>
                <div class="control">
                  <a class="button is-static">.pdf</a>
                </div>
              </div>
            </div>
            <div class="field">
              <label class="label">Patrón almacenamiento</label>
              <div class="field has-addons">
                <div class="control is-expanded">
                  <input class="input" type="text" name="patronAlmacenamiento" v-validate="'required'" :class="{'is-danger': errors.has('patronAlmacenamiento')}" v-model="settings.patron_almacenamiento">
                </div>
                <div class="control">
                  <a class="button is-static">.pdf</a>
                </div>
              </div>
            </div>
          </div>
          <div class="column content is-small">
            <table class="table is-narrow">
              <thead>
                <tr>
                  <th colspan="2">Patrones disponibles</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>#d</th>
                  <td>Día</td>
                </tr>
                <tr>
                  <th>#m</th>
                  <td>Mes</td>
                </tr>
                <tr>
                  <th>#Y</th>
                  <td>Año</td>
                </tr>
                <tr>
                  <th>#uc</th>
                  <td>CUIT del empleado</td>
                </tr>
                <tr>
                  <th>#ui</th>
                  <td>ID del empleado</td>
                </tr>
                <tr>
                  <th>#rt</th>
                  <td>Tipo de recibo</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="field">
          <div class="notification is-primary">
            <i class="fas fa-info-circle fa-fw"></i> Recuerde asignar los roles <span class="tag is-white is-rounded">Empleador</span> y <span class="tag is-white is-rounded">Empleado</span>  a los usuarios para que utilicen la funcionalidad.
          </div>
        </div>
        <div class="field is-clearfix">
          <div class="buttons is-pulled-right">
            <button @click="cancelar" class="button is-dark is-outlined">
              Cancelar
            </button>
            <button v-if="params" @click="reinstalar" :disabled="errors.count() > 0" class="button is-primary">
              Guardar
            </button>
            <button v-else @click="instalar" :disabled="errors.count() > 0" class="button is-primary">
              Instalar módulo
            </button>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import Explorer from '@/components/utils/Explorer'
import fieldsUtils from '@/mixins/fieldsUtils'

export default {
  props: ['showInstaller', 'idOrganization', 'params'],
  mixins: [fieldsUtils],
  data () {
    return {
      settings: {
        origen_id: null,
        patron_carga: '#Y-#m-#d-#uc',
        patron_almacenamiento: '#Y-#m-#d-#rt-#uc',
        firmaEmpleado: 1,
        almacenesSoft: [],
        pathCertificados: '',
        areaEmpleadores: '',
        coorConf: [
          null,
          null,
          null,
          null
        ],
        coorDisc: [
          null,
          null,
          null,
          null
        ],
        coorEmpleador: [
          null,
          null,
          null,
          null
        ]
      },
      rutas: null,
      selected: null,
      isLoading: true,
      directorio: []
    }
  },
  components: {
    Explorer
  },
  created: function () {
    this.isLoading = false
    if (this.params) {
      this.settings.coorDisc = this.params.parametros.coordenadas_disconformidad
      this.settings.coorConf = this.params.parametros.coordenadas_conformidad
      this.settings.coorEmpleador = this.params.parametros.coordenadas_empleador
      this.settings.origen_id = this.params.parametros.origen_id
      this.settings.firmaEmpleado = this.params.parametros.firma_empleado
      this.settings.almacenesSoft = this.params.parametros.almacenes_software ? this.params.parametros.almacenes_software : []
      this.settings.pathCertificados = this.params.parametros.ruta_certificados
      this.settings.areaEmpleadores = this.params.parametros.area_empleadores
      this.settings.patron_carga = this.params.parametros.patron_carga.slice(0, -4)
      this.settings.patron_almacenamiento = this.params.parametros.patron_almacenamiento.slice(0, -4)
    }
  },
  methods: {
    cancelar: function () {
      this.selected = null
      if (this.params) { this.$emit('cancelarReinstalar') } else { this.$emit('cancelarInstalar') }
    },
    instalar: function () {
      this.isLoading = true
      this.$http
        .request({
          method: 'post',
          url: '/organizacion/' + this.organizacion.id + '/modulo/recib',
          data: this.payload
        })
        .then(response => {
          this.$buefy.snackbar.open({
            message: 'El modulo ha sido instalado con éxito',
            duration: 8000
          })
          this.isLoading = false
          this.$emit('instalado')
        })
        .catch(error => {
          this.errorMessage = error.message
          this.$buefy.snackbar.open({
            message: 'Error al instalar el modulo',
            type: 'is-warning',
            actionText: 'Cerrar',
            duration: 8000
          })
          this.isLoading = false
        })
    },
    reinstalar: function () {
      this.isLoading = true
      this.$http
        .request({
          method: 'post',
          url: '/instalacion/' + this.params.id,
          data: this.payload
        })
        .then(response => {
          this.$buefy.snackbar.open({
            message: 'El modulo ha sido reinstalado con éxito',
            duration: 8000
          })
          this.isLoading = false
          this.$emit('instalado')
        })
        .catch(error => {
          this.errorMessage = error.message
          this.$buefy.snackbar.open({
            message: 'Error al reinstalar el modulo',
            type: 'is-warning',
            actionText: 'Cerrar',
            duration: 8000
          })
          this.isLoading = false
        })
    }
  },
  computed: {
    organizacion: function () {
      return this.$store.state.organizacion.organizacion
    },
    getRuta: function () {
      if (this.selected == null) {
        return this.organizacion.slug
      } else {
        return this.selected.ruta
      }
    },
    getIdRuta: function () {
      return this.selected != null ? this.selected.id : null
    },
    payload: function () {
      return {
        origen_id: this.getIdRuta,
        patron_carga: this.settings.patron_carga + '.pdf',
        patron_almacenamiento: this.settings.patron_almacenamiento + '.pdf',
        firma_empleado: this.settings.firmaEmpleado,
        almacenes_software: this.settings.almacenesSoft,
        ruta_certificados: this.isFieldOptional(this.settings.pathCertificados),
        area_empleadores: this.isFieldOptional(this.settings.areaEmpleadores),
        coordenadas_conformidad: this.settings.coorConf,
        coordenadas_disconformidad: this.settings.coorDisc,
        coordenadas_empleador: this.settings.coorEmpleador
      }
    }
  }
}
</script>

<style lang="scss" scoped>
table.table{
  margin-bottom: 0;
}
</style>
