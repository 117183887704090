const validatorUtils = {
  methods: {
    clearValidatorErrors: function (scope) {
      this.$nextTick().then(() => {
        this.$validator.reset(scope)
        this.errors.clear(scope)
      })
    }
  }
}

export default validatorUtils
