<template>
    <div class="card">
        <div class="card-content">
            <div class="level">
                <div class="level-left">
                    <div class="level-item">
                        <h1 class="title is-4">
                            <i class="fas fa-cube fa-fw"></i> Auditorias</h1>
                    </div>
                </div>
                <div class="level-right">
                    <div class="level-item" v-if="$store.getters.hasAnyRole(['lyris', constants.STRINGS.ROLES.LYRIS_SOPORTE.ID])">
                        <b-switch v-model="isSwitched" @click.native="watchInput">{{isSwitched ? 'Habilitado' : 'Deshabilitado'}}</b-switch>
                    </div>
                    <div class="level-item" v-if="$store.getters.hasAnyRole(['admin'])">
                        <i class="fas fa-lg fa-fw" :class="isSwitched ? 'fa-check' : 'fa-info-circle'"></i> {{isSwitched ? 'Habilitado' : 'Consulte con Lyris para contratar el servicio'}}
                    </div>
                </div>
            </div>
            <div class="columns">
                <div class="column is-8">
                    <div class="content">
                        <p>{{about.descripcion}}</p>
                    </div>
                </div>
                <div class="column">
                    <div class="content is-small">
                        <table class=" table is-narrow is-bordered">
                            <thead>
                                <tr>
                                    <th colspan="2" class="has-text-centered">Configuración del módulo</th>
                                </tr>
                            </thead>
                            <tbody v-if="settings && settings.parametros != null && settings.parametros.length > 0">
                                <tr v-for="(param, index) in settings.parametros" :key="index">
                                    <th>{{index}}</th>
                                    <td>{{param}}</td>
                                </tr>
                            </tbody>
                            <tbody v-else>
                                <tr>
                                    <td colspan="2" class="has-text-centered">El módulo no tiene parámetros configurables</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <installer :idOrganization="idOrganization" :id-modulo="about.id" :show-installer.sync="showInstaller" @cancelarInstalar="cancelarInstalar" @instalado="instalado"></installer>
            <uninstall :show-uninstaller.sync="showUninstaller" :idOrganization="idOrganization" :modulo="settings.modulo_id" @cancelarDesinstalar="cancelarDesinstalar" @desinstalado="desinstalado" v-if="settings != undefined"></uninstall>
        </div>
    </div>
</template>

<script>
import Installer from './ModuleInstaller'
import Uninstall from '../Uninstall'
import constants from '@/constants'

export default {
  props: ['idOrganization', 'about', 'settings'],
  components: {
    Installer,
    Uninstall
  },
  data () {
    return {
      constants,

      isSwitched: this.settings !== undefined,
      showInstaller: false,
      showUninstaller: false
    }
  },
  methods: {
    instalar: function () {
      this.showInstaller = true
    },
    instalado: function () {
      this.showInstaller = false
      this.$emit('update')
    },
    desinstalar: function () {
      this.showUninstaller = true
    },
    desinstalado: function () {
      this.showUninstaller = false
      this.$emit('update')
    },
    cancelarInstalar: function () {
      this.showInstaller = false
      this.isSwitched = false
    },
    cancelarDesinstalar: function () {
      this.showUninstaller = false
      this.isSwitched = true
    },
    watchInput: function () {
      if (this.isSwitched) {
        this.desinstalar()
      } else {
        this.instalar()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
