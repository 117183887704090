<template>
  <div class="card">
    <div class="card-content">
      <h1 class="title is-3 has-text-centered">Carga masiva de usuarios</h1>
      <h2 class="subtitle is-5 has-text-centered">Cargue un CSV para hacer una carga masiva de usuarios</h2>
      <b-message type="is-primary">
        <div class="content">
          <p>
           <b>Nota:</b> Puede descargar el siguiente template de ejemplo: <a href="/statics/batch-usuarios-template.csv" target="_blank"><i class="fas fa-file"></i> Descargar</a>
          </p>
        </div>
      </b-message>
        <div class="field">
          <label for="" class="label">Subir CSV</label>
          <div class="control">
            <div class="file has-name is-fullwidth">
              <label class="file-label">
                <input @change="processFile($event)" class="file-input" type="file" accept="text/csv" name="resume">
                <span class="file-cta">
                  <span class="file-icon">
                    <i class="fas fa-search"></i>
                  </span>
                  <span class="file-label">
                    Examinar...
                  </span>
                </span>
                <span class="file-name">
                  {{file ? file.name : 'Seleccione un archivo .csv para subir' }}
                </span>
              </label>
            </div>
          </div>
        </div>
      <hr />
      <b-message type="is-danger" v-if="error">
        {{error}}
      </b-message>
      <div class="buttons is-centered">
        <button class="button is-dark is-outlined" type="button" @click="$parent.close()">Cancelar</button>
        <button class="button is-primary" type="button" @click="submit">Cargar</button>
      </div>
    </div>
  </div>
</template>

<script>
import constants from '@/constants'

export default {
  props: ['idOrganization', 'idUser'],
  data () {
    return {
      constants,

      file: null,
      ok: false,
      error: null
    }
  },
  methods: {
    processFile (event) {
      this.file = event.target.files[0]
    },
    submit: function () {
      if (!this.$store.getters.hasAnyRole(['lyris', constants.STRINGS.ROLES.LYRIS_SOPORTE.ID])) {
        return false
      }
      this.ok = false
      this.error = null
      this.startLoading()
      const data = new FormData()
      data.append('archivo', this.file)
      this.$http
        .request({
          method: 'post',
          url: '/organizacion/' + this.idOrganization + '/batch-usuarios',
          data: data
        })
        .then(response => {
          this.ok = true
          this.$buefy.snackbar.open({
            message: 'Se ha hecho la carga masiva de usuarios',
            duration: 6000
          })
          this.$emit('reload')
          console.log(response)
          if (response.data.excluidos.length) {
            this.error = `Falló la carga de algunos usuarios: [${response.data.excluidos.join(',')}]`
          } else {
            this.$parent.close()
          }
        })
        .catch(error => {
          this.error = error.mensaje
          console.error(error)
          this.$buefy.snackbar.open({
            message: `Error: ${error.message}`,
            type: 'is-warning',
            actionText: 'Cerrar',
            duration: 6000
          })
        })
        .finally(() => {
          this.stopLoading()
        })
    }
  }
}
</script>
