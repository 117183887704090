<template>
  <section>
      <div class="card is-radiusless" v-if="documento.permisos_heredados">
        <div class="card-content is-radiusless py-3 px-4">
          <p class="is-size-7"><i class="fas fa-info fa-fw fa-lg has-text-primary mr-3"></i>{{getNombreFile()}} <i :class="getIconFile()"></i> cuenta con permisos <b>heredados</b></p>
        </div>
      </div>
      <div class="card is-radiusless" v-if="documento.permisos_bloqueados && documento.tipo_documento_id === 'carpeta'">
        <div class="card-content is-radiusless py-3 px-4">
          <p class="is-size-7"><i class="fas fa-info fa-fw fa-lg has-text-primary mr-3"></i>Se está forzando la herencia de permisos hacia abajo.</p>
        </div>
      </div>
      <div class="card is-radiusless"
        v-for="(permiso,index) in documento.permisos"
        :key="`permiso-${index}`"
      >
        <div class="card-header is-radiusless" :class="{'has-background-warning-light': isPermisoHeredado(permiso)}">
          <div class="card-header-title is-400 is-flex-direction-row is-align-items-start is-justify-content-space-between pr-0">
            <div>
              <a @click="toggleModalConfirmDelete(permiso)" v-if="canEditPermisos" :disabled="permiso.dependencia_id != null || permiso.nivel == 9"><i class="fas fa-times-circle"></i></a>
              <span class="ml-2">

              <i class="fas fa-fw" :class="getTipoPermisoIcon(permiso)"></i>
              &nbsp;{{getSujetoPermiso(permiso)}}
              <b-tooltip v-if="isPermisoHeredado(permiso)" position="is-right" type="is-warning" :label="permiso.modificado ? 'El permiso heredado ha sido modificado' : 'El permiso es heredado de un recurso superior'">
                <span class="tag is-warning is-light is-rounded ml-2 is-unselectable">
                  <i class="fas fa-level-down-alt fa-fw" v-if="!permiso.modificado"></i><i class="fas fa-pencil-alt fa-fw" v-else></i>&nbsp;{{permiso.modificado ? "Modificado" : "Heredado"}}
                </span>
              </b-tooltip>
              </span>
            </div>
            <div class="has-text-right">
                <span class="is-size-7 is-unselectable">Nivel <b>{{permiso.nivel}}</b></span>&nbsp;
                 <b-tooltip :label="`Nivel ${nivelBoton}`"
                  v-for="nivelBoton in 9"
                  :key="`permiso-${permiso.id}-nivelBoton-${nivelBoton}`"
                  position="is-left"
                  type="is-info"
                  :animated="false">

                <i
                @click="changeLevel(permiso, nivelBoton)"
                :disabled="isChangeNivelDisabled(permiso, nivelBoton)"
                class="fas fa-lock fa-fw"
                :class="getColor(permiso, nivelBoton)"
                ></i>
                </b-tooltip>
            </div>
          </div>
          <a class="card-header-icon" @click="openCollapse(index)">
            <i class="fas fa-lg" :class="isOpen === index ? 'fa-angle-up' : 'fa-angle-down'" />
          </a>
        </div>
        <b-collapse
          :open="isOpen === index"
          animation="slide"
          >
          <div class="card-content is-radiusless">
              <ListadoPermisos :level="permiso.nivel" />
          </div>
        </b-collapse>
      </div>
      <!-- <div class="card is-radiusless">
        <div class="card-content is-radiusless has-background-white">
        </div>
      </div> -->
      <div class="card is-radiusless" v-if="canEditPermisos">
        <div class="card-content is-radiusless p-3">
          <div class="field is-grouped">
            <div class="control is-expanded">
              <button @click="openModalAgregarUsuario" class="button is-fullwidth is-outlined is-fullwdith is-small is-primary">
                <i class="fas fa-user fa-fw"></i>&nbsp;Agregar usuario
              </button>
            </div>
            <div class="control is-expanded">
              <button @click="openModalAgregarArea" class="button is-fullwidth is-outlined is-small is-primary">
                <i class="fas fa-users fa-fw"></i>&nbsp;Agregar area
              </button>
            </div>
            <div class="control is-expanded" v-if="isOwnerDocumento">
              <button @click="openModalCambiarPropietario" class="button is-fullwidth is-outlined is-small is-primary">
                <i class="fas fa-exchange-alt fa-fw"></i>&nbsp;Cambiar propietario
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="card is-radiusless" v-if="isOwnerDocumento">
        <b-collapse
          :open="isOpen === 10000"
          animation="slide"
          >
          <template #trigger="props">
            <div class="card-header is-radiusless"  role="button">
              <div class="card-header-title is-flex-direction-row is-align-items-start is-justify-content-space-between">
                  Opciones avanzadas
              </div>
              <a class="card-header-icon" @click="openCollapse(10000)">
                <i class="fas fa-lg" :class="props.open ? 'fa-angle-up' : 'fa-angle-down'" />
              </a>
            </div>
        </template>
          <div class="card-content">
            <div class="columns">
              <div class="column">
                <div class="field">
                  <label class="label">Heredar permisos superiores</label>
                  <p class="is-size-7 mb-3">Al estar <b>activado</b>, {{getNombreFile().toLowerCase()}} <i :class="getIconFile()"></i> heredará los permisos de la carpeta superior. Los permisos heredados pueden ser luego <b>modificados</b> con otros niveles de permiso en caso de requerirlo</p>
                  <p class="is-size-7 mb-3"><span class="has-text-danger"><i class="fas fa-exclamation-triangle"></i> IMPORTANTE: </span>Solo el <b>propietario</b> <i class="far fa-star"></i> del recurso puede cambiar esta opción.<span v-if="!heredado">&nbsp;Al activar la herencia, puede haber cambio de propietario. Los cambios de propietarios son <b>permanentes</b></span></p>
                  <div class="control">
                    <b-switch
                        @input="openModalConfirmarHerenciaPermisos"
                        size="is-small"
                        :type="showPosibleHerenciaForzada ? 'is-danger' : null"
                        v-model="heredado"
                      >{{heredado ? 'Activado' : 'Desactivado'}}</b-switch>
                  </div>
                  <p class="is-size-7 mt-2 has-text-danger" v-if="showPosibleHerenciaForzada"><i class="fas fa-exclamation-triangle"></i>&nbsp;Error al cambiar la herencia de permisos. ¿Revisó si la carpeta contenedora de esta carpeta o documento está forzando la herencia?</p>
                </div>
              </div>
              <div class="column" v-if="canBlockPermisos">
                <div class="field">
                  <label class="label">Forzar herencia de permisos hacia abajo</label>
                  <p class="is-size-7 mb-3">Al estar <b>activado</b>, los documentos y carpetas dentro de {{getNombreFile().toLowerCase()}} <i :class="getIconFile()"></i> heredaran los permisos por defecto y no podrá ser removida</p>
                  <p class="is-size-7 mb-3">Solo el <b>propietario</b> <i class="far fa-star"></i> del recurso puede cambiar esta opción</p>
                  <div class="control">
                    <b-switch
                        @input="openModalBloquearPermisos"
                        size="is-small"
                        v-model="blocked"
                      >{{blocked ? 'Activado' : 'Desactivado'}}</b-switch>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-collapse>
      </div>
  </section>
</template>

<script>
import ModalAgregarUsuario from './ModalAgregarUsuario'
import ModalAgregarArea from './ModalAgregarArea'
import ModalCambiarPropietario from './ModalCambiarPropietario'
import ListadoPermisos from './ListadoPermisos'
import ModalListadoPermisos from './ModalListadoPermisos'
import ModalConfirmAction from '@/components/utils/ModalConfirmAction'

export default {
  props: {
    documento: {
      type: Object,
      required: true
    },
    // carpetaSuperior: {
    //   type: Object,
    //   required: false,
    //   validator: function (folder) {
    //     // The value must match one of these strings
    //     return folder.tipo_documento_id === 'carpeta'
    //   }
    // },
    nivelPermisoUser: {
      type: Number,
      required: true,
      default: 0
    }
  },
  data () {
    return {
      isOpen: null,
      heredado: false,
      blocked: false,
      showPosibleHerenciaForzada: false
    }
  },
  components: {
    ListadoPermisos
  },
  mounted: function () {
    // No es mas facil documento.permisos_bloqueados ?
    // this.heredado = this.chequearHerencia()
    this.heredado = this.documento.permisos_heredados
    this.blocked = this.documento.permisos_bloqueados
  },
  computed: {
    user: function () {
      return this.$store.getters.getUser
    },
    isOwnerDocumento: function () {
      return this.nivelPermisoUser === 9
    },
    isPermisosDocumentosHeredados: function () {
      return this.documento.permisos_heredados
    },
    canEditPermisos: function () {
      return this.nivelPermisoUser >= 8
    },
    canBlockPermisos: function () {
      return this.documento.tipo_documento_id === 'carpeta'
    }
    // isHerenciaForzada: function () {
    //   return this.carpetaSuperior.permisos_bloqueados
    // }
  },
  methods: {
    openCollapse (index) {
      if (this.isOpen === index) this.isOpen = null
      else this.isOpen = index
    },
    isChangeNivelDisabled: function (permiso, nivelBoton) {
      /**
       * NOTA: Cambiado, que yo este forzando la herencia para abajo no evita que yo pueda seguir cambiando los permisos!
       * El usuario no tiene permiso de modificar permisos sobre el documento
       */
      // if (this.blocked) return true
      /**
       * El usuario no tiene permiso de modificar permisos sobre el documento
       */
      if (this.nivelPermisoUser < 8) return true
      /**
       * Si el permiso es de un usuario y es un permiso de propietario,
       * no se puede modificar ninguno de los botones del permiso del propietario
       */
      if (permiso.usuario && permiso.nivel === 9) return true
      /**
       * Si el permiso no es de una dependencia_id pero es propietario del archivo,
       * no se puede modificar ninguno de los botones del permiso del propietario
       */
      if (permiso.dependencia_id != null && permiso.nivel === 9) return true
      /**
       * Si el boton es el de propietario, deshabilitar, porque hay un boton para cambiar propietario
       */
      if (nivelBoton === 9) return true
      /**
       * Todo ok, no esta deshabilitado
       */
      return false
      /**
       * Old reglas... evaluar que quiere decir
       */
      // (permiso.usuario && permiso.nivel == 9) || (permiso.dependencia_id != null && permiso.nivel == 9) || (((ermiso.usuario && permiso.usuario.id != user.id) || (permiso.usuario == null)) && n == 9)
    },
    chequearHerencia: function () {
      return this.documento.permisos.some(p => p.dependencia_id != null)
    },
    isPermisoHeredado: function (permiso) {
      return permiso.dependencia_id != null
    },
    toggleModalPermisosHelp: function (nivel) {
      this.$buefy.modal.open({
        parent: this,
        component: ModalListadoPermisos,
        width: 720,
        hasModalCard: true,
        props: {
          nivel: nivel
        }
      })
    },
    toggleModalConfirmDelete: function (permiso) {
      this.$buefy.modal.open({
        parent: this,
        component: ModalConfirmAction,
        width: 720,
        props: {
          title: 'Eliminar permiso',
          actionText: 'Eliminar',
          actionClass: 'has-text-danger'
        },
        events: {
          accept: () => this.deletePermiso(permiso)
        }
      })
    },
    getColor: function (permiso, nivelBoton) {
      const isDisabled = this.isChangeNivelDisabled(permiso, nivelBoton)
      if (permiso.nivel >= nivelBoton) {
        return {
          'has-text-primary': true,
          'is-clickable': !isDisabled,
          'disabled-nivel': isDisabled && this.canEditPermisos
        }
      } else {
        return {
          'has-text-grey-light': true,
          'is-clickable': !isDisabled,
          'disabled-nivel': isDisabled && this.canEditPermisos
        }
      }
    },
    getIcon: function (nivel, top) {
      if (nivel >= top) {
        return 'fa-check'
      }
      return 'fa-times'
    },
    getIconFile () {
      switch (this.documento.tipo_documento_id) {
        case 'carpeta':
          return 'far fa-folder'
        case 'recibo':
          return 'fas fa-dollar-sign'
        case 'unidadCompuesta':
          return 'far fa-file-archive'
        default:
          return 'far fa-file'
      }
    },
    getNombreFile () {
      switch (this.documento.tipo_documento_id) {
        case 'carpeta':
          return 'La carpeta'
        case 'recibo':
          return 'El recibo'
        case 'unidadCompuesta':
          return 'La unidad compuesta'
        default:
          return 'El documento'
      }
    },
    getTipoPermisoIcon: function (permiso) {
      if (permiso.usuario) {
        return permiso.nivel === 9 ? 'fa-user-lock' : 'fa-user'
      }
      if (permiso.area) {
        return 'fa-users'
      }
      if (permiso.organizacion) {
        return 'fa-building'
      }
      return 'fa-question'
    },
    getSujetoPermiso: function (permiso) {
      if (permiso.usuario) {
        return permiso.usuario.nombre + ' ' + permiso.usuario.apellido
      }
      if (permiso.area) {
        return permiso.area.nombre
      }
      if (permiso.organizacion) {
        return permiso.organizacion.razon_social
      }
      return '???'
    },
    crearPermiso: function (type, id, nivel) {
      const payload = {
        nivel: nivel
      }
      payload[type] = id
      this.startLoading()
      this.$http.post(`/documento/${this.documento.id}/permiso`, payload)
        .then(response => {
          this.$buefy.snackbar.open({
            message: 'Permiso creado',
            type: 'is-success'
          })
          this.$emit('updateDocument')
        })
        .catch(error => {
          console.error('Fail', error)
          this.$buefy.snackbar.open({
            message: 'Error, no se pudo crear el permiso',
            type: 'is-danger'
          })
        })
        .finally(() => {
          this.stopLoading()
        })
    },
    deletePermiso: function (permiso) {
      this.startLoading()
      this.$http
        .delete(`/permiso/${permiso.id}`)
        .then(response => {
          this.$buefy.snackbar.open({
            message: 'Permiso eliminado',
            type: 'is-success'
          })
          this.$emit('updateDocument')
        })
        .catch(error => {
          console.error('Fail', error)
          this.$buefy.snackbar.open({
            message: 'Error, no se pudo eliminar el permiso',
            type: 'is-danger'
          })
        })
        .finally(() => {
          this.stopLoading()
        })
    },
    cambiarPropietario: function (nuevoPropietarioId) {
      if (this.nivelPermisoUser < 9) return
      const payload = {
        propietario_id: nuevoPropietarioId
      }
      this.startLoading()
      this.$http.patch(`/documento/${this.documento.id}/propietario`, payload)
        .then(response => {
          this.$buefy.snackbar.open({
            message: 'Permiso creado',
            type: 'is-success'
          })
          this.$emit('updateDocument')
        })
        .catch(error => {
          console.error('Fail', error)
          this.$buefy.snackbar.open({
            message: 'Error, no se pudo crear el permiso',
            type: 'is-danger'
          })
        })
        .finally(() => {
          this.stopLoading()
        })
    },
    changeLevel: function (permiso, level) {
      if (this.isChangeNivelDisabled(permiso, level)) return
      const payload = {
        nivel: level
      }
      // if (permiso.usuario) {
      //   payload.usuario_id = permiso.usuario.id;
      // }
      // if (permiso.organizacion) {
      //   payload.organizacion_id = permiso.organizacion.id;
      // }
      // if (permiso.area) {
      //   payload.area_id = permiso.area.id;
      // }
      this.$http.patch(`/permiso/${permiso.id}`, payload)
        .then(response => {
          this.$buefy.snackbar.open({
            message: 'Permiso actualizado',
            type: 'is-success'
          })
          this.$emit('updateDocument')
        })
        .catch(error => {
          console.error('Fail', error)
          this.$buefy.snackbar.open({
            message: 'Error, no se pudo actualizar el permiso',
            type: 'is-danger'
          })
        })
    },
    openModalAgregarArea: function () {
      this.$buefy.modal.open({
        parent: this,
        component: ModalAgregarArea,
        width: 720,
        events: {
          crearPermiso: (type, id, nivel) => this.crearPermiso(type, id, nivel)
        }
      })
    },
    openModalAgregarUsuario: function () {
      this.$buefy.modal.open({
        parent: this,
        component: ModalAgregarUsuario,
        width: 720,
        events: {
          crearPermiso: (type, id, nivel) => this.crearPermiso(type, id, nivel)
        }
      })
    },
    openModalCambiarPropietario: function () {
      this.$buefy.modal.open({
        parent: this,
        component: ModalCambiarPropietario,
        width: 720,
        events: {
          cambiarPropietario: (nuevoPropietarioId) => this.cambiarPropietario(nuevoPropietarioId)
        }
      })
    },
    openModalConfirmarHerenciaPermisos: function () {
      this.$buefy.modal.open({
        parent: this,
        component: ModalConfirmAction,
        width: 720,
        props: {
          title: this.heredado ? 'Heredar permisos' : 'Quitar herencia de permisos',
          subtitle: this.heredado ? 'Recuerde: Los cambios de propietarios son <b>permanentes</b> y usted puede perder permiso de propietario. ¿Esta seguro?' : null
        },
        events: {
          accept: () => {
            if (this.heredado) {
              this.activarPermisosHeredados()
            } else {
              this.desactivarPermisosHeredados()
            }
          },
          cancel: () => {
            this.heredado = !this.heredado
          }
        },
        onCancel: () => {
          this.heredado = !this.heredado
        }
      })
    },
    activarPermisosHeredados: function () {
      this.startLoading()
      this.$http
        .post(`/documento/${this.documento.id}/permisos-heredados`)
        .then(res => {
          this.$buefy.snackbar.open({
            message:
              'El documento ahora hereda permisos de su carpeta contenedora',
            type: 'is-success'
          })
          this.$emit('updateDocument')
        })
        .catch(err => {
          this.heredado = !this.heredado
          this.showPosibleHerenciaForzada = true
          console.error(err)
          this.$buefy.snackbar.open({
            message: 'Error, no se pudo actualizar los permisos del documento',
            type: 'is-danger'
          })
        })
        .finally(() => {
          this.stopLoading()
        })
    },
    desactivarPermisosHeredados () {
      this.startLoading()
      this.$http
        .delete(`/documento/${this.documento.id}/permisos-heredados`)
        .then(res => {
          this.$buefy.snackbar.open({
            message:
              'El documento ya no hereda permisos de su carpeta contenedora',
            type: 'is-success'
          })
          this.$emit('updateDocument')
        })
        .catch(err => {
          this.heredado = !this.heredado
          this.showPosibleHerenciaForzada = true
          console.error(err)
          this.$buefy.snackbar.open({
            message: 'Error, no se pudo actualizar los permisos del documento',
            type: 'is-danger'
          })
        })
        .finally(() => {
          this.stopLoading()
        })
    },
    openModalBloquearPermisos: function () {
      this.$buefy.modal.open({
        parent: this,
        component: ModalConfirmAction,
        width: 720,
        props: {
          title: this.blocked ? 'Bloquear permisos' : 'Desbloquear permisos'
        },
        events: {
          accept: () => {
            if (this.blocked) {
              this.bloquearPermisos()
            } else {
              this.desbloquearPermisos()
            }
          },
          cancel: () => {
            this.blocked = !this.blocked
          }
        },
        onCancel: () => {
          this.blocked = !this.blocked
        }
      })
    },
    bloquearPermisos: function () {
      this.startLoading()
      this.$http
        .post(`/documento/${this.documento.id}/permisos-bloqueados`)
        .then(res => {
          this.$buefy.snackbar.open({
            message: 'Se han bloqueado los permisos correctamente',
            type: 'is-success'
          })
          this.$emit('updateDocument')
        })
        .catch(err => {
          console.error(err)
          this.$buefy.snackbar.open({
            message: 'Error, no se han bloqueado los permisos',
            type: 'is-danger'
          })
        })
        .finally(() => {
          this.stopLoading()
        })
    },
    desbloquearPermisos () {
      this.startLoading()
      this.$http
        .delete(`/documento/${this.documento.id}/permisos-bloqueados`)
        .then(res => {
          this.$buefy.snackbar.open({
            message: 'Se han desbloqueado los permisos correctamente',
            type: 'is-success'
          })
          this.blocked = !this.blocked
          this.$emit('updateDocument')
        })
        .catch(err => {
          console.error(err)
          this.blocked = !this.blocked
          this.$buefy.snackbar.open({
            message: 'Error, no se ha podido desbloquear los premisos',
            type: 'is-danger'
          })
        })
        .finally(() => {
          this.stopLoading()
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.is-heredado {
  background-color: #fff4ba;
}
.disabled-nivel{
  opacity: 0.4;
}
</style>
