<template>
  <section>
    <div class="box">
      <h1 class="subtitle is-4">Oficial de Registro</h1>
      <h1 class="title is-3">Oficiales de la organización</h1>
      <div class="content">
        <p>Usuarios de la organización con el rol "Oficial de registro".</p>
      </div>
    </div>
    <div class="box">
      <Listado />
    </div>
  </section>
</template>
<script>
import Listado from '@/components/organizacion/autoridad-certificante/oficiales-registro/Listado'
export default {
  components: {
    Listado
  }
}
</script>
