// import store from '@/store'
// import http from '@/http'

const globals = {
  methods: {
    startLoading: function () {
      this.$eventHub.$emit('startLoading')
    },
    stopLoading: function () {
      this.$eventHub.$emit('stopLoading')
    }
  }
}

export default globals
