<template>
    <div class="card">
      <header class="card-header">
        <p class="card-header-title">Crear unidad compuesta</p>
      </header>
      <section class="card-content">
        <div class="field">
          <label class="label">Nombre de unidad compuesta</label>
          <div class="control">
            <input type="text" class="input" name="Nombre de unidad compuesta" v-validate="{required:true}" v-model="nombre" data-vv-scope="crear-unidad-compuesta">
            <span
            class="help is-danger"
            v-if="errors.has('Nombre de unidad compuesta','crear-unidad-compuesta')"
          >{{errors.first('Nombre de unidad compuesta','crear-unidad-compuesta')}}</span>
          </div>
        </div>
        <div class="field">
          <label class="label">Serie documental de la unidad compuesta</label>
          <div class="control">
            <div class="select is-fullwidth">
              <select name="Serie" v-model="serie_documental_id" v-validate="{required:true}" data-vv-scope="crear-unidad-compuesta">
                <option :value="null" disabled>- Seleccione una serie documental -</option>
                <option :value="serie.id" v-for="(serie, index) in seriesDocumentales" :key="index">{{serie.nombre}}</option>
              </select>
            </div>
              <span
                  class="help is-danger"
                  v-if="errors.has('Serie','crear-unidad-compuesta')"
                >{{errors.first('Serie','crear-unidad-compuesta')}}</span>
          </div>
        </div>
        <div class="field">
          <label class="label">Heredar permisos</label>
          <p class="is-size-7 has-text-primary mb-3" v-if="forceHerencia"><i class="fas fa-exclamation-triangle"></i>&nbsp;<b>Nota:</b> Los documentos y carpetas de la carpeta {{currentFolder.name}} heredarán los permisos por defecto</p>
          <div class="control">
          <b-switch size="is-small" v-model="permisos" :disabled="forceHerencia">Heredar permisos de la carpeta superior</b-switch>
          </div>

        </div>
      </section>
      <footer class="card-footer">
            <a class="card-footer-item" type="button" @click="$parent.close()">Cancelar</a>
        <a class="card-footer-item has-text-weight-bold is-uppercase" @click.prevent="submit()">Crear</a>
      </footer>
    </div>
</template>

<script>
export default {
  data () {
    return {
      nombre: null,
      serie_documental_id: null,
      seriesDocumentales: [],
      permisos: true
      // error: ''
    }
  },
  computed: {
    currentFolder: function () {
      return this.$store.state.repository.currentFolder
    },
    forceHerencia: function () {
      return this.currentFolder.permisos_bloqueados
    },
    organizacionId: function () {
      return this.$store.getters.getUser.organization
    }
  },
  created () {
    this.getSeries()
  },
  methods: {
    getSeries: function () {
      this.startLoading()
      this.$http(`/organizacion/${this.organizacionId}/serie-documental?abierta=1`)
        .then(res => {
          this.seriesDocumentales = res.data
        }).catch(error => {
          console.error(error)
          this.$buefy.snackbar.open({
            message: 'Error al obtener el listado de series documentales',
            type: 'is-warning'
          })
        }).finally(() => {
          this.stopLoading()
        })
    },
    submit: function () {
      this.$validator.validateAll('crear-unidad-compuesta').then(result => {
        if (!result) {
          this.$buefy.snackbar.open({
            message: 'Hay errores en el formulario',
            type: 'is-danger'
          })
          return
        }
        const payload = {
          nombre: this.nombre,
          serie_documental_id: this.serie_documental_id,
          origen_id: this.currentFolder.id,
          permisos_heredados: this.permisos
        }
        this.$http.request({
          method: 'post',
          url: '/unidad-compuesta',
          data: payload
        })
          .then(res => {
            this.$buefy.snackbar.open({
              message: res.data.mensaje,
              type: 'is-success'
            })
            this.$parent.close()
            // console.log(res.data)
            this.$router.push('/unidad-compuesta/' + res.data.unidad.id)
          })
          .catch(error => {
            this.$buefy.snackbar.open({
              message: 'Ocurio un error',
              type: 'is-danger'
            })
            console.error(error)
          })
      }).catch(err => {
        console.error(err)
      })
    }
  }
}
</script>

<style lang="scss">
   .upload .upload-draggable{
    width: 100%;
  }
</style>
