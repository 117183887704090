<template>
  <section>

  <div class="box">
    <h1 class="subtitle is-5">Administrar organización</h1>
      <h1 class="title is-1">Series documentales</h1>
      <hr>
     <router-link :to="{ name: 'organizacionCrearSerieDocumental' }" class="button is-outlined is-primary is-fullwidth" exact>
      <i class="fas fa-plus fa-fw"></i>&nbsp;Nueva serie documental
    </router-link>
  </div>
  <b-collapse
      class="card mb-1 is-radiusless"
      animation="slide"
      v-for="(serie, index) of seriesDoc"
      :key="index"
      :open="openCollapse == index"
      @open="openCollapse = index">
      <template #trigger="props">
          <div
              class="card-header is-radiusless"
              role="button"
          >
              <div class="card-header-title is-flex-direction-column is-align-items-start">
                  <p class="">{{serie.nombre}}<span class="tag is-black ml-2 is-400" v-show="!serie.abierta"><i class="fas fa-lock"></i>&nbsp;Cerrada</span></p>
                  <p class="is-size-7 is-400">{{truncate(serie.descripcion, 100)}}</p>
                        <!--<div class="tags is-400">
                          <span
                            class="tag"
                            :class="e != serieDocumental.estado_inicial ? 'is-light' : 'is-link'"
                            v-for="e in evento.estados_iniciales"
                            :key="e"
                          >{{serieDocumental.estados[e]}}</span>
                          <span class="tag is-white">
                            <i class="fas fa-arrow-right"></i>
                          </span>
                          <span
                            class="tag"
                            :class="evento.estado_final != serieDocumental.estado_inicial ? 'is-light' : 'is-link'"
                            v-if="serieDocumental.estados[evento.estado_final]"
                          >{{serieDocumental.estados[evento.estado_final]}}</span>
                          <span class="tag is-light" v-else>
                            <i class="fas fa-asterisk"></i>
                          </span>
                        </div> -->
              </div>
              <div class="card-header-icon">
                  <b-icon
                      :icon="props.open ? 'caret-up' : 'caret-down'">
                  </b-icon>
                  <router-link :to="{name: 'organizacionVerSerieDocumental', params: {idSerie: serie.id} }">
                    <b-icon
                      icon="arrow-alt-circle-right">
                  </b-icon>
                  </router-link>
              </div>
          </div>
      </template>
      <div class="card-content is-radiusless">
        <nav class="breadcrumb has-dot-separator" aria-label="breadcrumbs">
          <ul>
            <li><router-link :to="{name: 'organizacionVerSerieDocumental', params: {idSerie: serie.id} }"><i class="fas fa-eye"></i>&nbsp;Ver serie</router-link></li>
            <li><router-link :to="{name: 'organizacionEditarSerieDocumental', params: {idSerie: serie.id} }"><i class="fas fa-edit"></i>&nbsp;Editar serie</router-link></li>
            <li v-if="serie.abierta"><a @click="openConfirmCerrar(serie)"><i class="fas fa-lock"></i>&nbsp;Cerrar serie</a></li>
            <li v-else><a @click="openConfirmAbrir(serie)"><i class="fas fa-lock-open"></i>&nbsp;Abrir serie</a></li>
            <li><a @click="openConfirmDelete(serie)" class="has-text-danger"><i class="fas fa-trash"></i>&nbsp;Eliminar serie</a></li>
          </ul>
        </nav>
          <h2 class="subtitle is-5">Descripción</h2>
          <div class="content">
              {{ serie.descripcion }}
          </div>
      </div>
    </b-collapse>
  </section>
</template>

<script>
import stringUtils from '@/mixins/stringUtils'

export default {
  props: ['idOrganization'],
  data () {
    return {
      openCollapse: null
      // seriesDoc: []
    }
  },
  mixins: [stringUtils],
  methods: {
    openConfirmDelete: function (serie) {
      this.$buefy.dialog.confirm({
        title: 'Eliminar serie documental',
        message: `<div class="content has-text-danger">
          <h1 class="title has-text-danger">Atención</h1>
          <p>
            La acción que está por realizar tiene un efecto importante de las unidades compuesta que se instanciaron desde la serie
            <b>${serie.nombre}</b>
          </p>
          <p>De proceder con la eliminación, se <b>eliminarán</b> todas las unidades compuestas asociadas a este atributo.</p>
          <p><i class="fas fa-exclamation-triangle"></i>&nbsp;<b>IMPORTANTE:</b> Si existen unidades compuestas las cuales se han subido subunidades documentales a la misma, no podra eliminarse la serie. Deberá eliminar primero las unidades compuestas con subunidades documentales.</p>
          <p>
            Como alternativa puede cerrar la serie documental para que no se instancien mas unidades compuestas a partir de esta serie.
          </p>
        </div>
        <p>
          ¿Esta seguro que quiere <b>eliminar</b> la serie <b>${serie.nombre}</b>?
        </p>`,
        confirmText: 'Eliminar',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => this.deleteSerie(serie)
      })
    },
    openConfirmAbrir: function (serie) {
      this.$buefy.dialog.confirm({
        title: 'Abrir serie documental',
        message: `<p>Al abrir la serie documental, habilita la instanciación de nuevas unidades compuestas de la serie <b>${serie.nombre}</b></p>`,
        confirmText: 'Abrir serie',
        onConfirm: () => this.abrirSerie(serie)
      })
    },
    openConfirmCerrar: function (serie) {
      this.$buefy.dialog.confirm({
        title: 'Cerrar serie documental',
        message: `<p>Al cerrar la serie documental, deshabilita la instanciación de nuevas unidades compuestas de la serie <b>${serie.nombre}</b></p>`,
        confirmText: 'Cerrar serie',
        onConfirm: () => this.cerrarSerie(serie)
      })
    },
    deleteSerie: function (serie) {
      this.startLoading()
      this.$http.delete(`/serie-documental/${serie.id}`)
        .then(res => {
          this.$store.dispatch('organizacion/fetchSeriesDocumentales', this.idOrganization)
          this.$buefy.snackbar.open({
            message: 'La serie documental se ha eliminado exitosamente',
            type: 'is-success'
          })
        })
        .catch(error => {
          console.error('Fail', error)
          this.$buefy.snackbar.open({
            message: `Error al eliminar la serie documental: ${error.mensaje}`,
            type: 'is-danger'
          })
        })
        .finally(() => {
          this.stopLoading()
        })
    },
    abrirSerie: function (serie) {
      this.startLoading()
      this.$http.patch(`/serie-documental/${serie.id}`, { abierta: true })
        .then(res => {
          this.$store.dispatch('organizacion/fetchSeriesDocumentales', this.idOrganization)
          this.$buefy.snackbar.open({
            message: 'La serie documental se abrio exitosamente',
            type: 'is-success'
          })
        })
        .catch(error => {
          console.error('Fail', error)
          this.$buefy.snackbar.open({
            message: 'Error al abrir la serie documental',
            type: 'is-warning'
          })
        })
        .finally(() => {
          this.stopLoading()
        })
    },
    cerrarSerie: function (serie) {
      this.startLoading()
      this.$http.patch(`/serie-documental/${serie.id}`, { abierta: false })
        .then(res => {
          this.$store.dispatch('organizacion/fetchSeriesDocumentales', this.idOrganization)
          this.$buefy.snackbar.open({
            message: 'La serie documental se cerrado exitosamente',
            type: 'is-success'
          })
        })
        .catch(error => {
          console.error('Fail', error)
          this.$buefy.snackbar.open({
            message: 'Error al cerrar la serie documental',
            type: 'is-warning'
          })
        })
        .finally(() => {
          this.stopLoading()
        })
    }
  },
  computed: {
    seriesDoc: function () {
      return this.$store.state.organizacion.seriesDocumentales
    }
  }
}
</script>
