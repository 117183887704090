<template>
<div class="box p-4 has-background-white mb-0">

<nav class="level is-mobile">
  <!-- Left side -->
  <div class="level-left">
    <div v-if="estado != 'pendiente'" class="level-item">
      <a @click="$emit('descargar', 'recibo')" class="button is-primary">
        <i class="fas fa-download"></i>&nbsp;Descargar recibo
      </a>
    </div>
    <div v-else class="level-item">
      <a @click="$emit('descargar', 'recibo')" class="button is-primary">
        <i class="fas fa-download"></i>&nbsp;Descargar liquidación
      </a>
    </div>
    <div class="level-item">
      <a @click="$emit('preview')" class="button is-light is-hidden-touch">
        <i class="fas fa-glasses fa-fw"></i>&nbsp;Ver</a>
    </div>
    <div class="level-item">
      <a @click="$emit('print')" class="button is-light is-hidden-touch">
        <i class="fas fa-print fa-fw"></i>&nbsp;Imprimir</a>
    </div>
    <div class="level-item">
      <a @click="$emit('refresh')" class="button is-light">
        <i class="fas fa-sync fa-fw"></i>&nbsp;Recargar</a>
    </div>
    <!-- <div v-if="$store.getters.hasAnyRole(['empleador']) && estado == 'pendiente'" class="level-item">
      <a @click="$emit('eliminar')"  class="button is-light">
        <i class="fas fa-trash"></i>&nbsp;Eliminar
      </a>
    </div> -->
  </div>
  <div class="level-right" v-if="$store.getters.hasAnyRole(['empleador']) && estado == 'pendiente'">
    <div class="level-item">
      <b-dropdown  aria-role="list" position="is-bottom-left">
        <button class="button is-light" slot="trigger" slot-scope="{ active }">
            Otras acciones&nbsp;<i class="fas fa-fw" :class="active ? 'fa-chevron-up' : 'fa-chevron-down'"></i>
        </button>
        <b-dropdown-item aria-role="listitem" @click="$emit('preview')"><i class="fas fa-glasses fa-fw"></i>&nbsp;Ver</b-dropdown-item>
        <b-dropdown-item aria-role="listitem" @click="$emit('eliminar')" v-if="$store.getters.hasAnyRole(['empleador']) && estado == 'pendiente'" class="has-text-danger"><i class="fas fa-trash fa-fw"></i>&nbsp;Eliminar</b-dropdown-item>
      </b-dropdown>
    </div>
   </div>
</nav>
</div>
</template>

<script>
export default {
  props: ['estado']
}
</script>

<style lang="scss" scoped>

</style>
