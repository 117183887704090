<template>
  <div class="card">
    <div class="card-header">
        <p class="card-header-title"> Descargar resultado de búsqueda</p>
    </div>
    <div class="card-content">
      <b-field label="Nombre del archivo" class="mb-6">
        <div class="field has-addons">
          <p class="control is-expanded">
            <b-input type="text"
              v-model="searchResultName"
              @keyup.native.enter="downloadSearchResults"
              ref="searchInput"
              maxlength="100"
              placeholder="Ej: Documentos a ser eliminados (DD-MM-YYYY)'">
            </b-input>
            <span class="mt-0 pt-0 help">(Opcional) Nombre del archivo una vez descargado. Por defecto es 'ResultadoDeBusqueda.pdf'.</span>
            <span class="help has-text-danger" v-if="invalidNameMessage">{{invalidNameMessage}}</span>
          </p>
          <p class="control">
            <a class="button is-static">
              {{extension}}
            </a>
          </p>
        </div>
      </b-field>
      <div class="field has-addons mt-6">
        <p class="control is-expanded">
          <b-field label="Descripción" class="mb-0 pb-0">
            <b-input type="textarea"
                v-model="searchResultDescription"
                maxlength="250"
                placeholder="Ej: Documentos subidos a la plataforma a partir de la fecha DD-MM-YYYY que se encuentran dentro de la carpeta 'Actas'">
              </b-input>
            </b-field>
            <span class="mt-0 pt-0 help">(Opcional) Esta descripción será añadida dentro del documento una vez generado.</span>
        </p>
      </div>
    </div>
    <div class="field m-5">
      <div class="notification is-primary">
        <i class="fas fa-info-circle fa-fw"></i> Sólo podrá descargar hasta <b>300</b> documentos a la vez. Utilice los filtros para realizar una búsqueda más específica.
      </div>
    </div>
    <div class="card-footer">
        <a class="card-footer-item" type="button" @click="$parent.close()">Cancelar</a>
        <a v-if="!invalidName" class="card-footer-item has-text-weight-bold is-uppercase" @click.prevent="downloadSearchResults">Descargar</a>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      searchResultName: '',
      searchResultDescription: '',
      extension: '.pdf'
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.$refs.searchInput.focus()
    })
  },
  methods: {
    downloadSearchResults: function () {
      if (this.invalidName) {
        return
      }
      this.$emit('download-search-results', this.searchResultName, this.searchResultDescription)
      this.$parent.close()
    }
  },
  computed: {
    invalidName: function () {
      // if searchResultName contains invalid characters
      var regex = /[<>:;,?"*|\\/]+/
      return regex.test(this.searchResultName)
    },
    invalidNameMessage: function () {
      // if searchResultName contains invalid characters
      var regex = /[<>:;,?"*|\\/]+/
      if (regex.test(this.searchResultName)) {
        return 'El nombre del archivo no puede contener ninguno de los siguientes caracteres < > : ; , ? “ * | /'
      }
      return false
    }
  }
}
</script>
