<template>
<div class="modal-card">
    <header class="modal-card-head">
        <p class="modal-card-title is-800">Editar evento</p>
    </header>
    <div class="modal-card-body">
       <div class="field">
        <label class="label" :class="{'has-text-danger': errors.has('nombre','serie') }">Nombre del evento</label>
        <div class="control">
          <input
            v-model="nombre"
            name="nombre"
            class="input"
            placeholder="Requerido"
            data-vv-scope="serie"
            :class="{'is-danger': errors.has('nombre','serie') }"
            v-validate="{required:true}"
          >
        </div>
        <span
          class="help is-danger"
          v-if="errors.has('nombre','serie')"
        >{{errors.first('nombre','serie')}}</span>
      </div>
       <div class="field">
          <label class="label">Reestringir ejecución por area</label>
        </div>
        <div class="field">
          <b-checkbox v-model="habilitarRestringirAreas" :native-value="true">Habilitar restringir por areas</b-checkbox>
        </div>
        <div v-if="habilitarRestringirAreas">
          <h3 class="title is-6 ">Seleccione las areas que podran correr la acción</h3>
        <p class="subtitle is-7" v-if="areasSeleccionadas.length == 0"><i class="fas fa-info-circle fa-lg fa-fw"></i>&nbsp;No ha seleccionado ningun area en particular. Por lo tanto, todas las areas e individuos con privilegios pueden ejecutar el evento</p>
          <div class="notification" v-if="fetchedAreas">
            <b-checkbox v-model="areasSeleccionadas"
              v-for="area in areasDisponibles"
              :native-value="area.id"
              :key="area.id">
              {{area.nombre}}
            </b-checkbox>
        </div>
          <div v-if="fetchingAreas && !fetchedAreas">
            <p><i class="fas fa-sync fa-spin"></i>&nbsp;Cargando areas...</p>
          </div>
        </div>
    </div>
    <div class="modal-card-foot is-justify-content-flex-end">
      <button class="button is-dark is-outlined" type="button" @click="$parent.close()">Cancelar</button>
        <button class="button is-primary" type="button" @click="submit">Editar</button>
    </div>
  </div>
</template>

<script>
export default {
  props: ['resource', 'idOrganizacion'],
  data () {
    return {
      nombre: this.resource.nombre,
      habilitarRestringirAreas: false,
      fetchingAreas: false,
      fetchedAreas: false,
      areasDisponibles: [],
      areasSeleccionadas: []
    }
  },
  mounted: function () {
    this.fetchAreasDisponibles()
  },
  methods: {
    fetchAreasDisponibles: function () {
      this.fetchingAreas = true
      this.$http
        .get('/organizacion/' + this.idOrganizacion + '/area')
        .then(response => {
          this.areasDisponibles = response.data.data
          this.fetchedAreas = true
          this.fetchingAreas = false
          if (this.resource.areas_habilitadas != null) {
            this.syncAreasHabilitadas()
          }
        })
        .catch(error => {
          console.error('Fail', error)
          this.$buefy.snackbar.open({
            message: 'No se pudieron obtener las areas de la organización. Puede llegar a tener problemas en la creacion de acciones',
            type: 'is-danger'
          })
        })
    },
    syncAreasHabilitadas: function () {
      this.areasSeleccionadas = this.resource.areas_habilitadas
      this.habilitarRestringirAreas = true
    },
    submit: function () {
      this.$validator.validateAll().then(result => {
        if (!result) {
          this.$buefy.snackbar.open({
            message:
              'Hay errores en los campos de editar un evento la acción',
            type: 'is-danger'
          })
          return
        }
        const payload = {
          nombre: this.nombre,
          areas_habilitadas: this.areasSeleccionadas.length > 0 ? this.areasSeleccionadas : null
        }
        this.$emit('submit', this.resource, payload)
        this.$parent.close()
      })
    }
  },
  watch: {
    habilitarRestringirAreas: function (newVal, oldVal) {
      if (!newVal) this.areasSeleccionadas = []
    }
  }
}
</script>
