import OficialesRegistro from './OficialesRegistro'
import Certificados from './Certificados'

const routes = [
  {
    path: 'autoridad-certificante/oficiales-registro',
    component: OficialesRegistro,
    name: 'organizacionAutoridadCertificanteOficialesRegistro',
    props: true,
    meta: {
      requiresAuth: true,
      requiredRoles: ['lyris']
    }
  },
  {
    path: 'autoridad-certificante/certificados',
    component: Certificados,
    name: 'organizacionAutoridadCertificanteCertificados',
    props: true,
    meta: {
      requiresAuth: true,
      requiredRoles: ['lyris']
    }
  }
]

export default routes
