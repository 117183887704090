// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import Buefy from 'buefy'
import http from './http'
import store from './store'
import router from './router'
import globals from './globals'
import App from './App.vue'
import VueTheMask from 'vue-the-mask'
import esValidator from 'vee-validate/dist/locale/es'
import VeeValidate, {
  Validator
} from 'vee-validate'
// Localize takes the locale object as the second argument (optional) and merges it.
// Add Fontawesome
import ForgePlugin from '@/forge/forge.js'

import VueMoment from 'vue-moment'
const moment = require('moment')
require('moment/locale/es')

Vue.config.productionTip = false

Vue.prototype.$http = http
Vue.prototype.$eventHub = new Vue()

Vue.use(Buefy, {
  defaultIconPack: 'fas',
  defaultDialogConfirmText: 'OK',
  defaultDialogCancelText: 'Cancelar',
  defaultDayNames: ['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab'],
  defaultMonthNames: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']
})

Validator.localize('es', esValidator)
Vue.use(VueMoment, {
  moment
})
Vue.use(VueTheMask)
Vue.mixin(globals)
Vue.use(VeeValidate, {
  locale: 'es'
})
Vue.directive('autofocus', {
  inserted: function (el, binding) {
    if (el.tagName === 'INPUT') {
      el.focus()
    } else {
      el.querySelector('input').focus()
    }
  }
})
Vue.use(ForgePlugin)
/* eslint-disable no-new */
new Vue({
  http,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
