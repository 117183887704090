<template>
  <div class="card">
    <div class="card-content">
      <div class="columns is-mobile">
        <div class="column is-7-desktop">
          <h1 class="title is-3 has-text-primary">{{resource.nombre}}</h1>
          <h1 class="subtitle is-5">Tipo: {{estructura.nombre}}<span v-if="estructura.multiple" class="has-text-primary is-size-6">&nbsp;(Múltiple)</span></h1>
            <div class="buttons">
              <button class="button is-primary is-outlined" type="button" @click="descargar">
                <i class="fas fa-download"></i>&nbsp;Descargar
              </button>
              <button class="button is-dark is-outlined" type="button" @click="$parent.close()">
                <i class="fas fa-times"></i>&nbsp;Cerrar
              </button>
            </div>
          <informacion :subunidad="resource" class="is-size-7"></informacion>
        </div>
        <div class="column is-4-mobile is-5-desktop">
          <figure class="image">
            <a @click="previewModalActive = true" v-if="resource.preview_uri">
              <img :src="resource.preview_uri" alt="Previsualización" />
            </a>
            <img src="/statics/default-document.png" v-else alt="Previsualización" />
          </figure>
        </div>
        <b-modal :active.sync="previewModalActive">
          <figure class="image">
            <a :href="resource.preview_uri" target="_blank">
              <img :src="resource.preview_uri" alt="Previsualización" />
            </a>
          </figure>
        </b-modal>
      </div>
      <firmas :subunidad="resource"></firmas>
    </div>
  </div>
</template>

<script>
import Firmas from '@/components/documento/Firmas'
import Informacion from '@/components/documento/Informacion'

export default {
  props: ['resource', 'estructura'],
  data () {
    return {
      previewModalActive: false
    }
  },
  components: {
    Firmas,
    Informacion
  },
  methods: {
    descargar: function () {
      this.$emit('descargar', this.resource.id)
    }
  }
}
</script>
