
// Panel
import Repositorio from './Repositorio'

const BASE_PATH = '/repositorio'

const routes = [
  {
    path: BASE_PATH,
    name: 'repositorio',
    meta: {
      requiresAuth: true,
      requiredRoles: ['user', 'lyris']
    },
    component: Repositorio,
    props: true,
    children: [
      {
        path: ':idFolder*',
        name: 'repositorioFolder',
        component: Repositorio,
        props: true
      }
    ]
  }
]
export default routes
