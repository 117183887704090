
import http from '@/http'
import store from '@/store'

import UnidadCompuesta from './UnidadCompuesta'

const BASE_PATH = '/unidad-compuesta'

const routes = [
  {
    path: BASE_PATH + '/:id',
    name: 'unidad-compuesta',
    component: UnidadCompuesta,
    props: true,
    meta: {
      requiresAuth: true
    },
    children: [

    ],
    beforeEnter: (to, from, next) => {
      const orgId = store.getters.getUser.organization
      console.log('First time entering, getting unidad-compuesta')
      Promise.all([
        http.get('/unidad-compuesta/' + to.params.id),
        http.get('/documento-operacion'),
        http.get('/organizacion/' + orgId + '/area?size=100')
      ]).then(responses => {
        console.log('GET OK - Got unidad compuesta')
        store.commit('unidadCompuesta/unidadCompuesta', responses[0].data)
        console.log('GET OK - Got Operaciones Disponibles')
        store.commit('unidadCompuesta/operacionesDisponibles', responses[1].data)
        console.log('GET OK - Got Areas Organizaciones')
        store.commit('organizacion/areas', responses[2].data.data)
        next()
      }).catch(error => {
        console.log('Fetching data failed', error)
        next()
      })
    }
  }
]
export default routes
