<template>
  <section>
    <div class="content">
      <p>
        <b>Subunidad:</b>
        {{subunidad.nombre}} -
        <b>Formatos:</b>
        {{subunidad.formatos.join(',')}} -
        <b>Multiples:</b>
        {{subunidad.multiple ? 'Si' : 'No'}}
      </p>
    </div>
    <div class="field" v-if="subunidad.multiple">
      <label class="label">Seleccione el folio</label>
      <div class="control">
        <div class="select is-fullwidth">
          <select
            v-model="folio"
            name="Folio"
            v-validate="{required:true}"
            :class="{'is-danger': errors.has('Folio') }"
          >
            <option :value="null" disabled>- Seleccione un folio -</option>
            <option
              :value="numeroFolio"
              v-for="(numeroFolio, index) in foliosDisponibles"
              :key="index"
            >{{index+1}}</option>
          </select>
        </div>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <button class="button is-success is-fullwidth" @click="submit">
          <i class="fas fa-file-signature"></i>&nbsp;Firmar subunidad
        </button>
      </div>
      <div class="column">
        <button class="button is-dark is-fullwidth" @click="updateDocument">
          <i class="fas fa-sync"></i>&nbsp;Verificar firmado
        </button>
      </div>
    </div>
    <b-modal :active.sync="showFirmando" has-modal-card :canCancel="false">
      <div class="modal-card">
        <section class="modal-card-body has-text-centered">
          <h1 class="title is-4">
            <i class="fas fa-file-signature"></i>&nbsp;Firmando subunidad
          </h1>
          <h1 class="subtitle is-5 animated flash">Abriendo firmador</h1>
          <img src="/statics/signar-logo.svg" class="image signar-logo" alt>
          <div class="content">
            <p>Una vez que haya realizado la firma, Haca clic en "Recargar unidad compuesta" para reflejar los cambios en el sistema.</p>
            <p class="has-text-info is-size-7">
              NOTA: Si la descarga
              <u>NO</u> comenzó, verifique si su navegador está bloqueando los pop-ups.
              <br>Recomendamos habilitar los pop-ups para esta página.
            </p>
          </div>
          <button class="button is-primary is-fullwidth" @click.prevent="updateDocument()">
            <i class="fas fa-sync"></i>&nbsp;Recargar unidad compuesta
          </button>
        </section>
      </div>
    </b-modal>
  </section>
</template>

<script>
export default {
  props: ['accionId', 'parametros'],
  data () {
    return {
      folio: null,
      showFirmando: false
    }
  },
  methods: {
    submit: function () {
      this.$validator
        .validateAll()
        .then(result => {
          if (!result) {
            this.$buefy.snackbar.open({
              message: 'Hay errores en el formulario',
              type: 'is-danger'
            })
            return
          }
          if (this.subunidad.multiple && this.folio === null) {
            this.$buefy.snackbar.open({
              message: 'Debe seleccionar un folio',
              type: 'is-danger'
            })
          }
          const payload = {
            opcion: 'ejecuciones',
            parametros: {
              ejecuciones: [
                {
                  id: this.accionId
                }
              ]
            }
          }
          if (this.subunidad.multiple) {
            payload.parametros.ejecuciones[0].folio = this.folio
          }
          this.$store.commit('firmador/cleanPila')
          this.$store.dispatch('firmador/unload')
          this.$store.commit('firmador/setGoBack', { name: 'unidad-compuesta', params: { id: this.unidadCompuestaId } })
          this.$store.commit('firmador/setPayload', payload)
          this.$router.push({ name: 'launchFirmador' })
        })
        .catch(err => {
          console.error(err)
          this.$buefy.snackbar.open({
            message: 'Error interno.',
            type: 'is-danger'
          })
        })
    },
    updateDocument: function () {
      this.showFirmando = false
      this.$emit('updateDocument')
    }
  },
  computed: {
    subunidad: function () {
      return this.$store.state.unidadCompuesta.unidadCompuesta.serie_documental.estructura[
        this.parametros.subunidad
      ]
    },
    foliosDisponibles: function () {
      return this.$store.state.unidadCompuesta.unidadCompuesta.parametros.folios[
        this.parametros.subunidad
      ]
    },
    userId: function () {
      return this.$store.getters.getUser.id
    },
    unidadCompuestaId: function () {
      return this.$store.state.unidadCompuesta.unidadCompuesta.id
    }
  }
}
</script>

<style lang="scss" scoped>
.signar-logo {
  width: 100px;
  margin: 0 auto 15px;
}
</style>
