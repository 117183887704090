// Panel
import Error from './Error'
import Page404 from './Page404'
import Unauthorized from './Unauthorized'

// const BASE_PATH = '/panel'

const routes = [
  {
    path: '/error',
    name: 'error',
    component: Error
  },
  {
    path: '/unauthorized',
    name: '403',
    component: Unauthorized
  },
  {
    path: '*',
    name: '404',
    component: Page404
  }
]
export default routes
