<template>
  <div id="bread-container" class="mb-0 p-2 has-shadow has-background-info">
  <nav class="breadcrumb m-0 is-hidden-touch" :class="{'is-medium': size != 'is-small'}" aria-label="breadcrumbs">
    <ul class="">
      <li v-for="(ruta, index) in routes" :key="index">
        <router-link :to="root + routes.slice(0,index+1).join('/')" v-if="routes.length - 1 != index" class="has-text-white">
          <i class="fas fa-fw" :class="icon(index)"></i>&nbsp;{{ruta.length > 20 ? ruta.substring(0,20) + '...' : ruta}}
        </router-link>
        <a v-else class="has-text-white">
          <i class="fas fa-fw" :class="icon(index)"></i>&nbsp;{{ruta.length > 20 ? ruta.substring(0,20) + '...' : ruta}}
        </a>
      </li>
    </ul>
  </nav>
   <b-dropdown aria-role="list" class="is-hidden-desktop" style="margin-top: -1px;">
    <a slot="trigger" slot-scope="{ active }" class="has-text-white button-dropdown">
      <i class="far fa-fw" :class="{'fa-file': endsFile, 'fa-folder-open': !endsFile }" ></i>&nbsp;{{lastRoute}} <i class="fas" :class="{'fa-chevron-up': active, 'fa-chevron-down': !active}"></i>
    </a>
    <b-dropdown-item aria-role="listitem" has-link v-for="(ruta, index) in routes" :key="index">
      <a class="has-text-dark" @click="goToRoute(root + routes.slice(0,index+1).join('/'))" v-if="routes.length - 1 != index">
        <span :style="{'paddingLeft': `${index*10}px`}"></span><i class="far fa-fw" :class="icon(index)"></i>&nbsp;{{ruta.length > 20 ? ruta.substring(0,20) + '...' : ruta}}
      </a>
        <a v-else class="has-text-dark has-text-weight-bold">
        <span :style="{'paddingLeft': `${index*10}px`}"></span><i class="far fa-fw" :class="icon(index)"></i>&nbsp;{{ruta.length > 20 ? ruta.substring(0,20) + '...' : ruta}}
        </a>
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<script>
export default {
  props: {
    routes: {
      type: Array
    },
    endsFile: {
      type: Boolean
    },
    size: {
      type: String
    },
    root: {
      type: String,
      default: () => '/repositorio/'
    }
  },
  methods: {
    goToRoute (ruta) {
      console.log('caca')
      this.$router.push(ruta)
    },
    icon: function (index) {
      if (index === 0) {
        return 'fa-building'
      }
      if (this.routes.length - 1 === index && this.endsFile === true) {
        return 'fa-file'
      }
      return 'fa-folder-open'
    }
  },
  computed: {
    lastRoute () {
      return this.routes ? this.routes[this.routes.length - 1] : '?'
    }
  }
}
</script>

<style lang="scss" scoped>
nav.breadcrumb {
  // border-bottom-left-radius: 4px;
  // border-bottom-right-radius: 4px;
    font-size: 0.8rem;
  ul li:last-child a {
    font-weight: bold;
    color: $primary;
  }
}
#bread-container .button-dropdown{
    font-size: 0.8rem;
    font-weight: bold;
    margin-top: -2px
}
</style>
