<template>
  <div class="modal-card">
    <header class="modal-card-head">
        <p class="modal-card-title is-800">Editar acción</p>
    </header>
    <div class="modal-card-body">
       <div class="field">
        <label class="label">Descripción de la acción</label>
        <div class="control">
          <input
            v-model="descripcion"
            name="Descripcion de la acción"
            v-validate="{required: true}"
            class="input"
            :class="{'is-danger': errors.has('Descripcion de la acción') }"
          />
          <span class="help">Describa en detalle de que se trata la acción</span>
          <span
            class="help is-danger"
            v-if="errors.has('Descripcion de la acción')"
          >{{errors.first('Descripcion de la acción')}}</span>
        </div>
      </div>
    </div>
    <div class="modal-card-foot is-justify-content-flex-end">
      <button class="button is-dark is-outlined" type="button" @click="$parent.close()">Cancelar</button>
      <button class="button is-primary" type="button" @click="submit">Editar</button>
    </div>
  </div>
</template>

<script>
export default {
  props: ['evento', 'accion'],
  data () {
    return {
      descripcion: this.accion.descripcion
    }
  },
  methods: {
    submit: function () {
      this.$validator.validateAll().then(result => {
        if (!result) {
          this.$buefy.snackbar.open({
            message:
              'Hay errores en el campo de Descripcion de la acción',
            type: 'is-danger'
          })
          return
        }
        const payload = {
          descripcion: this.descripcion
        }
        this.$emit('submit', this.evento, this.accion, payload)
        this.$parent.close()
      })
    }
  }
}
</script>
