<template>
  <div class="column is-3 is-2-desktop is-hidden-touch">
    <div class="card sticky-column">
      <div class="card-content">
        <div class="pt-2 pb-4">
          <div class="has-text-centered is-size-3 has-text-weight-bold">
              <i class="fas fa-receipt"></i>&nbsp;{{selectedDocuments.length}}
          </div>
        </div>
        <p class="subtitle is-5 has-text-centered"> Documento{{selectedDocuments.length > 1 ? 's' : ''}} seleccionado{{selectedDocuments.length > 1 ? 's' : ''}}</p>
      </div>
      <hr class="m-0">
      <div class="card-content has-text-centered">
        <ul>
          <li class="my-2">
            <a @click="$emit('download')" class="has-text-info">
              <i class="fas fa-download fa-fw"></i>&nbsp;Descargar
            </a>
          </li>
          <li class="my-2">
          </li>
        </ul>
      </div>
      <div class="card-footer">
        <a @click="unselect()" class="has-text-dark card-footer-item">
          <i class="fas fa-times-circle fa-fw"></i>&nbsp;Limpiar
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    unselect () {
      this.$store.commit('otrosDocumentos/setSelected', [])
    }
  },
  computed: {
    selectedDocuments () {
      return this.$store.state.otrosDocumentos.selected
    }
  }
}
</script>

<style lang="scss" scoped>
.sticky-column{
  position: sticky;
  top: 10px;
  max-height: 95vh;
}
</style>
