<template>
<div class="column is-3-desktop is-2-fullhd is-hidden-touch">
  <div class="card sticky-column">
    <div class="card-content">
      <div class="pt-2 pb-4">
        <div class="has-text-centered is-size-3 has-text-weight-bold">
            <i class="far fa-copy"></i>&nbsp;{{selectedDocumentsIndex.length}}
        </div>
      </div>
      <!-- <div class="square mb-4">
        <div class="the-content is-size-2-desktop is-size-5-fullhd has-text-weight-bold">
          <i class="far fa-copy"></i>&nbsp;{{selectedDocumentsIndex.length}}
        </div>
      </div> -->
      <p class="subtitle is-5 has-text-centered"> archivos seleccionados</p>
    </div>
    <hr class="m-0">
    <div class="card-content has-text-centered">
      <ul>
        <li v-if="allFilesAreType('documento')"
            @click="canSignAll? addAllToPila() : openWarningSnackBar(messageSnackbar)"
            :class="canSignAll? 'my-2' : 'my-2 disabled-option'">
          <a :class="canSignAll? 'has-text-info' : 'disabled-option'">
            <i class="fas fa-plus fa-fw"></i>
            <i class="fas fa-inbox fa-fw"></i>&nbsp;Agregar para firmar
          </a>
        </li>
        <li v-if="allFilesAreType('documento')"
            @click="canDownloadAll? downloadAll() : openWarningSnackBar(messageSnackbar)"
            :class="canDownloadAll? 'my-2' : 'my-2 disabled-option'">
          <a :class="canDownloadAll? 'has-text-info' : 'disabled-option'">
            <i class="fas fa-download fa-fw"></i>&nbsp;Descargar
          </a>
        </li>
        <li @click="canMoveAll? moveAll() : openWarningSnackBar(messageSnackbar)"
            :class="canMoveAll? 'my-2' : 'my-2 disabled-option'">
          <a :class="canMoveAll? 'has-text-info' : 'disabled-option'">
            <i class="fas fa-dolly fa-fw"></i>&nbsp;Mover
          </a>
        </li>
        <li @click="canDeleteAll? deleteAll() : openWarningSnackBar(messageSnackbar)"
            :class="canDeleteAll? 'my-2' : 'my-2 disabled-option'">
          <a :class="canDeleteAll? 'has-text-danger' : 'disabled-option'">
            <i class="fas fa-trash fa-fw"></i>&nbsp;Eliminar
          </a>
        </li>
      </ul>
    </div>
    <div class="card-footer">
      <a @click="unselectDocuments()" class="has-text-dark card-footer-item">
        <i class="fas fa-times-circle fa-fw"></i>&nbsp;Limpiar
      </a>
    </div>
  </div>
  </div>
</template>

<script>
import constants from '@/constants'
import permissionsUtils from '@/mixins/permissionsUtils'

export default {
  mixins: [permissionsUtils],
  data () {
    return {
      constants: constants,

      minimumLevelPermissionTo: {
        download: 1,
        // downloadConfidential: 2, // Aun no se utiliza, pero se planea usar en el futuro
        sign: 3,
        move: 7,
        delete: 7
      }
    }
  },
  methods: {
    addAllToPila: function () {
      this.selectedDocuments.forEach(file => this.$store.commit('firmador/addPila', file))
    },
    downloadAll: function () {
      this.$buefy.snackbar.open({
        message:
          'Comprimiendo los documentos seleccionados para descargar, aguarde un momento',
        type: 'is-success'
      })
      const selectedIds = this.selectedDocuments.map(file => file.id)
      this.$http
        .get('/documento/' + selectedIds.join(',') + '/descarga')
        .then(response => {
          window.location.href = response.data.link
        })
        .catch(error => {
          console.error('Fail', error)
          this.$buefy.snackbar.open({
            message:
              'Ha ocurrido un error con la descarga',
            type: 'is-danger'
          })
        })
    },
    moveAll () {
      this.$emit('move-multiple-documents')
    },
    deleteAll () {
      this.$emit('delete-multiple-documents')
    },
    unselectDocuments () {
      this.$emit('unselect-documents')
    },
    allFilesAreType (type) {
      return this.selectedDocuments.every(file => type === file.tipo_documento_id)
    }
  },
  computed: {
    messageSnackbar () {
      return constants.STRINGS.ERRORS.DO_NOT_HAVE_MINIMUM_LEVEL_PERMISSION.OVER_SOME_DOCUMENTS
    },
    selectedDocuments () {
      return this.$store.state.repository.selectedDocuments
    },
    selectedDocumentsIndex () {
      return this.$store.state.repository.selectedDocumentsIndex
    },
    currentFolder: function () {
      return this.$store.state.repository.currentFolder
    },
    user: function () {
      return this.$store.getters.getUser
    },
    allArePDFs () {
      return this.selectedDocuments.every(file => file.mime === 'application/pdf')
    },
    canSignAll () {
      return (this.allArePDFs && this.hasPermissionLevelOver(this.selectedDocuments, this.minimumLevelPermissionTo.sign))
    },
    canDownloadAll () {
      return this.hasPermissionLevelOver(this.selectedDocuments, this.minimumLevelPermissionTo.download)
    },
    canMoveAll () {
      return this.hasPermissionLevelOver(this.selectedDocuments, this.minimumLevelPermissionTo.move)
    },
    canDeleteAll () {
      return this.hasPermissionLevelOver(this.selectedDocuments, this.minimumLevelPermissionTo.delete)
    }
  }
}
</script>

<style lang="scss" scoped>
.sticky-column{
  position: sticky;
  top: 10px;
  max-height: 95vh;
  overflow-y: scroll;
}
.disabled-option {
  cursor: not-allowed;
  color: #808080
}
</style>
