// Panel

import Administracion from './Administracion'
import Dashboard from './Dashboard'
import ConfigurarDemo from './ConfigurarDemo'
import ListarAspectosGlobales from './aspectos/Listado'
import CrearAspectosGlobales from './aspectos/Crear'
import EditarAspectosGlobales from './aspectos/Editar'
import OrganizacionesListado from './organizaciones/Listado'
import OrganizacionCrear from './organizaciones/Crear'

const BASE_PATH = '/admin'

const routes = [
  {
    path: BASE_PATH,
    component: Administracion,
    children: [
      {
        path: '',
        component: Dashboard,
        name: 'adminDashboard',
        meta: {
          requiresAuth: true,
          requiredRoles: ['lyris', 'lyris-soporte']
        }
      },
      {
        path: 'organizaciones',
        component: OrganizacionesListado,
        name: 'adminOrganizacionesListado',
        meta: {
          requiresAuth: true,
          requiredRoles: ['lyris', 'lyris-soporte']
        }
      },
      {
        path: 'crear-organizacion',
        component: OrganizacionCrear,
        name: 'adminOrganizacionCrear',
        meta: {
          requiresAuth: true,
          requiredRoles: ['lyris']
        }
      },
      {
        path: 'configurar-demo',
        component: ConfigurarDemo,
        name: 'lyrisConfigurarDemo',
        meta: {
          requiresAuth: true,
          requiredRoles: ['lyris', 'lyris-soporte']
        }
      },
      {
        path: 'aspectos-globales',
        component: ListarAspectosGlobales,
        name: 'adminAspectosGlobalesListado',
        meta: {
          requiresAuth: true,
          requiredRoles: ['lyris', 'lyris-soporte']
        }
      },
      {
        path: 'aspectos-globales/crear',
        component: CrearAspectosGlobales,
        name: 'adminAspectosGlobalesCrear',
        meta: {
          requiresAuth: true,
          requiredRoles: ['lyris', 'lyris-soporte']
        }
      },
      {
        path: 'aspectos-globales/:idAspecto/editar',
        component: EditarAspectosGlobales,
        name: 'adminAspectosGlobalesEditar',
        props: true,
        meta: {
          requiresAuth: true,
          requiredRoles: ['lyris', 'lyris-soporte']
        }
      }
    ]
  }
]
export default routes
