<template>
  <div class="box">
      <span class="tag is-pulled-right is-success" v-if="accion.finalizada">Hecho</span>
      <span class="tag is-pulled-right is-warning" v-else>Pendiente</span>
      <h1 class="title is-6 has-text-primary">
        <i class="fas fa-info-circle"></i>&nbsp;
        <span class>{{accion.documento_accion.descripcion}}</span>
      </h1>
      <p class="subtitle is-6">{{getOperacion(accion.documento_accion.documento_operacion_id).nombre}}</p>
      <anexar-subunidad
        v-if="isAnexarSubunidad && !accion.finalizada"
        :accion-id="accion.id"
        :parametros="accion.documento_accion.parametros"
        @updateDocument="updateDocument"
      ></anexar-subunidad>
      <firmar-pdf-subunidad
        v-if="isFirmarPdfSubunidad && !accion.finalizada"
        :accion-id="accion.id"
        :parametros="accion.documento_accion.parametros"
        @updateDocument="updateDocument"
      >
      </firmar-pdf-subunidad>
      <cargar-aspecto-compuesta
        v-if="isCargarAspectoCompuesta && !accion.finalizada"
        :accion-id="accion.id"
        :parametros="accion.documento_accion.parametros"
        @updateDocument="updateDocument"
      >
      </cargar-aspecto-compuesta>
  </div>
</template>

<script>
import AnexarSubunidad from './acciones/AnexarSubunidad.vue'
import FirmarPdfSubunidad from './acciones/FirmarPdfSubunidad.vue'
import CargarAspectoCompuesta from './acciones/CargarAspectoCompuesta.vue'
export default {
  props: ['accion'],
  components: {
    AnexarSubunidad,
    FirmarPdfSubunidad,
    CargarAspectoCompuesta
  },
  methods: {
    getOperacion: function (id) {
      return this.$store.getters['unidadCompuesta/getOperacion'](id)
    },
    updateDocument: function () {
      this.$emit('updateDocument')
    }
  },
  computed: {
    isAnexarSubunidad: function () {
      return this.accion.documento_accion.documento_operacion_id === 'anexarSubunidad'
    },
    isFirmarPdfSubunidad: function () {
      return this.accion.documento_accion.documento_operacion_id === 'firmarPdfSubunidad'
    },
    isCargarAspectoCompuesta: function () {
      return this.accion.documento_accion.documento_operacion_id === 'cargarAspectoCompuesta'
    }
  }
}
</script>

<style>
</style>
