<template>
<div class="box p-4 has-background-white mb-0">

<nav class="level is-mobile">
  <!-- Left side -->
  <div class="level-left">
    <div class="level-item is-hidden-mobile" v-if="isSignable">
      <a @click="$emit('addpila')" class="button is-primary">
        <i class="fas fa-plus fa-fw"></i>
        <i class="fas fa-inbox fa-fw"></i>&nbsp;Agregar para firmar</a>
    </div>
    <div class="level-item">
      <a @click="$emit('descargar')" v-if="nivel >= 1" class="button is-light">
        <i class="fas fa-download fa-fw"></i>&nbsp;Descargar</a>
    </div>
    <div class="level-item">
      <a @click="$emit('recargar')" class="button is-light">
        <i class="fas fa-sync fa-fw"></i>&nbsp;Recargar</a>
    </div>
  </div>
  <div class="level-right">
    <div class="level-item">
       <b-dropdown  aria-role="list" position="is-bottom-left">
        <button class="button is-light" slot="trigger" slot-scope="{ active }">
            Otras acciones&nbsp;<i class="fas fa-fw" :class="active ? 'fa-chevron-up' : 'fa-chevron-down'"></i>
        </button>
        <b-dropdown-item aria-role="listitem" @click="$emit('addpila')" v-show="isSignable" class="is-hidden-desktop has-text-primary has-text-weight-bold"><i class="fas fa-plus fa-fw"></i><i class="fas fa-inbox fa-fw"></i>&nbsp;Agregar para firmar</b-dropdown-item>
        <b-dropdown-item aria-role="listitem" @click="$emit('moveDocument')" class="" v-if="nivel >= 6" ><i class="fas fa-dolly fa-fw"></i>&nbsp;Mover</b-dropdown-item>
        <b-dropdown-item aria-role="listitem" @click="$emit('renameDocument')" class="" v-if="nivel >= 6" ><i class="fas fa-terminal fa-fw"></i>&nbsp;Renombrar</b-dropdown-item>
        <b-dropdown-item aria-role="listitem" @click="$emit('side-by-side')" v-show="canPreview" class="is-hidden-touch"><i class="fas fa-columns fa-fw"></i>&nbsp;Lado a lado</b-dropdown-item>
        <b-dropdown-item aria-role="listitem" @click="$emit('print')" v-show="canPreview"><i class="fas fa-print fa-fw"></i>&nbsp;Imprimir</b-dropdown-item>
        <b-dropdown-item aria-role="listitem" @click="$emit('deleteDocument')" v-if="canDelete" class="has-text-danger"><i class="fas fa-trash fa-fw"></i>&nbsp;Eliminar</b-dropdown-item>
    </b-dropdown>
    </div>
  </div>
</nav>
</div>
</template>

<script>
export default {
  data () {
    return {
      showDeleteModal: false,
      mimesTypesForPreview: [
        'application/pdf',
        'application/json',
        'image/png',
        'image/jpeg',
        'image/webp'
      ],
      mimesTypesForPrint: [
        'application/pdf',
        'application/json',
        'image/png',
        'image/jpeg',
        'image/webp'
      ]
    }
  },
  computed: {
    documento: function () {
      return this.$store.state.document.document
    },
    user: function () {
      return this.$store.getters.getUser
    },
    nivel: function () {
      const miPermiso = this.documento.permisos.find(x => {
        return x.usuario && x.usuario.id === this.user.id
      })
      return miPermiso && miPermiso.nivel
    },
    canPreview: function () {
      return this.mimesTypesForPreview.includes(this.documento.mime)
    },
    canPrint: function () {
      return this.mimesTypesForPrint.includes(this.documento.mime)
    },
    canDelete: function () {
      return ((this.nivel >= 7 && this.isVersionable) || this.$store.getters.hasAnyRole('lyris'))
    },
    isSignable: function () {
      return (this.documento.mime === 'application/pdf' && this.isVersionable)
    },
    isVersionable: function () {
      return (this.documento.tipo_documento_id !== 'recibo' && this.documento.tipo_documento_id !== 'otroDocumento')
    }
  }
}
</script>
