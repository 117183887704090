<template>
  <div class="modal-card">
    <div class="modal-card-body has-text-centered">
        <img src="/statics/signar-logo.svg" class="image is-centered my-4" width="100" alt>
      <h1 class="title is-4">Abriendo firmador</h1>
      <div class="content">

      <p>Si el firmador
        <u>NO</u> se ejecutó, verifique si su navegador está bloqueando los pop-ups, deshabilitelos y vuelva a intentarlo
      </p>
      <p>
        <b>Recomendamos habilitar los pop-ups en Signar®</b>
      </p>
      <p v-if="type === 'current'">¿No cuenta con Signar® Firmador?<br><a href="https://firmador.lyris.com.ar" target="_blank"><i class="fas fa-download"></i>&nbsp;Descárguelo aquí</a></p>
      </div>
      <div class="buttons is-centered">
        <a @click="$parent.close()" class="button is-dark">
          Cerrar
        </a>
        <a @click="reload" class="button is-primary">
          <i class="fas fa-redo"></i>&nbsp;Recargar listado
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['type'],
  methods: {
    reload: function () {
      this.$emit('reload')
      this.$parent.close()
    }
  }
}
</script>
