<template>
  <router-view @updateResource="updateSerie"></router-view>
</template>

<script>
// import http from '@/http'
import store from '@/store'

export default {
  props: ['idSerie'],
  methods: {
    updateSerie: function () {
      this.startLoading()
      this.$store.dispatch('serieDocumental/fetchSerieDocumental', this.idSerie)
        .catch(error => {
          console.error('Fetching data failed', error)
        })
        .finally(() => {
          this.stopLoading()
        })
    }
  },
  beforeRouteUpdate: (to, from, next) => {
    // Save the next function so its visible for the promise..
    // by default this is pased to the promise.
    if (to.params.idSerie === from.params.idSerie) {
      console.info('[ROUTER] No need to fetch, already fetched')
      next()
    } else {
      console.info('[ROUTER] Updated route - Getting serie data')
      Promise.all([
        store.dispatch('serieDocumental/fetchSerieDocumental', to.params.idSerie),
        store.dispatch('signar/fetchAspectosGlobales'),
        store.dispatch('organizacion/fetchAspectos', to.params.idOrganization),
        store.dispatch('organizacion/fetchAreas', to.params.idOrganization)
      ])
        .then(responses => {
          console.info('[ROUTER] Data fetch success. Continue to route')
          next()
        })
        .catch(error => {
          console.error('[ROUTER] Fetching data failed', error)
          next({ name: 'error' })
        })
    }
  }

}
</script>
