<template>
  <div class="box p-4 has-background-white mb-0">

<nav class="level is-mobile">
  <div class="level-left">
    <div class="level-item">
      <a @click="$emit('download')" class="button is-primary">
        <i class="fas fa-download"></i>&nbsp;Descargar {{ this.getTipoOtroDocumento }}
      </a>
    </div>
    <div class="level-item">
      <a @click="$emit('preview')" class="button is-light is-hidden-touch">
        <i class="fas fa-glasses fa-fw"></i>&nbsp;Ver</a>
    </div>
    <div class="level-item">
      <a @click="$emit('print')" class="button is-light is-hidden-touch">
        <i class="fas fa-print fa-fw"></i>&nbsp;Imprimir</a>
    </div>
    <div class="level-item">
      <a @click="$emit('refresh')" class="button is-light">
        <i class="fas fa-sync fa-fw"></i>&nbsp;Recargar</a>
    </div>
  </div>
  <div class="level-right" v-if="this.hasAdminOrLyrisRole">
    <div class="level-item">
      <b-dropdown  aria-role="list" position="is-bottom-left">
        <button class="button is-light" slot="trigger" slot-scope="{ active }">
            Otras acciones&nbsp;<i class="fas fa-fw" :class="active ? 'fa-chevron-up' : 'fa-chevron-down'"></i>
        </button>
        <b-dropdown-item aria-role="listitem" @click="$emit('preview')"><i class="fas fa-glasses fa-fw"></i>&nbsp;Ver</b-dropdown-item>
        <b-dropdown-item aria-role="listitem" @click="$emit('deleteDocument')" class="has-text-danger"><i class="fas fa-trash fa-fw"></i>&nbsp;Eliminar</b-dropdown-item>
      </b-dropdown>
    </div>
    </div>
</nav>
</div>
</template>

<script>
import Constants from '../../constants'

export default {
  data () {
    return {
      Constants: Constants
    }
  },
  computed: {
    getTipoOtroDocumento: function () {
      return (this.otroDocumento.tipo_otro_documento.id === 'etc') ? Constants.STRINGS.COMMON_WORDS.DOCUMENT_LOWERCASE : this.otroDocumento.tipo_otro_documento.nombre.toLowerCase()
    },
    hasAdminOrLyrisRole: function () {
      return this.$store.getters.hasAnyRole([Constants.STRINGS.ROLES.ADMINISTRADOR_DE_OTROS_DOCUMENTOS.ID, Constants.STRINGS.ROLES.LYRIS.ID])
    },
    isPendingStatus: function () {
      return this.otroDocumento.estado === Constants.STRINGS.DOCUMENTS.STATUS.PENDING_SIGNATURE
    },
    otroDocumento: function () {
      return this.$store.state.otroDocumento.otroDocumento
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
