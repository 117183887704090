import { SnackbarProgrammatic as Snackbar } from 'buefy'

const firmadorModule = {
  namespaced: true,
  state: {
    firmadorSelected: null,
    payload: {},
    goBack: null,
    pila: []
  },
  mutations: {
    setFirmadorSelected (state, firmador) {
      state.firmadorSelected = firmador
    },
    setPayload (state, payload) {
      state.payload = payload
    },
    setGoBack (state, goBack) {
      state.goBack = goBack
    },
    unload (state) {
      state.payload = {}
      state.goBack = null
    },
    unloadPila (state) {
      state.pila = []
    },
    addPila (state, doc) {
      const found = state.pila.find((element) => element.id === doc.id)
      if (!found) state.pila.push(doc)
      else {
        Snackbar.open({
          message: 'El documento ya se encuentra en la pila',
          type: 'is-warning'
        })
      }
    },
    removePila (state, idToRemove) {
      state.pila = state.pila.filter(doc => doc.id !== idToRemove)
    },
    cleanPila (state) {
      state.pila = []
    },
    setPila (state, pila) {
      state.pila = pila
    }
  },
  getters: {},
  actions: {
    unload (context) {
      context.commit('unload')
    },
    unloadPila (context) {
      context.commit('unloadPila')
    }
  }
}

export default firmadorModule
