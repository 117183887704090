// import http from '@/http'
import store from '@/store'

import SerieDocumental from './SerieDocumental'
import SerieDocumentalCrear from './Crear'
import SerieDocumentalVer from './Ver'
import SerieDocumentalEditar from './Editar'
import SerieDocumentalListado from './Listado'

const routes = [
  {
    path: 'serie-documental',
    component: SerieDocumentalListado,
    name: 'organizacionSerieDocumental',
    props: true,
    meta: {
      requiresAuth: true,
      requiredRoles: ['lyris', 'admin', 'serie-admin', 'lyris-soporte']
    },
    beforeEnter: (to, from, next) => {
      console.info('[ROUTER] First time entering:', to.path)
      Promise.all([
        store.dispatch('organizacion/fetchSeriesDocumentales', to.params.idOrganization)
      ])
        .then(responses => {
          console.info('[ROUTER] Data fetch success. Continue to route')
          next()
        })
        .catch(error => {
          console.error('[ROUTER] Data fetch failed', error)
          next({ name: 'error' })
        })
    }
  },
  {
    path: 'serie-documental/crear',
    component: SerieDocumentalCrear,
    name: 'organizacionCrearSerieDocumental',
    props: true,
    meta: {
      requiresAuth: true,
      requiredRoles: ['lyris', 'admin', 'serie-admin', 'lyris-soporte']
    }
  },
  {
    path: 'serie-documental/:idSerie',
    component: SerieDocumental,
    props: true,
    meta: {
      requiresAuth: true,
      requiredRoles: ['lyris', 'admin', 'serie-admin', 'lyris-soporte']
    },
    children: [
      {
        path: '',
        component: SerieDocumentalVer,
        name: 'organizacionVerSerieDocumental',
        props: true,
        meta: {
          requiresAuth: true,
          requiredRoles: ['lyris', 'admin', 'serie-admin', 'lyris-soporte']
        }
      },
      {
        path: 'editar',
        component: SerieDocumentalEditar,
        name: 'organizacionEditarSerieDocumental',
        props: true,
        meta: {
          requiresAuth: true,
          requiredRoles: ['lyris', 'admin', 'serie-admin', 'lyris-soporte']
        },
        beforeEnter: (to, from, next) => {
          console.info('[ROUTER] First time entering:', to.path)
          Promise.all([
            store.dispatch('signar/fetchOperacionesDisponibles')
          ])
            .then(responses => {
              console.info('[ROUTER] Data fetch success. Continue to route')
              next()
            })
            .catch(error => {
              console.error('[ROUTER] Data fetch failed', error)
              next({ name: 'error' })
            })
        }
      }
    ],
    beforeEnter: (to, from, next) => {
      console.info('[ROUTER] First time entering:', to.path)
      Promise.all([
        store.dispatch('serieDocumental/fetchSerieDocumental', to.params.idSerie),
        store.dispatch('signar/fetchAspectosGlobales'),
        store.dispatch('organizacion/fetchAspectos', to.params.idOrganization),
        store.dispatch('organizacion/fetchAreas', to.params.idOrganization)
      ])
        .then(responses => {
          console.info('[ROUTER] Data fetch success. Continue to route')
          next()
        })
        .catch(error => {
          console.error('[ROUTER] Data fetch failed', error)
          next({ name: 'error' })
        })
    }
  }
  // children: [
  //   {
  //     path: '',
  //     component: SerieDocumentalListado,
  //     name: 'organizacionSerieDocumental',
  //     props: true,
  //     meta: {
  //       requiresAuth: true,
  //       requiredRoles: ['lyris', 'admin', 'serie-admin']
  //     }
  //   },
  //   {
  //     path: 'crear',
  //     component: SerieDocumentalCrear,
  //     name: 'organizacionCrearSerieDocumental',
  //     props: true,
  //     meta: {
  //       requiresAuth: true,
  //       requiredRoles: ['lyris', 'admin', 'serie-admin']
  //     }
  //   },
  //   {
  //     path: ':idSerie',
  //     component: SerieDocumentalVer,
  //     name: 'organizacionVerSerieDocumental',
  //     props: true,
  //     meta: {
  //       requiresAuth: true,
  //       requiredRoles: ['lyris', 'admin', 'serie-admin']
  //     },
  //     children: [
  //       {
  //         path: 'editar',
  //         component: SerieDocumentalEditar,
  //         name: 'organizacionEditarSerieDocumental',
  //         props: true,
  //         meta: {
  //           requiresAuth: true,
  //           requiredRoles: ['lyris', 'admin', 'serie-admin']
  //         }
  //       }
  //     ],
  //     beforeEnter: (to, from, next) => {
  //       console.log('TEST - Get Serie')
  //       console.log(to.params)
  //       Promise.all([
  //         http.get(`/serie-documental/${to.params.idSerie}`)
  //       ])
  //         .then(responses => {
  //           console.log('GET OK - Got Serie')
  //           console.log('serie')
  //           next()
  //         })
  //         .catch(error => {
  //           console.log('Fetching data serie failed.', error)
  //           next({ name: 'error' })
  //         })
  //     }
  //   }

  //   ]
  // }
  // {
  //   path: 'serie-documental',
  //   component: SerieDocumental,
  //   // name: 'organizacionSerieDocumental',
  //   props: true,
  //   meta: {
  //     requiresAuth: true,
  //     requiredRoles: ['lyris', 'admin', 'serie-admin']
  //   },
  //   children: [
  //     {
  //       path: '',
  //       component: SerieDocumentalListado,
  //       name: 'organizacionSerieDocumental',
  //       props: true,
  //       meta: {
  //         requiresAuth: true,
  //         requiredRoles: ['lyris', 'admin', 'serie-admin']
  //       }
  //     },
  //     {
  //       path: 'crear',
  //       component: SerieDocumentalCrear,
  //       name: 'organizacionCrearSerieDocumental',
  //       props: true,
  //       meta: {
  //         requiresAuth: true,
  //         requiredRoles: ['lyris', 'admin', 'serie-admin']
  //       }
  //     },
  //     {
  //       path: ':idSerie',
  //       component: SerieDocumentalVer,
  //       name: 'organizacionVerSerieDocumental',
  //       props: true,
  //       meta: {
  //         requiresAuth: true,
  //         requiredRoles: ['lyris', 'admin', 'serie-admin']
  //       },
  //       children: [
  //         {
  //           path: 'editar',
  //           component: SerieDocumentalEditar,
  //           name: 'organizacionEditarSerieDocumental',
  //           props: true,
  //           meta: {
  //             requiresAuth: true,
  //             requiredRoles: ['lyris', 'admin', 'serie-admin']
  //           }
  //         }
  //       ],
  //       beforeEnter: (to, from, next) => {
  //         console.log('TEST - Get Serie')
  //         console.log(to.params)
  //         Promise.all([
  //           http.get(`/serie-documental/${to.params.idSerie}`)
  //         ])
  //           .then(responses => {
  //             console.log('GET OK - Got Serie')
  //             console.log('serie')
  //             next()
  //           })
  //           .catch(error => {
  //             console.log('Fetching data serie failed.', error)
  //             next({ name: 'error' })
  //           })
  //       }
  //     }

  //   ]
  // }
  // {
  //   path: 'serie-documental/crear',
  //   component: SerieDocumentalCrear,
  //   name: 'organizacionCrearSerieDocumental',
  //   props: true,
  //   meta: {
  //     requiresAuth: true,
  //     requiredRoles: ['lyris', 'admin', 'serie-admin']
  //   }
  // },
  // {
  //   path: 'serie-documental/:idSerie',
  //   component: SerieDocumentalVer,
  //   name: 'organizacionVerSerieDocumental',
  //   props: true,
  //   meta: {
  //     requiresAuth: true,
  //     requiredRoles: ['lyris', 'admin', 'serie-admin']
  //   },
  //   beforeEnter: (to, from, next) => {
  //     console.log('TEST - Going to Serie')
  //     console.log(to.params)
  //     Promise.all([
  //       http.get(`/serie-documental/${to.params.idSerie}`)
  //     ])
  //       .then(responses => {
  //         // store.commit('setOrganizacion', response.data)
  //         // store.commit('organizacion/organizacion', responses[0].data)
  //         // store.commit('organizacion/areas', responses[1].data)
  //         // store.commit('organizacion/roles', responses[2].data)
  //         console.log('GET OK - Got Serie')
  //         console.log('serie')
  //         next()
  //       })
  //       .catch(error => {
  //         console.log('Fetching data serie failed.', error)
  //         next({ name: 'error' })
  //       })
  //     // http
  //     //   .get('/organizacion/' + to.params.idOrganization)
  //     //   .then(response => {
  //     //     // store.commit('setOrganizacion', response.data)
  //     //     store.commit('organizacion/organizacion', response.data)
  //     //     console.log('GET OK - Got organizacion')
  //     //     next()
  //     //   })
  //     //   .catch(error => {
  //     //     console.log('Fetching data failed', error)
  //     //     next({ name: 'error' })
  //     //   })
  //   }
  // },
  // {
  //   path: 'serie-documental/:idSerie/editar',
  //   component: SerieDocumentalEditar,
  //   name: 'organizacionEditarSerieDocumental',
  //   props: true,
  //   meta: {
  //     requiresAuth: true,
  //     requiredRoles: ['lyris', 'admin', 'serie-admin']
  //   }
  // }
]

export default routes
